<template>
  <div class="search-input" :class="{ open }">
    <transition name="fade" mode="out-in">
      <div class="backdrop" id="global-search-backdrop" v-if="open" @click.self="onClickOutside"></div>
    </transition>

    <div class="dropdown-container">
      <!-- input -->
      <div class="input-container">
        <icon icon="search" class="search-icon" />
        <input
          id="global-search-input"
          ref="input"
          type="text"
          maxlength="100"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          class="input form-control"
          :placeholder="placeholder"
          aria-label="Global Search"
          :value="value"
          @input="onInputChange"
          @click="onInputClick"
          @focus="onInputClick"
          @blur="onInputBlur"
        />
        <div class="search-control" v-if="open">
          <btn
            class="icon-clear-container"
            :class="{ 'help-guide-dev': $store.getters['auth/roles'].includes('DEVELOPER') }"
            v-if="$h.truthy(value)"
            variant="outline-circle"
            @click="onClear"
          >Clear</btn>
          <btn
            v-if="['DEVELOPER', 'ADMINISTRATOR', 'RESEARCHER_ACCRUAL', 'RESEARCHER_TRIAL'].some((role) => role == $store.getters['auth/roles'])"
            v-tooltip="'Open Help Guide'"
            id="global-search-help"
            class="global-search-help-guide"
            aria-label="Help Guide"
            variant="outline-circle"
            :disabled="disableHelp"
            @click="openHelpGuide"
          >
            <icon icon="question" />
          </btn>
        </div>
      </div>

      <!-- slot content -->
      <div class="slot-container">
        <transition name="slide-down" mode="out-in">
          <div class="slot" v-if="open">
            <slot />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { startIntro } from "@/services/IntroService";
import globalSearchMenuIntroSteps from "@/steps/components/globalSearch";
import globalSearchResultsIntroSteps from "@/steps/components/globalSearchResults";

export default {
  name: "search-input",
  props: {
    value: String,
    bindOpen: Boolean,
    disableHelp: Boolean
  },
  data() {
    return {
      open: false,
      globalSearchMenuIntroSteps,
      globalSearchResultsIntroSteps
    };
  },
  mounted() {
    document.onkeydown = evt => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.onClickOutside();
      }
    };
  },
  computed: {
    activeContainer() {
      return this.$store.getters["settings/activeContainer"];
    },
    placeholder() {
      return this.$h.truthy(this.activeContainer)
        ? `Search the ${this.activeContainer.name}`
        : "Search for anything";
    }
  },
  methods: {
    onInputChange(event) {
      this.$emit("input", this.$h.get(event, "target.value", ""));
    },
    onInputClick() {
      this.open = true;
    },
    onInputBlur() {
      if (this.$h.truthy(this.value)) {
        this.$store.dispatch("search/addRecentSearch", this.value);
      }
    },
    onClickOutside() {
      this.open = false;
      this.$refs.input.blur();
    },
    onClear() {
      this.$emit("input", "");
      this.$refs.input.focus();
    },
    openHelpGuide() {
      if (this.$h.truthy(this.value)) {
        this.startIntro(this.globalSearchResultsIntroSteps);
      } else {
        this.startIntro(this.globalSearchMenuIntroSteps);
      }
    },
    startIntro
  },
  watch: {
    bindOpen(open) {
      if (!open) {
        this.open = false;
      }
    },
    open(open) {
      this.$emit("update:bindOpen", open);
      document.body.className = open ? "hide-overflow" : "";

      if (open) {
        let input = document.getElementById("global-search-input");
        setTimeout(() => {
          input.select();
        }, 10);
      }
    }
  },
  beforeDestroy() {
    document.onkeydown = null;
  }
};
</script>

<style lang="scss" scoped>
@import "src/styles/component";

$search-width: 215px;
$search-width-open: 590px;
$trans-time: 0.3s;

.search-input {
  width: $search-width;
  height: auto;
  position: relative;
  transition: width $trans-time ease;
}
.dropdown-container {
  position: absolute;
  top: -1.1rem;
  width: $search-width;
  transition: width $trans-time ease;
  z-index: 1;
}
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: fade-out(black, 0.5);
  z-index: 99999;
}

// input-container
.input-container {
  position: relative;
}
.search-icon {
  position: absolute;
  transform: translate(0.75rem, 0.65rem);
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 1rem;
  transition: transform $trans-time ease;
}
.input {
  background-color: transparent;
  width: 100%;
  height: 36px;
  font-size: 0.9rem;
  border-radius: 25px;
  padding-left: 2.25rem;
  color: white;
  transition: padding-left $trans-time ease, background-color 0.1s ease,
    height $trans-time ease;

  &::placeholder {
    color: hsla(0, 0%, 100%, 0.7);
    font-weight: $thin;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.search-control {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  display: flex;
  align-items: center;

  animation: easein 1s ease;

  @keyframes easein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.btn.icon-clear-container,
.btn.icon-close-container {
  transform: scale(0.9);
  margin: 0;
}
.btn.icon-clear-container {
  width: auto;
  border-radius: 25px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.1rem;
  margin-right: -0.5rem;
  color: $link;
  border-color: transparent;

  &.help-guide-dev {
    margin-right: 0;
  }
}

// slot-container
.slot-container {
  background-color: white;
  border-radius: 0 0 $border-radius-sm $border-radius-sm;
  pointer-events: none;

  max-height: 0px;

  z-index: 999;
  overflow: hidden;
  opacity: 0;
  border-top: solid thin $n3;
  transition: max-height 0.75s ease;

  .slot {
    position: relative;
    overflow: hidden;
  }
}

///////////////
// open
.search-input.open {
  width: $search-width-open;

  .dropdown-container {
    width: $search-width-open;
    box-shadow: $popover-shadow;
    border-radius: $border-radius-sm;
    z-index: 999999;
  }
  .search-icon {
    color: $text;
    font-size: 1.1rem;
    transform: translate(1.25rem, 1.2rem);
  }
  .input {
    height: 55px;
    color: $text;
    background-color: white;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    font-size: 1.1rem;
    padding-top: 0.5rem;
    padding-left: 3rem;
    padding-right: 10rem;
    font-weight: $regular;

    &::placeholder {
      color: fade-out($text, 0.5);
    }
  }
  .slot-container {
    max-height: 83vh;
    opacity: 1;
  }
}
.search-input:not(.open) {
  .input {
    cursor: default;
  }
}
.search-input:not(.open):hover {
  .input {
    background-color: #fff;
    color: $text;

    &::placeholder {
      color: fade-out($text, 0.5);
    }
  }
  .search-icon {
    color: $text;
  }
}

@keyframes slot {
  0% {
    width: $search-width;
    margin-top: -18px;
    border-radius: 0 0 25px 25px;
  }
  100% {
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
    width: $search-width-open;
    margin-top: 0px;
  }
}

.introjs-fixParent {
  z-index: 9999 !important;
}
</style>
