<template>
  <nav class="strap-nav-bar">
    <!-- <a class="skip-main" href="#main">Skip to main content</a> -->

    <!-- logo -->
    <router-link tag="div" :to="'/'" class="nav-logo-container">
      <img
        height="28"
        src="../../../public/STRAP-logo.svg"
        alt="STRAP"
        class="logo"
      />
    </router-link>

    <!-- search -->
    <div id="intro-search" class="nav-global-search-container">
      <nav-bar-search-2
        v-if="!roles.includes('CANCER_CENTER') && !roles.includes('REVIEWER')"
      />
    </div>

    <!-- link -->
    <div id="intro-link" class="nav-link-container">
      <div class="overflow-container">
        <nav-bar-links
          v-if="!roles.includes('CANCER_CENTER') && !roles.includes('REVIEWER')"
        ></nav-bar-links>
      </div>
    </div>

    <div class="nav-btn-group">
      <!-- data settings -->
      <div class="nav-containers-container nav-btn">
        <btn
          id="download-popover"
          v-tooltip="'Downloads'"
          aria-label="Downloads"
          :class="{
            notification: downloadState.notification && !downloadState.open,
          }"
          v-if="!roles.includes('CANCER_CENTER') && !roles.includes('REVIEWER')"
          class="dropdown-nav-button"
        >
          <icon icon="download"></icon>
        </btn>
      </div>

      <!-- downloads-popover -->
      <popover
        target="download-popover"
        triggers="click"
        placement="bottom"
        @shown="
          downloadState.notification = false;
          downloadState.open = true;
          getDownloads();
        "
        @hidden="
          downloadState.open = false;
          downloadState.notification = false;
          downloadState.notificationIndexes = [];
        "
      >
        <state-handler
          v-bind="downloadState"
          error-size="sm"
          no-transition
          keep-response-alive
          ignore-loading
        >
          <template #response>
            <div class="downloads-popover popover-pad">
              <div class="downloads-popover__header">
                <span v-if="downloadState.loading"
                  >Loading Files Available for Download...</span
                >
                <span v-else
                  ><b>{{ downloadState.total }}</b> Files Available for
                  Download</span
                >
                <btn
                  :disabled="downloadState.loading"
                  class="downloads-popover__refresh"
                  variant="outline-circle"
                  icon
                  @click="getDownloads"
                  ><icon size="sm" icon="sync"></icon
                ></btn>
                <btn
                  class="downloads-popover__close"
                  variant="outline-circle"
                  icon
                  @click="closeDownloadDialog"
                  ><icon size="sm" icon="times"></icon
                ></btn>
              </div>
              <table
                class="downloads-popover__table"
                style="width: 100%"
                :class="{
                  loading:
                    !$h.truthy(downloadState.response) && downloadState.loading,
                }"
              >
                <tr class="downloads-popover__table-header">
                  <th style="width: 220px">File Name</th>
                  <th style="width: 150px">Date Created</th>
                  <th style="width: 120px">Status</th>
                  <th style="width: 120px">Expires in</th>
                </tr>
                <tr
                  class="downloads-popover__table-row"
                  v-for="(download, i) in downloadState.response"
                  :key="i"
                >
                  <td
                    style="width: 220px"
                    :class="{
                      notificationRow:
                        downloadState.notificationIndexes.includes(i),
                    }"
                  >
                    <span
                      v-if="download.file_uri === 'N/A'"
                      v-text="download.original_filename"
                    ></span>
                    <a
                      v-else
                      v-text="download.original_filename"
                      :href="download.file_uri"
                      :download="download.original_filename"
                    ></a>
                  </td>
                  <td style="width: 150px">
                    {{ $h.get(download, "created") | date("M/D/YY hh:mm A") }}
                  </td>
                  <td style="width: 120px">
                    {{
                      $h.get(download, "file_status")
                        | replaceDashes
                        | titleCase
                    }}
                  </td>
                  <td style="width: 120px">
                    {{ $h.get(download, "expiration_time") | timeAgo }}
                  </td>
                </tr>
              </table>
              <div
                class="table-footer"
                v-if="downloadState.loading && downloadState.total === 0"
              >
                <spinner :radius="60" width="8" />
              </div>
              <div class="table-footer" v-else-if="downloadState.total === 0">
                No files available
              </div>
            </div>
          </template>
          <template #error>
            <div class="downloads-popover__error pa-5">
              <div>Something went wrong, please try again in a few minutes</div>
              <btn variant="outline-circle" icon @click="getDownloads"
                ><icon size="sm" icon="sync"></icon
              ></btn>
            </div>
          </template>
        </state-handler>
      </popover>

      <!-- data settings -->
      <div id="intro-containers" class="nav-containers-container nav-btn ml-2">
        <btn
          v-tooltip="'Data Settings'"
          aria-label="Data Settings"
          class="dropdown-nav-button"
          @click="openDataSettings"
          v-if="
            !roles.includes('CANCER_CENTER') &&
            !roles.includes('REVIEWER')
          "
        >
          <icon icon="cogs"></icon>
        </btn>
      </div>

      <!-- intro -->
      <div
        id="intro-start"
        class="nav-intro nav-btn ml-2"
        v-if="roles.includes('DEVELOPER')"
      >
        <btn
          v-tooltip="'Open Guide'"
          aria-label="Open Introduction"
          class="dropdown-nav-button"
          @click="openIntro"
        >
          <icon icon="question"></icon>
        </btn>
      </div>

      <!-- user info -->
      <div id="intro-user" class="nav-user-container nav-btn ml-2">
        <dropdown
          right
          no-caret
          class="dropdown"
          toggle-class="dropdown-nav-button"
        >
          <!-- button -->
          <template #button-content>
            <span class="letter">{{ email[0] | upperCase }}</span>
          </template>

          <!-- card -->
          <div class="column-card">
            <div class="avatar">{{ email[0] | upperCase }}</div>
            <div
              class="email"
              v-text="email || 'strap.researcher-trial@bioappdev.org'"
            ></div>
            <div class="role" v-html="roleDisplay"></div>
          </div>

          <dropdown-button
            class="logout-button"
            @click="$router.push({ name: 'logout' })"
          >
            <icon class="absolute" icon="sign-out-alt"></icon>Logout
          </dropdown-button>
        </dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
/* istanbul ignore file */
import NavBarLinks from "./AppNavBarLinks";
import NavBarSearch2 from "./AppNavBarSearch3";

import Dropdown from "bootstrap-vue/es/components/dropdown/dropdown";
import DropdownButton from "bootstrap-vue/es/components/dropdown/dropdown-item-button";
import Popover from "bootstrap-vue/es/components/popover/popover";
import { apiGet } from "@/services/DataService";
import { EventBus } from "@/event-bus.js";

import { startIntro } from "@/services/IntroService";
import { mapGetters, mapActions } from "vuex";
import Spinner from "@/components/utils/Spinner";
import moment from "moment";

export default {
  name: "app-nav-bar",
  components: {
    NavBarLinks,
    Dropdown,
    DropdownButton,
    NavBarSearch2,
    Popover,
    Spinner,
  },
  data() {
    return {
      navBarIntroEnabled: false,
      downloadState: {
        open: false,
        notification: false,
        notificationIndexes: [],
        loading: false,
        polling: false,
        response: [],
        lastResponse: [],
        error: null,
        interval: null,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      name: "auth/name",
      email: "auth/email",
      roles: "auth/roles",
      pageName: "intro/pageName",
      isContainerActive: "settings/containerEnabled",
    }),
    roleDisplay() {
      return this.$options.filters.titleCase(
        this.$options.filters.replaceUnderscores(this.roles[0])
      );
    },
  },
  mounted() {
    this.getDownloads();
    EventBus.$on("start-poll", () => {
      this.startDownloadPoll();
    });
  },
  methods: {
    ...mapActions({
      openDataSettings: "modal/openDataSettings",
      openFeature: "modal/openFeature",
    }),
    closeDownloadDialog() {
      this.$root.$emit("bv::hide::popover", "download-popover");
    },
    openIntro() {
      const combinedPageNames = [
        "home",
        "study",
        "site",
        "investigator",
      ];
      combinedPageNames.includes(this.pageName)
        ? this.startIntro(["navbar", this.pageName], this.isContainerActive)
        : this.startIntro(["navbar"], this.isContainerActive);
    },
    startIntro,

    startDownloadPoll() {
      if (this.downloadState.polling === true) {
        return;
      }
      this.downloadState.polling = true;
      this.getDownloads();
      this.downloadState.interval = setInterval(() => {
        if (!this.downloadState.open) {
          this.getDownloads();
        }
      }, 10000);
    },
    stopDownloadPoll() {
      this.downloadState.polling = false;
      if (this.downloadState.interval !== null) {
        clearInterval(this.downloadState.interval);
        this.downloadState.interval = null;
      }
    },

    async getDownloads() {
      try {
        this.downloadState.error = null;
        this.downloadState.loading = true;

        let response = await apiGet({
          baseURL: "users",
          endpoint: "/user/datafiles",
        });
        this.downloadState.response = response
          .sort((a, b) => {
            let nameA = moment.unix(a.last_updated);
            let nameB = moment.unix(b.last_updated);
            if (nameA < nameB) return 1;
            if (nameA > nameB) return -1;
            return 0;
          })
          .filter((file) => {
            if (file.filename === undefined) {
              return false;
            } else if (file.file_status === "In-progress") {
              return moment.unix(file.created).isSame(moment(), "day");
            } else if (file.expiration_time !== undefined) {
              return moment() <= moment.unix(file.expiration_time);
            }
            return false;
          });

        if (
          response.some((item) => {
            let lastUpdated = moment.unix(item.last_updated);
            let today = moment();
            let timeSince = moment.duration(today.diff(lastUpdated));
            return (
              item.file_status === "In-progress" && timeSince.asHours() < 1
            );
          }) &&
          !this.downloadState.polling
        ) {
          this.startDownloadPoll();
        }

        if (
          response.length === 0 ||
          response.every((item) => {
            let lastUpdated = moment.unix(item.last_updated);
            let today = moment();
            let timeSince = moment.duration(today.diff(lastUpdated));
            return (
              item.file_status !== "In-progress" || timeSince.asHours() > 1
            );
          })
        ) {
          this.stopDownloadPoll();
        }

        // if there is not last response
        if (this.$h.truthy(this.downloadState.lastResponse)) {
          this.downloadState.response.forEach((item, i) => {
            let lastItem = this.downloadState.lastResponse.find(
              (l) => item.filename === l.filename
            );
            if (
              this.$h.truthy(lastItem) &&
              lastItem.file_status !== item.file_status
            ) {
              this.downloadState.notificationIndexes.push(i);
            }
          });
        }
        this.downloadState.notification =
          this.downloadState.notificationIndexes.length !== 0;
        this.downloadState.lastResponse = this.downloadState.response;
        this.downloadState.total = this.downloadState.response.length;
      } catch (error) {
        this.downloadState.error = error;
      } finally {
        this.downloadState.loading = false;
      }
    },
    downloadFile(file) {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", file.file_uri);
        link.setAttribute("download", file.filename + ".xlsx");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
  beforeDestroy() {
    this.stopDownloadPoll();
  },
};
</script>

<style lang="scss">
@import "src/styles/component";

.column-card {
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid $border-color;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $dark;
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: $bold;
    font-size: 1.75rem;
  }
  .role {
    border-radius: 4px;
    background-color: $light;
    padding: 0.35rem 0.5rem;
    font-weight: $thin;
    font-size: 0.8rem;
  }
}
.logout-button {
  // padding: 0.25rem 1rem;
  text-align: center;

  .absolute {
    margin-right: 0.25rem;
  }
}

.strap-nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark;
  padding: 1.15rem 1rem;
  position: relative;
}

.downloads-popover {
  width: 645px;

  &__header {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  &__table {
    border: solid thin $border-color;
    table-layout: fixed;

    &.loading {
      opacity: 0.5;
    }

    &-header {
      background-color: fade-out(black, 0.95);
      th {
        padding: 0.5rem;

        &:first-child {
          padding-left: 2rem;
        }
      }
    }
    &-row {
      &:not(:last-child) td {
        border-bottom: solid thin lighten($border-color, 2);
      }

      td {
        padding: 0.5rem;
        position: relative;

        &:first-child {
          padding-left: 2rem;
        }
      }
      &.loading {
        border: solid thin $border-color;
        border-top: none;
      }
      &.loading td {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
      }
    }

    td:first-child,
    th:first-child {
      padding-left: 1rem;
    }
  }
  &__close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
  &__refresh {
    position: absolute;
    top: 0.5rem;
    right: 3.75rem;

    &:disabled {
      animation: spin 2s linear infinite;
    }
  }

  &__error {
    display: flex;
    align-items: center;

    div {
      margin-right: 0.5rem;
    }
  }

  .table-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    border: solid thin $n2;
    border-top: none;
    font-style: italic;
  }
}

// main
.nav-logo-container {
  flex: 0 0 auto;
  cursor: pointer;
}
.nav-link-container {
  flex: 0 1 100%;
  padding: 0 0.75rem;
  position: relative;

  .overflow-container {
    position: absolute;
    top: -0.75rem;
  }
}
.nav-search-container {
  flex: 0 0 auto;
  height: 35px;
  position: relative;
  padding-right: 6.5rem;
}
.nav-global-search-container {
  flex: 0 0 auto;
  position: relative;
  margin-left: 1.5rem;
}
.nav-btn-group {
  display: flex;
  align-items: center;
  background-color: $dark;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  padding-right: 1rem;
}
.nav-btn {
  // margin-left: 0.5rem;
}
.nav-containers-container {
  flex: 0 0 auto;
  // margin-left: 0.75rem;
  // z-index: 1;

  .dropdown-nav-button {
    svg {
      font-size: 1rem;
    }
  }
}
.notification {
  position: relative;
  border-radius: 50%;

  &:after {
    content: "";
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    background-color: red;
    border: solid 3px darken(red, 10);
    position: absolute;
    top: -0.15rem;
    right: 0.15rem;
    box-shadow: $box-shadow;
  }
}
.notificationRow::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 15px;
  width: 20px;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  background-color: red;
  // position: absolute;
}
.nav-intro {
  flex: 0 0 auto;

  .dropdown-nav-button {
    svg {
      font-size: 1rem;
    }
  }
}
.nav-user-container {
  flex: 0 0 auto;

  .dropdown-menu {
    box-shadow: 0 0.25rem 0.75rem 0.01rem fade-out($dark, 0.7);
    box-shadow: $popover-shadow;
    z-index: 4;
  }
  > .dropdown {
    .email {
      width: auto;
      color: $n6;
      padding: 0.75rem 1rem;
      text-align: center;

      display: flex;

      .letter {
        flex: 0 0 auto;
        width: 45px;
        height: 45px;
        background-color: $n1;
        display: flex;
        align-items: center;
        justify-content: center;

        color: $text-light;
        font-weight: $bold;
        font-size: 1.2rem;
        margin-right: 0.75rem;
        border-radius: $border-radius-sm;

        &.letter-developer {
          width: 55px;
          height: 55px;
          font-size: 1.5rem;
          margin-top: 0.3rem;
        }
      }
      .text {
        flex: 0 1 100%;
      }
      h5 {
        font-size: 1rem;
        font-weight: $bold;
      }
      h6 {
        font-size: 0.9rem;
        font-weight: $thin;
        color: $n4;

        &.role {
          font-size: 0.85rem;
          font-weight: $regular;
        }
      }
    }
  }
  .logout-link {
    width: 100%;
  }
  .dropdown-divider {
    display: none;
  }
}

// helper
.dropdown-nav-button {
  &:hover {
    box-shadow: 0 0 0 0.2rem $link;
  }
}
.how-to-use {
  font-size: 1rem;
}
.container-selecter {
  width: 250px;
  .group {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

.pa-5 {
  padding: 1rem;
  font-weight: $bold;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

