<script>
import DataMixin from "@/mixins/DataMixin";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import resources from "@/services/ResourceService";

export default {
  name: "async-data-wrapper",
  mixins: [DataMixin],
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Number,
      default: 0,
    },
    defaultResponse: {
      default: undefined,
    },
    once: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
    }
  },
  data() {
    return {
      debouncedGet: undefined,
      called: false,
    };
  },
  computed: {
    isQueryActive() {
      return (
        (this.$h.truthy(this.query) || this.resource !== undefined) &&
        !this.disabled
      );
    },
  },
  created() {
    this.debouncedGet = debounce(this.get, this.debounce);
    this.getFormatter();
  },
  mounted() {
    this.debouncedGet();
  },
  methods: {
    get() {
      if (this.isQueryActive) {
        this.$emit("start");
        const resourceFunction = this.$h.get(resources.queries, this.resource);
        const query =
          resourceFunction !== undefined
            ? resourceFunction(this.query)
            : this.query;

        this.$get(query, {
          resolve: this.onResolve,
          reject: this.onReject,
        });
      }
    },
    onResolve(response) {
      this.$emit("resolve", response);
    },
    onReject(error) {
      this.$emit("reject", error);
    },
    getFormatter() {
      if (this.resource) {
        this.DataMixin_formatter = this.$h.get(
          resources,
          `formatters.${this.resource}Formatter`
        );
      }
    },
  },
  watch: {
    $state: {
      handler() {
        this.$emit("state", this.$state);
      },
      deep: true,
    },
    query: {
      handler(newQuery, oldQuery) {
        if (!isEqual(newQuery, oldQuery)) {
          if (!this.once) {
            this.debouncedGet();
          }
        }
      },
      deep: true,
    },
    resource() {
      if (!this.once) {
        this.debouncedGet();
      }
    },
  },
  render() {
    if (!this.$scopedSlots.default) {
      return () => {};
    }

    return this.$scopedSlots.default({
      _state: {
        response: get(this.$state, "response", this.defaultResponse),
        loading: get(this.$state, "loading", false),
        error: get(this.$state, "error", ""),
      },
      _reload: this.debouncedGet,
    });
  },
};
</script>
