<template>
  <form-builder
    id="form-field"
    no-persist
    no-actions
    submit-on-change
    submit-on-mount
    :key="updateKey"
    :schema="schema"
    :values="value"
    :open="open"
    :alwaysCallMounted="alwaysCallMounted"
    @change="formSubmit"
  ></form-builder>
</template>

<script>
export default {
  name: "form-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: {
    event: "change",
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    alwaysCallMounted: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
  },
  data() {
    return {
      values: {},
      updateKey: 0,
    };
  },
  methods: {
    formSubmit(values) {
      this.$emit("change", values);
    },
  },
  watch: {
    value(value) {
      if (isEmpty(value)) {
        this.updateKey++; // update on reset
      }
    },
  },
};
function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
</script>