import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

const defaultProps = {
  id: "",
  title: "",
  variant: "trials",
  query: {},
  initialValues: {},
  canDownload: true,
  noPersist: true,
  minHeight: false,
  noKeyword: true,
};

export default {
  set({ commit }, props) {
    commit("setProps", merge(cloneDeep(defaultProps), cloneDeep(props)));
  },
  close({ commit }) {
    commit("setProps", {});
  },
};
