<template>
  <e-charts
    ref="chart"
    :options="$activeOptions"
    :auto-resize="false"
    @click="$click"
  ></e-charts>
</template>

<script>
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";

import ChartMixin from "@/mixins/ChartMixin";
import { localeString, titleCase, abbreviate } from "@/services/FilterService";

export default {
  name: "pie-chart",
  components: { ECharts },
  mixins: [ChartMixin],
  props: {
    donut: {
      type: Boolean,
      default: false,
    },
    closedLegend: {
      type: Boolean,
      default: false,
    },
    needsLabelFormat: {
      type: Boolean,
      default: false,
    },
    abbreviateLabels: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
    },
  },
  data() {
    const tooltip = this.$optionDefault("tooltip", []);
    const legend = this.$optionDefault("legend", []);
    const series = this.$optionDefault("series.0", {});
    let formattedLengend = this.$optionMerge(legend, {
      bottom: "auto",
      left: 38,
      top: 16,
      orient: "vertical",
      show: true,
      textStyle: {
        color: "#3F546C",
        fontSize: 12,
      },
    });

    // disable click
    if (this.disableClick) {
      series.cursor = "default";
    }

    //append 'None' object to series for data missing value
    const barSum = series.data.reduce((acc, current) => {
      return acc + current.value;
    }, 0);

    if (barSum < this.total) {
      const obj = {
        name: "None",
        value: this.total - barSum,
      };
      series.data.push(obj);
    }

    // add color
    this.$colorData(series.data);

    // format label
    tooltip.formatter = (params) =>
      `${params.name}<br/>${localeString(params.value)} (${params.percent}%)`;

    if (this.needsLabelFormat) {
      series.data.forEach((data) => {
        if (data.name) {
          data.name = data.name
            .split(";")
            .map((name) => titleCase(name))
            .join(", ");
        }
      });
    }

    return {
      defaultOptions: {
        tooltip,
        animation: true,
        series: this.$optionMerge(series, {
          radius: this.donut ? ["45%", "60%"] : ["0%", "57%"],
          center: ["50%", "50%"],
          label: {
            normal: {
              formatter: (label) => {
                return !this.expanded && this.abbreviateLabels
                  ? abbreviate(label.name, 20)
                  : label.name;
              },
              show: !this.closedLegend,
            },
          },
          labelLine: {
            normal: {
              show: !this.closedLegend,
            },
          },
        }),
        legend: this.$optionMerge(legend, {
          show: this.closedLegend,
          left: 35,
          top: 10,
        }),
      },
      expandedOptions: {
        series: this.$optionMerge(series, {
          radius: ["0%", "70%"],
          center: ["50%", "50%"],
        }),
        // legend: formattedLengend,
      },
    };
  },
  watch: {
    expanded() {
      this.$emit("expand");
    },
  },
};
</script>