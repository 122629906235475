import searchActions from "./actions";
import searchMutations from "./mutations";
import searchGetters from "./getters";

export default {
  namespaced: true,

  state: {
    recentSearches: [],
    recentEntities: [],
    diseaseConditionSearch:[],
    leadDiseaseSearch:[],
    leadDiseaseSearchHomeDashboard:[],
    participantDiseaseConditionSearch:[],
  },

  getters: searchGetters,

  mutations: searchMutations,

  actions: searchActions,
};
