import { library } from '@fortawesome/fontawesome-svg-core'

import { faNotesMedical } from '@fortawesome/free-solid-svg-icons/faNotesMedical'
import { faHospital } from '@fortawesome/free-solid-svg-icons/faHospital'
import { faUserMd } from '@fortawesome/free-solid-svg-icons/faUserMd'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile'
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList'
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faMapPin } from '@fortawesome/free-solid-svg-icons/faMapPin'
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons/faVectorSquare'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons/faHeartbeat'
import { faPrescriptionBottleAlt } from '@fortawesome/free-solid-svg-icons/faPrescriptionBottleAlt'
import { faBriefcaseMedical } from '@fortawesome/free-solid-svg-icons/faBriefcaseMedical'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons/faHandPaper'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo'
import { faBookReader } from '@fortawesome/free-solid-svg-icons/faBookReader'
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax'
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar'
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons/faThermometerHalf'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle'
import { faBookmark } from '@fortawesome/free-solid-svg-icons/faBookmark'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'

library.add(
  faPencilAlt,
  faDotCircle,
  faLocationArrow,
  faThermometerHalf,
  faFax,
  faCar,
  faGlobe,
  faCopy,
  faBell,
  faListUl,
  faUndo,
  faHome,
  faExternalLinkAlt,
  faPen,
  faRedo,
  faUniversity,
  faArrowLeft,
  faBriefcaseMedical,
  faPrescriptionBottleAlt,
  faHeartbeat,
  faSignOutAlt,
  faCaretDown,
  faVectorSquare,
  faExchangeAlt,
  faSearch,
  faChartBar,
  faFileAlt,
  faThLarge,
  faQuestion,
  faExclamation,
  faExclamationTriangle,
  faNotesMedical,
  faExpand,
  faCircleNotch,
  faHospital,
  faInfoCircle,
  faUsers,
  faUserMd,
  faCamera,
  faCheck,
  faDownload,
  faFilter,
  faPlus,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faTrash,
  faFile,
  faSync,
  faSyncAlt,
  faCogs,
  faTimes,
  faQuestionCircle,
  faThList,
  faBan,
  faPhone,
  faEnvelope,
  faFlag,
  faChevronLeft,
  faMapMarkedAlt,
  faMinus,
  faMapPin,
  faHandPaper,
  faStar,
  faBookReader,
  faBookmark
)
