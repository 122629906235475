

  function getCount(states) {
    let count = 0;
    states.forEach((state) => count += state.count);
    return count;
  }
  function getName(states) {
    if (states.length <= 2) {
      return states.map((state) => state.name).join(' and ');
    }
    else {
      return states.map((state) => state.abbreviation).join(', ');
    }
  }
export default {
    page: (state) => state.page,
    pageHistory: (state) => state.pageHistory,
    mainPage: (state) => ['cluster', 'states', 'heatmap'].includes(state.page),
    selectedPage: (state) => ['state-selection', 'organization-selection', 'study-selection'].includes(state.page),
    stateSelection: (state) => state.stateSelection,
    stateSelectionName: (state) => getName(state.stateSelection),
    stateSelectionCount: (state) => getCount(state.stateSelection),
    excludedStates: (state) => state.excludedStates,
    selectedStudy: (state) => state.selectedStudy,
    selectedOrganization: (state) => state.selectedOrganization,
    rendered: (state) => state.rendered,
    loading: (state) => state.loading,
    error: (state) => state.error,
    cluster: (state) => state.cluster,
    distance: (state) => state.distance,
    zoom: (state) => state.zoom,
    center: (state) => state.center,
    collapsed: (state) => state.collapsed,
    minMax: (state) => state.minMax,
    stateCounts: (state) => state.stateCounts,
    getMapState: (state) => (id) => state.maps[id],
  };
