export default {
  open(state, name) {
    state[name] = true;
  },
  close(state, name) {
    state[name] = false;
  },

  setFormSummaryValues(state, model) {
    state.formSummaryValues = model[0];
    state.formSummaryVariant = model[1];
    state.formSummarySchema = model[2];
  },
  setExportModel(state, model) {
    state.exportModel = model;
  },
};
