<template>
  <portal to="destination">
    <transition name="slide-fade-down">
      <nav
        ref="menu"
        class="menu"
        :style="menuStyle"
        v-if="show"
        :key="show"
        v-click-outside="close"
      >
        <ul class="menu-items">
          <li class="menu-item" v-if="!$h.truthy(colDef.noCopy)" @click="copy">
            Copy
            <icon icon="copy"></icon>
          </li>
          <li class="menu-item" @click="copyRow">
            Copy row
            <icon icon="th-list"></icon>
          </li>
          <!-- <li class="menu-item" @click="rowSummary">Row Summary<icon icon="book-reader"></icon></li> -->
          <li
            class="menu-item"
            v-if="colDef.cellClass === 'link' && value !== undefined"
            @click="link"
          >
            Go to {{getId(value) | abbreviate(16)}}
            <icon icon="external-link-alt"></icon>
          </li>
          <li
            class="menu-item"
            v-if="colDef.hasOwnProperty('linkTo') && value !== undefined"
            @click="linkNewTab"
          >
            Open in new tab
            <icon icon="plus"></icon>
          </li>
        </ul>
      </nav>
    </transition>
  </portal>
</template>

<script>
import ClickOutside from "vue-click-outside";

let hasBeenOpened = false;

export default {
  name: "grid-context-menu",
  inheritAttrs: false,
  directives: { ClickOutside },
  props: ["value", "colDef", "data", "event", "title"],
  data() {
    return {
      show: false,
      el: undefined,
    };
  },
  mounted() {
    if (this.event !== undefined) {
      this.open();
    }
  },
  computed: {
    menuStyle() {
      let left = this.$h.get(this.event, "pageX", 0);
      let top = this.$h.get(this.event, "pageY", 0);

      if (window.innerWidth - left < 280) {
        left = window.innerWidth - 280;
      }

      return `
        left: ${left}px;
        top: ${top}px;
      `;
    },
  },
  methods: {
    open() {
      hasBeenOpened = true;
      setTimeout(() => {
        hasBeenOpened = false;
      }, 150);
      this.show = true;
      this.el = this.event.srcElement;
      this.el.classList.add("ag-row-context");
    },
    close() {
      if (!hasBeenOpened) {
        this.el.classList.remove("ag-row-context");
        this.show = false;
      }
    },
    copy() {
      if (Object.prototype.hasOwnProperty.call(this.colDef, "valueFormatter")) {
        copyTextToClipboard(this.colDef.valueFormatter({ value: this.value }));
      } else {
        copyTextToClipboard(this.value);
      }

      this.close();
    },
    copyRow() {
      copyTextToClipboard(JSON.stringify(this.data));
      this.close();
    },
    rowSummary() {
      this.$emit("summary", { data: this.$h.cloneDeep(this.data) });
      this.close();
    },
    link() {
      this.el.click();
      this.close();
    },
    linkNewTab() {
      let route = this.$router.resolve(
        this.colDef.linkTo(this.value, this.data)
      );
      window.open(route.href, "_blank");
      this.close();
    },
    getId(id) {
      if (typeof id === "object") {
        return id.name;
      } else {
        return id;
      }
    },
  },
  beforeDestroy() {
    if (this.el !== undefined) {
      this.el.classList.remove("ag-row-context");
    }
  },
};

function copyTextToClipboard(text) {
  var textArea = document.createElement("textarea");

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = "transparent";
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    // eslint-disable-next-line
    console.log("Oops, unable to copy");
  }

  document.body.removeChild(textArea);
}
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.menu {
  position: absolute;
  z-index: 9999;
  padding: 12px 0;
  width: 240px;
  background-color: white;
  border: solid 1px $n3;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow-sm;
  font-size: 0.9rem;
}
.menu-items {
  padding: 0;
  margin: 0;
}
.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1.2rem;
  margin: 0;
  font-weight: $regular;
  color: $text;
  font-size: 0.9rem;

  svg {
    color: $text;
    opacity: 0.25;
  }
  &:hover {
    background-color: $n1;
    cursor: pointer;

    svg {
      opacity: 1;
    }
  }
  &:active {
    background-color: $link;
    color: white;
    cursor: pointer;
    svg {
      color: white;
    }
  }
}
</style>
