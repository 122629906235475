import cloneDeep from "lodash/cloneDeep";

import { Options } from "../utils";

import {
  getSECSearchSchema, // this method builds the schema / form for searching SEC by name or cCode
  getSEC, // this method builds the returnObject which is the params for the API request for the SEC filter search
  secNameCodeLabel, // this method returns the selected SEC Name 
  getSecType, // this method returns selected Trial Search Type (Eligibile or Focus)
  getSecCriteria, // get selected (name or cCode) SECCriteria value
  secNameCodeLabelSearchByCode,
} from "./SECSearchSchema";

let secSearchType = "default";

// this function builds the schema / form for SEC filter . also dispalys all SEC filters applied
export function getSECSchema() {
  return [
    /* min age */
    {
      type: "text",
      id: "sec_text",
      group: {
        label: "Structured Eligibility Criteria",
      },
      input: {
        stacked: true,
      },
      isActive() {
        return false;
      },
    },
    {
      type: "radio-field",
      id: "searchType",
      default: "default",
      group: {
        label: "Select Trial Search Type",
      },
      input: {
        textField: "label",
        valueField: "value",
        stacked: true,
        options: Options.getOptions("sec_search_type"),
      },
      get(returnObject) {
        secSearchType = this.value;
        returnObject["sec_search_type"] = this.value;
      },
    },
    {
      type: "simple-multi-form-field",
      id: "ids-search-sec",
      idType: "sec",
      input: {
        name: "sec",
        label: "Structured Eligibility Criteria",
        open: true,
        showForm: true,
        labelFormatter(value) {
          if (value["id-selector"] && value["id-selector"].value === "code") {
            return `<span>Criteria: <strong>${getSecCriteria(
              value
            )}</strong> <br> Term(s): <strong>${secNameCodeLabelSearchByCode(
              value
            )}</strong> ${getSecType(value, secSearchType)} </span>`;
          } else {
            return `<span>Criteria: <strong>${getSecCriteria(
              value
            )}</strong> <br> Term(s): <strong>${secNameCodeLabel(
              value
            )}</strong>${getSecType(value, secSearchType)} </span>`;
          }
        },
        schema: cloneDeep(getSECSearchSchema("ids-sec")),
      },
      get(returnObject) {
        // used for building the return query when making API request
        getSEC(returnObject, this.value);
      },
    },
  ];
}

