import { apiGet } from '@/services/DataService';

export default {
  saveForm({ commit }, form) {
      commit('updateForm', form);
      commit('updateQuery', form);
    },

    clearForms({ commit }) {
      commit('clearAllForms');
      commit('updateKeyword', '');
    },

    clearForm({ commit }, id) {
      commit('updateForm', { id, model: {} });
    },

    updateKeyword({ commit }, keyword) {
      commit('updateKeyword', keyword);
    },

    updateDisease({ commit }, payload) {
      commit('updateDisease', payload);
    },

    async setOptions({ commit, getters }, { id, query }) {
      if (getters['getOptions'](id) === undefined) {
        try {
          let options = await apiGet(query);
          commit('setOptions', { id, options });
        } catch (e) {
   /* eslint-disable-next-line no-console */
          console.error(e);
        }
      }
    },
    clearOptions({ commit }) {
      commit('clearOptions');
    }
  };