import get from 'lodash/get';

const docURLMap = {
  page: {
    containers: '/documentation',
    homePage: '/documentation',
    studySearchPage: '/documentation',
    studyLandingPage: '/documentation',
    organizationSearchPage: '/documentation',
    organizationLandingPage: '/documentation',
    investigatorSearchPage: '/documentation',
    investigatorLandingPage: '/documentation',
    participantsSearchPage: '/documentation',
    reportsPage: '/documentation',
    helpPage: '/documentation'
  },
  chart: {
    studiesContainerChart: '/documentation',
    participantsContainerChart: '/documentation',
    homeInterventionTypeChart: '/documentation',
    homeRaceChart: '/documentation',
    homeSexChart: '/documentation',
    homeEthnicityChart: '/documentation',
    homePrimaryPurposeChart: '/documentation',
    homeCurrentStudyStatusChart: '/documentation',
    homePhaseChart: '/documentation',
    adminChart: '/documentation',
    aggregationChart: '/documentation',
    studyLandingAgeChart: '/documentation',
    studyLandingRaceChart: '/documentation',
    studyLandingSexChart: '/documentation',
    studyLandingEthnicityChart: '/documentation',
    studySearchStudySourceChart: '/documentation',
    studySearchPhaseChart: '/documentation',
    studySearchCurrentStudyStatusChart: '/documentation',
    studySearchPrimaryPurposeChart: '/documentation'
  },
  list: {
    studyLandingDiseasesList: '/documentation',
    studyLandingSiteList: '/documentation',
    participantsSearchStudyList: '/documentation',
  }
};

const defaultDocURL = '/documentation';

export function getDocURL(docKey) {
  return get(docURLMap, docKey, defaultDocURL);
}