import modalActions from "./actions";
import modalMutations from "./mutations.js";
import modalGetters from "./getters";

export default {
  namespaced: true,

  state: {
    dataView: false,
    dataSettings: false,
    formSummary: false,
    export: false,
    feature: false,
    exportModel: {},
    formSummaryValues: {},
    formSummaryVariant: "",
    formSummarySchema: [],
  },

  getters: modalGetters,
  mutations: modalMutations,
  actions: modalActions,
};
