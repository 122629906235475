<script>
import sortBy from 'sort-by'
import debounce from 'lodash/debounce'

export default {
  name: 'local-data-wrapper',
  props: {
    model: { type: [Array, Object], default: () => [] },
    query: { type: Object, default: () => ({}) },
    keywordFields: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    noUpdateOnChange: { type: Boolean, default: false },
    debounce: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      loading: true,
      response: [],
      dGet: null,
      parseWorker: null,
    }
  },
  created() {
    this.dGet = debounce(this.get, this.debounce)
    this.dGet()

    if (typeof(Worker) !== 'undefined') {
      this.parseWorker = this.$worker.create([
        { message: 'parse', func: (data, params, searchKeys) => {

          // loop over each key, and compare against corresponding row value
          searchKeys.forEach(key => {
            if (!['include', 'size', 'from', 'to', 'sort', 'order'].includes(key)) {
              data = data.filter(row => {

                // get compare values
                const rowValue = row[key]
                const searchValue = params[key]


                // search
                if (searchValue === undefined) {
                  return true
                }
                if (key === '_keyword') {
                  return rowSearch(row, searchValue)
                }
                else if (rowValue === undefined) {
                  return false
                }
                else if (isArray(searchValue)) {    
                  return searchValue.some(s => stringCompare(rowValue, s))
                }
                else if (isArray(rowValue)) {
                  return rowValue.some(s => stringCompare(s, searchValue))
                }
                else {
                  return stringCompare(rowValue, searchValue)
                }
              })
            }
          })

          return data

          // helper
          function rowSearch(row, searchValue) {
            return objectValues(row).some(value => {
              if (value === undefined) {
                return false
              }
              else if (isObject(value)) {
                return false
              }
              else if (isArray(value)) {
                return value.some(v => stringCompare(v, searchValue))
              }
              else {
                return stringCompare(value, searchValue)
              }
            })
          }
          function objectValues(obj) {
            var res = [];
            for (var i in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, i)) {
                res.push(obj[i]);
              }
            }
            return res;
          }
          function isObject(value) {
            return (!!value) && (value.constructor === Object)
          }
          function isArray(value) {
            return !!value && value.constructor === Array
          }
          function stringCompare(value, searchValue) {
            if (value !== undefined) {
              return value.toString().toLowerCase().search(searchValue.toLowerCase()) !== -1
            }
            else {
              return false
            }
          }

        }},
      ])
    }
  },
  methods: {
    async get() {
      if (!this.disabled) {
        this.$emit('start')
        this.loading = true
        const model = this.$h.cloneDeep(this.model)
        const params = this.$h.cloneDeep(this.$h.get(this.query, 'params', ''))
        const searchKeys = Object.keys(params).filter(key => this.$h.truthy(params[key]))

        if (typeof(Worker) !== 'undefined') {
          this.parseWorker.postMessage('parse', [model, params, searchKeys])
            .then(this.onResolve)
            .catch(this.onError)
        }
        else {
          alert('Query failed, please update your browser')
        }
      }
    },
    onResolve(model) {
      const size = this.$h.get(this.query, 'params.size', model.length)
      const from = this.$h.get(this.query, 'params.from', 0)
      const sort = this.$h.get(this.query, 'params.sort', '')
      const order = this.$h.get(this.query, 'params.order', '')

      if (sort) {
        model = model.sort(sortBy(`${order === 'desc' ? '-': ''}${sort}`))
      }
   
      this.$emit('resolve', model)
      this.response = model.splice(from, size)
      this.loading = false
    },
    onError(error) {
      this.$emit('reject', error)
      this.loading = false
    },
  },
  watch: {
    query: {
      handler() {
        if (!this.noUpdateOnChange) {
          this.dGet() 
        }
      },
      deep: true,
    },
    model: {
      handler() { this.dGet() },
      deep: true,
    },
  },
  render() {
    if (!this.$scopedSlots.default) {
      return () => {}
    }

    return this.$scopedSlots.default({
      _state: {
        response: this.response,
        loading: this.loading,
        error: '',
      },
      _reload: () => {}
    })
  },
  destroyed() {
    this.parseWorker = null
  }
}
</script>
