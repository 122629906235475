var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"strap-nav-bar"},[_c('router-link',{staticClass:"nav-logo-container",attrs:{"tag":"div","to":'/'}},[_c('img',{staticClass:"logo",attrs:{"height":"28","src":require("../../../public/STRAP-logo.svg"),"alt":"STRAP"}})]),_c('div',{staticClass:"nav-global-search-container",attrs:{"id":"intro-search"}},[(!_vm.roles.includes('CANCER_CENTER') && !_vm.roles.includes('REVIEWER'))?_c('nav-bar-search-2'):_vm._e()],1),_c('div',{staticClass:"nav-link-container",attrs:{"id":"intro-link"}},[_c('div',{staticClass:"overflow-container"},[(!_vm.roles.includes('CANCER_CENTER') && !_vm.roles.includes('REVIEWER'))?_c('nav-bar-links'):_vm._e()],1)]),_c('div',{staticClass:"nav-btn-group"},[_c('div',{staticClass:"nav-containers-container nav-btn"},[(!_vm.roles.includes('CANCER_CENTER') && !_vm.roles.includes('REVIEWER'))?_c('btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Downloads'),expression:"'Downloads'"}],staticClass:"dropdown-nav-button",class:{
          notification: _vm.downloadState.notification && !_vm.downloadState.open,
        },attrs:{"id":"download-popover","aria-label":"Downloads"}},[_c('icon',{attrs:{"icon":"download"}})],1):_vm._e()],1),_c('popover',{attrs:{"target":"download-popover","triggers":"click","placement":"bottom"},on:{"shown":function($event){_vm.downloadState.notification = false;
        _vm.downloadState.open = true;
        _vm.getDownloads();},"hidden":function($event){_vm.downloadState.open = false;
        _vm.downloadState.notification = false;
        _vm.downloadState.notificationIndexes = [];}}},[_c('state-handler',_vm._b({attrs:{"error-size":"sm","no-transition":"","keep-response-alive":"","ignore-loading":""},scopedSlots:_vm._u([{key:"response",fn:function(){return [_c('div',{staticClass:"downloads-popover popover-pad"},[_c('div',{staticClass:"downloads-popover__header"},[(_vm.downloadState.loading)?_c('span',[_vm._v("Loading Files Available for Download...")]):_c('span',[_c('b',[_vm._v(_vm._s(_vm.downloadState.total))]),_vm._v(" Files Available for Download")]),_c('btn',{staticClass:"downloads-popover__refresh",attrs:{"disabled":_vm.downloadState.loading,"variant":"outline-circle","icon":""},on:{"click":_vm.getDownloads}},[_c('icon',{attrs:{"size":"sm","icon":"sync"}})],1),_c('btn',{staticClass:"downloads-popover__close",attrs:{"variant":"outline-circle","icon":""},on:{"click":_vm.closeDownloadDialog}},[_c('icon',{attrs:{"size":"sm","icon":"times"}})],1)],1),_c('table',{staticClass:"downloads-popover__table",class:{
                loading:
                  !_vm.$h.truthy(_vm.downloadState.response) && _vm.downloadState.loading,
              },staticStyle:{"width":"100%"}},[_c('tr',{staticClass:"downloads-popover__table-header"},[_c('th',{staticStyle:{"width":"220px"}},[_vm._v("File Name")]),_c('th',{staticStyle:{"width":"150px"}},[_vm._v("Date Created")]),_c('th',{staticStyle:{"width":"120px"}},[_vm._v("Status")]),_c('th',{staticStyle:{"width":"120px"}},[_vm._v("Expires in")])]),_vm._l((_vm.downloadState.response),function(download,i){return _c('tr',{key:i,staticClass:"downloads-popover__table-row"},[_c('td',{class:{
                    notificationRow:
                      _vm.downloadState.notificationIndexes.includes(i),
                  },staticStyle:{"width":"220px"}},[(download.file_uri === 'N/A')?_c('span',{domProps:{"textContent":_vm._s(download.original_filename)}}):_c('a',{attrs:{"href":download.file_uri,"download":download.original_filename},domProps:{"textContent":_vm._s(download.original_filename)}})]),_c('td',{staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.$h.get(download, "created"),"M/D/YY hh:mm A"))+" ")]),_c('td',{staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(_vm._f("titleCase")(_vm._f("replaceDashes")(_vm.$h.get(download, "file_status"))))+" ")]),_c('td',{staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(_vm._f("timeAgo")(_vm.$h.get(download, "expiration_time")))+" ")])])})],2),(_vm.downloadState.loading && _vm.downloadState.total === 0)?_c('div',{staticClass:"table-footer"},[_c('spinner',{attrs:{"radius":60,"width":"8"}})],1):(_vm.downloadState.total === 0)?_c('div',{staticClass:"table-footer"},[_vm._v(" No files available ")]):_vm._e()])]},proxy:true},{key:"error",fn:function(){return [_c('div',{staticClass:"downloads-popover__error pa-5"},[_c('div',[_vm._v("Something went wrong, please try again in a few minutes")]),_c('btn',{attrs:{"variant":"outline-circle","icon":""},on:{"click":_vm.getDownloads}},[_c('icon',{attrs:{"size":"sm","icon":"sync"}})],1)],1)]},proxy:true}])},'state-handler',_vm.downloadState,false))],1),_c('div',{staticClass:"nav-containers-container nav-btn ml-2",attrs:{"id":"intro-containers"}},[(
          !_vm.roles.includes('CANCER_CENTER') &&
          !_vm.roles.includes('REVIEWER')
        )?_c('btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Data Settings'),expression:"'Data Settings'"}],staticClass:"dropdown-nav-button",attrs:{"aria-label":"Data Settings"},on:{"click":_vm.openDataSettings}},[_c('icon',{attrs:{"icon":"cogs"}})],1):_vm._e()],1),(_vm.roles.includes('DEVELOPER'))?_c('div',{staticClass:"nav-intro nav-btn ml-2",attrs:{"id":"intro-start"}},[_c('btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Open Guide'),expression:"'Open Guide'"}],staticClass:"dropdown-nav-button",attrs:{"aria-label":"Open Introduction"},on:{"click":_vm.openIntro}},[_c('icon',{attrs:{"icon":"question"}})],1)],1):_vm._e(),_c('div',{staticClass:"nav-user-container nav-btn ml-2",attrs:{"id":"intro-user"}},[_c('dropdown',{staticClass:"dropdown",attrs:{"right":"","no-caret":"","toggle-class":"dropdown-nav-button"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"letter"},[_vm._v(_vm._s(_vm._f("upperCase")(_vm.email[0])))])]},proxy:true}])},[_c('div',{staticClass:"column-card"},[_c('div',{staticClass:"avatar"},[_vm._v(_vm._s(_vm._f("upperCase")(_vm.email[0])))]),_c('div',{staticClass:"email",domProps:{"textContent":_vm._s(_vm.email || 'strap.researcher-trial@bioappdev.org')}}),_c('div',{staticClass:"role",domProps:{"innerHTML":_vm._s(_vm.roleDisplay)}})]),_c('dropdown-button',{staticClass:"logout-button",on:{"click":function($event){return _vm.$router.push({ name: 'logout' })}}},[_c('icon',{staticClass:"absolute",attrs:{"icon":"sign-out-alt"}}),_vm._v("Logout ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }