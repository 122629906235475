<template>
  <div class="grid-layout" :class="gridClass">
    <slot></slot>
  </div>
</template>

<script>
  function layoutValidator(value) {
    return value.length === 3
  }
  export default {
    name: 'layout',
    props: {
      columns: {
        type: Array,
        default: () => [18, 18, 18],
        validator: layoutValidator,
      },
      rows: {
        type: Array,
        default: () => [2, 2, 2],
        validator: layoutValidator,
      },
    },
    computed: {
      gridClass() {
        return [
          this.buildClass('columns', this.columns),
          this.buildClass('rows', this.rows),
        ]
      },
    },
    methods: {
      buildClass(type, model) {
        return `${type}-sm-${model[0]} ${type}-md-${model[1]} ${type}-lg-${model[2]}`
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.grid-layout {
  display: grid;
  min-width: 0;

  @for $i from 3 through 26 {

    @include media-breakpoint-down(sm) {
      grid-gap: 1rem;
      &.columns-sm-#{$i} { grid-template-columns: repeat($i, 1fr) }
      &.rows-sm-#{$i} { grid-auto-rows: #{$i}px }
    }

    @include media-breakpoint-only(md) {
      grid-gap: 1.5rem;
      &.columns-md-#{$i} { grid-template-columns: repeat($i, 1fr) }
      &.rows-md-#{$i} { grid-auto-rows: #{$i}px }
    }

    @include media-breakpoint-up(lg) {
      grid-gap: 1.5rem;
      &.columns-lg-#{$i} { grid-template-columns: repeat($i, 1fr) }
      &.rows-lg-#{$i} { grid-auto-rows: #{$i}px }
    }
    
  }
}

</style>
