<template>
  <div>

    <modal class="build-info-modal" v-model="open" hide-footer hide-header size="sm">
      <div class="build-modal-header">
        <h2>Build Information</h2>
        <btn v-tooltip="'Close'" aria-label="Close" variant="outline-circle" @click="open = false">
          <icon icon="times"/>
        </btn>
      </div>

      <ul class="version-list" v-if="open">
        <li class="version" v-for="(entity, index) in entities" :key="index">
          <div class="top">
            <h5 v-html="entity.name" v-if="entity.name !== 'Swagger Page Link'"></h5>
            <router-link v-else :to="entity.link" class="nav-logo-container" target="_blank">Swagger Page</router-link>
          </div>

          <div class="bottom" v-if="entity.name !== 'Swagger Page Link'">
            <async-data-wrapper :query="entity.query">
              <template v-slot="{ _state }">
                <state-handler class="content" v-bind="_state">
                  <template #error>
                    <span></span>
                  </template>
                  <template #response>
                    <span class="time">{{$h.get(_state, 'response.buildTime', '') | date}}</span>
                  </template>
                </state-handler>
              </template>
            </async-data-wrapper>
          </div>
        </li>
      </ul>
    </modal>

    <!-- link -->
    <a class="build-information-anchor" @click="open = true">API Build Information</a>

  </div>
</template>

<script>
  import Modal from 'bootstrap-vue/es/components/modal/modal'
  import DataMixin from '@/mixins/DataMixin'

  export default {
    name: 'app-footer-build-info',
    mixins: [ DataMixin ],
    components: { Modal },
    data() {
      return {
        open: false,
        entities: [
          {
            name: 'Study',
            query: {
              endpoint: 'trials/version'
            }
          },
          {
            name: 'Person',
            query: {
              baseURL: 'investigators',
              endpoint: 'persons/version'
            }
          },
          {
            name: 'Organization',
            query: {
              baseURL: 'sites',
              endpoint: 'organizations/version'
            }
          },
          {
            name: 'Container',
            query: {
              baseURL: 'containers',
              endpoint: 'containers/version'
            }
          },
          {
            name: 'Participants',
            query: {
              baseURL: 'participants',
              endpoint: 'patients/version'
            }
          },
          {
            name: 'Swagger Page Link',
            link: '/swagger'
          }
        ]
      }
    },
    computed: {
      authToken() {
        return this.$h.get(JSON.parse(localStorage.getItem('okta-token-storage')), 'idToken.idToken', '')
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.build-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  h2 {
    flex: 0 0 auto;
    font-size: 1.25rem;
    color: $dark;
  }
}

.build-information-anchor {
  color: white!important;
  text-decoration: underline!important;
  cursor: pointer;
}
.version-list {
  margin: 0; padding: 0;
}
.version {
  margin: 0; padding: 1rem 0;
  display: block;
  border-bottom: solid thin $n2;


  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  >.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 1rem;
      color: $text;
    }
  }
  .time {
    margin-top: .75rem;
    color: $dark;
    font-weight: $thin;
    font-size: .9rem;
  }
}
.version-block {
  display: flex; 
  align-items: center;

  margin-bottom: .75rem;
  padding-bottom: .75rem;
  border-bottom: solid thin fade-out(black, .95);

  >.title {
    flex: 0 0 120px;
    font-size: 1.1rem;
    font-weight: 500;
    color: $dark;
  }
  .content {
    flex: 0 1 100%;
    background-color: transparent;

    .time {
      font-size: .9rem;
      color: black;
      font-weight: 500;
      background-color: transparent;
      text-align: right;
    }
  }

  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  span {
    color: black;
  }
}
</style>
