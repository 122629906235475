import router from '@/router'
import store from '@/store'
import {
  objectArrayToString,
  titleCase,
  replaceUnderscores,
  date
} from '@/services/FilterService'
import get from 'lodash/get'
import { addTitle } from './helperFuncs'
import { hasAccess } from '@/services/RoleService'

function linkRenderer (name, id) {
  const route = { name, params: { id } }
  const link = document.createElement('a')
  const href = router.resolve(route).href

  link.href = `${href}/${id}`
  link.innerText = id

  link.addEventListener('click', e => {
    e.preventDefault()
    router.push({ path: `/studies/${id}` })
  })

  return link
}

export default [
  {
    // nci id
    field: 'nci_id',
    exportField: 'nci_id',
    headerName: 'NCI ID',
    exportName: 'NCI ID',
    headerClass: addTitle,
    width: 135,
    minWidth: 135,
    cellClass: 'link',
    linkTo: id => ({ path: `/studies/${id}` }),
    cellRenderer (params) {
      return linkRenderer('studies', params.value)
    },
    editable: true,
    hide: false
  },
  {
    // nct_id
    field: 'nct_id',
    exportField: 'nct_id',
    headerName: 'NCT ID',
    exportName: 'NCT ID',
    headerClass: addTitle,
    width: 135,
    minWidth: 145,
    cellClass: 'link',
    onCellClicked (col) {
      router.push({ path: `/ctgov/${col.value}` })
    },
    hide: false
  },
  // lead_organization_id
  {
    field: 'lead_organization_id',
    exportField: 'lead_organization_id',
    headerName: 'Lead Org. Study ID',
    exportName: 'Lead Org. Study ID',
    exportLabel: 'Lead Org. Study ID',
    headerClass: addTitle,
    width: 230,
    minWidth: 230,
    suppressSorting: true,
    hide: true
  },
  // ctep_id
  {
    field: 'ctep_id',
    exportField: 'ctep_id',
    headerName: 'CTEP ID',
    exportName: 'CTEP ID',
    headerClass: addTitle,
    width: 140,
    minWidth: 140,
    hide: true
  },
  // dcp_id
  {
    field: 'dcp_id',
    exportField: 'dcp_id',
    headerName: 'DCP ID',
    exportName: 'DCP ID',
    headerClass: addTitle,
    width: 135,
    minWidth: 135,
    hide: true
  },
  // ccr_id
  {
    field: 'ccr_id',
    exportField: 'ccr_id',
    headerName: 'CCR ID',
    exportName: 'CCR ID',
    headerClass: addTitle,
    width: 135,
    minWidth: 135,
    hide: true
  },
  // NCI Program ID(s)
  {
    field: 'nci_programs',
    exportField: 'nci_programs',
    headerName: 'NCI Program ID(s)',
    exportName: 'NCI Program ID(s)',
    headerClass: addTitle,
    width: 200,
    minWidth: 200,
    suppressSorting: true,
    valueFormatter: ({ value }) => {
      if (value !== undefined && Array.isArray(value)) {
        return value
          .reduce((acc, cur) => {
            return [...acc, cur.program]
          }, [])
          .join(', ')
      } else {
        return ''
      }
    },
    hide: false
  },
  // title
  {
    field: 'title',
    exportField: 'title',
    headerName: 'Official Title',
    exportName: 'Official Title',
    headerClass: addTitle,
    width: 600,
    minWidth: 200,
    hide: true,
    editable: true
  },
  {
    // public_title
    field: 'public_title',
    exportField: 'public_title',
    headerName: 'Brief Title',
    exportName: 'Brief Title',
    headerClass: addTitle,
    width: 400,
    minWidth: 200,
    hide: false,
    editable: true,
    rowDrag: true
  },
  {
    // phase
    field: 'phase',
    exportField: 'phase',
    headerName: 'Phase',
    exportName: 'Phase',
    headerClass: addTitle,
    width: 135,
    minWidth: 135,
    hide: true
  },
  {
    // study_type
    field: 'study_type',
    exportField: 'study_type',
    headerName: 'Clinical Research Category',
    exportName: 'Clinical Research Category',
    headerClass: addTitle,
    width: 280,
    minWidth: 280,
    hide: true,
    valueFormatter: col => titleCase(replaceUnderscores(col.value))
  },
  {
    // primary_purpose
    field: 'primary_purpose',
    exportField: 'primary_purpose',
    headerName: 'Primary Purpose',
    exportName: 'Primary Purpose',
    headerClass: addTitle,
    width: 200,
    minWidth: 200,
    hide: true,
    valueFormatter: col => titleCase(replaceUnderscores(col.value))
  },
  // {
  //   // consortia_trial_category (not show up)
  //   field: 'consortia_trial_category',
  //   exportField: 'consortia_trial_category',
  //   headerName: 'Consortia Study Category',
  //   exportName: 'Consortia Study Category',
  //   headerClass: addTitle,
  //   width: 170,
  //   render() {
  //     return false
  //   },
  //   hide: true,
  // },
  {
    // study_source
    field: 'study_source',
    exportField: 'study_source',
    headerName: 'Study Source',
    exportName: 'Study Source',
    headerClass: addTitle,
    width: 200,
    minWidth: 200,
    hide: true,
    valueFormatter: col => {
      const value = Object.prototype.hasOwnProperty.call(col.data, "consortia_trial_category")
        ? col.data.consortia_trial_category
        : col.value
      return titleCase(replaceUnderscores(value))
    }
  },
  {
    // current_trial_status
    field: 'current_trial_status',
    exportField: 'current_trial_status',
    headerName: 'Current Study Status',
    exportName: 'Current Study Status',
    headerClass: addTitle,
    width: 240,
    minWidth: 240,
    valueFormatter: col => titleCase(replaceUnderscores(col.value)),
    hide: false
  },
  {
    // current_trial_status_date
    field: 'current_trial_status_date',
    exportField: 'current_trial_status_date',
    headerName: 'Current Study Status Date',
    exportName: 'Current Study Status Date',
    headerClass: addTitle,
    width: 275,
    minWidth: 275,
    valueFormatter: col => date(col.value, 'MMM DD, YYYY'),
    hide: true
  },
  {
    // start_date_type
    field: 'start_date_type',
    exportField: 'start_date_type',
    headerName: 'Start Date Type',
    exportName: 'Start Date Type',
    headerClass: addTitle,
    width: 170,
    minWidth: 170,
    suppressSorting: true,
    valueFormatter: col =>
      titleCase(replaceUnderscores(get(col, 'value', ''))).split(' ')[0],
    hide: true
  },
  {
    // start_date_value
    field: 'start_date_value',
    exportField: 'start_date_value',
    headerName: 'Start Date',
    exportName: 'Start Date',
    headerClass: addTitle,
    width: 190,
    minWidth: 190,
    valueFormatter: col => date(col.value, 'MMM DD, YYYY'),
    hide: true
  },
  {
    // completion_date_type
    field: 'completion_date_type',
    exportField: 'completion_date_type',
    headerName: 'Completion Date Type',
    exportName: 'Completion Date Type',
    headerClass: addTitle,
    width: 230,
    minWidth: 230,
    suppressSorting: true,
    valueFormatter: col =>
      titleCase(replaceUnderscores(get(col, 'value', ''))).split(' ')[0],
    hide: true
  },
  {
    // completion_date_value
    field: 'completion_date_value',
    exportField: 'completion_date_value',
    headerName: 'Completion Date',
    exportName: 'Completion Date',
    headerClass: addTitle,
    width: 220,
    minWidth: 220,
    valueFormatter: col => date(col.value, 'MMM DD, YYYY'),
    hide: true
  },
  // primary_completion_date_type
  {
    field: 'primary_completion_date_type',
    exportField: 'primary_completion_date_type',
    headerName: 'Primary Completion Date Type',
    exportName: 'Primary Completion Date Type',
    headerClass: addTitle,
    width: 270,
    minWidth: 270,
    suppressSorting: true,
    valueFormatter: col =>
      titleCase(replaceUnderscores(get(col, 'value', ''))).split(' ')[0],
    hide: true
  },
  // primary_completion_date_value
  {
    field: 'primary_completion_date_value',
    exportField: 'primary_completion_date_value',
    headerName: 'Primary Completion Date',
    exportName: 'Primary Completion Date',
    headerClass: addTitle,
    width: 280,
    minWidth: 280,
    valueFormatter: col => date(col.value, 'MMM DD, YYYY'),
    hide: true
  },
  //posted date
  {
    field: 'results_first_posted_datetime',
    exportField: 'results_first_posted_datetime',
    headerName: 'Results Posted Date',
    exportName: 'Results Posted Date',
    headerClass: addTitle,
    width: 190,
    minWidth: 190,
    hide: true,
    valueFormatter: col => date(col.value, 'MMM DD, YYYY')
  },
  //Submitted date
  {
    field: 'results_first_submitted_datetime',
    exportField: 'results_first_submitted_datetime',
    headerName: 'Results Submitted Date',
    exportName: 'Results Submitted Date',
    headerClass: addTitle,
    width: 190,
    minWidth: 190,
    hide: true,
    valueFormatter: col => date(col.value, 'MMM DD, YYYY')
  },
  {
    // principal_investigators
    field: 'principal_investigators',
    exportField: 'principal_investigators.name',
    headerName: 'Principal Investigators',
    exportName: 'Principal Investigators',
    headerClass: addTitle,
    width: 220,
    minWidth: 220,
    suppressSorting: true,
    cellClass: 'link',
    linkTo: value => ({ path: `/investigators/${get(value, '0.id')}` }),
    valueFormatter: col => get(col, 'value.0.name', ''),
    onCellClicked: col =>
      router.push({ path: `/investigators/${get(col, 'value.0.id')}` }),
    hide: true
  },
  // lead_organizations
  {
    field: 'lead_organizations',
    exportField: 'lead_organizations.name',
    headerName: 'Lead Organization',
    exportName: 'Lead Organization',
    headerClass: addTitle,
    sortKey: 'lead_organizations.name',
    width: 250,
    minWidth: 250,
    cellClass: 'link',
    linkTo: value => ({ path: `/organizations/${value.id}` }),
    valueFormatter: col => {
      const name = get(col, 'value.name')
      return name || `Name not found (${get(col, 'value.id', 'No ID')})`
    },
    onCellClicked: col =>
      router.push({ path: `/organizations/${get(col, 'value.id')}` }),
    hide: false
  },
  // lead organization family
  {
    field: 'lead_organization_family',
    exportField: 'lead_organization_family',
    headerName: 'Lead Organization Family',
    exportName: 'Lead Organization Family',
    headerClass: addTitle,
    width: 500,
    minWidth: 300,
    hide: true
  },
  {
    // lead_diseases
    field: 'lead_diseases',
    exportField: 'lead_diseases.name',
    headerName: 'Lead Diseases/Conditions',
    exportName: 'Lead Diseases/Conditions',
    headerClass: addTitle,
    width: 250,
    minWidth: 250,
    suppressSorting: true,
    valueFormatter: ({ data }) => {
      const leadDiseases = store.getters['settings/useOriginalDiseases']
        ? data.lead_diseases
        : data.lead_diseases2
      const maintype =
        leadDiseases && leadDiseases.filter(i => i.type !== 'subtype')
      return maintype ? maintype.map(i => i.name).join(', ') : ''
    },
    hide: true
  },
  {
    // lead_diseases
    field: 'lead_diseases2',
    exportField: 'lead_diseases2.name',
    headerName: 'Lead Diseases/Conditions',
    exportName: 'Lead Diseases/Conditions',
    headerClass: addTitle,
    width: 250,
    minWidth: 250,
    suppressSorting: true,
    hide: true,
    render () {
      return false
    }
  },
  {
    // total_accrual
    field: 'total_accruals',
    exportField: 'total_accruals',
    headerName: 'Actual Enrollment',
    exportName: 'Actual Enrollment',
    headerClass: addTitle,
    width: 190,
    minWidth: 190,
    hide: true,
    suppressSorting: true,
    // cellClass: 'link',
    // onCellClicked: (col) => {
    //   store.dispatch('form/clearForm', 'participants-filters')
    //   router.push({
    //     name: 'participants',
    //     params: {
    //       initialValues: {
    //         nci_id: get(col, 'data.nci_id'),
    //       },
    //     },
    //   })
    // },
    render () {
      return (
        hasAccess('participants') && !store.getters['settings/containerEnabled']
      )
    }
  },
  {
    // min_target_accrual
    field: 'min_target_accrual',
    exportField: 'min_target_accrual',
    headerName: 'Target Enrollment',
    exportName: 'Target Enrollment',
    headerClass: addTitle,
    width: 210,
    minWidth: 210,
    hide: true
  },
  {
    // record_verification_date
    field: 'record_verification_date',
    exportField: 'record_verification_date',
    headerName: 'Record Verification Date',
    exportName: 'Record Verification Date',
    headerClass: addTitle,
    width: 270,
    minWidth: 270,
    valueFormatter: col => date(col.value, 'MMM DD, YYYY'),
    hide: true
  },
  {
    // va_associated
    field: 'va_associated',
    exportField: 'va_associated',
    headerName: 'VA',
    exportName: 'VA',
    headerClass: addTitle,
    width: 100,
    minWidth: 100,
    valueFormatter: col => (col.value ? 'Yes' : 'No'),
    hide: true
  },
  // Division Program
  {
    field: 'grant_program',
    exportField: 'grants.nci_division_program',
    headerName: 'NCI Division/Program',
    exportName: 'NCI Division/Program',
    headerClass: addTitle,
    width: 250,
    minWidth: 200,
    suppressSorting: true,
    valueFormatter: col => {
      if (col.data.grant_program !== undefined) {
        return col.data.grant_program.join(', ')
      } else {
        return ''
      }
    },
    hide: true
  },
  // Funding Mechanism
  {
    field: 'grant_funding',
    exportField: 'grants.id',
    headerName: 'Funding Mechanism',
    exportName: 'Funding Mechanism',
    headerClass: addTitle,
    width: 400,
    minWidth: 400,
    suppressSorting: true,
    valueFormatter: col => {
      if (col.data.grant_funding !== undefined) {
        return col.data.grant_funding.join(', ')
      } else {
        return ''
      }
    },
    hide: true
  },
  // Is this Study NCI Funded?
  {
    field: 'nci_grant_provided',
    exportField: 'nci_grant_provided',
    headerName: 'Is this Study NCI Funded?',
    exportName: 'Is this Study NCI Funded?',
    headerClass: addTitle,
    width: 400,
    minWidth: 400,
    hide: true
  },
  // Sex Criteria
  {
    field: 'sex_criteria',
    exportField: 'sex_criteria',
    headerName: 'Sex Criteria',
    exportName: 'Sex Criteria',
    headerClass: addTitle,
    width: 130,
    minWidth: 130,
    hide: true,
    valueFormatter: col => {
      if (col.value !== undefined) {
        return col.value.replace('SEX: ', '')
      } else {
        return ''
      }
    }
  },
  // min age
  {
    field: 'min_age_in_years',
    exportField: 'min_age',
    headerName: 'Min. Age',
    exportName: 'Min. Age',
    headerClass: addTitle,
    width: 130,
    minWidth: 130,
    hide: true,
    valueFormatter: ({ data }) =>
      data.min_age_in_years !== undefined ? `${data.min_age}` : ''
  },
  // max age
  {
    field: 'max_age_in_years',
    exportField: 'max_age',
    headerName: 'Max. Age',
    exportName: 'Max. Age',
    headerClass: addTitle,
    width: 130,
    minWidth: 130,
    hide: true,
    valueFormatter: ({ data }) =>
      data.max_age_in_years !== undefined ? `${data.max_age}` : ''
  },
  //Submission Type
  {
    field: 'proprietary_trial',
    exportField: 'proprietary_trial',
    headerName: 'Submission Type',
    exportName: 'Submission Type',
    headerClass: addTitle,
    width: 200,
    minWidth: 200,
    hide: true
  },
  // section 801
  {
    field: 'section801_indicator',
    exportField: 'section801_indicator',
    headerName: 'Section 801',
    exportName: 'Section 801',
    headerClass: addTitle,
    width: 130,
    minWidth: 130,
    hide: true,
    valueFormatter: ({ value }) =>
      value !== undefined ? (value === true ? 'Yes' : 'No') : value
  },
  // sponsor
  {
    field: 'sponsor',
    exportField: 'sponsor',
    headerName: 'Sponsor',
    exportName: 'Sponsor',
    headerClass: addTitle,
    width: 130,
    minWidth: 130,
    hide: true
    //valueFormatter: ({value}) => value !== undefined ? (value === true ? 'Yes' : 'No') : value
  }
]
