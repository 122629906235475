import store from '@/store'
import get from 'lodash/get'
import sortBy from 'sort-by'
import { truthy } from './CommonsService'
import { mapToParticipantParams } from './ParticpantsQueryService'

// --------- main ---------
const studies = query => {
  return {
    baseURL: 'studies',
    endpoint: 'trials',
    params: {
      ...query.params,
      ...container(query.params)
    }
  }
}
const studiesFormatter = data => {
  let modifiedData = []

  data.data.forEach(item => {
    let grant_funding = []
    let grant_program = []
    let cMinAge, cMaxAge
    if (
      Object.prototype.hasOwnProperty.call(item, "grants") &&
      item.grants &&
      Array.isArray(item.grants)
    ) {
      item.grants.forEach(grantItem => {
        grant_program.push(grantItem.nci_division_program)
        grant_funding.push(grantItem.id)
      })
    }
    item.grant_funding = grant_funding
    item.grant_program = grant_program

    modifiedData.push(item)
  })
  return {
    data: modifiedData,
    total: data.total
  }
}

const studiesTotalAccrual = query => {
  return {
    baseURL: 'studies',
    endpoint: 'trials',
    params: {
      ...query.params,
      merge_accruals: 'total_accruals',
      post_search_include: ['nci_id', 'organizations.id'],
      ...summaryPatients(true)
    }
  }
}

const organizations = query => {
  return {
    baseURL: 'organizations',
    endpoint: 'organizations',
    params: {
      ...query.params,
      ...container()
    }
  }
}

const investigators = query => {
  if (isContainerActive()) {
    const { id } = store.getters['settings/activeContainer']
    const keyword = get(query, 'params._keyword')
    if (keyword !== undefined) {
      query.params['_keyword_field'] = ['containers.principal_investigator']
    }
    return {
      baseURL: 'studies',
      endpoint: 'trials/containers',
      params: {
        id,
        aggregation_agg_field: 'containers.principal_investigator',
        merge_agg_persons: ['first_name', 'last_name', 'ctep_id']
      }
    }
  } else {
    return {
      baseURL: 'investigators',
      endpoint: 'persons',
      params: query.params
    }
  }
}

const investigatorsFormatter = response => {
  if (!isContainerActive()) {
    return response
  } else {
    const data = get(
      response,
      `aggregations['containers.principal_investigator']`,
      []
    ).map(pi => ({
      id: pi.person_id,
      first_name: pi.person_first_name,
      last_name: pi.person_last_name,
      ctep_id: pi.person_ctep_id
    }))
    const total = data.length
    return { data, total }
  }
}

const participants = query => {
  if (isContainerActive()) {
    if (memberOnlyInformation()) {
      const { id } = store.getters['settings/activeContainer']
      return {
        baseURL: 'containers',
        endpoint: 'containers',
        params: {
          id,
          include: ['memberships', 'id'],
          merge_accruals: 'total_accruals',
          // ...query.params,
          ...summaryPatients(true)
        }
      }
    } else {
      return {
        baseURL: 'studies',
        endpoint: 'trials',
        params: {
          size: 10000,
          include: ['nci_id'],
          merge_accruals: 'total_accruals',
          ...container(),
          ...summaryPatients(true)
        }
      }
    }
  } else {
    return {
      baseURL: 'participants',
      endpoint: 'patients',
      params: {
        ...query.params,
        ...summaryPatients()
      }
    }
  }
}

const participantsFormatter = data => {
  if (isContainerActive()) {
    const path = memberOnlyInformation()
      ? 'data.0.total_accruals'
      : 'all_trials_total_accruals'
    return { total: get(data, path, 0) }
  } else {
    return data
  }
}

const studyOrganizations = query => {
  return {
    baseURL: 'studies',
    endpoint: 'trials/organizations',
    params: {
      ...query.params,
      ...container()
    }
  }
}
// --------- counts ---------
const studiesCount = () => {
  return {
    baseURL: 'studies',
    endpoint: 'trials',
    params: {
      include: 'none',
      ...container()
    }
  }
}
const organizationsCount = () => {
  return {
    baseURL: 'organizations',
    endpoint: 'organizations',
    params: {
      include: 'none',
      ...container()
    }
  }
}
const investigatorsCount = () => {
  if (isContainerActive()) {
    const { id } = store.getters['settings/activeContainer']
    return {
      baseURL: 'studies',
      endpoint: 'trials/containers',
      params: {
        id,
        aggregation_agg_field: 'containers.principal_investigator'
      }
    }
  } else {
    return {
      baseURL: 'investigators',
      endpoint: 'persons',
      params: {
        include: 'none'
      }
    }
  }
}

const investigatorsCountFormatter = data => {
  if (isContainerActive()) {
    const total = get(
      data,
      `aggregations['containers.principal_investigator']`,
      []
    ).length
    return { total }
  } else {
    return data
  }
}

const participantsCount = query => {
  if (isContainerActive()) {
    if (memberOnlyInformation()) {
      const { id } = store.getters['settings/activeContainer']
      return {
        baseURL: 'containers',
        endpoint: 'containers',
        params: {
          id,
          include: ['memberships', 'id'],
          merge_accruals: 'total_accruals',
          ...query.params,
          ...summaryPatients(true)
        }
      }
    } else {
      return {
        baseURL: 'studies',
        endpoint: 'trials',
        params: {
          size: 10000,
          include: ['nci_id'],
          merge_accruals: 'total_accruals',
          ...query.params,
          ...container(),
          ...summaryPatients(true)
        }
      }
    }
  } else {
    return {
      baseURL: 'participants',
      endpoint: 'patients',
      params: {
        include: 'none',
        ...query.params,
        ...summaryPatients()
      }
    }
  }
}

const patientsCount = patientParams => {
  const participantParams = mapToParticipantParams(patientParams, true)

  return {
    baseURL: 'participants',
    endpoint: 'patients',
    params: {
      include: 'none',
      sex_not: 'SYSTEM_GENERATED',
      ...participantParams,
      ...summaryPatients()
    }
  }
}

const patientsCountFormatter = response => {
  return response.total
}

const participantsPageTotal = queryParams => {
  return patientsCount(queryParams.params ? queryParams.params : queryParams)
}

const participantsPageTotalFormatter = response => {
  return response
}
const studyParticipantsCount = params => {
  let hasParams = Object.values(params).some(v => truthy(v))
  // When no filters applied
  if (!hasParams) {
    return {
      baseURL: 'participants',
      endpoint: 'patients',
      params: {
        include: 'none',
        ...summaryPatients()
      }
    }
  } else { //When Show summary selected in data setting
    const participantParams = mapToParticipantParams(params, true)
    if (store.getters['settings/showSummaryPatients']) {
      return {
        baseURL: 'participants',
        endpoint: 'patients',
        params: {
          include: 'none',
          ...participantParams,
          ...summaryPatients(true)
        }
      }
    }
    else {
      return {
        baseURL: 'participants',
        endpoint: 'patients',
        params: {
          include: 'none',
          sex_not: 'SYSTEM_GENERATED',
          ...participantParams,
          ...summaryPatients(true)
        }
      }
    }
  }
}

const participantsCountFormatter = data => {
  if (isContainerActive()) {
    const path = memberOnlyInformation()
      ? 'data.0.total_accruals'
      : 'all_trials_total_accruals'
    return { total: get(data, path, 0) }
  } else {
    return data
  }
}

const trialParticipantsCount = query => {
  return {
    baseURL: 'participants',
    endpoint: 'patients',
    params: {
      include: 'none',
      ...query.params,
      ...summaryPatients()
    }
  }
}

const containerParticipantsCount = query => {
  return {
    baseURL: 'studies',
    endpoint: 'trials',
    params: {
      size: 10000,
      include: ['nci_id', 'organizations.id'],
      merge_accruals: 'total_accruals',
      m_merge_accruals: 'total_accruals',
      ...query.params,
      ...container(),
      ...summaryPatients(true)
    }
  }
}

const containerParticipantsCountFormatter = data => {
  return {
    total: get(data, 'requested_container_all_trials_total_accruals', 0)
  }
}

// --------- container charts ---------
const studiesContainerChart = () => {
  return {
    baseURL: 'containers',
    endpoint: 'containers',
    params: {
      merge_trials: 'total_trials',
      chart_value: 'total_trials',
      chart_type: 'bar',
      chart_key: 'name',
      chart_title: 'x',
      size: 100,
      include: ['name', 'id', 'memberships'],
      chart_label_format: false
    }
  }
}
const participantsContainerChart = () => {
  return {
    baseURL: 'containers',
    endpoint: 'containers',
    params: {
      merge_accruals: 'total_accruals',
      chart_value: 'total_accruals',
      chart_type: 'bar',
      chart_key: 'name',
      chart_title: 'x',
      size: 100,
      include: ['name', 'id', 'memberships'],
      chart_label_format: false,
      ...summaryPatients(true)
    }
  }
}

// --------- study analytics ---------
const studyChart = query => {
  return createChartQuery(query.chart, query.type, 'studies', 'trials', {
    ...container(),
    ...query.params
  })
}
const topInterventions = query => {
  return {
    baseURL: 'studies',
    endpoint: 'trials/interventions',
    params: {
      ...query.params,
      sort: 'count',
      order: 'desc',
      // include: ['name', 'count'],
      size: 300,
      ...container()
    }
  }
}
const topInterventionsFormatter = data => {
  const rows = get(data, 'data', [])
  return {
    data: rows,
    total: rows.length
  }
}

// --------- participant analytics ---------
const participantsChart = query => {
  if (isContainerActive()) {
    if (memberOnlyInformation()) {
      const { id } = store.getters['settings/activeContainer']
      return createChartQuery(
        query.chart,
        query.type,
        'containers',
        'containers',
        {
          id,
          include: ['memberships', 'id', 'name'],
          merge_accruals: 'total_accruals',
          merge_accruals_merge_aggregates: query.chart,
          ...query.params,
          ...removeSummaryPatients(true)
        }
      )
    } else {
      return {
        baseURL: 'studies',
        endpoint: 'trials',
        params: {
          size: 9999,
          include: 'nci_id',
          merge_accruals: 'total_accruals',
          merge_accruals_merge_aggregates: query.chart,
          agg_field: query.chart,
          post_search_include: 'none',
          chart_type: query.type,
          chart_title: 'x',
          ...query.params,
          ...container(),
          ...removeSummaryPatients(true)
        }
      }
    }
  } else {
    return createChartQuery(
      query.chart,
      query.type,
      'participants',
      'patients',
      {
        ...query.params,
        ...removeSummaryPatients()
      }
    )
  }
}

const participantsChartFormatter = data => {
  let result
  if (isContainerActive()) {
    if (memberOnlyInformation()) {
      let total = 0
      get(data, 'chart_options.series.0.data', []).forEach(item => {
        total += get(item, 'value', item)
      })
      result = { ...data, total }
    } else {
      result = {
        ...data,
        total: data.all_trials_total_accruals
      }
    }
  } else {
    result = data
  }
  return result
}

// --------- lead diseases ---------

const leadDiseases = query => {
  if (store.getters['settings/useOriginalDiseases']) {
    return {
      baseURL: 'studies',
      endpoint: 'trials',
      params: {
        agg_field: 'lead_disease',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        include: 'none'
      }
    }
  } else {
    return {
      baseURL: 'studies',
      endpoint: 'trials',
      params: {
        agg_field: 'lead_disease2',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        include: 'none'
      }
    }
  }
}

const leadDiseasesFormatter = data => {
  // By default, API returns the data in the descending order of studies count
  // and that is how we will display the lead diseases within the "Studies by Lead Diseases" modal
  const aggregations = get(data, 'aggregations', {})
  let rows
  if (store.getters['settings/useOriginalDiseases']) {
    rows = aggregations['lead_disease'] || []
  } else {
    rows = aggregations['lead_disease2'] || []
  }

  return {
    data: rows,
    total: rows.length
  }
}

const topDiseases = query => {
  if (isContainerActive()) {
    if (memberOnlyInformation()) {
      const { id } = store.getters['settings/activeContainer']
      return {
        baseURL: 'containers',
        endpoint: 'containers',
        params: {
          id,
          merge_accruals: 'total_accruals',
          merge_accruals_merge_aggregates: 'evs_ctrp_display_name',
          merge_maintype: store.getters['settings/useOriginalDiseases'] ? 1 : 2,
          agg_field: 'evs_ctrp_display_name',
          agg_field_sort: 'count',
          agg_field_order: 'desc',
          ...query.params,
          include: ['memberships', 'id'],
          ...summaryPatients(true)
        }
      }
    } else {
      return {
        baseURL: 'studies',
        endpoint: 'trials',
        params: {
          merge_accruals: 'total_accruals',
          post_search_include: 'none',
          merge_accruals_merge_aggregates: 'evs_ctrp_display_name',
          merge_maintype: store.getters['settings/useOriginalDiseases'] ? 1 : 2,
          agg_field: 'evs_ctrp_display_name',
          agg_field_sort: 'count',
          agg_field_order: 'desc',
          size: 9999,
          ...query.params,
          ...container(),
          include: ['nci_id'],
          ...summaryPatients(true)
        }
      }
    }
  } else {
    // return {
    //   baseURL: 'participants',
    //   endpoint: 'patients/diseases',
    //   params: {
    //     include: ['count', 'name', 'codes'],
    //     type: 'maintype',
    //     type_not: 'subtype',
    //   },
    // }
    return {
      baseURL: 'participants',
      endpoint: 'patients',
      params: {
        agg_field: 'evs_ctrp_display_name',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        size: 1,
        merge_maintype: store.getters['settings/useOriginalDiseases'] ? 1 : 2,
        ...query.params,
        include: 'none',
        ...summaryPatients()
      }
    }
  }
}
const topDiseasesFormatter = data => {
  let rows = get(data, 'aggregations.evs_ctrp_display_name', [])
  rows.sort(sortBy('-doc_count'))
  return {
    data: rows,
    total: rows.length
  }
}
const topSites = query => {
  if (isContainerActive() && memberOnlyInformation()) {
    return {
      baseURL: 'containers',
      endpoint: 'containers/memberships',
      params: {
        post_search_include: 'none',
        merge_accruals: 'total_accruals',
        merge_accruals_merge_aggregates: 'organization_id',
        merge_agg_organizations: ['name'],
        merge_aggregates: 'organization_id',
        agg_field: 'organization_id',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        size: 1000,
        merge_accruals_nci_id: get(query, 'params.nci_id'),
        cid: store.getters['settings/activeContainer'].id,
        type: 'ORGANIZATION',
        ...summaryPatients(true)
      }
    }
  } else if (isContainerActive()) {
    return {
      baseURL: 'studies',
      endpoint: 'trials',
      params: {
        size: 9999,
        include: 'nci_id',
        merge_accruals: 'total_accruals',
        merge_accruals_merge_aggregates: 'organization_id',
        merge_agg_organizations: ['name'],
        merge_aggregates: 'organization_id',
        agg_field: 'organization_id',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        post_search_include: 'none',
        ...query.params,
        cid: store.getters['settings/activeContainer'].id,
        type: 'ORGANIZATION',
        ...summaryPatients(true)
      }
    }
  } else {
    return {
      baseURL: 'participants',
      endpoint: 'patients',
      params: {
        include: 'none',
        agg_field: 'organization_id',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        merge_agg_organizations: 'name',
        ...query.params,
        ...summaryPatients()
      }
    }
  }
}
const topSitesFormatter = data => {
  const rows = get(data, 'aggregations.organization_id', [])
  return {
    data: rows,
    total: rows.length
  }
}
const participantsTopStudies = query => {
  if (isContainerActive() && memberOnlyInformation()) {
    return {
      baseURL: 'containers',
      endpoint: 'containers/memberships',
      params: {
        post_search_include: 'none',
        merge_accruals: 'total_accruals',
        merge_accruals_merge_aggregates: 'nci_id',
        merge_aggregates: 'nci_id',
        agg_field: 'nci_id',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        size: 1000,
        cid: store.getters['settings/activeContainer'].id,
        type: 'ORGANIZATION',
        ...summaryPatients(true)
      }
    }
  } else if (isContainerActive()) {
    return {
      baseURL: 'studies',
      endpoint: 'trials',
      params: {
        size: 9999,
        include: 'nci_id',
        merge_accruals: 'total_accruals',
        merge_accruals_merge_aggregates: 'nci_id',
        merge_aggregates: 'nci_id',
        agg_field: 'nci_id',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        post_search_include: 'none',
        ...query.params,
        m_cid: store.getters['settings/activeContainer'].id,
        m_type: 'ORGANIZATION',
        ...summaryPatients(true)
      }
    }
  } else {
    return {
      baseURL: 'participants',
      endpoint: 'patients',
      params: {
        include: 'none',
        agg_field: 'nci_id',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        ...query.params,
        ...summaryPatients()
      }
    }
  }
}
const participantsTopStudiesFormatter = data => {
  const rows = get(data, 'aggregations.nci_id', [])
  return {
    data: rows,
    total: rows.length
  }
}
const participantsTrialChart = query => {
  if (isContainerActive() && memberOnlyInformation()) {
    return {
      baseURL: 'containers',
      endpoint: 'containers/memberships',
      params: {
        post_search_include: 'none',
        merge_accruals: 'total_accruals',
        merge_aggregates: query.chart,
        merge_accruals_merge_aggregates: query.chart,
        agg_field: query.chart,
        size: 1000,
        merge_accruals_nci_id: get(query, 'params.nci_id'),
        chart_type: query.type || 'pie',
        chart_title: 'x',
        cid: store.getters['settings/activeContainer'].id,
        type: 'ORGANIZATION',
        ...summaryPatients(true)
      }
    }
  } else {
    return createChartQuery(
      query.chart,
      query.type,
      'participants',
      'patients',
      {
        ...query.params,
        ...summaryPatients()
      }
    )
  }
}
const participantsTrialChartFormatter = data => {
  if (isContainerActive() && memberOnlyInformation()) {
    let total = 0
    get(data, 'chart_options.series.0.data', []).forEach(item => {
      total += get(item, 'value', item)
    })
    return { ...data, total }
  } else {
    return data
  }
}
const topTrialDiseases = query => {
  if (isContainerActive() && memberOnlyInformation()) {
    return {
      baseURL: 'containers',
      endpoint: 'containers/memberships',
      params: {
        post_search_include: 'none',
        merge_accruals: 'total_accruals',
        merge_accruals_merge_aggregates: 'evs_ctrp_display_name',
        merge_aggregates: 'evs_ctrp_display_name',
        merge_maintype: store.getters['settings/useOriginalDiseases'] ? 1 : 2,
        agg_field: 'evs_ctrp_display_name',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        size: 1000,
        merge_accruals_nci_id: get(query, 'params.nci_id'),
        cid: store.getters['settings/activeContainer'].id,
        type: 'ORGANIZATION',
        ...summaryPatients(true)
      }
    }
  } else if (isContainerActive()) {
    return {
      baseURL: 'studies',
      endpoint: 'trials',
      params: {
        merge_accruals: 'total_accruals',
        post_search_include: 'none',
        merge_accruals_merge_aggregates: 'evs_ctrp_display_name',
        merge_maintype: store.getters['settings/useOriginalDiseases'] ? 1 : 2,
        agg_field: 'evs_ctrp_display_name',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        size: 9999,
        ...query.params,
        ...container(),
        include: ['nci_id'],
        ...summaryPatients(true)
      }
    }
  } else {
    return {
      baseURL: 'participants',
      endpoint: 'patients',
      params: {
        agg_field: 'evs_ctrp_display_name',
        agg_field_sort: 'count',
        agg_field_order: 'desc',
        merge_maintype: store.getters['settings/useOriginalDiseases'] ? 1 : 2,
        size: 1,
        ...query.params,
        include: query.params.include ? query.params.include : 'none',
        post_search_include: 'none',
        ...summaryPatients()
      }
    }
  }
}
const topTrialDiseasesFormatter = data => {
  let rows = get(data, 'aggregations.evs_ctrp_display_name', [])
  rows.sort(sortBy('-doc_count'))
  return {
    data: rows.sort((a, b) => b.doc_count - a.doc_count),
    total: rows.length
  }
}

const participantsStudysCount = query => {
  if (isContainerActive()) {
    if (memberOnlyInformation()) {
      const { id } = store.getters['settings/activeContainer']
      return {
        baseURL: 'containers',
        endpoint: 'containers',
        params: {
          id,
          include: ['memberships', 'id'],
          merge_accruals: 'total_accruals',
          ...query.params,
          ...summaryPatients(true)
        }
      }
    } else {
      return {
        baseURL: 'studies',
        endpoint: 'trials',
        params: {
          size: 50000,
          include: ['nci_id'],
          merge_accruals: 'total_accruals',
          ...query.params,
          ...container(),
          ...summaryPatients(true)
        }
      }
    }
  } else {
    return {
      baseURL: 'studies',
      endpoint: 'trials',
      params: {
        size: 50000,
        include: ['nci_id'],
        merge_accruals: 'total_accruals',
        ...query.params,
        ...container(),
        ...summaryPatients(true)
      }
    }
  }
}

const participantsStudysCountFormatter = data => {
  if (isContainerActive()) {
    const path = memberOnlyInformation()
      ? 'data.0.total_accruals'
      : 'all_trials_total_accruals'
    return { total: get(data, path, 0) }
  } else {
    return data
  }
}

const studyParticipantsCountFormatter = response => {
  const data = Object.prototype.hasOwnProperty.call(response, "all_trials_total_accruals") 
    ? response.all_trials_total_accruals
    : response.total
  return data
}

// helper
function createChartQuery (
  agg_field,
  chart_type = 'pie',
  baseURL = 'studies',
  endpoint = 'trials',
  params
) {
  return {
    baseURL,
    endpoint,
    params: {
      include: 'none',
      chart_type,
      agg_field,
      ...params
    }
  }
}
function areSummaryPatientsActive () {
  return store.getters['settings/showSummaryPatients']
}
function isContainerActive () {
  return store.getters['settings/containerEnabled']
}
function memberOnlyInformation () {
  return store.getters['settings/memberInformation']
}
function container (params = {}) {
  const container = store.getters['settings/activeContainer']
  if (isContainerActive()) {
    return {
      m_cid: params.m_cid || container.id,
      m_type: 'organization'
    }
  } else {
    return {}
  }
}
function summaryPatients (container = false) {
  if (!areSummaryPatientsActive()) {
    return {
      [container ? 'merge_accruals_sex_not' : 'sex_not']: 'SYSTEM_GENERATED'
      // [container
      //   ? 'merge_accruals_races_string_not'
      //   : 'races_string_not']: 'SYSTEM_GENERATED',
      // [container
      //   ? 'merge_accruals_ethnicity_not'
      //   : 'ethnicity_not']: 'SYSTEM_GENERATED',
    }
  } else {
    return {}
  }
}
function removeSummaryPatients (container = false) {
  return {
    [container ? 'merge_accruals_sex_not' : 'sex_not']: 'SYSTEM_GENERATED'
    // [container
    //   ? 'merge_accruals_races_string_not'
    //   : 'races_string_not']: 'SYSTEM_GENERATED',
    // [container
    //   ? 'merge_accruals_ethnicity_not'
    //   : 'ethnicity_not']: 'SYSTEM_GENERATED',
  }
}

const studies2 = query => {
  return {
    baseURL: 'studies',
    endpoint: 'trials',
    params: {
      ...query.params,
      ...container(query.params)
    }
  }
}

export default {
  queries: {
    studies,
    studies2,
    studiesTotalAccrual,
    organizations,
    investigators,
    participants,
    studyOrganizations,

    studiesCount,
    organizationsCount,
    investigatorsCount,
    participantsCount,
    trialParticipantsCount,
    containerParticipantsCount,

    studyChart,
    participantsChart,
    participantsTrialChart,

    studiesContainerChart,
    participantsContainerChart,

    leadDiseases,
    // leadDiseases2,

    topTrialDiseases,
    topDiseases,
    topInterventions,
    topSites,
    participantsTopStudies,

    participantsStudysCount,
    studyParticipantsCount,
    patientsCount,
    participantsPageTotal
  },
  formatters: {
    studiesFormatter,
    studiesTotalAccrualFormatter: studiesFormatter,
    investigatorsCountFormatter,
    participantsCountFormatter,
    participantsChartFormatter,
    containerParticipantsCountFormatter,
    topDiseasesFormatter,
    topInterventionsFormatter,
    investigatorsFormatter,
    leadDiseasesFormatter,
    topSitesFormatter,
    participantsTrialChartFormatter,
    topTrialDiseasesFormatter,
    participantsFormatter,
    participantsTopStudiesFormatter,
    participantsStudysCountFormatter,
    studyParticipantsCountFormatter,
    patientsCountFormatter,
    participantsPageTotalFormatter
  }
}
