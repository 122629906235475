import { Getters } from "../utils";
import { truthy } from "@/services/CommonsService";

// this method builds the schema / form for selecting one or more Sex Filter value (Male, Female, All)
export function getSexSchema() {
  return [
    /* min age */
    {
      type: "select-field",
      id: "sex_criteria",
      group: {
        label: "Sex",
      },
      input: {
        multiple: true,
        closeOnSelect: false,
        preserveSearch: false,
        searchable: false,
        placeholder: "Select one or more sexes",
        options: [
          {
            label: "Male",
            value: "MALE",
          },
          {
            label: "Female",
            value: "FEMALE",
          },
          {
            label: "All",
            value: "ALL",
          },
        ],
      },
      get: Getters.array(),

    },
  ];
  
}
// This method builds the returnObject which is the params for the API request 
export function getSexValueObj(returnObject, value) {
  if (truthy(value)) {
    returnObject["sex_criteria"] = value.map(
      (sex) => sex.value
    );
  }
}

