<template>
  <e-charts ref="chart" :options="$activeOptions" :auto-resize="false" @click="$click"></e-charts>
</template>

<script>
  import ECharts from 'vue-echarts/components/ECharts'
  import 'echarts/lib/chart/line'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/legendScroll'
  import 'echarts/lib/component/dataZoom'
  import ChartMixin from '@/mixins/ChartMixin'
  import { abbreviate, localeString } from '@/services/FilterService'

  const text = '#3F546C'
  const textLight = '#93A1B2'

  // format label and value
  const labelFormatter = label => abbreviate(label, 15)
  const valueFormatter = label => localeString(label)
  const defaultFormatter = label => abbreviate(label, 25)

  const labelAxisNameTextStyle = { color: text }
  const valueAxisNameTextStyle = { color: textLight }

  export default {
    name: 'line-chart',
    components: { ECharts },
    mixins: [ ChartMixin ],
    props: {
      labelAxisName: {
        type: String,
      },
      valueAxisName: {
        type: String,
      },
      gridConfig: {
        type: Object
      },
      dataZoom: {
        type: Boolean,
        default: false
      },
      legend: {
        type: Boolean,
        default: false
      },
      rotate: {
        type: Number,
      },
      enableLabelSort: {
        type: Boolean,
        default: true
      },
      labelSortFunc: {
        type: Function,
        default: (a, b) => b.value - a.value
      }
    },
    data() {
      return {
        defaultOptions: {},
        expandedOptions: {},
        labelNames: []
      }
    },
    created() {
      const { defaultOptions, expandedOptions } = this.createChart()
      this.defaultOptions = defaultOptions
      this.expandedOptions = expandedOptions
    },
    methods: {
      createChart() {
        let legend = this.$optionDefault('legend', [])
        let tooltip = this.$optionDefault('tooltip', {})
        let series = this.$optionDefault('series', [])
        let labels = this.$h.get(this.options, 'xAxis.data', [])
        // let grid = {
        //   top: '32px',
        //   left: '40px',
        //   right: '48px',
        //   bottom: this.labelAxisName ? '108px' : '48px',
        //   containLabel: true
        // }

        let gridConfig = this.$h.cloneDeep(this.gridConfig)
        let grid = gridConfig.default
        let extraGrid = gridConfig.expanded

        let dataZoom = null
        let formattedLegend = {}

        // sort
        let dataTemp = this.getLabelValue(labels, series[0].data, this.labelSortFunc, this.enableLabelSort)
        series[0].data = this.$h.cloneDeep(dataTemp).map(data => data.value)
        this.labelNames = this.$h.cloneDeep(dataTemp).map(data => data.label)

        // set up axis
        const axises = this.getAxisData()
        const axisesExpanded = this.getAxisData(true)

        // color
        if (this.variant) {
          this.$colorBar(series[0])
          series[0].itemStyle = {
            color: '#004866'
          }
        }
        // data zoom
        if (this.dataZoom) {
          dataZoom = {
            show: true,
            start: 0,
            end: 100,
            bottom: '20px'
          }
        }

        // disable click
        if (this.disableClick) {
          series.forEach(serie => {
            serie.cursor = 'default'
          })
        }

        return {
          defaultOptions: {
            grid,
            series,
            ...axises,
            legend: formattedLegend,
            tooltip: legend.length ? { backgroundColor: 'grey' } : this.$optionMerge(tooltip, { trigger: 'axis' }),
          },
          expandedOptions: {
            dataZoom,
            ...axisesExpanded,
            grid: this.$optionMerge(grid, { ...extraGrid })
          }
        }
      },
      getLabelValue(labels, values, cb, flag) {
          let newData = []
          for (let i = 0; i < labels.length; ++i) {
              let obj = Object.assign({}, { label: labels[i], value: values[i] })
              newData.push(obj)
          }
          return flag ? newData.sort(cb) : newData
      },
      getAxisData(expanded = false) {
        let labelAxis = this.$optionDefault('xAxis', {})
        let valueAxis = this.$optionDefault('yAxis', {})
        let labelKey = 'xAxis'
        let valueKey = 'yAxis'

        return {
          [labelKey]: {
            ...labelAxis,
            data: this.labelNames,
            axisLabel: {
              formatter: this.getFormatter('label', expanded),
              textStyle: labelAxisNameTextStyle,
              rotate: this.rotate,
            },
            name: this.labelAxisName,
            nameLocation: 'middle',
            nameGap: expanded ? this.gridConfig.nameGap.labelAxis[0] : this.gridConfig.nameGap.labelAxis[1],
            nameTextStyle: {
              color: text,
              fontSize: 11,
              fontWeight: 600,
              padding: [0, 0, 0, 0]
            }
          },
          [valueKey]: {
            ...valueAxis,
            axisLabel: {
              formatter: this.getFormatter('value', expanded),
              textStyle: valueAxisNameTextStyle
            },
            name: this.valueAxisName,
            nameLocation: 'middle',
            nameGap: expanded ? this.gridConfig.nameGap.valueAxis[0] : this.gridConfig.nameGap.valueAxis[1],
            nameTextStyle: {
              color: text,
              fontWeight: 600,
              fontSize: 11,
              padding: [0, 0, 0, 0]
            }
          }
        }
      },
      getFormatter(type, expanded = false) {
        if (type === 'label') {
          return expanded ? defaultFormatter : labelFormatter
        }
        else {
          return valueFormatter
        }
      }
    }
  }
</script>