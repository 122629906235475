<template>
    <div id="accordion-header">
    <div id="accordion" class="accordion-container">
        <div v-for="(group, i) in getGroupsFilter()" :key="i" id="study-group-title" role="button"
            @click="toggleAccordion(i)" :class="[
                'accordion',
                {
                    'is-open':
                        isOpen.includes(i) ? isOpen.includes(i) : group.isGroupActive,
                },
            ]">
            <span>
                {{ group.groupTitle }}
                <span class="group-icon">
                    <icon v-if="isOpen.includes(i) && (group.isGroupActive || group.isGroupVisible)" class="plus-minus" :icon="'chevron-down'"></icon>
                    <icon v-else class="plus-minus" :icon="!isOpen.includes(i) ? 'chevron-up' : 'chevron-down'"></icon>
                </span>
            </span>
            <div class="accordion-body">
                <ul class="study-groupfilters-list default" id="study-group-subtitle" ref="scroll">
                    <li class="advanced-filter-list-item" v-for="(field, index) in group.filterInGroup.filter(function(item){
                        return item != null && item != undefined;
                    })"
                        v-if="(field.id === 'actual_enrollment' || field.id === 'target_enrollment') ? false:true" v-show="$h.get(field, 'show2', true)"  :id="`aside-${field.id}`" :key="index"
                        :class="[field.state, field.group.label, `tab-${field.tab}`]"
                        @click="toggleFieldAndExpandAccordion(field, i)">
                        <span v-html="field.groupingFilterDisplayLabel ? field.groupingFilterDisplayLabel.label : field.group.label"></span>
                        <span class="icons">
                          <icon key="check" class="plus-minus" v-if="field.state.active" :icon="'check'"></icon>
                          <icon :key="field.state.visible" class="plus-minus" v-else
                            :icon="field.state.visible ? 'minus' : 'plus'"></icon>
                        </span>
                    </li>
                </ul>
            </div> 
        </div>

        <!-- below is for filters that do not have a subfilter such as "study id" and "keyword" -->
        <div>
            <div id="study-group-title" role="button" v-for="(field, index) in  noSubfilterGroup" :key="index" @click="callfromSubFilterGroup(field)">
                <span v-if="field">
                    {{ field.studyFilterGroup.label }}
                    <span class="group-icon">
                            <icon key="check" class="plus-minus" v-if="field.state.active" :icon="'check'"></icon>
                            <icon :key="field.state.visible" class="plus-minus" v-else
                            :icon="field.state.visible ? 'minus' : 'plus'"></icon>
                    </span>
                </span>
            </div>
        </div>
        <ul class="data-view-filters-filter-list default" ref="scroll">
             <li class="advanced-filter-list-item clear" v-if="advancedSearch != null && advancedSearch.length"
                 @click.prevent="clearSearch">
                 <a href="#">Clear Search</a>
             </li>
         </ul>
    </div>
    </div>
</template>
<script>
import { truthy } from '@/services/CommonsService'
import { showAge } from '../../form/schemas/AgeSchema';
import { hasSECFilterValues } from '../../form/utils/EligibilityCriteria';
export default {
    name: "grouped-study-filters",
    inheritAttrs: false,
    components: {},

    props: {
        searchedFields: Array,
        toggleField: {},
        toggleFieldVisible: {},
        resetField: {},
        resetFilters: {},
        filtersActive: Boolean,
        showOnlyActiveFields: Function,
        tab: String,
        advancedSearch: String,
    },
    data() {
        return {

            toggleGroupVisible: false,
            isActive: null,
            studyGroupsReferenceTable: [
                { label: "Participant Characteristics" },
                { label: "Study Design & Status" },
                { label: "Study Location" },
                { label: "Study Funding Information" },
                { label: "Dates" },
            ],
            isOpen: [],
            isFirst: false,
            isKeyword: false,
        };
    }, 
    computed: {
        noSubfilterGroup() {
            // Return all the special filters such as "Study ID" that do not have a subfilter in Groups tab.
            const noSubfilterGroupIDs = ["ids-search-trial", "_keyword_form"];
            let orderedList = [];
           // the noSubfilters should be displayed in the order their id's are listed in noSubFilterGroupIds array
            noSubfilterGroupIDs.forEach(groupId => {
                orderedList.push(this.searchedFields?.find(item => {
                    return item.id == groupId;

                }))
            })
            return orderedList;
        }
    },
    watch:{
        searchedFields(){
            this.openAccordionForSearch();
        }
    },
    created() {
        this.isFirst = true
     },
    mounted() {
        this.getGroupsFilter();
        this.$nextTick(this.resetFilterState)
    },
    methods: {
        clearSearch(){
             this.$emit('clear-search');
         },
        openAccordionForSearch() {
            // Note: 'searchedField' determines what groups get expanded. 
            // 'searchedField' is updated when 'advancedSearch' is updated.
            if(this.advancedSearch.length > 0){
                // this.advancedSearch.length > 0 means user has entered something in the filter search box to search for a filter  
                let groupFilters = this.getGroupsFilter();
                let activeGroups = [];
                for(let i = 0; i < groupFilters.length; i++){
                    if(truthy(groupFilters[i].filterInGroup)){
                        activeGroups.push(i);
                    }
                }
                this.isOpen = activeGroups;
            }
            //if no advancedSearch is entered, set this.isOpen to empty to close the accordion, 
            else {
                //STRP-2644 - Adding a couple of checks and setting the isOpen=[] only if 'Keyword' is not selected the first time
                //Otherwise, it was closing the already open accordions when coming in for the first time 
                if (this.isFirst && this.isKeyword) {
                    //this.isOpen = [];
                    //Resetting the flags
                    this.isFirst = false;
                    this.isKeyword = false;
                } else {
                    this.isOpen = [];
                }
            }
        },
        callfromSubFilterGroup(field) {
            if (field.id === '_keyword_form' && this.advancedSearch.length == 0) {
                this.isKeyword = true;
            }
            this.toggleField(field);
        },
        resetFilterState() {
            this.searchedFields.filter(sfEl => {
                if (!truthy(sfEl.value)) {
                    sfEl.state.active = false;
                    sfEl.state.visible = false;
                } else {
                    if ((sfEl.id === 'study-participant-char-age' && (!showAge(sfEl.value, 'min') && !showAge(sfEl.value, 'max'))) || 
                        ((sfEl.id === 'study-participant-char-priorTherapy' && !hasSECFilterValues(sfEl.value, sfEl.id))) ||
                        ((sfEl.id === 'study-participant-char-sex' && ((!truthy(sfEl.value['sex_criteria']) || sfEl.value['sex_criteria'].length == 0))))) {
                        sfEl.state.active = false;
                        sfEl.state.visible = false;
                    }
                }
            });
        },
        toggleFieldAndExpandAccordion(field, i) {
            this.toggleAccordion(i);
            this.toggleField(field);
        },
        toggleAccordion(index) {
            //keep track of which accordion section is open or closed,
            if (this.isOpen.includes(index)) {
                this.isOpen = this.isOpen.filter((i) => i !== index);
                return;
            }
            this.isOpen.push(index);
        },

        getGroupsFilter() {
            const groupedFilterObject = [];
            
            this.studyGroupsReferenceTable.forEach(refGroup => {
                // set group - filter each searchFields (a - z filters) based on matched group label / group title
                let group = this.searchedFields.filter(sfEle => {
                    return sfEle.studyFilterGroup?.label === refGroup.label;
                });
                
                // Sort Participant Characteristics/Study Funding Information group in a special way (STRP-1984, 1987, 2363) in Groups tab.
                if(truthy(group)){
                    group = this.sortGroupInCustomizedOrder(refGroup.label, group);
                }

                let groupedFilters = this.setGroupedFilters(refGroup, group);
                groupedFilterObject.push(groupedFilters);
            });
            return groupedFilterObject;
        },

        sortGroupInCustomizedOrder(groupName, group){
            /* 
                Creates a special display order for the Groups tab
            */
            let sortedGroup = [];

            /* 
                specialOrderSet has all the filters that should be sorted in a special way.
                All other filters not in the specialOrderSet but in the same group should not be ignored 
                and should be added at the end 
            */
            let specialOrderSet = new Set([]);
            if(groupName === "Participant Characteristics"){
                specialOrderSet = new Set(["disease", "biomarker", "study-participant-char-priorTherapy", "study-participant-char-age", "study-participant-char-sex"]);
            }else if(groupName === "Study Design & Status"){
                specialOrderSet = new Set(["study_type", "phase", "primary_purpose", "lead-disease", "current_trial_status_grouping", "interventions"]);
            }
            else if(groupName === "Study Funding Information"){
                specialOrderSet = new Set(["study_source", "division_department_id", "nci_programs.program", "funding"]);
            }
            else if(groupName === "Dates"){
                specialOrderSet = new Set(["date-range", "registration-date-range"]);
            }
            else if(groupName === "Study Location"){
                specialOrderSet = new Set(["investigator", "center-org", "organization", "ids-search-org", "va_associated"]);
            }

            for (const filterName of specialOrderSet) {
                sortedGroup.push(group.find(filter => filter.id === filterName));
            }

            // Adding the rest of the filters NOT in the specialOrderSet
            group.forEach( (filter) => {
                if(!specialOrderSet.has(filter.id)){
                    sortedGroup.push(filter);
                }
            })
            return sortedGroup;
        },

        /*
        setGroupedFilters method : Creates grouped filter list for populating left side of grouped filter model 
        { groupTitle:'' , filterInGroup:{[]}, isOpen: boolean, isGroupActive: boolean, isGroupVisible: boolean }
        */
        setGroupedFilters(refGroup, group) {
            const tempObj = {};
            tempObj.groupTitle = refGroup.label; // set groupTitle property based on group labels in studyGroupsReferenceTable
            tempObj.filterInGroup = group;
            tempObj.isOpen = false;
            
            // Method (return element where any filter's state.active is true in filterGroup).
            const activFilterInGroup = tempObj.filterInGroup.find(function (element) {
                return truthy(element) ? element.state?.active === true : false;
            });
            // if found create property isGroupActive property and set its value to true else false; 
            // isGroupActive is used to toogle open the group accordtion if filter is selected in  a-z filter
            tempObj.isGroupActive = activFilterInGroup ? true : false;

            // Method (return element where any filter's state.visbile is true in filterGroup).
            const visibleFilterInGroup = tempObj.filterInGroup.find(function (element) {
                return truthy(element) ? element.state?.visible === true : false;
            });
            // if found create property isGroupVisible property and set its value to true else false;
            // isGroupVisible is used to toogle open the group accordtion if filter is visible in  a-z filter
            tempObj.isGroupVisible = visibleFilterInGroup ? true : false;
            return tempObj;
        }
    },
};
</script>

<style lang="scss">
@import "src/styles/component";


.study-groupfilters {
    padding: 0.75rem 1rem !important;
    font-size: 1rem;
    
    >.icons {
        cursor: pointer;
        margin-right: 0.5rem;
        font-size: 0.9rem;
        float: right;
    }

    .study-groupfilters-list {
        display: flex;
        text-indent: 15px;
        padding: .75rem .5rem .5rem .5rem;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-top: solid thin transparent;
        border-bottom: solid thin transparent;
        cursor: pointer;
        text-decoration: none !important;


        font-size: 0.9rem;
        font-weight: $thin;
        color: $text;

        .plus-minus {
            font-size: 0.8rem;
        }

        &.hide {
            display: none;
        }

        &:not(.visible) {
            .plus-minus {
                color: fade-out($text, 0.75);
            }

            // &:hover .plus-minus{
            //   color: $green;
            // }
        }

        &.visible {
            color: $link;
            font-weight: $regular;
            background-color: mix($link, white, 10);
        }

        &:hover {
            background-color: $n1;
        }

        &:active {
            color: white !important;
            background-color: $link;

            .plus-minus {
                color: white;
            }
        }

        &.visible:active {
            color: white;
            background-color: $danger;

            .plus-minus {
                color: white;
            }
        }

        &.visible:hover {
            color: $danger;

            svg {
                color: $danger;
            }

            &.active:after {
                background: $danger;
                box-shadow: 0 0 0 3px fade-out($danger, 0.5);
            }
        }

        &.clear {
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;

            &:hover {
                background-color: $n1 !important;
            }
        }

        &.empty {
            text-align: center;
            opacity: 0.75;
            pointer-events: none;
        }
    }
}

#study-group-title{
        list-style-type:none;
        padding: 6px 16px;
        cursor: pointer;
    .group-icon{
        float:right;
        padding-right: 15px !important;
        cursor: pointer;
    }
}

#study-group-subtitle{
    margin-bottom: 0 !important;
    padding-inline-start: 0px;
}

#accordion-header{
    display: flex;
    flex-direction: column;
}

div#accordion.accordion-container{
    height: calc(80vh - 141px); // studies page groups view height
    overflow-y: auto;

  //background-color: $n1; rgba(177, 174, 174, 0.3)
  &::-webkit-scrollbar-button {
    display: none;
  }
&::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px $n1;;
  background-color: $n1;
}
&::-webkit-scrollbar {
    width: 5px;
    margin-left: -3px;
    height: 8%;
  }
&::-webkit-scrollbar-thumb {
  background-color: $n3;
    border-radius: 15px;
}
 &::-webkit-scrollbar-thumb:hover {
    background-color: darken($n3, 5);
    cursor: hand;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $n4;
    cursor: hand;
  }

}

.accordion:not(.is-open) > .accordion-body {
  display: none;
}
</style>