import Options from '../options';
// import store from '@/store';

function getOptions(key) {
  try {
    // if (!store.getters['settings/showSummaryPatients']) {
    //   console.log('test')
    //   Options[key].options.splice(Options[key].options.length - 1, 1);
    // }
    return Options[key].options;
  }
  catch(error) {
    return [];
  }
}

function getOption(key, value, accessKey = 'label') {
  if (typeof value !== 'string') {
    return value;
  }
  return getOptions(key).find(option => option[accessKey] === value);
}

export default {
  getOptions,
  getOption
};
