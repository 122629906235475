<template>
  <div class="grid-item" :class="gridItemClass">
    <slot></slot>
  </div>
</template>

<script>
  function constructSizeClass(model, size) {
    return `sr-${size}-${model[0]} sc-${size}-${model[1]} order-${size}-${model[2]}`
  }
  function layoutValidator(value) {
    return value.length === 3
  }

  export default {
    name: 'layout-item',
    props: {
      sm: {
        type: Array,
        required: true,
        validator: layoutValidator,
      },
      md: {
        type: Array,
        default: undefined,
        validator: layoutValidator,
      },
      lg: {
        type: Array,
        default: undefined,
        validator: layoutValidator,
      },
    },
    computed: {
      gridItemClass() {
        
        // use small as baseline size
        const sm = this.sm
        const md = this.$h.get(this, 'md', sm)
        const lg = this.$h.get(this, 'lg', md)

        return [
          constructSizeClass(sm, 'sm'),
          constructSizeClass(md, 'md'), 
          constructSizeClass(lg, 'lg'),
        ]
      },
    }
  }
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.grid-item {
  position: relative;
  justify-self: stretch;
  min-width: 0;
  font-size: 1rem;

  // create span classes
  @for $i from 3 through 26 {

    @include media-breakpoint-down(sm) {
      &.sc-sm-#{$i} { grid-column: span $i }
      &.sr-sm-#{$i} { grid-row: span $i }
      &.order-sm-#{$i} { order: $i }
    }

    @include media-breakpoint-only(md) {
      &.sc-md-#{$i} { grid-column: span $i } 
      &.sr-md-#{$i} { grid-row: span $i }
      &.order-md-#{$i} { order: $i }
    }

    @include media-breakpoint-up(lg) {
      // border: solid thin blue;
      &.sc-lg-#{$i} { grid-column: span $i }
      &.sr-lg-#{$i} { grid-row: span $i }    
      &.order-lg-#{$i} { order: $i }
    }

  }

  .order-lg--1 {
    display: none;
    border :solid thin red
  }
}
</style>
