import { Validations } from '../utils';
export default [
  { 
    type: 'text-field',
    id: 'filename',
    group: {
      label: 'Save Chart As...'
    },
    input: {
      autocomplete: 'off',
      placeholder: 'Save Chart As...',
    },
    validations: [Validations.required, Validations.maxLength(30)]
  },
]