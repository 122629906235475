import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { truthy } from './CommonsService'
import { hasAccess, hasRole } from '@/services/RoleService'

export default {
  get,
  cloneDeep,
  truthy,
  hasAccess,
  hasRole,
}
