// import Vue from 'vue'

import authActions from './actions';
import authMutations from './mutations';
import authGetters from './getters';

export default {
    namespaced: true,
  
    state: {
      name: '',
      email: '',
      roles: [],
      containerAffiliation: []
    },
  
    getters: authGetters,
  
    mutations: authMutations,
  
    actions: authActions
};
