import { truthy } from '@/services/CommonsService'
import { Options } from '../utils'
import { Validations } from '../utils'

export function getIDSearchSchema(type) {
  return [
    {
      type: 'select-field',
      id: 'id-selector',
      default: Options.getOptions(type)[0],
      group: {},
      input: {
        allowEmpty: false,
        closeOnSelect: true,
        options: Options.getOptions(type),
      },
      isActive() {
        return false
      },
    },
    {
      type: 'text-field',
      id: 'id-input',
      group: {},
      input: {
        autocomplete: 'off',
        placeholder: 'Enter an ID',
        maxLength: 1800 // to show 125 ids

      },
      validations: [Validations.required],
      changed(model, fields) {
        const idSelect = getField(fields, 'id-selector')
        idSelect.state.active = truthy(this.value)
      },
      mounted(model, fields) {
        const idSelect = getField(fields, 'id-selector')
        idSelect.state.active = truthy(this.value)
      },
      isActive() {
        return false
      },
    },
  ]
}

function getField(fields, id) {
  return fields.find((field) => field.id === id)
}


export function getID(type, rawInputs, returnObject, merge = false) {
  if (rawInputs !== undefined && Array.isArray(rawInputs)) { // add type check for array rawInputs)
    rawInputs.forEach((id) => {
      // if (id['id-selector'].value === 'nct_id' || id['id-selector'].value === 'nci_id') {
      //   let studyIdValue = id['id-input'];
      //   let studyIdArray = studyIdValue.split(' ');
      //   studyIdArray.filter(function(x) {
      //     return x !== 'None';
      //   });
      // }

      if (id['id-label'] === 'all') {
        [
          'nci_id',
          'nct_id',
          'dcp_id',
          'ccr_id',
        ].forEach(key => {
          returnObject[`${key}._auto`] = id.value
        })
        return
      }

      let key = id['id-selector'].value
      let value = id['id-input']
      // for lead org we need to select it out of the ids array
      if (key === 'ids.value') {
        returnObject['ids.type'] = 'LEAD_ORG_ID'
      }
      else if (returnObject['ids.type']) {
        delete returnObject['ids.type']
      }
      
      value = value.replace(/;/g, " ")
      value = value.split(/[ ,]+/)
                   .map((entry) => entry.trim())
                   .filter((entry) => entry !== "None")
                   .filter((entry) => entry !== "");

      // add a t if merging with trials
      if (merge) {
        key = `t_${key}`
      }
      
      //adding asterisks to facilitate wildcard search 
      if (value) {
          if (!returnObject[`${key}._raw`]) {  
            if (Array.isArray(value)) {
              for(let v = 0; v < value.length; v++) {
                value[parseInt(v)] = "*" + value[parseInt(v)] + "*";
              }
              returnObject[`${key}._raw`] = [...value];
            } else {
              value = "*" + value + "*";
              returnObject[`${key}._raw`] = [value];
            }
          }
        else {
          if (Array.isArray(value)) {
            for(let v = 0; v < value.length; v++) {
              value[parseInt(v)] = "*" + value[parseInt(v)] + "*";
              returnObject[ `${key}._raw`] = returnObject[`${key}._raw`].concat(value);
            }
          } else {
            returnObject[`${key}._raw`].push(value);
          }
        }
      }
    })
  }
}
