export default {
  setState(state, { id, flag }) {
    state[id] = flag;
  },
  goTo(state, page) {
    state.page = page;
  },
  setZoom(state, zoom) {
    if (state.mainPage) {
      state.zoom = zoom;
    }
  },
  setCenter(state, center) {
    state.center = center;
  },
  setMinMax(state, minMax) {
    state.minMax = minMax;
  },
  setStateCounts(state, states) {
    state.stateCounts = states;
  },
  setStateSelection(state, stateSelection) {
    state.stateSelection = stateSelection;
  },
};
