<template>
  <div class="toaster-container">
    <transition name="slide-fade">
      <div class="toaster" :class="variant" v-if="open && message">
        <div class="icon">
          <icon :icon="icon"></icon>
        </div>
        <div class="content">
          <h4 v-html="message"></h4>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'toaster',
  computed: {
    ...mapGetters({
      open: 'toaster/open',
      message: 'toaster/message',
      variant: 'toaster/variant',
      icon: 'toaster/icon',
    })
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.toaster-container {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: $z-index-toaster;
  pointer-events: none;
}
.toaster {
  position: absolute;
  width: auto;
  padding: 1rem 2rem 1rem 1.5rem;
  bottom: 2rem; right: 2rem;
  border-radius: $border-radius-sm;
  display: flex;

  @each $name, $color in $theme-colors {
    &.#{$name} {
      background-color: fade-out($color, .2);
    }
  }
}
.icon {
  flex: 0 0 30px;
  height: 30px;
  color: white;
  font-size: .9rem;
  background-color: fade-out(black, .8);
  box-shadow: 0 0 0 .4rem fade-out(black, .9);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center; 
}
.content {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  min-width: 230px;

  h4 {
    color: white;
    font-size: 1rem;
  }
}
</style>
