import Vue from "vue";
import Vuex from "vuex";

import auth from "./store/auth/index.js";
import form from "./store/form/index.js";
import view from "./store/view/index.js";
import settings from "./store/settings/index.js";
import intro from "./store/intro/index.js";
import modal from "./store/modal/index.js";
import toaster from "./store/toaster/index.js";
import search from "./store/search/index.js";
import chartTables from "./store/chartTables/index.js";
import map from "./store/map/index.js";
import headers from "./store/headers/index.js";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    width: 0,
    studiesPage: {
      currentPage: 0,
      size: 20,
    },
    organizationsPage: {
      currentPage: 0,
      size: 20,
    },
    investigatorsPage: {
      currentPage: 0,
      size: 20,
    },
    participantsPage: {
      currentPage: 0,
      size: 20,
    },
  },
  getters: {
    width: (state) => state.width,
    widthState: (state) => getState(state.width),
  },
  mutations: {
    setWidth(state, width) {
      state.width = width;
    },
    setCurrentPage(state, currentPage) {
      state[currentPage.id].currentPage = currentPage.page;
    },
    setPageSize(state, currentPage) {
      state[currentPage.id].size = currentPage.size;
    },
  },
  actions: {
    resize({ commit }) {
      commit("setWidth", document.documentElement.clientWidth);
    },
    forceResize({ commit, getters }) {
      commit("setWidth", getters.width + 1);
    },
    clear() {
      window.localStorage.removeItem("okta-token-storage");
      this.dispatch("auth/reset");
      this.dispatch("settings/reset");
      // window.localStorage.clear()
    },
  },

  modules: {
    auth,
    form,
    settings,
    intro,
    view,
    toaster,
    modal,
    search,
    map,
    headers,
    chartTables,
  },
});

function getState(width) {
  if (width > 1500) {
    return "lg";
  } else if (width > 1190) {
    return "md";
  } else {
    return "sm";
  }
}
