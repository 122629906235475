var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar"},[(_vm.$h.truthy(_vm.$h.get(_vm.options, 'extra')))?_c('div',{staticClass:"extra"},[_c('ul',{staticClass:"table-age-row is-chart"},[_c('li',[_c('span',{staticClass:"label"},[_vm._v("Mean")]),_c('span',{staticClass:"value",domProps:{"textContent":_vm._s(_vm.$h.get(_vm.options, 'extra.mean', 'N/A'))}})]),_c('li',[_c('span',{staticClass:"label"},[_vm._v("Standard Deviation")]),_c('span',{staticClass:"value",domProps:{"textContent":_vm._s(_vm.$h.get(_vm.options, 'extra.sd', 'N/A'))}})]),_c('li',[_c('span',{staticClass:"label"},[_vm._v("Range")]),_c('span',{staticClass:"value",domProps:{"textContent":_vm._s(
            `${_vm.$h.get(_vm.options, 'extra.range.from', '0')} to ${_vm.$h.get(
              _vm.options,
              'extra.range.to',
              '0'
            )}`
          )}})])])]):_vm._e(),_c('e-charts',{ref:"chart",attrs:{"options":_vm.$activeOptions,"auto-resize":false},on:{"click":_vm.$click}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }