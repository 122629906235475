export default [
  {
    element: '#global-search-help',
    intro: `This is the global keyword search box.<br><a style="font-size: .9rem;" href="/documentation/docs/search-studies/" target="_blank">Click for details</a>`,
    tooltipClass: 'regular-tooltip-bg'
  },
  {
    element: 'div.search-input.open > div.dropdown-container > div.slot-container > div.slot > div > div.search-results',
    intro: 'The search results are listed here.',
    tooltipClass: 'regular-tooltip-bg'
  }
];