<template>
  <div
    class="multi-form"
    :class="{ edit: editIndex !== -1, empty, open: showForm }"
  >

    <div class="multiform-form-message mb-2" v-if="studyIdField">
      <checkbox class="thin" v-model="allIds">
        Search by all Study IDs
      </checkbox>
    </div>

    <div v-if="allIds">
      <text-field
        placeholder="Enter an ID"
        :value="initValue"
        @change="submitAllIds"
      ></text-field>
      <span class="thin sub"
        >Filters by NCI ID, NCT ID, DCP ID, and CCR ID</span
      >
    </div>

    <div v-else>
      <ul
        class="multi-form__list"
        :class="{
          open: showForm && open,
          edit: editIndex !== -1,
        }"
        v-if="(value.length === 0 && !showForm) || value.length > 0"
      >
        <li
          class="multi-form__list-item"
          v-for="(item, index) in value"
          :key="index"
          v-show="item['id-label'] !== 'all'"
          :class="{ edit: editIndex === index }"
        >
          <div
            :class="[`multi-form__list-item-label${name === 'sec' ? '-no-suffix' : ''}`]"
            v-html="labelFormatter(item)"
          ></div>
          <btn
            class="multi-form__list-item-btn"
            v-tooltip="'Edit'"
            variant="outline-link"
            @click="editValue(index)"
            :disabled="disabled"
          >
            <icon icon="pen"></icon>
          </btn>
          <btn
            class="multi-form__list-item-btn"
            v-tooltip="'Remove'"
            variant="outline-danger"
            @click="removeValue(index)"
            :disabled="disabled"
          >
            <icon icon="trash"></icon>
          </btn>
        </li>
        <li
          class="multi-form__list-item empty"
          variant="link"
          v-if="value.length === 0"
        >
          <div class="label">No {{ noInputLabel() }} selected</div>
        </li>
      </ul>

      <btn class="multi-form__btn" @click="showForm = true; open = true" v-if="!showForm">
        <icon icon="plus"></icon>
        {{ addButtonLabel() }}
      </btn>

      <form-builder
        v-if="showFields"
        id="simple-multiform-form"
        :class="{ large: largeInputs }"
        class="simple-multiform-form no-highlight mb-3"
        ref="form"
        no-persist
        submit-variant="studies"
        :submit-text="submitText"
        :reset-text="resetText"
        :no-reset="noReset"
        :schema="schema"
        :values="values"
        @submit="formSubmit"
        @cancel="formCancel"
        @change="formChange"
      ></form-builder>

    </div>

  </div>
</template>

<script>
import TextField from "bootstrap-vue/es/components/form-input/form-input";
import Checkbox from "bootstrap-vue/es/components/form-checkbox/form-checkbox";
import { EventBus } from "@/event-bus.js";

export default {
  name: "simple-multi-form-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: { event: "change" },
  components: { Checkbox, TextField },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    formValues: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
    schema: {
      type: Array,
      required: true,
    },
    labelFormatter: {
      type: Function,
      default: (value) => value,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: {},
      editIndex: -1,
      showForm: false,
      allIds: false,
      largeInputs: true,
      initValue: "",
      updatedSchema: []
    };
  },
  computed: {
    submitText() {
      return `${this.editIndex === -1 ? "Add" : "Update"} ${this.isSEC() ? this.name.toUpperCase() : "ID"}(s)`;
    },
    resetText() {
      return !this.value.length && this.editIndex !== -1 ? "Reset" : "Cancel";
    },
    empty() {
      return !this.$h.truthy(this.value);
    },
    studyIdField() {
      return this.name === "Study ID" && false;
    },
    noReset() {
      return !this.isSEC();
    },
    showFields() {
      return this.showForm && ( (!this.isSEC() && this.open) || true);
    },
  },
  mounted() {
    this.secTypeRadioIcon();
    //Event subscribed that is thrown from FormBuilder
    EventBus.$on("searchType-field-event", (data) => {
      this.handleEvent(data, this.schema);
    });
    //Event subscribed from Formbuilder to handle sec Type change based on eligibity trial search in case of page refresh or another application tab is opened
    EventBus.$on("refreshed-searchType-field-event", (data) => {
      this.handleEvent(data, this.schema);
    });
    this.updatedSchema = this.$h.cloneDeep(this.schema)

    this.showForm = (this.value.length === 0 && !this.isSEC()) || true;

    let allIdsObjectIndex = this.value && Array.isArray(this.value)? this.value.findIndex(
      (v) => v["id-label"] === "all"
    ) : -1;
    this.allIds = allIdsObjectIndex !== -1;

    if (this.allIds) {
      this.initValue = this.value[allIdsObjectIndex].value;
    }

    this.updatedSchema = this.$h.cloneDeep(this.schema)
  },
  methods: {
    triggerFormchange(data, Changedvalue) {
      EventBus.$emit("sec-criteria-change", data, Changedvalue);
    },
    //Codacy:ignore-start
    handleEvent(Changedvalue, data) {
      const labelMapping = {
        portfolio: ['Unspecified','Inclusion', 'Exclusion'],
        default: ['Unspecified','Focus', 'Eligible']
      };

      for (var i in data) {
        if (data[`${i}`]['id'] === 'sec_type' && Object.prototype.hasOwnProperty.call(labelMapping,`${Changedvalue}`)) {
          const optionLabels = labelMapping[`${Changedvalue}`];
          this.schema[`${i}`]['input']['options'][0]['label'] = optionLabels[0];
          this.schema[`${i}`]['input']['options'][1]['label'] = optionLabels[1];
          this.schema[`${i}`]['input']['options'][2]['label'] = optionLabels[2];
        }
      }

      this.triggerFormchange(this.schema, Changedvalue);
    },
    //codacy:ignore-end
    isSEC() {
      return this.name === 'sec';
    },
    noInputLabel() {
      return this.label ? this.label : this.name;
    },
    addButtonLabel() {
      return (this.value.length === 0 ? "Add " : "Add another ") + (this.isSEC() ? this.label : this.name)
    },
    submitAllIds(value) {
      let values = this.$h.cloneDeep(this.value);
      let newValue = {};
      let allIdsObjectIndex = this.value.findIndex(
        (v) => v["id-label"] === "all"
      );
      if (allIdsObjectIndex === -1) {
        newValue = {
          "id-label": "all",
          value,
        };
        values.push(newValue);
      } else {
        values[allIdsObjectIndex] = {
          "id-label": "all",
          value,
        };
      }

      // push value
      this.$emit("change", values);
    },
    editValue(index) {
      if (index !== this.editIndex) {

        // Each value saved will be in this format: 
        // Example: Organization CTEP ID: {id-input: '111', 'id-selector': {label: 'Organization CTEP ID', value: 'organizations.ctep_id'}}
        const model = this.$h.cloneDeep(this.value[index]);

        this.showForm = true;
        this.$nextTick(() => {
          this.$refs.form.updateValue(
            "id-selector",
            model["id-selector"]
          );

          this.$refs.form.updateValue(
            "id-input",
            this.$h.get(model, "id-input")
          );

          if (this.isSEC()) {
            this.$refs.form.updateValue(
              "sec_criteria",
              model["sec_criteria"]
            );
            this.$refs.form.updateValue(
              "sec_type",
              this.$h.get(model, "sec_type")
            );
          }
          this.editIndex = index;
        });
      } else {
        this.resetForm();
      }
    },
    addValue(newValue) {
      let values = this.$h.cloneDeep(this.value);
      values.push(newValue);
      this.$emit("change", values);
    },
    updateValue(newValue, index) {
      this.resetForm();
      let values = this.$h.cloneDeep(this.value);
      if(newValue['id-selector'] && newValue['id-selector'].value === "code" && !newValue['sec_name']){
        if(newValue['id-input'] && this.value[index]['sec_name'] && newValue['id-input'] === this.value[index]['sec_name'][0].code){
          newValue['sec_name'] = this.value[index]['sec_name'];
        }
      }
      values[index] = newValue;
      this.$emit("change", values);
    },
    removeValue(index) {
      let values = this.$h.cloneDeep(this.value);
      values.splice(index, 1);
      this.$emit("change", values);
    },
    formSubmit(value) {
      if (this.editIndex === -1) {
        this.addValue(value);
      } else {
        this.updateValue(value, this.editIndex);
      }
      this.resetForm();
    },
    formCancel() {
      this.resetForm();
    },
    formChange(value) {
      // This method is invoked when there is a change event in the filter form
      // the below code is executed only if the modal object has the "toggle-code-sec" key,
      // which is added only when there is a change event in the eligibility criteria sec name/code select box
      if ('toggle-code-sec' in value) {
        if (value['toggle-code-sec']) {
          this.hideSECCodeTextField(false, value)
        } else {
          this.hideSECCodeTextField(true, value)
        }
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
        this.$refs.form.updateFieldState("ids-search-trial", "validation", "");
        this.$refs.form.updateFieldState("ids-search-org", "validation", "");
        this.$refs.form.updateFieldState("ids-search-sec", "validation", "");
      }
      this.editIndex = -1;
      this.showForm = false;
    },
    hideSECCodeTextField(hideSECCodeTextField) {
      // When the 'hideSECCodeTextField' flag is true, then hide the code input text field & only show the name select field
      // and vice-versa when the flag, 'hideSECCodeTextField' is false
      // This refers to the name/code select box field, when name is selected, we show the "select box" for the sec name
      // and when "code" is selected, we show the "text field" for the sec code

      this.updatedSchema.forEach((field) => {
        if (!field.dynamicFieldMapping && field['field-type'] === 'sec') {
          if (hideSECCodeTextField) {
            if (field['type'] === 'select-field') {
              delete field['hideField']
              field['id'] = 'id-input';
            } else {
              field['hideField'] = true;
              field['id'] = 'id-text-input'
            }
          } else {
            if (field['type'] === 'select-field') {
              field['hideField'] = true;
              field['id'] = 'id-select-input'
            } else {
              delete field['hideField']
              field['id'] = 'id-input';
            }
          }
        }
      });
      // Emit the event with the updated schema.
      EventBus.$emit("sec-field-change", this.updatedSchema);
    },
    /* eslint-disable */
    //Logic to add icon and tooltip message on initial page load and subsequent refresh
    secTypeRadioIcon() {
      const radioInputs = document.querySelectorAll('input[type="radio"][id*="simple-multiform-form-sec_type-input__BV_option"]');
        // Check if sec_type_exists to determine initial load or refresh
        const sec_type_exists = this.schema.find((s) => s.id === 'sec_type');

        for (const input of radioInputs) {
          const label = input.labels[0];
          if (!label.querySelector('.info-icon-st-right') && !label.querySelector('.info-icon-st-left')) {
            const icon = document.createElement('span');
            //Creating seperate class for tooltip icon to apply seperate styling
            const className = input.id.includes('simple-multiform-form-sec_type-input__BV_option_0') || input.id.includes('simple-multiform-form-sec_type-input__BV_option_1') ? 'info-icon-st-right' : 'info-icon-st-left';
            icon.className = className;
            icon.innerHTML = '&#9432;';
            label.appendChild(icon);

            if (input.id.includes('simple-multiform-form-sec_type-input__BV_option_0')) {
              icon.setAttribute('data-title', "Will return trials with term regardless of eligibility criteria type (i.e., exists on trial)");
            } else if (input.id.includes('simple-multiform-form-sec_type-input__BV_option_1')) {
              // Check if sec_type_exists exists and has the corresponding label for Inclusion/Focus
              if (sec_type_exists && sec_type_exists.input.options[1].label === 'Inclusion') {
                icon.setAttribute('data-title', "Returns all trials with terms listed as Inclusion Criteria");
              } else {
                icon.setAttribute('data-title', "Will return trials with term only if the eligibility criteria type is specified as inclusion (i.e., required to enroll)");
              }
            } else if (input.id.includes('simple-multiform-form-sec_type-input__BV_option_2')) {
              // Check if sec_type_exists exists and has the corresponding label Exclusion/Eligible
              if (sec_type_exists && sec_type_exists.input.options[2].label === 'Exclusion') {
                icon.setAttribute('data-title', "Returns all trials with terms listed as Exclusion Criteria");
              } else {
                icon.setAttribute('data-title', "Will return everything BUT trials with term specified as exclusion");
              }
            }
          }
        }
      }
    /* eslint-disable */
  },
  updated() {
    this.secTypeRadioIcon();
  },
  watch: {
    allIds(value) {
      if (!value) {
        let values = this.$h.cloneDeep(this.value);
        let allIdsObjectIndex = this.value.findIndex(
          (v) => v["id-label"] === "all"
        );
        if (allIdsObjectIndex !== -1) {
          values.splice(allIdsObjectIndex, 1);
          this.$emit("change", values);
        }
      }
    },
    open(flag) {
      if (!flag) {
        this.showForm = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/component";
form.simple-multiform-form {
  display: flex;
  &.large {
    flex-wrap: wrap;
    // flex-direction: ;
    div.form-actions {
      margin-top: 0.5rem;
      margin-left: 0;
      justify-content: flex-end;

      button:first-child {
        margin-left: 0;
      }
    }

    #simple-multiform-form-id-input {
      flex: 1 1 auto;
    }
  }
  div.form-actions {
    display: -webkit-box;
    width: 100%;
    justify-content: flex-start;

    button {
      height: 38px;
    }

    button:nth-child(1) {
      margin-left: 0.5rem;
    }
    button:nth-child(2) {
      margin-left: 0.3rem;
    }
  }
}
.multi-form {
  position: relative;
  .thin {
    font-size: 0.95rem;
    font-weight: 300;
  }
  .blue {
    color: cornflowerblue;
  }
  .allIds {
    opacity: 0.25;
    pointer-events: none;
  }
  .sub {
    margin-top: 0.25rem;
    font-size: 0.8rem;
    color: fade-out(black, 0.25);
  }
  .multiform-form-message {
    // position: absolute;
    // top: -2rem;
    // right: 0px;
    font-size: 0.9rem;

    .custom-control-label:after,
    .custom-control-label:before {
      transform: translateY(-2px);
    }
  }
}
#simple-multiform-form-id-selector {
  flex: 0 0 auto;
  .multiselect__tags,
  .multiselect__single,
  .multiselect__spinner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
#simple-multiform-form-id-input {
  flex: 0 1 400px;
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: transparent;
  }
}
#simple-multiform-form-sec_type {
      margin-bottom: 60px;
    }
.info-icon-st-right {
  cursor: help;
  margin-left: 10px;
  position:relative;
  text-shadow: 0 0 2 px black;
}

.info-icon-st-right:hover::before {
  content: attr(data-title);
  position:absolute;
  background-color: black;
  color: white;
  padding: 8px 8px;
  border-radius: 4px;
  top: 100%;
  left: 100%;
  width: 20em;
  z-index: 9999;
  max-width: 300px;
}
.info-icon-st-left {
  cursor: help;
  margin-left: 10px;
  position:relative;
  text-shadow: 0 0 2 px black;
}

.info-icon-st-left:hover::before {
  content: attr(data-title);
  position:absolute;
  background-color: black;
  color: white;
  padding: 8px 8px;
  border-radius: 4px;
  top: 100%;
  // Below attribute can be changed for left or right orientation for future usage.As of now this is redundant set up for future usage
  left: 100%;
  width: 12em;
  z-index: 9999;
  max-width: 300px;
}
</style>
