import Tooltip from 'bootstrap-vue/es/directives/tooltip/tooltip'
import { hasAccess } from '@/services/RoleService'

const Section = {
  inserted: (el, binding, vnode) => {
    if (binding.value && !hasAccess(binding.value)) {
      commentNode(el, vnode)
    }
  }
}

function commentNode(el, vnode) {
  const comment = document.createComment(' ')

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })

  vnode.text = ' '
  vnode.elm = comment
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  vnode.data.directives = undefined

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

export default {
  Tooltip,
  Section,
}