export default {
  setContainer(state, container) {
    state.activeContainer = container;
  },
  setEnabled(state, enabled) {
    state.containerEnabled = enabled;
  },
  setMemberInformation(state, enabled) {
    state.memberInformation = enabled;
  },
  setShowSummaryPatients(state, showSummaryPatients) {
    state.showSummaryPatients = showSummaryPatients;
  },
  setUseOriginalDiseases(state, useOriginalDiseases) {
    state.useOriginalDiseases = useOriginalDiseases;
  },
  setSkipCache(state, skipCache) {
    state.skipCache = skipCache;
  },
};
