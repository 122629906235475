<template>
  <div>
      <!-- Banner Section -->
      <div class="banner" >
          <icon icon="info-circle" class="banner-icon"></icon>
          <span class="banner-message">Note: if no “Target Keyword Search” options are selected, the keyword search field will search the following Study data fields: NCI ID, NCT ID, Lead Organization (ID), Lead Organization (Name), Official Title, Brief Title, Principal Investigator (Name), Eligibility Criteria (Description), Outcome Measure.  </span>
  </div>
      <div>

  <div class="multi-form" >
     <div class="keyword-err ">{{ keywordErrMsg }} </div>
    <form-builder
    id="form-field"
    no-persist
    no-actions
    submit-on-change
    submit-on-mount
    :schema="schema"
    :values="value"
    :key="updateKey"
    @change="formSubmit"
  ></form-builder>
  </div>
  </div>
</div>
</template>

<script>

/* istanbul ignore file */
function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export default {
  name: "keyword-form-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: {
    event: "change",
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
  },
  data() {
    return {
      values: {},
      update: false,
      showStateDD: true,
      updateKey: 0,
      showNoteSection: true,
      showform: true,
      textField:{},
      options:{},
      keywordErrMsg: '',

    };
  },
  methods: {
    formSubmit(values) {
      this.validateKeywordInput(values)
      // get element to show error message
      let keywordErrEle = this.$el.querySelector(".keyword-err ")
      let keywordTargetSearchEle = this.$el.querySelector("#form-field-keyword_search_type");
      keywordTargetSearchEle?.parentNode.insertBefore(keywordErrEle, keywordTargetSearchEle);
      this.$emit("change", values);
    },
    validateKeywordInput(values) {
      if (!this.$h.truthy(values)) {
        return;
      }
      let keywordInputEle = this.$el.querySelector("input#form-field-_keyword-input")
      this.keywordErrMsg = "";
      // conditionally add error message and error class
      if ((values._keyword === undefined || values._keyword == '') && values.keyword_search_type.length > 0) {
        keywordInputEle?.classList.add("keywordErrorClass"); // add red border
        this.keywordErrMsg = "Keyword field value is required, if Target Keyword Search option is selected."
      }
      else { 
      keywordInputEle?.classList.remove("keywordErrorClass"); // remove error border
      this.keywordErrMsg = "";
      } 
    },
    /* eslint-disable */
    checkboxIcon() {
      const allElements = document.getElementsByTagName('*');
      for (let i = 0; i < allElements.length; i++) {
        let element = allElements[i];
        if (element.tagName === 'INPUT' && element.type === 'checkbox' && element.id.includes('keyword_search_type-input__BV_option_') && !element.labels[0].querySelector('.info-icon')) {
          const icon = document.createElement('span');
          icon.className = 'info-icon';
          icon.innerHTML = '&#9432;';
          if (element.id.includes('keyword_search_type-input__BV_option_0')) {
            icon.setAttribute('data-title',"When selected, keyword search is targeted to search within the descriptive criteria data and return trials that matches the search criteria");
          } else {
            icon.setAttribute('data-title',"When selected, keyword search is targeted to search within the outcome measure data set and return trials that matches the search criteria");
          }
          const label = element.labels[0];
          label.appendChild(icon);
        }
      }
    }
    /* eslint-disable */
  },
  computed: {
  },
  mounted() {
    this.checkboxIcon();
    this.validateKeywordInput(this.values);
 },
  updated() {
    this.checkboxIcon();
    this.validateKeywordInput(this.values);
  },
  watch: {
    value(value) {
      if (isEmpty(value)) {
        this.updateKey++; // update on reset
      
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/component";
.banner {
  background-color: #e7f6f8 ;
  color: #000000;
  padding:10px;
  display:flex;
  align-items:center;
  border-left: 10px solid #01bde3;
  margin-bottom: 20px;
}

.banner-icon {
  margin-right: 10px;
  align-self: flex-start
}

#keyword-form-field_keyword {
  font-size: 40px !important;
  font-weight: bolder;
}

.info-icon {
  cursor: help;
  margin-left: 10px;
  position:relative;
  text-shadow: 0 0 2 px black;
}

.info-icon:hover::before {
content: attr(data-title);
position:absolute;
background-color: black;
color: white;
padding: 8px 8px;
border-radius: 4px;
top: 100%;
left: 100%;
width: 16em;
z-index: 9999;
max-width: 300px;
}

.info-icon:hover {
  color:blue;
  
}

.keyword-err {
  font-weight: normal;
  font-size: 80%;
  color: #e74c3c;
  padding-bottom: 6px;
}

.keywordErrorClass {
  border: solid thin red !important;
}
</style>

