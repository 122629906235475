export default {
  openExport({ commit }, model) {
    commit("open", "export");
    commit("setExportModel", model);
  },
  closeExport({ commit }) {
    commit("close", "export");
    commit("setExportModel", {});
  },

  openFeature({ commit }) {
    commit("open", "feature");
  },
  closeFeature({ commit }) {
    commit("close", "feature");
  },

  openDataView({ commit }) {
    commit("open", "dataView");
  },
  closeDataView({ commit }) {
    commit("close", "dataView");
  },

  openDataSettings({ commit }) {
    commit("open", "dataSettings");
  },
  closeDataSettings({ commit }) {
    commit("close", "dataSettings");
  },

  openFormSummary({ commit }, model) {
    commit("open", "formSummary");
    commit("setFormSummaryValues", model);
  },
  closeFormSummary({ commit }) {
    commit("close", "formSummary");
    commit("setFormSummaryValues", [{}, "", []]);
  },

  closeModals({ commit }) {
    commit("close", "dataSettings");
    commit("close", "dataView");
    commit("close", "formSummary");
    commit("close", "export");
    commit("setFormSummaryValues", [{}, "", []]);
    commit("setExportModel", {});
  },
};
