<template>
  <div class="login-watcher" style="z-index: 10000;">
    <modal
      class="modal-no-padding login-back-modal"
      :class="{'warning': !expiredFlag}"
      v-model="modalOpen"
      size="sm"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="session-expired" v-if="expiredFlag">
        <error
          size="logout"
          variant="warning"
          class="session-warning-error"
          icon="exclamation-triangle"
          title="You have been logged out!"
          error="Your session has expired, please log back in to continue."
          no-links
        ></error>
        <div class="btns">
          <btn class="warn-btn" variant="warning" @click="$auth.loginRedirect('/')"><icon icon="sign-out-alt"></icon>Log Back in</btn>
        </div>
      </div>
      <div class="session-warning" v-else-if="warningFlag">
        <error
          size="logout"
          variant="warning"
          icon="exclamation-triangle"
          class="session-warning-error"
          :title="`You will be logged out in ${countDown} seconds!`"
          error="Your session is about to expire, click the button below to stay signed in."
          no-links
        ></error>
        <div class="btns">
          <btn class="warn-btn" variant="warning" @click="clearWarning"><icon icon="check"></icon>Stay Logged in</btn>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { exitIntro } from '@/services/IntroService'
import Modal from 'bootstrap-vue/es/components/modal/modal'
import Error from '@/components/presentation/state-handler/DefaultError'

let refreshThrottle = 5 * 60 // seconds
let countDownTime = 2 * 60 // seconds
let warningThrottle = (28 * 60 * 1000) - 5000 // milliseconds

// let refreshThrottle = 5 * 60 // seconds
// let countDownTime = 10 // seconds
// let warningThrottle = 10 * 1000 // milliseconds

let warningTimeout = null
let expirationInterval = null

export default {
  name: 'session-modal',
  components: { Modal, Error },
  data() {
    return {
      countDown: countDownTime,
      warningFlag: false,
      expiredFlag: false,
    }
  },
  computed: {
    modalOpen() {
      return (this.expiredFlag || this.warningFlag) && this.$route.name !== 'login' 
        && this.$route.name !== 'unauthorized'
    },
    onValidPage() {
      return this.$route.name !== 'login' 
        && this.$route.name !== 'unauthorized'
    },
  },
  created() {
    addListeners(this.onInteraction, this.onFocus)
    startTimer(this.onWarning)
  },
  mounted() {
    this.$nextTick(this.onFocus)
  },
  methods: {
    onInteraction() {
      if (this.onValidPage) {
        if (hasBeenFiveMinutes(lastInteraction)) {
          refreshSession().catch(this.onExpiration)
        }
        startTimer(this.onWarning)
      }
    },
    onFocus() {
      if (this.onValidPage) {
        refreshSession().catch(this.onExpiration)
        this.onInteraction()
      }
    },
    onWarning() {
      this.exitIntro()

      this.warningFlag = true
      this.countDown = countDownTime

      if (expirationInterval !== null) {
        clearInterval(expirationInterval)
      }
      expirationInterval = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          this.onExpiration()
          clearInterval(expirationInterval)
        }
      }, 1000)
    },
    onExpiration() {
      this.exitIntro()
      this.expiredFlag = true
      this.$store.dispatch('clear')
      // localStorage.clear()
    },
    clearWarning() {
      this.onFocus()
      clearInterval(expirationInterval)
      this.warningFlag = false
    },
    exitIntro
  },
  beforeDestroy () {
    removeListeners(this.onInteraction)
    clearTimeout(warningTimeout)
    clearInterval(expirationInterval)
  }
}

function addListeners(callback, focusCallback) {
  window.addEventListener('mousedown', callback)
  window.addEventListener('keydown', callback)
  
  // eslint-disable-next-line
  if (/*@cc_on!@*/false) { // This is actually in use: it checks for Internet Explorer
    document.onfocusin = focusCallback
  } else {
    window.onfocus = focusCallback
  }
}
function removeListeners(callback) {
  window.removeEventListener('mousedown', callback)
  window.removeEventListener('keydown', callback)
  document.onfocusin = null
  window.onfocus = null
}

let date = new Date();
let lastInteraction = date.getTime()
function hasBeenFiveMinutes(compareTime) {
  let d = new Date()
  let currentInteraction = d.getTime()
  let timeDifference = (currentInteraction - compareTime)  / 1000
  lastInteraction = currentInteraction
  return timeDifference > refreshThrottle
}
function startTimer(callback) {
  if (warningTimeout !== null) {
    clearTimeout(warningTimeout)
  }
  warningTimeout = setTimeout(callback, warningThrottle)
}

// refreshing session
function refreshSession() {
  const url = process.env.VUE_APP_OKTA_URL;
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const { id } = await getSession(url)
      refresh(url, id)
    }
    catch(error) {
      try {
        const { id } = await getSession(url)
        refresh(url, id)
      }
      catch(error) {
        reject(error);
      }
    }
  })
}
async function getSession(url) {
  return new Promise((resolve, reject) => {
    fetch(`${url}/api/v1/sessions/me`, {
      method: 'GET',
      credentials: 'include',
    })
    .then(res => {
      if (!res.ok) {
        reject()
      }
      else {
        resolve(res.json())
      }
    })
  })
}
async function refresh(url, id) {
  return new Promise(resolve => {
    fetch(`${url}/api/v1/sessions/${id}/lifecycle/refresh`, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
    })
    .then(resolve)
  })
}

</script>

<style lang="scss">
@import "src/styles/component";

.session-warning-error {
  position: relative!important;
  justify-content: flex-start!important;
}
.login-watcher-modal .modal-content {
  border: none;
  border-radius: $border-radius;
}
.login-watcher-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;

  .entity-logo {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    z-index: -1;
  }
  h3 {
    display: block;
    font-size: 1.1rem;
    color: $text;
  }
}
.session-expired, .session-warning {
  padding: 6rem 3rem 3rem;

  h4 {
    color: $text-light;
    font-weight: $thin;
  }
  .body {
    margin-bottom: 1rem;
    font-weight: $bold;
    z-index: 1;
  }
  .btns {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .btn {
      width: 300px;
      color: white;
      background-color: #FFBB34;

      svg {
        margin-right: .5rem;
      }
    }
  }
}
.warn-btn:active {
  color: white!important;
}
</style>
