<template>
  <layout class="page-body trial-analytics-view" :rows="[21, 21, 21]" :columns="[16, 16, 16]">

    <layout-item :sm="[11, 6, 2]">
      <chart-wrapper
        wrapper-class="card"
        resource="studyChart"
        :query="chartQueries.pieChart"
        :summaryFields="summaryFields"
        :summaryValues="values"
        :summarySchema="schema"
        id="study-pie-chart"
        :help-link="getDocURL(`chart.studySearch${selectedStudyPieChart.label.split(' ').join('')}Chart`)"
        :intro-configs="introConfigs[selectedStudyPieChart.chart]"
      >
        <template v-slot:title="{ _state }">
          <title-form
            option="studyPieChartOptions"
            title="Studies by"
            v-model="selectedStudyPieChart"
          ></title-form>
        </template>
        <template v-slot="{ _chartState }">
          <pie-chart
            v-bind="_chartState"
            @click="$chartClick($event.name, selectedStudyPieChart.chart)"
            shadow
          ></pie-chart>
        </template>
      </chart-wrapper>
    </layout-item>

    <layout-item :sm="[11, 10, 2]">
      <chart-wrapper
        wrapper-class="card"
        resource="studyChart"
        :query="chartQueries.barChart"
        :summaryFields="summaryFields"
        :summaryValues="values"
        :summarySchema="schema"
        id="study-bar-chart"
        :help-link="getDocURL(`chart.studySearch${selectedStudyBarChart.label.split(' ').join('')}Chart`)"
        :intro-configs="introConfigs[selectedStudyBarChart.chart]"
        expand-size="wide"
      >
        <template v-slot:title="{ _state }">
          <title-form
            option="studyChartOptionsInTrialAnalyticsView"
            title="Studies by"
            v-model="selectedStudyBarChart"
          ></title-form>
        </template>
        <template v-slot="{ _chartState }">
          <bar-chart
            v-bind="_chartState"
            value-axis-name="Number of Studies"
            :label-axis-name="`${selectedStudyBarChart.label}`"
            :gridConfig="gridConfigs[selectedStudyBarChart.chart]"
            @click="$chartClick($event.name, selectedStudyBarChart.chart)"
            shadow
          ></bar-chart>
        </template>
      </chart-wrapper>
    </layout-item>

    <!-- aggregation -->
    <layout-item :sm="[15, 16, 6]">
      <aggregation-chart v-bind="{ values, schema, query }" 
        @openFilters="$emit('openFilters')" :intro-configs="introConfigs.aggregation"
        :summaryFields="summaryFields"></aggregation-chart>
    </layout-item>

  </layout>
</template>

<script>
import Layout from '@/components/presentation/layout/Layout'
import LayoutItem from '@/components/presentation/layout/LayoutItem'
import ChartWrapper from '@/components/presentation/charting/ChartWrapper'
import Charts from '@/components/presentation/charting/charts'
import { BarChartGridConfigs } from '@/components/presentation/charting/utils'
import AggregationChart from '@/components/presentation/charting/custom/AggregationChart'
import ChartHelperMixin from '@/mixins/ChartHelperMixin'
import TitleForm from '@/components/form/TitleForm'
import { getDocURL } from '@/services/DocumentationService'
import trialAnalyticsIntroConfigs from '@/steps/components/trialAnalyticsDashboard'

export default {
  name: 'trial-analytics-view',
  mixins: [ChartHelperMixin],
  props: ['schema', 'headers', 'active', 'summaryFields'],
  components: {
    Layout,
    LayoutItem,
    ChartWrapper,
    AggregationChart,
    TitleForm,
    ...Charts,
  },
  data() {
    return {
      selectedStudyPieChart: {
        label: 'Phase',
        chart: 'phase',
        type: 'pie',
      },
      selectedStudyBarChart: {
        label: 'Primary Purpose',
        chart: 'primary_purpose',
        type: 'bar',
      },
      gridConfigs: BarChartGridConfigs.trialAnalyticsView,
      introConfigs: trialAnalyticsIntroConfigs
    }
  },
  computed: {
    values() {
      return this.$store.getters['form/getForm']('studies-filters')
    },
    query() {
      return this.$store.getters['form/getQuery']('studies-filters')
    },
    isContainerActive() {
      return this.$store.getters['settings/containerEnabled']
    },
    chartQueries() {
      const params = this.$h.get(this, 'query', {})

      if (this.isContainerActive && Object.prototype.hasOwnProperty.call(params, "m_cid")) {
        delete params['m_cid']
      }

      return {
        pieChart: {
          ...this.selectedStudyPieChart,
          params,
        },
        barChart: {
          ...this.selectedStudyBarChart,
          params,
        },
      }
    }
  },
  methods: { getDocURL }
}
</script>

<style lang="scss">
.trial-analytics-view, .participant-dashboard {
  background-color: #F3F5F8;
}
</style>
