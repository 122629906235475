/* istanbul ignore file */
export default {
  SET(_state, { id, state }) {
    if (id === "cancer-center-participants") {
      _state.tables["cancer-center"].extra = state;
    } else {
      _state.tables[id] = state;
    }
  },
  CLEAR(_state) {
    _state.tables.race = {
      loading: true,
    };
    _state.tables.ethnicity = {
      loading: true,
    };
    _state.tables.diseases = {
      loading: true,
    };
    _state.tables.site = {
      loading: true,
    };
    _state.tables.sex = {
      loading: true,
    };
    _state.tables.registration = {
      loading: true,
    };
    _state.tables.study = {
      loading: true,
    };
    _state.tables["cancer-center"] = {
      loading: true,
    };
    _state.tables["diseases-home"] = {
      loading: true,
    };
    _state.tables["studies-charts"] = {
      loading: true,
    };
    _state.tables["participants-charts"] = {
      loading: true,
    };
  },
};
