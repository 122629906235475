<template>
  <span class="loading-bar" :class="`dark-${dark}`" :style="style"><div class="slide-bar" :style="barStyle">&nbsp;</div></span>
</template>

<script>
export default {
  name: 'loading-bar',
  props: {
    height: {
      type: [String, Number],
      default: '14',
    },
    width: {
      type: [String, Number],
      default: '100',
    },
    delay: {
      type: [String, Number],
      default: '0',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return `
        height: ${this.height}px;
        width: ${this.width}%;
      `
    },
    barStyle() {
      return `
        animation-delay: ${this.delay}s;
      `
    }
  }
}
</script>

<style lang="scss">

@import "src/styles/component";

.loading-bar {
  display: inline-block;
  background-color: $n1;
  border-radius: 15px;
  overflow: hidden;
  margin-top: -3px;

  @keyframes slide-bar {
    from {margin-left: -300px}
    to {margin-left: 100%}
  }

  >.slide-bar {
    width: 300px; height: 100%;
    border-radius: 15px;
    background-color: $n2;

    animation-name: slide-bar;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.73,.02,.26,.99);
  }
  &.dark-true {
    background-color: $n2;
    >.slide-bar {
      background-color: $n3;
    }
  }
}

</style>
