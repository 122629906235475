let steps = [];

export default function getSteps() {
  steps = [
    {
      element: '#trial-general-page-header-widget-actions-help',
      intro: `This is the General tab of the Study landing page. This tab lists overview information for the current Study.<br><a style="font-size: .9rem;" href="/documentation/docs/landing-studies/#study-landing-page-general-section" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg',
      highlightClass: 'special-highlight-bg'
    },
    {
      element: 'div.page.general > div.page-body > div.general-information > section.ids',
      intro: 'This section lists the related IDs of the current Study.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: 'div.page.general > div.page-body > div.general-information > section.detail',
      intro: 'This section lists details for the current Study. Click on \'Show more\' to view additional details.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: 'div.page.general > div.page-body > div.general-information > section.description',
      intro: 'This section diplays the title and summary infomration for the current Study. Click on \'Show more\' to view additional details.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: 'div.page.general > div.page-body > ul.general-lists > div#general-items',
      intro: 'The Principal Investigator and Cancer Center Principal Investigator or Lead Organization (if any) are listed here.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: 'div.page.general > div.page-body > ul.general-lists > li.general-counts',
      intro: 'This section displays counts for the current Study. Note that some counts are visible only to certain user roles.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: 'div.page.general > div.page-body > ul.general-lists > div#widget-lists',
      intro: 'This section diplays lists related to the current Study (if any).',
      tooltipClass: 'regular-tooltip-bg'
    }
  ];
  return steps;
}