import TextField from 'bootstrap-vue/es/components/form-input/form-input'
import RadioField from 'bootstrap-vue/es/components/form-radio/form-radio-group'
import CheckboxField from 'bootstrap-vue/es/components/form-checkbox/form-checkbox-group'
import TextareaField from 'bootstrap-vue/es/components/form-textarea/form-textarea'

import SelectField from './SelectField'
import DateField from './DateField'
import MultiFormField from './MultiFormField'
import SimpleMultiFormField from './SimpleMultiFormField'
import IdField from './IdField'
import FormField from './FormField'
import NotchedSlider from './NotchedSlider'
import PictureSelect from './PictureSelect'
import AgeFormField from './AgeFormField'
import EligibilityFormField from './EligibilityFormField'
import SiteLocationFormField from './SiteLocationFormField'
import KeywordFormField from './KeywordFormField';

export default {
  AgeFormField,
  EligibilityFormField,
  SiteLocationFormField,
  KeywordFormField,
  TextField,
  SelectField,
  RadioField,
  DateField,
  MultiFormField,
  SimpleMultiFormField,
  IdField,
  FormField,
  CheckboxField,
  TextareaField,
  NotchedSlider,
  PictureSelect,
}
