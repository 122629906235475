import intersection from 'lodash/intersection'
import { truthy } from '@/services/CommonsService'
import get from 'lodash/get'

const roleSections = {
  home: [
    'SYSTEM_ADMINISTRATOR',
    'ADMINISTRATOR',
    'RESEARCHER_ACCRUAL',
    'RESEARCHER_TRIAL'
  ],
  studies: [
    'SYSTEM_ADMINISTRATOR',
    'ADMINISTRATOR',
    'RESEARCHER_ACCRUAL',
    'RESEARCHER_TRIAL'
  ],
  organizations: [
    'SYSTEM_ADMINISTRATOR',
    'ADMINISTRATOR',
    'RESEARCHER_ACCRUAL',
    'RESEARCHER_TRIAL'
  ],
  investigators: [
    'SYSTEM_ADMINISTRATOR',
    'ADMINISTRATOR',
    'RESEARCHER_ACCRUAL',
    'RESEARCHER_TRIAL'
  ],
  participants: ['SYSTEM_ADMINISTRATOR', 'ADMINISTRATOR', 'RESEARCHER_ACCRUAL'],
  reports: [
    'SYSTEM_ADMINISTRATOR',
    'ADMINISTRATOR',
    'CANCER_CENTER',
    'REVIEWER'
  ],
  admin: ['SYSTEM_ADMINISTRATOR', 'ADMINISTRATOR'],
  cancerCenters: [
    'SYSTEM_ADMINISTRATOR',
    'ADMINISTRATOR',
    'RESEARCHER_ACCRUAL',
    'RESEARCHER_TRIAL'
  ],
  swagger: ['DEVELOPER'],
  help: [
    'DEVELOPER',
    'SYSTEM_ADMINISTRATOR',
    'ADMINISTRATOR',
    'RESEARCHER_ACCRUAL',
    'RESEARCHER_TRIAL'
  ]
}

export function hasAccess (section) {
  // todotemp
  // if (process.env.VUE_APP_MOCK === 'true') {
  //   return true
  // }

  const roles = getRoles()

  if (!truthy(section) || !truthy(roles)) {
    return false
  } else if (section === 'redirect') {
    return true
  } else if (section === 'test') {
    return false
  } else if (roles.includes('DEVELOPER')) {
    return true
  } else if (section === 'containers') {
    return false
  } else {
    if (roleSections[section]) {
      return intersection(roleSections[section], roles).length !== 0
    } else {
      return true
    }
  }
}

export function isDeveloper () {
  return getRoles().includes('DEVELOPER')
}

export function hasRole (role) {
  return getRoles().includes(role)
}

function getRoles () {
  if (process.env.VUE_APP_MOCK === 'true') {
    // For testing any role locally, replace DEVELOPER with the role you want to test
    // Ensure you switch it back to DEVELOPER, when you check in the code to your branch
    return ['DEVELOPER']
  }
  const oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'))
  return get(oktaStorage, 'idToken.claims.roles', [])
}
