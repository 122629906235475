/* istanbul ignore file */
import chartTablesActions from "./actions";
import chartTablesMutations from "./mutations";
import chartTablesGetters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      tables: {
        race: {
          loading: true,
        },
        ethnicity: {
          loading: true,
        },
        diseases: {
          loading: true,
        },
        site: {
          loading: true,
        },
        sex: {
          loading: true,
        },
        registration: {
          loading: true,
        },
        study: {
          loading: true,
        },
        "cancer-center": {
          loading: true,
          extra: {},
        },
        "diseases-home": {
          loading: true,
        },
        "race-home": {
          loading: true,
        },
        "studies-charts": {
          loading: true,
        },
        "participants-charts": {
          loading: true,
        },
        "participants-home-total": 0,
      },
    };
  },
  getters: chartTablesGetters,
  mutations: chartTablesMutations,
  actions: chartTablesActions,
};
