<template>
    <div>
    <ul class="data-view-filters-filter-list default" ref="scroll" :class="[`${this.$route.name}-page`]">
         <li class="advanced-filter-list-item" v-for="(field, index) in getAZStudyFilters(!this.advancedSearch && clearGroupSearch ? this.originalSFields : this.searchedFields)" 
         v-if="field.hasOwnProperty('show') && typeof field.show === 'function'
             ? field.show() : (field.id === 'actual_enrollment' || field.id === 'target_enrollment') ? false : true" 
              v-show="$h.get(field, 'show2', true)" :id="`aside-${field.id}`" :key="index"
         :class="[field.state, field.group.label, `tab-${field.tab}`]" @click="toggleField(field)">
        <span v-html="field.group.label"></span>
        <span class="icons">
            <icon key="check" class="plus-minus" v-if="field.state.active" :icon="'check'"></icon>
            <icon :key="field.state.visible" class="plus-minus" v-else
            :icon="field.state.visible ? 'minus' : 'plus'"></icon>
        </span>
        </li>
        <li class="advanced-filter-list-item empty" v-if="!this.searchedFields.length">
        No filters found
        </li>
        <li class="advanced-filter-list-item clear" v-if="advancedSearch.length"
        @click.prevent="clearSearch">
        <a href="#">Clear Search</a>
        </li>
    </ul>
    </div>
</template>
<script>
import { truthy } from '@/services/CommonsService'
export default {
    name: "az-study-filters",
    inheritAttrs: false,
    components: {},

    props: {
        searchedFields: Array,
        toggleField: {},
        toggleFieldVisible: Function,
        resetField: {},
        resetFilters: {},
        advancedSearch: String,
        originalSFields: Array,
    },
    data() {
        return {
            clearGroupSearch: false,
        };
    },
    computed: {},
    created() { },
    mounted() {
        this.getAZStudyFilters();
        this.$nextTick(this.resetFilterState);
    },
    methods: {
        clearSearch(){
            this.$emit('resetFilters');
            this.$emit('clear-search');
            this.clearGroupSearch = true
            this.getAZStudyFilters(this.originalSFields);
        },
        resetFilterState() {
            this.searchedFields.filter(sfEl => {
                if (!truthy(sfEl.value)) {
                    sfEl.state.active = false;
                    sfEl.state.visible = false;
                }
            });
        },
        getAZStudyFilters(searchFields){
            let azStudyFilters = [];

            let ptFlag = false;
            let priorTherapyFiltersGroup = ["study-participant-char-priorTherapy", "study-participant-char-sex", "study-participant-char-age"];

            truthy(searchFields) && searchFields.forEach((filter) => {
                if(priorTherapyFiltersGroup.includes(filter.id) && filter.state.active === true && filter.state.active === true){
                    ptFlag = true;
                }
            })

            truthy(searchFields) && searchFields.forEach((filter) => {
                if(priorTherapyFiltersGroup.includes(filter.id) && ptFlag){
                    filter.state.active = true;
                    filter.state.visible = true;
                }
                azStudyFilters.push(filter);
            })

            return azStudyFilters;
        }
    },
};
</script>

