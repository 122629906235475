import { apiGet } from '../services/DataService';
import get from 'lodash/get';

export default {
  props: {
    noMinLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      DataMixin_response: undefined,
      DataMixin_loading: true,
      DataMixin_error: '',
      DataMixin_min: 750,
      DataMixin_first: true,
      DataMixin_formatter: undefined,
    }
  },
  computed: {
    $state() {
      return {
        response: this.DataMixin_response,
        loading: this.DataMixin_loading,
        error: this.DataMixin_error,
      }
    },
  },
  methods: {

    $get(query, options) {

      // set options
      let resolve = get(options, 'resolve', () => {})
      let reject = get(options, 'reject', () => {})
      let final = get(options, 'finally', () => {})

      // reset state
      this.DataMixin_loading = true
      this.DataMixin_error = ''

      // make query
      apiGet(query)
        .then(response => this.resolve(resolve, response, final))
        .catch(error => this.reject(reject, error, final))

    },

    resolve(resolve, response, final = () => {}) {
      setTimeout(() => {
        this.DataMixin_loading = false
        this.DataMixin_first = false
        this.DataMixin_response = this.DataMixin_formatter !== undefined 
          ? this.DataMixin_formatter(response)
          : response

        resolve(this.DataMixin_response)
        final()

      }, this.DataMixin_first && !this.noMinLoading ? this.DataMixin_min : 0)
    },
    reject(reject, error, final = () => {}) {
      this.DataMixin_loading = false
      this.DataMixin_error = typeof error === "object" ? error.reason.toString() : error.toString();
      reject(error)
      final()
    },

  }
}
