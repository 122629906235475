<template>
  <div class="bar">
    <div class="extra" v-if="$h.truthy($h.get(options, 'extra'))">
      <ul class="table-age-row is-chart">
        <li>
          <span class="label">Mean</span>
          <span
            class="value"
            v-text="$h.get(options, 'extra.mean', 'N/A')"
          ></span>
        </li>
        <li>
          <span class="label">Standard Deviation</span>
          <span
            class="value"
            v-text="$h.get(options, 'extra.sd', 'N/A')"
          ></span>
        </li>
        <li>
          <span class="label">Range</span>
          <span
            class="value"
            v-text="
              `${$h.get(options, 'extra.range.from', '0')} to ${$h.get(
                options,
                'extra.range.to',
                '0'
              )}`
            "
          ></span>
        </li>
      </ul>
    </div>
    <e-charts
      ref="chart"
      :options="$activeOptions"
      :auto-resize="false"
      @click="$click"
    ></e-charts>
  </div>
</template>

<script>
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/dataZoom";
import ChartMixin from "@/mixins/ChartMixin";
import { abbreviate, localeString, titleCase } from "@/services/FilterService";
import { truthy } from "@/services/CommonsService";

function maxValue(dataArray) {
  return truthy(dataArray)
    ? dataArray.reduce((prev, current) => {
        return prev > current ? prev : current;
      })
    : 0;
}

function getMaxLabelLength(dataArray) {
  let lengthArray = [];
  dataArray.forEach((data) => {
    lengthArray.push(data.length);
  });

  return maxValue(lengthArray);
}

const text = "#3F546C";
const textLight = "#93A1B2";

// format label and value
const labelFormatter = (label) => abbreviate(label, 15);
const valueFormatter = (label) => localeString(label);
const defaultFormatter = (label) => abbreviate(label, 25);

function getLabelNameGap(labelAxisData, expanded) {
  let newLabelData = [];
  labelAxisData.forEach((label) => {
    label = expanded ? defaultFormatter(label) : labelFormatter(label);
    newLabelData.push(label);
  });
  let maxLabelLength = getMaxLabelLength(newLabelData);
  let interval;

  if (maxLabelLength <= 28 && maxLabelLength > 25) {
    interval = 6.5;
  } else if (maxLabelLength <= 25 && maxLabelLength > 20) {
    interval = 7;
  } else if (maxLabelLength <= 20 && maxLabelLength > 15) {
    interval = 7;
  } else if (maxLabelLength <= 15 && maxLabelLength > 10) {
    interval = 9;
  } else if (maxLabelLength <= 10 && maxLabelLength > 5) {
    interval = 10;
  } else {
    interval = 11;
  }
  return interval * maxLabelLength;
}

// let verticalGrid = {
//   top: '32px',
//   left: '40px',
//   right: '48px',
//   bottom: '48px',
//   containLabel: true
// }
// let horizontalGrid = {
//   top: '8px',
//   left: '40px',
//   right: '48px',
//   bottom: '32px',
//   containLabel: true
// }
const labelAxisNameTextStyle = { color: text };
const valueAxisNameTextStyle = { color: textLight };

export default {
  name: "bar-chart",
  components: { ECharts },
  mixins: [ChartMixin],
  props: {
    extra: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    gridConfig: {
      type: Object,
    },
    labelAxisName: {
      type: String,
    },
    valueAxisName: {
      type: String,
    },
    dataZoom: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    rotate: {
      type: Number,
    },
    enableLabelSort: {
      type: Boolean,
      default: true,
    },
    labelSortFunc: {
      type: Function,
      default: (a, b) => b.value - a.value,
    },
    needsLabelFormat: {
      type: Boolean,
      default: false,
    },
    needsToBeLogarithmic: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
    },
  },
  data() {
    return {
      maxValue: 0,
      labelNames: [],
      defaultOptions: {},
      expandedOptions: {},
      extraData: {},
    };
  },
  created() {
    const { defaultOptions, expandedOptions } = this.createChart();
    this.defaultOptions = defaultOptions;
    this.expandedOptions = expandedOptions;
  },
  methods: {
    createChart() {
      let legend = this.$optionDefault("legend", []);
      let tooltip = this.$optionDefault("tooltip", {});
      let series = this.$optionDefault("series", []);
      let labels = this.$h.get(this.options, "xAxis.data", []);
      // let grid = this.vertical ? verticalGrid : horizontalGrid
      // grid.bottom = this.labelAxisName ? '108px' : '48px'

      let gridConfig = this.$h.cloneDeep(this.gridConfig);
      let grid = gridConfig.default;
      let extraGrid = gridConfig.expanded;

      // let grid = {}
      // if (this.vertical) {
      //   grid = verticalGrid
      //   grid.bottom = this.labelAxisName ? '108px' : '48px'
      // } else {
      //   grid = horizontalGrid
      //   grid.left = this.labelAxisName ? '60px' : '40px'
      //   grid.right = this.valueAxisName ? '88px' : '48px'
      // }

      let dataZoom = null;
      let formattedLegend = {};

      // sort
      let dataTemp;

      if (this.appendNone(series)) {
        labels.push("None");
      }
      dataTemp = this.getLabelValue(
        labels,
        series[0].data,
        this.labelSortFunc,
        this.enableLabelSort
      );
      series[0].data = this.$h.cloneDeep(dataTemp).map((data) => data.value);

      if (this.needsLabelFormat) {
        this.labelNames = this.$h.cloneDeep(dataTemp).map((data) => {
          return data.label
            .split(";")
            .map((name) => titleCase(name))
            .join(", ");
        });
      } else {
        this.labelNames = this.$h.cloneDeep(dataTemp).map((data) => data.label);
      }

      // max
      if (this.needsToBeLogarithmic && this.options.yAxis) {
        this.options.yAxis.type = "log";
      }
      this.maxValue = maxValue(series[0].data);

      // set up axis
      const axises = this.getAxisData();
      const axisesExpanded = this.getAxisData(true);

      // color
      if (this.variant) {
        this.$colorBar(series[0]);
      }
      // data zoom
      if (this.dataZoom) {
        dataZoom = {
          show: true,
          start: 0,
          end: 100,
          bottom: "20px",
        };
      }

      // legend
      formattedLegend = {
        data: this.labelNames,
      };

      // bar shadow
      if (this.shadow) {
        this.addShadow(series);
      }

      // disable click
      if (this.disableClick) {
        series.forEach((serie) => {
          serie.cursor = "default";
        });
      }
      return {
        defaultOptions: {
          grid,
          series,
          ...axises,
          tooltip: legend.length
            ? { backgroundColor: "grey" }
            : this.$optionMerge(tooltip, { trigger: "axis" }),
        },
        expandedOptions: {
          dataZoom,
          ...axisesExpanded,
          legend: formattedLegend,
          grid: this.$optionMerge(grid, { ...extraGrid }),
        },
      };
    },

    appendNone(seriesData) {
      const barSum = seriesData[0].data.reduce((acc, current) => {
        return acc + current;
      }, 0);
      if (barSum < this.total) {
        seriesData[0].data.push(this.total - barSum);
        return true;
      } else {
        return false;
      }
    },

    getLabelValue(labels, values, cb, flag) {
      let newData = [];
      for (let i = 0; i < labels.length; ++i) {
        let obj = Object.assign({}, { label: labels[i], value: values[i] });
        newData.push(obj);
      }
      return flag ? newData.sort(cb) : newData;
    },
    getAxisData(expanded = false) {
      let labelAxis = this.$optionDefault("xAxis", {});
      let valueAxis = this.$optionDefault("yAxis", {});
      let labelKey = this.vertical ? "xAxis" : "yAxis";
      let valueKey = this.vertical ? "yAxis" : "xAxis";

      let labelNameGap;
      if (this.$h.truthy(this.gridConfig.nameGap.labelAxis)) {
        labelNameGap = expanded
          ? this.gridConfig.nameGap.labelAxis[0]
          : this.gridConfig.nameGap.labelAxis[1];
      } else {
        labelNameGap = getLabelNameGap(
          this.$h.cloneDeep(labelAxis.data),
          expanded
        );
      }
      let valueNameGap = expanded
        ? this.gridConfig.nameGap.valueAxis[0]
        : this.gridConfig.nameGap.valueAxis[1];

      return {
        [labelKey]: {
          ...labelAxis,
          data: this.labelNames,
          axisLabel: {
            formatter: this.getFormatter("label", expanded),
            textStyle: labelAxisNameTextStyle,
            rotate: this.rotate,
          },
          name: this.labelAxisName,
          nameLocation: "middle",
          nameGap: labelNameGap,
          nameTextStyle: {
            color: text,
            fontSize: 11,
            fontWeight: 600,
            padding: [0, 0, 0, 0],
          },
        },
        [valueKey]: {
          ...valueAxis,
          max: this.maxValue,
          axisLabel: {
            formatter: this.getFormatter("value", expanded),
            textStyle: valueAxisNameTextStyle,
          },
          name: this.valueAxisName,
          nameLocation: "middle",
          nameGap: valueNameGap,
          nameTextStyle: {
            color: text,
            fontWeight: 600,
            fontSize: 11,
            padding: [0, 0, 0, 0],
          },
        },
      };
    },
    getFormatter(type, expanded = false) {
      if (type === "label") {
        return expanded ? defaultFormatter : labelFormatter;
      } else {
        return valueFormatter;
      }
    },
    addShadow(series) {
      let dataShadow = [];
      const yMax = this.$h.truthy(series[0]) ? this.maxValue : 0;
      if (this.$h.truthy(series[0])) {
        for (let i = 0; i < series[0].data.length; ++i) {
          dataShadow.push(yMax);
        }
      }
      series.unshift({
        type: "bar",
        itemStyle: {
          normal: { color: "rgba(0,0,0,0.04)" },
        },
        barBorderRadius: [15, 15, 0, 0],
        barGap: "-100%",
        barCategoryGap: "40%",
        data: dataShadow,
        animation: false,
        tooltip: {
          show: false,
          formatter: "",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  width: 100%;
  height: 100%;
}
</style>