<template>
  <div :id="[routeName === 'studies' ? 'newFB' : 'newFBParticipant']">

    <!-- groupFiltersByFilterGroup() groups filters by their 
      filter group title (studyFilterGroup) and store each group into 'filtersByGroup'  -->
    <h1 v-if="hasStudyFilters(fields)" id="h1StudyFilter" >Study Filters</h1>
    <div v-for="(filtersByGroup, i) in groupFiltersByFilterGroup(fields)" :key="i">

    <b-form
      @submit.prevent="submit"
      @reset.prevent="reset"
      :class="{ onlyShowActive, search: fieldSearch }"
      @click="handlePageClick"
    >

      <!-- filtersByGroup[0] is the filter group title -->
      <!-- filtersByGroup[0] === participantFiltersKey is the group that contains all the participant filters-->
      <!-- filtersByGroup[1] contains all the filters under the same filter group title that are selected/visible-->
      <h1 v-if="filtersByGroup[0] === participantFiltersKey && filtersByGroup[1]" id="h1ParticipantFilter" >Participant Filters</h1>
      <h2 v-if="filtersByGroup[0] != participantFiltersKey" 
        :class="[routeName === 'studies' ? '' : 'h2StudyFilterGroup']"
      >{{ filtersByGroup[0] }} </h2>
      <div
        class="form-field"
        v-for="(field, index) in filtersByGroup[1]"
        :class="[
          field.state,
          `last-${index === lastFieldIndex}`,
          `${activeCTFinder === 'filter-groups'}` ? `tab-${field.tab}-group`: `tab-${field.tab}`,
          `disabled-${field.state.disabled}`,
          (field.width && field.width === 'full') || field.id === 'ids-search-sec' ? 'form-field-full-width' : ''
        ]"
        :key="field.id"
        :id="`${id}-${field.id}`"
      >

      <!-- Horizontal Divider -->
      <div v-if="field.id == 'funding-nci-fundType'" class="divider-container">
        <div>
          <span aria-label="OR">
              OR
          </span>
        </div>
        <br>
      </div>

        <!-- wrapper -->
        <template v-if="validateRegistrationDateValueIsNotNull(field)">
          <component
            :is="`${$h.get(field, 'wrapper.name', 'default')}-wrapper`"
            v-bind="field.wrapper"
            :model="field.value"
            :key="updateKey"
          >
            <!-- group -->
            <b-form-group
              v-bind="[field.id === 'date-range'? field.groupingFilterDisplayLabel : field.group ]"
              :state="field.state.validation === null"  
              :class="[
                field.hideField ? 'form-field-hide' : 'form-field-show'
              ]"
              :label-for="`${id}-${field.id}-input`"
              :invalid-feedback="field.state.validation"
              :dkey="JSON.stringify(field.state)"
            >
              <!-- field -->
              <component
                class="form-generator-field"
                :id="`${id}-${field.id}-input`"
                :is="`${field.type}`"
                :ref="field.id"
                v-model="field.value"
                v-bind="field.input"
                :state="!!field.state.validation ? false : null"
                :disabled="field.state.disabled"
                :alwaysCallMounted="field.state.active"
                :form-values="model"
                :open="open"
                @change="(value) => $nextTick(() => updateValue(field.id, value))"
                @update="(context) => onUpdate(field, context)"
              >
              <div v-if="searchTypeWarning" class="search-type-err">
                <p>Warning: The selected SEC filters(s) need to be deleted and reapplied when you switch between the Eligibility and Portfolio Trial Searches.</p>
              </div>
            </component>

              <div
                class="clear-field-btn"
                v-if="field.hasClear && field.state.active"
                @click="resetField(field)"
                v-tooltip.hover="`Reset ${field.group.label}`"
              >
                <icon icon="ban"></icon>
              </div>
            </b-form-group>
          </component>
        </template>
      </div>

      <div class="form-actions" v-if="!noActions">
        <btn
          type="reset"
          variant="header"
          v-if="!noReset"
          v-html="resetText"
          @click="$emit('cancel')"
        ></btn>
        <btn
          type="submit"
          :variant="submitVariant"
          class="submit"
          :block="blockSubmit"
          :disabled="disabled"
        >
          <span v-if="submitText === 'camera'">
            <icon icon="download"></icon>Download
          </span>
          <span v-else v-html="submitText"></span>
        </btn>
      </div>
    </b-form>



    </div>
  </div>

</template>

<script>
import Fields from "./fields";
import Wrappers from "./wrappers";
import bForm from "bootstrap-vue/es/components/form/form";
import bFormGroup from "bootstrap-vue/es/components/form-group/form-group";
import merge from "lodash/merge";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { EventBus } from "@/event-bus.js";
import { truthy } from '@/services/CommonsService'


export default {
  name: "form-builder-groups-tab",
  components: { ...Fields, ...Wrappers, bForm, bFormGroup },
  beforeCreate() {
    this.$options.components.FormBuilder = require("./FormBuilderGroupsTab.vue");
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
    searchedFields:{
      type: Array,
      required: true,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    bindFields: {
      type: Array,
      default: () => [],
    },
    lastFieldIndex: {
      type: Number,
      default: -1,
    },
    submitOnChange: {
      type: Boolean,
      default: false,
    },
    submitOnMount: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    persist: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyShowActive: {
      type: Boolean,
      default: false,
    },
    blockSubmit: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    submitVariant: {
      type: String,
      default: "success",
    },
    resetText: {
      type: String,
      default: "Cancel",
    },
    fieldSearch: {
      type: String,
      default: "",
    },
    noReset: {
      type: Boolean,
      default: false,
    },
    showActiveOnMount: {
      type: Boolean,
      default: false,
    },
    isFormActive: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    alwaysCallMounted: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      default: "global",
    },
    activeCTFinder: {
      type: String,
      default: "",
    },
    routeName: {
      type: String,
      default: "",
    },
    validateRegistrationDateValueIsNotNull: Function,
  },
  data() {
    return {
      model: {},
      states: {},
      fields: [],
      dSubmit: null,
      dActive: null,
      dCallMounted: null,
      isFieldsActive: false,
      searchTypeWarning: false,
      groupTitle: new Set(),
      updateKey: 0,
      applyFiltersFlag: false,
    };
  },
  created() {
    this.dCallMounted = debounce(this.callMounted, 150);
    this.dSubmit = debounce(this.submit, 10);
    this.dActive = debounce(() => {
      this.$emit(
        "active",
        this.fields.map((field) => ({
          field: field,
          tab: field.tab,
          active: field.state.active,
        }))
      );
    }, 10);
    this.participantFiltersKey = "participant";  // This defines the key of the group that all participant filters will be stored into.
    this.setUpFields();
  },
  mounted() {
    if (this.submitOnMount) {
      this.dSubmit();
    }
    this.dCallMounted();
  },
  methods: {
    atLeastOneFilterSelectedFromGroup(filterGroup){
      return truthy(filterGroup);
    },
    handlePageClick(){
      this.searchTypeWarning = false;
    },
    handleSecCriteriaChange(data, Changedvalue) {
      const field = this.fields.find((f) => f.id === 'sec_type');

      if (!field) {
        return;
      }

      const label1 = Changedvalue === 'portfolio' ? 'Inclusion' : 'Focus';
      const label2 = Changedvalue === 'portfolio' ? 'Exclusion' : 'Eligible';

      field.input.options[0].label = label1;
      field.input.options[1].label = label2;
      // this.schema.find((s) => s.id === 'sec_type').input.options[0].label = label1;
      // this.schema.find((s) => s.id === 'sec_type').input.options[1].label = label2;
    },
    isEligibilitySECFields(data) {
      return data.filter((field) => field['field-type'] === 'sec').length > 0;
    },

    //This flag is set to true only when coming from "Apply My Filters"
    setApplyFilterFlag(flag) {
      this.applyFiltersFlag = flag;
    },
    // setup
    setUpFields() {
      const storeModel = this.persist
        ? this.$store.getters["form/getForm"](this.id)
        : {};
      const propsModel = this.$h.cloneDeep(this.values);
      this.buildFields(merge({}, propsModel, storeModel));
    },
    groupFiltersByFilterGroup(fields){
      //display the SEC correct label on the page.
      fields.forEach((field)=> {
        if(field.id === "study-participant-char-age"){
          field.group.label = "Age"
        }else if(field.id === "study-participant-char-sex"){
          field.group.label = "Sex"
        }else if(field.id === "study-participant-char-priorTherapy"){
          field.group.label = "Eligibility Criteria"
        }
      });
      // generate the groups by group name/"studyFilterGroup" label.
      const studyFiltersHashMap = this.generateGroupsByStudyFilterGroupName(fields);
      const participantFiltersHashMap = this.getParticipantFilters(fields);

      // sort the right hand side alphabetically
      const sortedMap = this.sortRightHandSideAlphabetically(studyFiltersHashMap, participantFiltersHashMap);
      
      return sortedMap;
    },
    generateGroupsByStudyFilterGroupName(fields){
      /*
        This method splits up all fields by their group label (studyFilterGroup.label)
        so that all fields selected on the right side in the "Groups" tab will be displayed under 
        the correct group label. 
       */
      let hashMap = new Map()

      /* 
        Add an entry to the hashmap and use the label(field.studyFilterGroup.label)
        as the key, and the value will be an array to store all fields that have the same label. 
        (e.g. hashmap{ "Study Design & Status" : ["Current Study Status", "Interventions"]})
        We only want to add the field to the hashmap if it is visible, visible means it is selected by the user.
      */
      if(truthy(fields)){
        fields.forEach( (field) => {
          if(field.studyFilterGroup && !(hashMap.has(field.studyFilterGroup.label)) && field.state?.visible){
            hashMap.set(field.studyFilterGroup.label, [field]);
          }else if(field.studyFilterGroup && field.state?.visible){
            let arr = hashMap.get(field.studyFilterGroup.label);
            arr.push(field)
            hashMap.set(field.studyFilterGroup.label, arr);
          }
        })
      }
      return hashMap;
    },
    getParticipantFilters(fields){
      // Create a hash map and use the "participant" as the key to group all participant filters
      let hashMap = new Map()
      if(truthy(fields)){
        fields.forEach( (field) => {
          if(
            field.tab && field.tab === this.participantFiltersKey && 
            (field.id != "p-date-range" && (field.state?.visible || truthy(field.value))) ||
            (field.id === "p-date-range" && this.validateRegistrationDateValueIsNotNull(field))
          ){
            if(!hashMap.has(field.tab)){
              hashMap.set(field.tab, []);
            }
            field.state.visible = true;
            let arr = hashMap.get(field.tab);
            arr.push(field)
            hashMap.set(field.tab, arr);
          }
        })
      }
      return hashMap;
    },
    hasStudyFilters(fields){
      /*
        This method checks if there is any study filter has applied.
      */
      let res = false;
      if(truthy(fields)){
        fields.forEach( (field) => {
          if(field.tab && field.tab === 'study' && field.state?.visible){
            res = true;
          }
        })
      }
      return res;
    },
    sortRightHandSideAlphabetically(studyFiltersHashMap, participantFiltersHashMap){
      const studySortedKeys = Array.from(studyFiltersHashMap.keys()).sort();
      const sortedMap = new Map();

      // Sort the right hand side alphabetically by "groupingFilterDisplayLabel"
      studySortedKeys.forEach((key)=> {
        const sortedArray = this.sortArrayByGroupingFilterDisplayLabel(studyFiltersHashMap.get(key));
        sortedMap.set(key, sortedArray);
      })

      // Sort the right hand side alphabetically for participant filters. 
      // This should also ensure all participant filters display after study filters
      const sortedArray = participantFiltersHashMap.get(this.participantFiltersKey);
      sortedMap.set(this.participantFiltersKey, sortedArray);

      return sortedMap;
    },
    sortArrayByGroupingFilterDisplayLabel(arr){
      return arr.sort((a,b) => {
        // Sort by the labels in the Groups tab ("groupingFilterDisplayLabel")
        // if not available, sort by "group" label. Every field in the arr should have "group" label, which is how they appear in A-Z tab.
        const aLabel = a["groupingFilterDisplayLabel"] ? a["groupingFilterDisplayLabel"].label : a["group"].label;
        const bLabel = b["groupingFilterDisplayLabel"] ? b["groupingFilterDisplayLabel"].label : b["group"].label;
        if(aLabel < bLabel){
          return -1;
        }else if(aLabel > bLabel){
          return 1;
        }
        return 0;
      });
    },
    buildFields(model) {
      /*
        Renders the right side when user navigates from A-Z to Groups. 
        "this.fields" will be used to render the filters on the right side. 
        "this.searchedFields" contains filters selected in A-Z. 
        "this.model" retains filters value between studies and participants page.
       */
      this.fields = [];
      /* Adding the applyFiltersFlag check here, so that if the request is coming from Apply MY Filters (Saved Filters)
         then we want it to go to the else block to run buildFieldsFromScratch() */
      if (!this.applyFiltersFlag &&
          truthy(this.searchedFields) && (this.$route?.name === "studies" || this.$route?.name === "participants"))
        {
        this.searchedFields.forEach(
          (f) => {
            this.model[f.id] = f.value; // This line helps to make sure the filters applied are retained when switching between studies and participants page
          }
        )
        this.fields = this.searchedFields;
      }else{
        this.fields = this.buildFieldsFromScratch(model);
      }
      this.$emit("update:bindFields", this.fields);
      this.$emit("update:bindValues", this.model);
    },
    buildFieldsFromScratch(model) {
      this.fields = [];
      this.schema.forEach((schemaField) => {
        let show = this.$h.get(schemaField, "show", () => true)();
        let override = this.$h.get(schemaField, "overrideShow", false);
        if (show || (!show && override)) {
          // set field
          let field = this.$h.cloneDeep(schemaField);

          // get field value
          const value = Object.prototype.hasOwnProperty.call(model, schemaField.id)
            ? model[schemaField.id]
            : this.$h.get(schemaField, "default", undefined);

          field.value = value;
          field.state = getState(field);

          this.model[field.id] = value;
          this.fields.push(field);
          this.updateFieldActive(field);
          if (this.showActiveOnMount) {
            this.updateFieldState(field.id, "visible", field.state.active);
          }
        }
      });
      return this.fields;
    },
    callMounted() {
      this.fields.forEach((field) => {
        if (
          Object.prototype.hasOwnProperty.call(field, "mounted") &&
          (this.open || this.alwaysCallMounted || field.state.active)
        ) {
          field.mounted(
            this.model,
            this.fields,
            this.resetFields,
            this.updateFieldState
          );
        }
      });
    },

    // form actions
    submit() {
      const areFieldsValid = this.areFieldsValid();
      this.$emit("update:isFormActive", areFieldsValid);
      if (areFieldsValid) {
        const query = this.formatModel();
        this.$emit("submit", query);
        this.saveModel(query);
      }
    },
    reset() {
      this.$emit("cancel");
    },
    resetFields() {
      this.fields.forEach(this.resetField);
    },

    // field actions

    /**
     * Invoker:
     * Invoked when user edits the value of an existing saved field.
     * 
     * Purpose:
     * Updates the form with the value being edited, emits change event with the changed form data
     * @param {id} field id of the custom element (Example: id-selector for SimpleMultiFormField)
     * @param {value} field value of the custom element (Example: {label: 'Organization CTEP ID', value: 'organizations.ctep_id'})
     * @param {shouldChange} flag to update the fields list
     */
    updateValue(id, value, shouldChange = true) {
      let field = this.findField(id);
      if (field) {
        //Emit event
        if ((field.id === "searchType")) {
          if(this.findField("ids-search-sec").value && this.findField("ids-search-sec").value.length > 0){
            // Flip back the value to prevent a value change on SEC Trial Search Type if at least one sec has been selected.
            field.value = field.value === "default" ? "portfolio" : "default";
            this.searchTypeWarning = true;
            return;
          }else{
            EventBus.$emit("searchType-field-event", value);
          }
        }
        if (["min-age-input", "max-age-input"].includes(field.id) && 
        (value.length === 0 || parseInt(value) < 0 || parseInt(value) > 999)) {
          value = field.id === "min-age-input" ? '0' : '999';
        }
        if (!value && field.id === "sec_criteria") {
          value = field.default
        }

        if (field.id === "_keyword_form" && value) {
          // when keyword is updated in the textbox on Studies page,
          // the target keyword search values are retained
          if (value._keyword && field.value && field.value.keyword_search_type) {
              value = {
                  _keyword: value._keyword,
                  keyword_search_type: field.value.keyword_search_type || [],
                }
          }
          // this ensures that the Open Summary Filter bar will not list Keyword Search
          // when keyword is deleted in the textbox on Studies page or when the keyword filter is removed
          // check if keyword is empty or undefined - this happens when keyword_search_type is selected but no keyword is entered from filter popup input box
          if (value._keyword === "" || value._keyword == undefined) {
            value = null
          }
        }

        this.$set(this.model, field.id, value);
        field.value = value;
        if (shouldChange) {
          this.updateField(field);
        }
        if (field['field-type'] === 'sec' && field['id'] === 'id-selector') {
          if (value['value'] === 'code') {
            this.model['toggle-code-sec'] = true
          } else {
            this.model['toggle-code-sec'] = false
          }
        } else {
          delete this.model['toggle-code-sec']
        }
        this.$emit("change", this.model);
        this.$emit("update:bindValues", this.model);
      }
    },
    updateField(field) {
      this.updateFieldActive(field);
      this.updateFieldChanged(field);
      if (this.submitOnChange) {
        this.dSubmit();
      }
      this.$emit("update:bindFields", this.fields);
    },
    updateFieldValidation(field) {
      const validation = this.getFieldValidation(field);
      this.$set(field.state, "validation", validation);
      return validation;
    },
    updateFieldChanged(field) {
      if (Object.prototype.hasOwnProperty.call(field, "changed")) {
        field.changed(
          this.model,
          this.fields,
          this.resetField,
          this.updateFieldState,
          this.updateValue
        );
      }
    },
    updateFieldActive(field) {
      field.state.active = Object.prototype.hasOwnProperty.call(field, "isActive")
        ? field.isActive()
        : this.$h.truthy(field.value);

      this.dActive();
    },
    updateFieldState(id, state, payload) {
      let field = this.findField(id);
      this.$nextTick(() => {
        if (field) {
          field.state[state] = payload;
          this.$set(field.state, state, payload);
        }
      });
    },
    getFieldValidation(field) {
      if (!field.hideField && Object.prototype.hasOwnProperty.call(field, "validations")) {
        const validations = this.$h.cloneDeep(field.validations);
        const validationResult = validations
          .map((rule) => rule(field.value, this.model))
          .filter((result) => result !== null)
          .pop();

        return validationResult === undefined ? null : validationResult;
      } else {
        return null;
      }
    },
    resetField(field) {
      if (field.id !== 'ids-search-sec') {
        const value = Object.prototype.hasOwnProperty.call(field, "default") ? field.default : undefined;
        this.updateValue(field.id, value, false);
      }
    },

    // utils
    formatModel() {
      let model = {};
      const currentModel = this.$h.cloneDeep(this.model);
      this.fields.forEach((field) => {
        const currentValue = currentModel[field.id];
        if (Object.prototype.hasOwnProperty.call(field, "get")) {
          field.get(model, field, this.fields, this.model);
        } else {
          if(field.value){
            model[field.id] = this.$h.cloneDeep(field.value);
          }else{
            model[field.id] = currentValue;
          }
        }
      });
      return model;
    },
    saveModel(query) {
      /* 
        This method gets called when the Apply Filters button is clicked in 'Groups' tab. 
      */
      let newModel = this.$store.getters["form/getForm"](this.id) ? this.$store.getters["form/getForm"](this.id) : {};
      /*
        "this.model" is what get rendered on the right side in the "Groups" tab. 
        If any of the filters is updated in 'this.model', 
        we want to also update "this.$store.getters["form/getForm"](this.id)", 
        so that both "Groups" and "A-Z" are in sync.
       */
      for (const [filterKey, filterValue] of Object.entries(this.model)) {
          if(truthy(filterValue)){
            /* 
            This handles the following scenarios: 
            1. Apply a filter from A-Z => Switch to Groups => Add and apply a new filter from Groups
            2. Apply a filter from A-Z => Switch to Groups => Modify and apply the filter from Groups
            */

            // filterValue is truthy when user changes value of a filter or adds a new filter
            newModel[filterKey] = filterValue; 
          }else{
            /* 
            This handles the following scenarios: 
            1. Apply a filter from A-Z => Switch to Groups => remove a filter and click Apply Filters from Groups
            */

            // filterValue is NOT truthy when filter value is being removed
            newModel[filterKey] = {}; 
          }
      }
      if (this.persist) {
        this.$store.dispatch("form/saveForm", {
            query,
            id: this.id,
            model: this.$h.cloneDeep(newModel),
          });
      }
    },
    findField(id) {
      return this.fields.find((field) => field.id === id);
    },
    areFieldsValid() {
      const result = this.fields.map((field) => {
        return this.updateFieldValidation(field) === null;
      });
      return result.every((r) => r);
    },
    onUpdate(field, context) {
      if (Object.prototype.hasOwnProperty.call(field, "update")) {
        field.update(context);
      }
    },
    searchForFields(search) {
      if (search !== "") {
        this.fields.forEach((field) => {
          const label = this.$h.get(field, "group.label", "");
          field.state.visible = label
            .toLowerCase()
            .includes(search.toLowerCase());
        });
      }
    },
  },
  watch: {
    fieldSearch: "searchForFields",
    fields() {
      this.dCallMounted();
    },
    values(){
      // update component with "updateKey" only 
      // Ex. Keyword filter update in grid view should be reflected in the keyword filter in filters modal as well.
      this.updateKey++; 
    },
    // open: "callMounted",
  },
};

function getState(field) {
  const validations = get(field, "validations", []);
  return {
    active: false,
    visible: true,
    disabled: false,
    validation: "",
    required: validations
      ? validations.some((rule) => rule.name === "required")
      : false,
  };
}
</script>

<style lang="scss">
@import "src/styles/component";

form.onlyShowActive {
  > .form-field:not(.active) {
    display: none;
  }
}
.b-form-group {
  position: relative;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
  .submit {
    svg {
      margin-right: 0;
    }
  }
  button:not(:first-child) {
    margin-left: 0.75rem;
  }
}
.clear-field-btn {
  position: absolute;
  top: 1.8rem;
  right: -48px;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  color: darken($n1, 40);
  border: solid 2px transparent;

  display: none;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: fade-out($danger, 0.9);
    border-color: fade-out($danger, 0.85);
    color: $danger;
  }
  &:active {
    background-color: fade-out($danger, 0.8);
    color: darken($danger, 5);
  }
}
small {
  font-weight: $thin;
}

.divider-container {
  padding: 3rem 0rem;
  margin: -6%;
  div {
    width: 100%;
    height: 15px;
    border-bottom: 1px dashed #cccfd4;
    text-align: center;
    span {
      font-size: 20px;
      background-color: #ffffff;
      color: #000000;
      padding: 4px;
    }
  }
}

.divider-container {
  padding: 3rem 0rem;
  margin: -6%;
  div {
    width: 100%;
    height: 15px;
    border-bottom: 1px dashed #cccfd4;
    text-align: center;
    span {
      font-size: 20px;
      background-color: #ffffff;
      color: #000000;
      padding: 4px;
    }
  }
}
.form-field-sec_criteria, .form-field-sec_type {
  width: 100%;
}
.sub-header-container {
  div {
    width: 100%;
    height: 15px;
    margin-left: -4px;
    font-size: 18px;
    color: #000000;
    padding: 4px;
    font-weight: bold;
  }
}
.form-field-hide {
  display: none !important
}

#simple-multiform-form-sec_criteria, #simple-multiform-form-sec_type, .form-field-full-width {
  width: 100%
}

#form-field-sec_text {
  margin-right: 200px;
  margin-top: 2rem;
  font-weight: bolder;
}

.search-type-err {
  font-weight: normal;
  font-size: 80%;
  color: #e74c3c;
}

 #newFB{
    padding: 1.5rem 2rem 0.75rem !important;
 }

#h1StudyFilter{
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: solid 4px $studies;
  background-color: fade-out($studies, 0.95);
}

#h1ParticipantFilter {
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: solid 4px $participants;
  background-color: fade-out($participants, 0.95)
}

.h2StudyFilterGroup {
  padding-left: 2rem;
  border-left: solid 4px $studies;
  background-color: fade-out($studies, 0.95);
}
</style>
