/* istanbul ignore file */

import set from 'lodash/set'

export function resourceFilters(filters, resource) {
	
	if (filters === undefined) {
		return []
	}

	let returnFilters = []

	filters.forEach(filter => {
		if (filter.filter_object._resource === resource) {
			returnFilters.push(filter)
		}
	});

	return returnFilters
}

export function setResourceFilter(values, resource) {
	if (values === undefined) {
		return values
	}
	set(values, '_resource', resource)
	return values
}