export default {
  studies: [
    { show: true, intro: `This is the Studies page. The default view shows the data grid.<br><a style="font-size: .9rem;" href="/documentation/docs/datagrid-studies/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on these buttons to change the view of the page and show the data in either the grid view or analytics view.' },
    { show: false, intro: ''},
    { show: true, intro: `Click on these buttons to perform grid actions. The \'Columns\' button allows selection of the data fields that are visible in the grid. Note that there are additional columns available to be added to the default view. Clicking the \'Export\' button puts the data in a file, which can be accessed via a link sent by email. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid" target="_blank">Click for details</a>` },
    { show: true, intro: `Use this widget to perform filter actions. The data grid can be filtered by entering a keyword, or an advanced filter can be configured by clicking on the \'Open Filters\' link. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid-filters" target="_blank">Click for details</a>` }
  ],
  organizations: [
    { show: true, intro: `This is the Organizations data grid.<br><a style="font-size: .9rem;" href="/documentation/docs/datagrid-org/" target="_blank">Click for details</a>` },
    { show: false, intro: ''},
    { show: false, intro: '' },
    { show: true, intro: `Click on these buttons to perform grid actions. The \'Columns\' button allows selection of the data fields that are visible in the grid. Note that there are additional columns available to be added to the default view. Clicking the \'Export\' button puts the data in a file, which can be accessed via a link sent by email. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid" target="_blank">Click for details</a>` },
    { show: true, intro: `Use this widget to perform filter actions. The data grid can be filtered by entering a keyword, or an advanced filter can be configured by clicking on the \'Open Filters\' link. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid-filters" target="_blank">Click for details</a>` }
  ],
  investigators: [
    { show: true, intro: `This is the Investigators data grid.<br><a style="font-size: .9rem;" href="/documentation/docs/datagrid-investigators/" target="_blank">Click for details</a>` },
    { show: false, intro: ''},
    { show: false, intro: '' },
    { show: true, intro: `Click on these buttons to perform grid actions. The \'Columns\' button allows selection of the data fields that are visible in the grid. Note that there are additional columns available to be added to the default view. Clicking the \'Export\' button puts the data in a file, which can be accessed via a link sent by email. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid" target="_blank">Click for details</a>` },
    { show: true, intro: `Use this widget to perform filter actions. The data grid can be filtered by entering a keyword, or an advanced filter can be configured by clicking on the \'Open Filters\' link. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid-filters" target="_blank">Click for details</a>` }
  ],
  participants: [
    { show: true, intro: `This is the Participants page. The default view shows the data grid.<br><a style="font-size: .9rem;" href="/documentation/docs/datagrid-partic/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on these buttons to change the view of the page and show the data in either the grid view or analytics view.'},
    { show: false, intro: '' },
    { show: true, intro: `Click on these buttons to perform grid actions. The \'Columns\' button allows selection of the data fields that are visible in the grid. Note that there are additional columns available to be added to the default view. Clicking the \'Export\' button puts the data in a file, which can be accessed via a link sent by email. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid" target="_blank">Click for details</a>` },
    { show: true, intro: `Use this widget to perform filter actions. The data grid can be filtered by entering a keyword, or an advanced filter can be configured by clicking on the \'Open Filters\' link. <br><a style="font-size: .9rem;" href="/documentation/docs/datagrid/#data-grid-filters" target="_blank">Click for details</a>` }
  ]
};