<template>
  <ul class="picture-select">
    <li 
      v-for="(option, index) in options" 
      :key="index"
      class="picture-select-item"
      :class="{'active': value === index}"
      @click="$emit('change', index)"
    >
      <img class="img" :src="`${option.img}.png`" :alt="option.img">
      <!-- <div class="label" v-html="option.label"></div>
      <div class="check">
        <icon icon="check" v-if="value === index"></icon>
      </div> -->
    </li>
  </ul>
</template>

<script>
// import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'picture-select',
  props: {
    value: {},
    state: {},
    id: {},
    options: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    event: 'change',
  },
}
</script>

<style lang="scss">
@import 'src/styles/component';

.picture-select {
  margin: 0; padding: 0;
  display: flex;
}
.picture-select-item {
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
  
  &:not(:last-child) {
    margin-right: .75rem;
  }
  
  cursor: pointer;
  position: relative;

  .img {
    width: 90px; height: 90px;
    border-radius: $border-radius-sm;
    border: solid thin $n3;
    
    flex: 0 0 auto;
  }
  .label {
    font-weight: $bold;
    flex: 0 1 100%;
  }
  .check {
    flex: 0 0 auto;
    width: 27px; height: 27px;
    border-radius: 50%;
    border: solid 2px $n3;
    margin-right: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: .66rem;
      color: white;
    }
  }
  &.active {
    .img {
      box-shadow: $btn-shadow;
      border: solid 2px fade-out($link, 0);
    }
    .check {
      background-color: $link;
      border: solid 2px ligthen($link, 20);
      box-shadow: 0 0 0 .2rem fade-out($link, .8)
    }
    .label {
      color: $link;
    }
  }
}

</style>

