import Steps from '@/steps';
import introJs from 'intro.js';
// import debounce from 'lodash/debounce';
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

let intro = null
const defaultOptions = {
  doneLabel: 'Got it!',
  // showProgress: true,
  skipLabel: 'Exit',
  disableInteraction: true,
  scrollToElement: false,
  exitOnOverlayClick: false
};

// main
// export function startSteps(stepArray, isContainerActive, customOptions = {}) {
//   if (!isIntroActive()) {
//     let intro = getIntro();
//     let steps = [];
//     stepArray.forEach(step => {
//       steps = steps.concat(Steps[step](isContainerActive));
//     });
//     intro.setOptions(generateIntroConfig(steps, customOptions));
//     intro.start();
//   } else {
//     exitIntro();
//   }
// }

// export function startStep(stepName, id) {
//   if (!isIntroActive()) {
//     let intro = getIntro();
//     const options = Steps[stepName](defaultOptions);
//     intro.addStep(getStep(options.steps, id)).start();
//   } else {
//     exitIntro(id);
//   }
// }

/* ******* Main ******* */
export function startIntro(stepsArray, isContainerActive, customOptions) {
  if (!isIntroActive()) {
    let steps = [];
    let intro = getIntro();
    if (typeof stepsArray[0] === 'string') {
      // let steps = [];
      stepsArray.forEach(step => {
        steps = steps.concat(Steps[step](isContainerActive));
      });
    } else {
      steps = stepsArray;
    }
    intro.setOptions(generateIntroConfig(steps, customOptions));
    intro.start();
    document.onkeydown = () => false
  } else {
    exitIntro();
  }
}

// Transfer configs to steps
export function handleIntroConfigs(customIntroConfigs, defaultIntroSteps) {
  let introSteps = merge(cloneDeep(customIntroConfigs), defaultIntroSteps)
  introSteps = introSteps.filter(config => config.show)
  introSteps.forEach(config => delete config.show)
  return introSteps
}

export function exitIntro() {
  intro = null;
  intro = introJs();
  intro.exit();
}

/* ******* Helper Functions ******* */
// function getStep(steps, id) {
//   return steps.find(step => step.id === id)
// }
// function changeCallback() {
//   let currentStep = this._currentStep
//   let steps = this._introItems
//
//   if (steps[currentStep].hasOwnProperty('open')) {
//     steps[currentStep].open()
//   }
//
//   // wait for animations to complete before updating intro's position
//   setTimeout(() => { intro.refresh() }, 400);
// }

function isIntroActive() {
  return document.getElementsByClassName('introjs-overlay').length !== 0
}

function getIntro() {
  intro = null
  intro = introJs()
  // intro.onchange(debounce(changeCallback, 30))
  intro.onexit(() => {
    document.onkeydown = () => true
  })
  return intro
}







// This is for DataView.
function generateIntroConfig(steps, customOptions = {}) {
  return { ...defaultOptions, ...customOptions, steps }
}