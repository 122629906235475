export default function getSteps(isContainerActive) {
  let steps = [];

  if (isContainerActive) {
    steps = [
      {
        element: 'div.page.page-home > div.page-header > div.header-logo-name',
        intro: `This is the dashboard page.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: 'div.page.page-home > div.page-header > div.header-content > button',
        intro: 'This button opens the data settings window.',
        tooltipClass: 'regular-tooltip-bg',
        highlightClass: 'special-highlight-bg'
      },
      {
        element: 'div.page.page-home > div.page-header > div.header-content > button',
        intro: `This is a shortcut to the global data settings that are accessible from the settings button in the navbar on every page within STRAP.<br><a style="font-size: .9rem;" href="/documentation/docs/othercomponents/#dashboard-settings" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg',
        highlightClass: 'special-highlight-bg'
      },
      {
        element: '#intro-studies',
        intro: `These statistics boxes show the count of the specified entity (i.e. Studies) that are showing in the available context. Clicking on them will direct you to the entity search page.<br><a style="font-size: .9rem;" href="/documentation/docs/othercomponents/#statistics-box" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-intervention-type',
        intro: 'This bar chart shows the number of Studies for the selected Cancer Center, categorized by intervention type. This chart is visible when a specific Cancer Center is selected in the data settings window.',
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-intervention-type',
        intro: 'Note that for this chart, as well as the other charts on the page, clicking on the question mark icon in the chart frame will provide further details specific to that chart.',
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-participant-compare',
        intro: `This chart lists the total number of Participants grouped by sex and includes a horizontal stacked bar chart at the bottom of the window.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#participants-by-race" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-study-compare',
        intro: `This chart lists the totals of each type of study source and includes a horizontal stacked bar chart at the bottom of the window.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#studies-by-current-study-status" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-diseases',
        intro: 'This chart lists the total number of diseases and includes a count of the number of Participants with each disease.',
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-participant-chart',
        intro: `This chart shows statistics breakdowns for Participants. It can be toggled between two different views to show category breakout totals for race or ethnicity by clicking on the down-arrow next to the chart title.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#participants-by-race" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-interventions',
        intro: 'This chart lists all of the different intervention types along with the total number of Studies for each intervention. This chart is visible when a specific Cancer Center is selected in the data settings window.',
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-study-chart',
        intro: `This chart shows statistics breakdowns for Studies. It can be toggled between three different views to show category breakout totals for phase, primary purpose, or current study status by clicking on the down-arrow next to the chart title.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#studies-by-current-study-status" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      }
    ];
  } else {
    steps = [
      {
        element: 'div.page.page-home > div.page-header > div.header-logo-name',
        intro: `This is the dashboard page.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: 'div.page.page-home > div.page-header > div.header-content > button',
        intro: 'This button opens the data settings window.',
        tooltipClass: 'regular-tooltip-bg',
        highlightClass: 'special-highlight-bg'
      },
      {
        element: 'div.page.page-home > div.page-header > div.header-content > button',
        intro: `This is a shortcut to the global data settings that are accessible from the settings button in the navbar on every page within STRAP.<br><a style="font-size: .9rem;" href="/documentation/docs/othercomponents/#dashboard-settings" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg',
        highlightClass: 'special-highlight-bg'
      },
      {
        element: '#intro-studies',
        intro: `These statistics boxes show the count of the specified entity (i.e. Studies) that are showing in the available context. Clicking on them will direct you to the entity search page.<br><a style="font-size: .9rem;" href="/documentation/docs/othercomponents/#statistics-box" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-cancer-center',
        intro: `This bar chart displays the total count of Studies for each Cancer Center. This chart can be toggled to show the total count of Participants for each Cancer Center.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#studies-by-cancer-centers" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-cancer-center',
        intro: 'Note that for this chart, as well as the other charts on the page, clicking on the question mark icon in the chart frame will provide further details specific to that chart.',
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-study-compare',
        intro: `This chart lists the totals of each type of study source and includes a horizontal stacked bar chart at the bottom of the window. <br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#studies-by-current-study-status" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-diseases',
        intro: 'This chart lists the total number of diseases and includes a count of the number of Paricipants with each disease.',
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-participant-chart',
        intro: `This chart shows statistics breakdowns for Participants. It can be toggled between three different views to show category breakout totals for race, ethnicity, or sex by clicking on the down-arrow next to the chart title.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#participants-by-race" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      },
      {
        element: '#intro-study-chart',
        intro: `This chart shows statistics breakdowns for Studies. It can be toggled between three different views to show category breakout totals for phase, primary purpose, or current study status by clicking on the down-arrow next to the chart title.<br><a style="font-size: .9rem;" href="/documentation/docs/homedashboard/#studies-by-current-study-status" target="_blank">Click for details</a>`,
        tooltipClass: 'regular-tooltip-bg'
      }
    ];
  }

  return steps;
}