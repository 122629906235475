/* istanbul ignore file */
import get from 'lodash/get'
import { truthy } from '@/services/CommonsService'

const nameArrayFields = [
  'agent-intervention',
  'other-intervention',
  'center-org',
  'principal_investigators.name._raw',
  'site_principal_investigators.name',
  'lead_organizations.id',
  'participating_sites'
]

export default function (fields, values) {
  let summary = []

  fields
    .filter((field) => isFieldActive(field, values))
    .map((field) => {
      let value = get(values, field.id)
      let options = get(field, 'input.options', [])

      // disease
      if (['disease_evs_id', 'disease', 'lead-disease'].includes(field.id)) {
        value = value.map((disease) => {
          return {
            maintype: get(disease, 'maintype.name'),
            subtype: get(disease, 'subtype.name'),
            stage: get(disease, 'stage.name')
          }
        })
      } else if (
        ['disease_evs_id', 'disease', 'lead-disease2'].includes(field.id)
      ) {
        value = value.map((disease) => {
          return {
            maintype: get(disease, 'maintype.name'),
            subtype: get(disease, 'subtype.name'),
            stage: get(disease, 'stage.name')
          }
        })
      }
      // date range
      else if (field.id === 'date-range') {
        value = {
          key: get(value, 'date-range-key.label'),
          from: get(value, 'date-range-from'),
          to: get(value, 'date-range-to')
        }
      }
      // id
      else if (
        [
          'ids-search-trial',
          'ids-search-sec',
          'ids-search-org',
          'id-search'
        ].includes(field.id)
      ) {
        if (Array.isArray(value)) {
          value = value.map((v) => ({
            label: get(v, 'id-selector.label'),
            value: get(v, 'id-input')
          }))
        } else {
          value = {
            label: get(value, 'id-selector.label'),
            value: get(value, 'id-input')
          }
        }
      }
      // cancer center
      else if (nameArrayFields.includes(field.id)) {
        value = value.map((v) => v.name)
      }
      else if (field.type === 'radio-field') {
        value = findLabel(value, options)
      }
      // format options if available
      else if (field.id === 'current_trial_status') {
        if (Array.isArray(value.current_trial_status)) {
          value = value.current_trial_status.map((v) => findLabel(v, options))
        }
      }
      // format options if available
      else if (field.id === 'interventions') {
        if (truthy(value['agent-intervention'])) {
          summary.push({
            label: 'Drug/Drug Family',
            value: value['agent-intervention'].map((v) => v.name)
          })
        }
        if (truthy(value['groups.interventions.type'])) {
          summary.push({
            label: 'Intervention Type',
            value: value['groups.interventions.type']
          })
        }
        if (truthy(value['other-intervention'])) {
          summary.push({
            label: 'Other Treatments',
            value: value['other-intervention'].map((v) => v.name)
          })
        }
      }
      else if (field.id === 'current_trial_status_grouping') {
        if (value.current_trial_status) {
          value = value.current_trial_status.map((v) => findLabel(v, options))
        }
      }
      // biomarker
      else if (field.id === 'biomarker') {
        if (truthy(value['biomarkers-ctrp-names'])) {
          summary.push({
            label: 'Biomarker name',
            value: value['biomarkers-ctrp-names'].key
          })
        }
        if (truthy(value['biomarkers-branch'])) {
          summary.push({
            label: 'Biomarker branch',
            value: value['biomarkers-branch'].key
          })
        }
        if (truthy(value['biomarkers-semantic-types'])) {
          summary.push({
            label: 'Biomarker semantic type',
            value: value['biomarkers-semantic-types'].key
          })
        }
        if (truthy(value['biomarkers-use'])) {
          summary.push({
            label: 'Biomarker use',
            value: value['biomarkers-use'].key
          })
        }
        if (truthy(value['biomarkers-purpose'])) {
          summary.push({
            label: 'Biomarker purpose',
            value: value['biomarkers-purpose'].key
          })
        }
        if (truthy(value['biomarkers-assay-type'])) {
          summary.push({
            label: 'Biomarker assay type',
            value: value['biomarkers-assay-type'].key
          })
        }
        if (truthy(value['biomarkers-evaluation-type'])) {
          summary.push({
            label: 'Biomarker evaluation type',
            value: value['biomarkers-evaluation-type'].key
          })
        }
        if (truthy(value['biomarkers-tissue-specimen-type'])) {
          summary.push({
            label: ' Biomarker tissue specimen type',
            value: value['biomarkers-tissue-specimen-type'].key
          })
        }
      }
      else if (field.id === 'study-participant-char-sex') {
        if (truthy(value["sex_criteria"])) {
          summary.push({
            label: "Sex",
            value: value["sex_criteria"][0].value,
          });
        }
      }
      else if (field.id === 'study-participant-char-age') {
        if (
          truthy(value["min-age-input"]) &&
          value["min-age-input"].toString() !== "0"
        ) {
          summary.push({
            label: "Min age",
            value: value["min-age-input"].toString(),
          });
        }
        if (
          truthy(value["max-age-input"]) &&
          value["max-age-input"].toString() !== "999"
        ) {
          summary.push({
            label: "Max age",
            value: value["max-age-input"].toString(),
          });
        }
      }
      else if (field.id === "study-participant-char-priorTherapy") {
         if (
           truthy(value["searchType"]) &&
           truthy(value["ids-search-sec"]) &&
           truthy(value["ids-search-sec"][0])
         ) {
           const secSearchType =
             value["searchType"] === "portfolio"
               ? "Portfolio Trial Search"
               : "Eligibility Trial Search";
           summary.push({
             label: "Trial Search Type",
             value: secSearchType,
           });
           const sections = value["ids-search-sec"];

           sections.forEach((section) => {
             const idSelector = get(section, "id-selector").label.toString();
             const secCriteria = get(section, "sec_criteria")
               .value.toString()
               .replace(/PRIOR_THERAPY/g, "Prior Therapy");
             const secType =
               get(section, "sec_type").charAt(0).toUpperCase() +
               get(section, "sec_type").slice(1);
             const idInput = get(section, "id-input");
             const secInputs = Array.isArray(idInput)
               ? idInput
               : get(section, "sec_name");
             const secValues = secInputs.map((input) => {
               const name = Array.isArray(input) ? input[0].name : input.name;
               const code = Array.isArray(input) ? input[0].code : input.code;
               return `${name} (${code})`;
             });
             const secOutput = secValues.join(", ");
             const secEligOut = secType
               ? ` Criteria = ${secCriteria}; ${idSelector}(s) = ${secOutput}; Type = ${secType}`
               : ` Criteria = ${secCriteria}; ${idSelector}(s) = ${secOutput}`;
             summary.push({
               label: "SEC",
               value: secEligOut,
             });
           });
         }
      }
      else if (field.id === "funding") {
           if (truthy(value["funding-mechanism"])) {
             summary.push({
               label: "Funding Mechanism",
               value: value["funding-mechanism"].code,
             });
           }
           if (truthy(value["funding-institute"])) {
             summary.push({
               label: "Institute Code",
               value: value["funding-institute"].code,
             });
           }
           if (truthy(value["funding-serial"])) {
             summary.push({
               label: "Serial Number",
               value: value["funding-serial"],
             });
           }
           if (truthy(value["funding-program-id"])) {
             summary.push({
               label: "NCI Division/Program",
               value: value["funding-program-id"].value,
             });
           }
           if (truthy(value["funding-nci-fundType"])) {
             summary.push({
               label: "Is this Study NCI funded?",
               value: value["funding-nci-fundType"],
             });
           }
      }
      else if (field.id === "site-location") {
           if (value.c_country.code === "USA") {
             if (truthy(value["c_country"])) {
               summary.push({
                 label: "Country",
                 value: value.c_country.label,
               });
             }
             if (truthy(value["c_state_select"])) {
               summary.push({
                 label: "State",
                 value: truthy(value.c_state_select)
                   ? value.c_state_select.code
                   : "",
               });
             }
             if (truthy(value["c_city"])) {
               summary.push({
                 label: "City",
                 value: truthy(value.c_city) ? value.c_city : "",
               });
             }
             if (truthy(value["c_zipcode"])) {
               summary.push({
                 label: "Zipcode",
                 value: truthy(value.c_zipcode) ? value.c_zipcode : "",
               });
             }
           } else {
             if (truthy(value["c_country"])) {
               summary.push({
                 label: "Country",
                 value: value.c_country.label,
               });
             }
             if (truthy(value["c_state_input"])) {
               summary.push({
                 label: "State/Province",
                 value: truthy(value.c_state_input) ? value.c_state_input : "",
               });
             }
             if (truthy(value["c_city"])) {
               summary.push({
                 label: "City",
                 value: truthy(value.c_city) ? value.c_city : "",
               });
             }
             if (truthy(value["c_zipcode"])) {
               summary.push({
                 label: "Zipcode",
                 value: truthy(value.c_zipcode) ? value.c_zipcode : "",
               });
             }
           }
      }
      else if (options.length !== 0) {
           if (Array.isArray(value)) {
             value = value.map((v) => findLabel(v, options));
           } else {
             value = findLabel(value, options);
           }
         }

      if (
        field.id !== 'interventions' &&
        field.id !== 'funding' &&
        field.id !== 'site-location' &&
        field.id !== 'biomarker' &&
        field.id !== 'study-participant-char-age'&&
         field.id !== 'study-participant-char-sex'&&
         field.id !== 'study-participant-char-priorTherapy'
      ) {
        if (field.id === 'id-search') {
          summary.push(value)
        }
        //keyword
        else if (field.id === '_keyword_form'){
          summary.push({
            label: 'Study Keyword',
            value: value._keyword
          });
          if (truthy(value.keyword_search_type)) {
            //Map for display
            const valueMap = {
              'only_in_eligibility_criteria': 'In Eligibility Criteria',
              'only_in_outcome_measure':'In Outcome Measure'
            };
            // map and format values
            const keywordSeachType = value.keyword_search_type
            .map((val) => valueMap[`${val}`] || val)
            .join(', ');
            summary.push({
              label: 'Target Keyword Search',
              value: keywordSeachType
            });
          }
        } else {
          summary.push({
            label: get(field, 'group.label'),
            value
          })
        }
      }
    })

  return summary
}

function findLabel(value, options) {
  let option = options.find((v) => v.value === get(value, 'value', value))
  return get(option, 'label')
}

function isFieldActive(field, values) {
  // trial type set to all
  if (field.id === 'trial-type' && get(values, field.id) === -2) {
    return false
  }
  // id-selector
  if (
    field.id === 'ids-search-trial' ||
    field.id === 'ids-search-sec' ||
    field.id === 'ids-search-org'
  ) {
    return truthy(get(values, `${field.id}`))
  }
  if (field.id === 'id-search') {
    return truthy(get(values, `${field.id}.id-input`))
  }

  // date-range
  if (field.id === 'date-range') {
    return (
      truthy(get(values, `${field.id}.date-range-key`)) &&
      truthy(get(values, `${field.id}.date-range-from`)) &&
      truthy(get(values, `${field.id}.date-range-to`))
    )
  }
  if (field.id === 'p-date-range') {
    return (
      truthy(get(values, `${field.id}.p-date-range-key`)) &&
      truthy(get(values, `${field.id}.p-date-range-from`)) &&
      truthy(get(values, `${field.id}.p-date-range-to`))
    )
  }
  if (field.id === 'registration-date-range') {
    return (
      truthy(get(values, `${field.id}.registration-date-range-from`)) &&
      truthy(get(values, `${field.id}.registration-date-range-to`))
    )
  }
  // has value
  else if (truthy(get(values, field.id))) {
    return true
  }
  // default
  else {
    return false
  }
}
