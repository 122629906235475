<template>
  <div
    class="multi-form"
    :class="{ edit: editIndex !== -1, empty, open: showForm }"
  >
    <div class="multiform-form-message mb-2" v-if="editIndex !== -1">
      <span class="thin">
        <icon class="mr-2 blue" icon="info-circle"></icon>You are editing a
        selected disease
      </span>
    </div>
    <div
      class="multiform-form-message mb-2"
      v-if="duplicate && editIndex === -1"
    >
      <span class="thin">
        <icon class="mr-2 blue" icon="info-circle"></icon>This disease filter
        has already been selected
      </span>
    </div>

    <!-- <div class="multiform-form-message">
      <span>
        <icon class="mr-2" icon="info-circle"></icon>Editing Selected Disease
      </span>
    </div> -->

    <!-- <transition name="slide-fade-appear" mode="out-in"> -->

    <form-builder
      v-if="showForm && open"
      id="multiform-form"
      class="multiform-form no-highlight"
      ref="form"
      no-persist
      :submit-variant="variant"
      :submit-text="submitText"
      :reset-text="resetText"
      :schema="schema"
      :values="values"
      :open="open"
      @submit="formSubmit"
      @change="onChange"
      @cancel="formCancel"
    ></form-builder>

    <!-- value list -->
    <ul class="multi-form__list" v-else>
      <li
        class="multi-form__list-item"
        v-for="(item, index) in value"
        :key="index"
        :class="{ edit: editIndex === index }"
      >
        <div
          class="multi-form__list-item-label thin"
          v-html="labelFormatter(item)"
        ></div>
        <btn
          class="multi-form__list-item-btn"
          v-tooltip="'Edit'"
          variant="outline-link"
          @click="editValue(index)"
          :disabled="disabled"
        >
          <icon icon="pen"></icon>
        </btn>
        <btn
          class="multi-form__list-item-btn"
          v-tooltip="'Remove'"
          variant="outline-danger"
          @click="removeValue(index)"
          :disabled="disabled"
        >
          <icon icon="trash"></icon>
        </btn>
      </li>
      <li class="multi-form__list-item empty" v-if="value.length === 0">
        <div class="label">No {{ name }} selected</div>
      </li>
    </ul>

    <btn class="multi-form__btn" @click="showForm = true" v-if="!showForm">
      <icon icon="plus"></icon>
      Add {{ value.length === 0 ? "a" : "another" }} {{ name }}
    </btn>
  </div>
</template>

<script>
export default {
  name: "multi-form-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: {
    event: "change",
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
    schema: {
      type: Array,
      required: true,
    },
    labelFormatter: {
      type: Function,
      default: (value) => value,
    },
    name: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "studies",
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: {},
      editIndex: -1,
      showForm: false,
      duplicate: false,
    };
  },
  computed: {
    submitText() {
      return `${this.editIndex === -1 ? "Add" : "Update"} ${this.name}`;
    },
    resetText() {
      return !this.value.length && this.editIndex !== -1 ? "Reset" : "Cancel";
    },
    empty() {
      return !this.$h.truthy(this.value);
    },
  },
  mounted() {
    const isMLDorNoneLeadDisease = this.value && Array.isArray(this.value)? 
    this.value.filter((item) => item.maintype === "Multiple Lead Diseases" || item.maintype === "None").length > 0 
    : false;

    this.showForm = this.value.length === 0 || isMLDorNoneLeadDisease;
  },
  methods: {
    onChange(value) {
      if (this.value.length === 0) {
        this.duplicate = false;
        return;
      }
      let mainTypeMatches = false;
      let subTypeMatches = false;
      let stageMatches = false;
      this.value.forEach((disease) => {

        if (!mainTypeMatches) {
          mainTypeMatches =
            this.$h.get(disease, "maintype.name") ===
            this.$h.get(value, "maintype.name");
        }
        if (!subTypeMatches) {
          subTypeMatches =
            this.$h.get(disease, "subtype.name") ===
            this.$h.get(value, "subtype.name");
        }
        if (!stageMatches) {
          stageMatches =
            this.$h.get(disease, "stage.name") ===
            this.$h.get(value, "stage.name");
        }
      });

      this.duplicate = mainTypeMatches && subTypeMatches && stageMatches;
    },
    editValue(index) {
      if (index !== this.editIndex) {
        const model = this.$h.cloneDeep(this.value[index]);
        this.showForm = true;
        this.$nextTick(() => {
          this.$refs.form.updateValue(
            "maintype",
            this.$h.get(model, "maintype")
          );
          this.$refs.form.updateValue("subtype", this.$h.get(model, "subtype"));
          this.$refs.form.updateValue("stage", this.$h.get(model, "stage"));
          this.editIndex = index;
        });
      } else {
        this.resetForm();
      }
    },
    addValue(newValue) {
      if (this._props['name'] === 'Lead Disease'){
        this.$store.commit('search/setLeadDiseaseSearch', true)
        } else if (this._props['name'] === 'Disease/Condition'){
          this.$store.commit('search/setDiseaseConditionSearch', true)
        } else if (this._props['name'] === 'Participant Disease/Condition') {
          this.$store.commit('search/setParticipantDiseaseConditionSearch', true)
        }
      if (this._props['name'] === 'Lead Disease'){
        this.$store.commit('search/setLeadDiseaseSearch', true)
        } else if (this._props['name'] === 'Disease/Condition'){
          this.$store.commit('search/setDiseaseConditionSearch', true)
        }
      let values = this.$h.cloneDeep(this.value);
      values.push(newValue);
      this.$emit("change", values);
    },
    updateValue(newValue, index) {
      this.resetForm();
      let values = this.$h.cloneDeep(this.value);
      values[index] = newValue;
      this.$emit("change", values);
    },
    removeValue(index) {
      const hasMaintype = this.schema.some(s => s.id === 'maintype');
      const name = this._props['name'];
      
      if (hasMaintype && this.value.filter(nt => nt['maintype']).length === 1) {
        if (name === 'Disease/Condition') {
          this.$store.commit('search/setDiseaseConditionSearch', false);
        } else if (name === 'Lead Disease') {
          this.$store.commit('search/setLeadDiseaseSearch', false);
        } else if (name === 'Participant Disease/Condition' ){
          this.$store.commit('search/setParticipantDiseaseConditionSearch', false);
        }
      }
      let values = this.$h.cloneDeep(this.value);
      values.splice(index, 1);
      this.$emit("change", values);
    },
    formSubmit(value) {
      if (this.editIndex === -1) {
        this.addValue(value);
      } else {
        this.updateValue(value, this.editIndex);
      }
      this.resetForm();
    },
    formCancel() {
      this.resetForm();
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.$refs.form.updateFieldState("maintype", "validation", "");
      this.editIndex = -1;
      this.showForm = false;
    },
  },
  watch: {
    open(flag) {
      if (!flag) {
        this.showForm = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blue {
  color: cornflowerblue;
}
.thin {
  font-weight: 100;
}
</style>