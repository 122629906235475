<template>
  <div class="default-loading">
    <transition name="pop-in-appear" appear>
      <span class="loading-icon">
        <spinner class="icon" :variant="spinnerVariant" />
        <span class="loading-content">Loading {{loadingTitle || 'Data' | titleCase}}...</span>
      </span>
    </transition>
  </div>
</template>

<script>
import Spinner from '@/components/utils/Spinner'
export default {
  name: 'default-loading',
  props: ['error', 'reload', 'loadingTitle', 'spinnerVariant'],
  components: { Spinner },
}
</script>

<style lang="scss" scoped>

@import "src/styles/component";

.default-loading {
  width: 100%; height: 100%;
  font-size: 1.5rem;
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  .loading-icon {
    display: flex;
    align-items: center;
    flex-direction: column;

    .loading-content {
      flex: 0 1 auto;
      margin-left: .75rem;
      font-size: 1.1rem;
      font-weight: $thin;
      color: $text;
      margin-top: 1.25rem;
    }
  }
}

</style>
