<script>
/* istanbul ignore file */
export default {
  name: "id-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: { event: "change" },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
    schema: {
      type: Array,
      required: true,
    },
    labelFormatter: {
      type: Function,
      default: (value) => value,
    },
    name: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: {},
      editIndex: -1,
      showForm: false,
      single: false,
    };
  },
  computed: {
    activeSchema() {
      let singleSchema = this.$h.cloneDeep(this.schema);
      let multiSchema = this.$h.cloneDeep(this.schema);
      multiSchema[1].type = "textarea-field";
      multiSchema[1].input.rows = 3;
      multiSchema[1].input.placeholder = "Enter one or more IDs";
      return this.single ? singleSchema : multiSchema;
    },
    submitText() {
      return `${this.editIndex === -1 ? "Add" : "Update"} ID`;
    },
    edit() {
      return this.editIndex === -1;
    },
    empty() {
      return !this.$h.truthy(this.value);
    },
  },
  methods: {
    editValue(index) {
      if (index !== this.editIndex) {
        const model = this.$h.cloneDeep(this.value[index]);
        this.showForm = true;
        this.$nextTick(() => {
          this.$refs.form.updateValue(
            this.$h.get(model["id-selector"], "label"),
            this.$h.get(model["id-selector"], "value")
          );
          this.$refs.form.updateValue(
            "id-input",
            this.$h.get(model, "id-input")
          );
          this.editIndex = index;
        });
      } else {
        this.resetForm();
      }
    },
    addValue(newValue) {
      let values = this.$h.cloneDeep(this.value);
      values.push(newValue);
      this.$emit("change", values);
    },
    updateValue(newValue, index) {
      this.resetForm();
      let values = this.$h.cloneDeep(this.value);
      values[index] = newValue;
      this.$emit("change", values);
    },
    removeValue(index) {
      let values = this.$h.cloneDeep(this.value);
      values.splice(index, 1);
      this.$emit("change", values);
    },
    formSubmit(value) {
      if (this.editIndex === -1) {
        this.addValue(value);
      } else {
        this.updateValue(value, this.editIndex);
      }
      this.resetForm();
    },
    formCancel() {
      this.resetForm();
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.$refs.form.updateFieldState("ids-search-trial", "validation", "");
      this.$refs.form.updateFieldState("ids-search-org", "validation", "");
      this.$refs.form.updateFieldState("ids-search-sec", "validation", "");
      this.editIndex = -1;
      this.showForm = false;
    },
  },
};
</script>

<template>
  <div class="id-field" :class="{ edit, empty }">
    <!-- list -->
    <ul class="id-field__list" v-if="!showForm">
      <li
        class="id-field__list-item"
        v-for="(item, index) in value"
        :key="index"
        :class="{ edit: editIndex === index }"
      >
        <div
          class="id-field__list-item-label"
          v-html="labelFormatter(item)"
        ></div>
        <btn
          class="id-field__list-item-btn"
          v-tooltip="'Edit'"
          variant="outline-link"
          @click="editValue(index)"
          :disabled="disabled"
        >
          <icon icon="pen"></icon>
        </btn>
        <btn
          class="id-field__list-item-btn"
          v-tooltip="'Remove'"
          variant="outline-danger"
          @click="removeValue(index)"
          :disabled="disabled"
        >
          <icon icon="trash"></icon>
        </btn>
      </li>
      <li
        class="id-field__list-item empty"
        variant="link"
        v-if="value.length === 0"
      >
        <div class="label">No {{ name }}(s) selected</div>
      </li>
    </ul>

    <!-- list actions -->
    <btn
      v-if="!showForm"
      class="id-field__btn"
      variant="link"
      @click="showForm = true"
    >
      <icon icon="plus"></icon>
      Add multiple {{ name }}s
    </btn>

    <!-- form -->
    <form-builder
      v-if="showForm && open"
      id="id-form"
      class="id-field__form no-highlight"
      ref="form"
      no-persist
      submit-variant="studies"
      :submit-text="submitText"
      reset-text="Cancel"
      :schema="activeSchema"
      :values="values"
      @submit="formSubmit"
      @cancel="formCancel"
    />
  </div>
</template>

<style lang="scss">
@import "src/styles/component";

.id-field {
  &__list {
    margin: 0 0 0.5rem;
    padding: 0;

    &-item {
      display: flex;
      align-items: flex-start;

      padding: 0.5rem 1rem;
      margin-bottom: 0.5rem;
      background-color: $n1;
      border-radius: $border-radius;

      &-label {
        flex: 0 1 100%;

        span {
          margin-right: 0.5rem;
          font-weight: $thin;
          font-size: 0.9rem;

          &:after {
            content: ":";
          }
        }
      }
      &-btn {
        flex: 0 0 auto;
        margin-top: 0.35rem;
        border: none !important;
        color: fade-out($text-light, 0.5);
        font-size: 0.9rem;
      }

      &.empty {
        font-weight: $thin;
        font-size: 0.9rem;
        padding: 0.75rem 1rem;
        color: $n4;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;

    button {
      height: 38px;
    }
  }

  .btn-link {
    color: cornflowerblue !important;
    text-decoration: none !important;
    padding: 0 0.25rem;
    font-size: 0.95rem;

    &:active {
      color: white !important;
    }
  }

  #id-form-id-selector {
    width: 200px;
    margin-bottom: 0.5rem;
  }
  #id-form-id-input {
    margin-bottom: 1rem;
  }
}
</style>