import viewActions from "./actions";
import viewMutations from "./mutations";
import viewGetters from "./getters";

export default {
  namespaced: true,

  state: {
    props: {},
  },

  getters: viewGetters,

  mutations: viewMutations,

  actions: viewActions,
};
