<template>
  <page-header
    :header-class="`data-view-header tab-${
      activeTabs[activeTab]
    } filter-${canFilter} keyword-${!noKeyword} no-bar-${noBar}`"
    :title="title"
    :sub-title="headerSubTitle"
    :size="headerSize"
    :total="pageState.total"
    :hide-logo="noName"
    :icon="icon"
    :loading="loading"
    :variant="variant"
    :show-extra-name-options="showExtraNameOptions"
    :show-action-btns="showActionBtns"
    :intro-steps="introSteps"
    :id="id"
    local
  >
    <template v-if="nameSlot === 'summary-study-flag'" #summary-study-flag>
      <div
        id="page-header-summary-study-flag"
        class="summary-study-flag ml-3"
        v-tooltip.right="
          'Some Participant details are not available for a summary study.'
        "
      >
        <icon icon="users"></icon>
        <span>Summary Study</span>
      </div>
    </template>

    <template v-if="nameSlot === 'name-message'" #name-message>
      <div
        id="page-header-name-message"
        class="ml-2 mt-1"
        v-tooltip.right="'Charts ignore summary participants.'"
      >
        <icon icon="info-circle"></icon>
      </div>
    </template>

    <!-- todotemp -->
    <div
      class="data-view-participant-count"
      v-if="showParticiantCount && participantCountRole"
      @click="$emit('goToParticipants')"
      v-tooltip.hover="`Click to see participants`"
    >
      <div class="entity-logo participants">
        <icon icon="users"></icon>
      </div>
      <div class="value">
        <async-data-wrapper
          :query="bindParams"
          :key="participantQueryKey"
          :resource="participantsQuery"
          @start="incSlider"
          @resolve="incSlider"
          @reject="incSlider"
        >
          <template v-slot="{ _state }">
            <state-handler
              transition-name="fade"
              keep-response-alive
              transition="none"
              :loading="_state.loading"
              ignore-error
            >
              <template #loading>
                <spinner
                  class="nudge-up"
                  variant="participants"
                  :radius="27"
                  :strokeWidth="6"
                />
              </template>
              <template #response>
                <span
                  class="text"
                  key="response"
                  id="participantpage-header-total"
                >
                  {{ $h.get(_state, "response") | localeString }}
                </span>
              </template>
            </state-handler>
          </template>
        </async-data-wrapper>
      </div>
    </div>

    <tab-switcher
      :id="`${id}-tab-switcher`"
      :key="tabKey"
      class="header-tab-slider"
      v-if="activeTabs.length > 1 && !hideTabs"
      :disabled="loading"
      :value="activeTab"
      :tabs="activeTabs"
      :variant="variant"
      @change="$emit('changeTab', $event)"
    >
      <template v-for="(tab, index) in activeTabs" #[tab]>
        <div :key="tab">
          <icon class="icon" :icon="activeTabIcons[index]"></icon>
          <span v-html="tab"></span>
        </div>
      </template>
    </tab-switcher>

    <div :id="`${id}-extra-actions`" class="header-extra-actions">
      <slot></slot>
    </div>

    <transition name="slide-fade-appear" mode="in-out">
      <div
        :id="`${id}-grid-actions`"
        class="header-grid-actions"
        v-if="activeTab === 0"
      >

        <!-- Disease Maintype Indicator -->
        <disease-maintype-indicator v-if="this.id === 'studies'" :id="`data-view-header-disease-maintype-indicator`"></disease-maintype-indicator>

        <!-- column toggle -->
        <action-popover
          v-if="!noColumnToggle"
          title="Columns"
          icon="check"
          :disabled="pageState.total === 0"
          :id="`${id}-toggle`"
        >
          <div class="popover-pad no-top">
            <div
              class="popover-header"
              :class="{ wide: filteredHeaders.length > 7 }"
            >
              <h3>Show/Hide Columns</h3>
              <div class="popover-actions">
                <btn
                  aria-label="Close"
                  class="popover-action"
                  variant="outline-circle"
                  @click="closePopover(`${id}-toggle`)"
                >
                  <icon icon="times" />
                </btn>
              </div>
            </div>
            <ul class="popover-toggle-body columns" :key="useOriginalDiseases">
              <li v-for="(header, index) in headers" :key="index">
                <checkbox
                  v-if="
                    header.render !== undefined
                      ? header.render()
                      : header.group === undefined
                  "
                  :id="`header-check-${index}`"
                  :checked="!header.hide"
                  @click.native.prevent="$emit('toggleColumn', header)"
                  >{{ header.headerName }}</checkbox
                >
              </li>
              <li
                v-if="
                  $route.name === 'participants' ||
                  id === 'patients' ||
                  id === 'nested-participants'
                "
              >
                <checkbox
                  :checked="diseaseGroupCheckbox"
                  @click.native.prevent="toggleDiseaseGroup"
                  >Reported Subject Disease</checkbox
                >
              </li>
            </ul>
          </div>
        </action-popover>

        <!-- export -->
        <a
          v-if="canDownload"
          :id="`${id}-export`"
          tabindex="0"
          class="btn btn-outline header-dropdown h-button-md"
          :class="{ disabled: exporting || pageState.total === 0 }"
          @click="openExportModal"
        >
          <icon
            class="icon export"
            :icon="exporting ? 'circle-notch' : 'download'"
          ></icon>
          <span class="h-text-md">
            {{ exporting ? "Exporting..." : "Export" }}
          </span>
        </a>
      </div>
    </transition>

    <filter-action-group
      :id="`${id}-filter-actions`"
      class="header-filter-actions"
      :disabled="exporting"
      :fields="activeFields.length"
      v-bind="{
        canFilter,
        showFilter,
        showKeywordSearch,
        keyword,
        title,
      }"
      @keyword="debouncedSetFormKeyword"
      @open="$emit('update:filtersOpen', true)"
      @open-my-filters="$emit('open-my-filters')"
    ></filter-action-group>
  </page-header>
</template>

<script>
import PageHeader from "@/views/page/PageHeader";
import ActionPopover from "@/components/presentation/data-view/utils/ActionPopover";
import TabSwitcher from "@/components/presentation/data-view/utils/TabSwitcher";
import FilterActionGroup from "@/components/presentation/data-view/utils/FilterActionGroup";
import DownloadSchema from "@/components/form/schemas/DownloadSchema";
import Checkbox from "bootstrap-vue/es/components/form-checkbox/form-checkbox";
import debounce from "lodash/debounce";
import Spinner from "@/components/utils/Spinner";
import { handleIntroConfigs } from "@/services/IntroService";
import CreateSummaryString from "@/services/CreateSummaryString";
import { hasRole } from '@/services/RoleService';
import DiseaseMaintypeIndicator from "@/components/form/DiseaseMaintypeIndicator.vue";


export default {
  name: "data-view-header",
  inheritAttrs: false,
  components: {
    FilterActionGroup,
    PageHeader,
    Checkbox,
    ActionPopover,
    TabSwitcher,
    Spinner,
    DiseaseMaintypeIndicator,
  },
  props: [
    "id",
    "schema",
    "local",
    "activeParams",
    "pageState",
    "exporting",
    "activeTabIcons",
    "activeTabs",
    "activeTab",
    "variant",
    "icon",
    "noIcon",
    "title",
    "headers",
    "canDownload",
    "canFilter",
    "showFilter",
    "showKeywordSearch",
    "filters",
    "noPersist",
    "model",
    "noKeyword",
    "loading",
    "noName",
    "initialValues",
    "headerSize",
    "headerSubTitle",
    "active",
    "filtersActive",
    "noBar",
    "barMessage",
    "activeFields",
    "noColumnToggle",
    "bindParams",
    "bindValues",
    "bindFields",
    "exportTitle",
    "resource",
    "query",
    "showExtraNameOptions",
    "nameSlot",
    "showActionBtns",
    "introConfigs",
    "showParticiantCount",
    "hideTabs",
  ],
  data() {
    return {
      tabKey: 0,
      filtersOpen: false,
      myFiltersOpen: false,
      diseaseGroupCheckbox: false,
      keyword: "",
      downloadSchema: DownloadSchema,
      entityDocURL:
        process.env.VUE_APP_SERVER_URL + "documentation/docs/search/",
      dSetFormKeyword: undefined
    };
  },
  computed: {
    participantsQuery() {
      // For researcher accrual role, we need to use another request that brings only aggregated total
      // as the "participants" resource request gets participant detail data for the grid layout
      // Grid layout is not available for Researcher Accrual role
      return hasRole('RESEARCHER_ACCRUAL') ? "patientsCount" : "studyParticipantsCount"
    },
    useOriginalDiseases() {
      return this.$store.getters["settings/useOriginalDiseases"];
    },
    participantQueryKey() {
      return JSON.stringify(this.bindParams);
    },
    participantCountRole() {
      return (
        this.$h.hasAccess("participants") &&
        !this.$store.getters["settings/containerEnabled"]
      );
    },
    filteredHeaders() {
      let headers = this.$h.cloneDeep(this.headers);
      return headers.filter((header) => this.$h.get(header, "render", true));
    },
    defaultIntroConfigs() {
      return [
        {
          element: `#${this.id}-widget-actions-help`,
          tooltipClass: "regular-tooltip-bg",
          highlightClass: "special-highlight-bg",
        },
        {
          element: `#${this.id}-tab-switcher`,
          tooltipClass: "regular-tooltip-bg",
          highlightClass: "special-highlight-bg",
        },
        {
          element: `#${this.id}-extra-actions`,
          tooltipClass: "regular-tooltip-bg",
          highlightClass: "special-highlight-bg",
        },
        {
          element: `#${this.id}-grid-actions`,
          tooltipClass: "regular-tooltip-bg",
          highlightClass: "special-highlight-bg",
        },
        {
          element: `#${this.id}-filter-actions`,
          tooltipClass: "regular-tooltip-bg",
          highlightClass: "special-highlight-bg",
        },
      ];
    },
    introSteps() {
      return this.handleIntroConfigs(
        this.introConfigs,
        this.defaultIntroConfigs
      );
    },
    pageWidth() {
      return this.$store.getters["width"];
    },
  },
  mounted() {
    this.dSetFormKeyword = debounce(this.setFormKeyword, 400);
  },
  methods: {
    filtersSubmit(keyword = "") {
      this.keyword = keyword;
      this.$emit(
        "update:filtersActive",
        this.$h.cloneDeep(this.activeFields.length > 0)
      );
    },

    toggleDiseaseGroup() {
      this.diseaseGroupCheckbox = !this.diseaseGroupCheckbox;
      this.headers.forEach((header) => {
        if (header.group === "disease") {
          this.$emit("toggleColumn", header);
        }
      });
    },

    // keyword
    debouncedSetFormKeyword(word) {
      this.dSetFormKeyword(word);
    },
    setFormKeyword(word) {
      this.$emit("updateKeyword", word);
    },
    toggleColumns(flag) {
      this.filteredHeaders.forEach((header) => {
        if (flag) {
          if (header.hide) {
            this.$emit("toggleColumn", header);
          }
        } else {
          if (!header.hide) {
            this.$emit("toggleColumn", header);
          }
        }
      });
    },
    closePopover(id) {
      this.$root.$emit("bv::hide::popover", id);
    },
    openExportModal() {
      let query = this.$h.cloneDeep(this.query);
      let bindParams = this.$h.cloneDeep(this.bindParams);
      let grid = this.$h.get(this.$parent, "$parent.$refs.grid");
      let orderedHeaders = grid.getColumnOrder();
      let summaryObject = this.getSummaryObject();
      let headers = this.filteredHeaders.sort((a, b) => {
        return (
          orderedHeaders.indexOf(a.field) - orderedHeaders.indexOf(b.field)
        );
      });
      let activeQuery = {
        ...query,
        params: { ...bindParams, ...query.params },
      };

      this.$store.dispatch("modal/openExport", {
        title: this.title,
        activeQuery,
        resource: this.resource,
        headers: headers,
        summaryObject: summaryObject,
        exportTitle: this.exportTitle,
        local: this.local,
      });
    },
    getSummaryObject() {
      return CreateSummaryString(this.activeFields, this.bindValues);
    },
    incSlider() {
      setTimeout(() => {
        this.tabKey++;
      }, 100);
    },
    handleIntroConfigs,
  },
  watch: {
    filtersOpen(open) {
      this.$emit("update:filtersOpen", open);
      this.$forceUpdate();
    },
    pageWidth: "incSlider",
  },
};
</script>

<style lang="scss" scoped>
.export-disabled {
  pointer-events: none;
}
#participantpage-header-total {
  line-height: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #5e7286;
}
</style>
