import formActions from './actions';
import formMutations from './mutations';
import formGetters from './getters';

export default {
  namespaced: true,

  state: {
    forms: {},
    queries: {},
    globalKeyword: '',
    asyncOptions: {},
    selectedDiseases: {
      lead: [],
      disease: [],
    },
  },

  getters: formGetters,

  mutations: formMutations,

  actions: formActions,

};