<template>
  <div class="page-size">
    <span>Show</span>
    <select-field
      class="page-size-input"
      :value="size"
      hide-selected
      :text-field="null"
      :value-field="null"
      :searchable="false"
      :internal-search="false"
      :options="sizeList"
      :disabled="!sizeList.length"
      :allow-empty="false"
      openDirection="top"
      @change="newSize => $emit('pageSizeChange', newSize)"
    ></select-field>
  </div>
</template>

<script>
import SelectField from '@/components/form/fields/SelectField'

export default {
  name: 'page-size-controller',
  components: { SelectField },
  props: {
    page: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    sizes: {
      type: Array,
      default: () => [10, 20, 50, 100, 200],
    },
  },
  computed: {
    sizeList() {
      const total = this.total
      const sizes = this.sizes
      const activeSizes = sizes.filter(size => size <= total)

      // account for odd totals
      if (activeSizes.length !== sizes.length && !sizes.includes(total)) {
        if (total > activeSizes[activeSizes.length - 1]) {
          activeSizes.push(sizes[activeSizes.length])
        }
      }

      return activeSizes
    },
  }
}
</script>

<style lang="scss" scoped>
.page-size {
  display: flex;
  align-items: center;

  span {
    font-size: 300;
    color: #767676;
  
  }
  .page-size-input {
    margin-left: .5rem;
  }
}
</style>

