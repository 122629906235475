<template>
  <modal
    class="modal-no-padding modal-apply-width apply"
    style="z-index: 10000"
    size="sm"
    hide-header
    hide-footer
    :visible="value"
    @hidden="$emit('input', false)"
  >
    <div class="apply__header">
      <btn
        class="popover-action"
        variant="outline-circle"
        @click="$emit('input', false)"
        ><icon icon="times"></icon
      ></btn>
      <h3>Select from My Filters to Apply</h3>

      <async-data-wrapper :query="filterQuery" :key="value" v-slot="{ _state }">
        <select-field
          :disabled="_state.loading"
          class="mb-4"
          v-model="selectedFilter"
          :placeholder="
            _state.loading ? 'Loading filters...' : 'Select a filter...'
          "
          label="label"
          trackBy="label"
          :hide-selected="false"
          group-values="options"
          group-label="label"
          :selectLabel="''"
          :deselectLabel="''"
          :group-select="false"
          :searchable="false"
          :internal-search="false"
          :maxHeight="500"
          :options="getOptions(myFilterOptions, _state.response)"
          :allow-empty="false"
        >
          <template #caret v-if="_state.loading">
            <spinner class="spinner" :radius="40" :strokeWidth="6" />
          </template>
        </select-field>
      </async-data-wrapper>

      <btn
        :disabled="!$h.truthy(selectedFilter)"
        :variant="variant"
        class="button"
        @click="onFilterSelect"
        width="200"
      >
        <icon icon="check" /> Apply Filter
      </btn>
    </div>
    <div class="apply__body">
      <div class="apply__body-header">
        <span
          v-if="$h.truthy(selectedFilter)"
          v-text="selectedFilter.label"
        ></span>
      </div>
      <form-summary
        v-if="$h.truthy(selectedFilter)"
        class="form-summary"
        :variant="variant"
        :values="selectedFilter.values"
        :schema="schema"
      ></form-summary>
      <div class="apply__body-body" v-else>
        <div class="empty">
          <icon icon="ban" class="mr-2" /> No filter selected
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
/* istanbul ignore file */
import SelectField from "vue-multiselect";
import Modal from "bootstrap-vue/es/components/modal/modal";
import Defaults from "@/components/form/defaults";
import FormSummary from "@/components/form/summary/FormSummary";
import { resourceFilters } from "@/services/UserFilterService";
import Spinner from "@/components/utils/Spinner";

export default {
  name: "apply-filters-modal",
  components: { Modal, SelectField, FormSummary, Spinner },
  props: ["value", "variant", "resource", "schema", "activeFilter"],
  data() {
    return {
      selectedFilter: undefined,
      myFilterOptions: this.$h.get(Defaults, this.resource, []),
      filterQuery: {
        baseURL: "users",
        endpoint: "/user/filters",
        params: {},
      },
    };
  },
  methods: {
    onFilterSelect() {
      this.$emit("input", false);
      this.$emit("applyFilterFlag", true);
      this.$emit("submit", this.selectedFilter);
    },
    getOptions(defaults = [], myFilters = []) {
      let filters = resourceFilters(myFilters, this.resource);
      return [
        {
          label: "SYSTEM PRE-DEFINED FILTERS",
          options: defaults,
        },
        {
          label: "MY FILTERS",
          options: filters
            .map((filter) => ({
              id: filter.filter_id,
              label: filter.filter_name,
              values: filter.filter_object,
            }))
            .sort((a, b) => {
              let nameA = a.label[0].toLowerCase();
              let nameB = b.label[0].toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            }),
        },
      ];
    },
  },
  watch: {
    value(value) {
      this.selectedFilter = undefined;
      if (value && this.$h.truthy(this.activeFilter)) {
        this.selectedFilter = this.activeFilter;
      }
    },
  },
};
</script>

<style  lang="scss">
@import "src/styles/component";

.modal-apply-width .modal-body {
  overflow: visible !important;
}

.apply {
  display: flex;
  flex-direction: column;

  &__header {
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .popover-action {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    h3 {
      margin-top: 0.5rem;
      margin-bottom: 0.75rem;
      width: 100%;
      text-align: left;
    }
  }
  &__body {
    padding: 1rem 0 0;

    &-header {
      background-color: $n1;
      border-top: solid thin $n3;
      min-height: 45px;
      display: flex;
      align-items: center;
      padding-left: 2rem;
      margin-bottom: 1rem;
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;

      .empty {
        opacity: 0.25;
        font-size: 1.2rem;
      }
    }
    .form-summary {
      // margin-top: 2rem;
      padding: 0 2rem !important;
    }
  }

  .close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background-color: transparent;
    border-radius: 50%;
    border-color: $n3;
    color: $n6;
    padding-bottom: 0.25rem;
  }
  .spinner {
    position: absolute;
    top: 0.45rem;
    right: 0.65rem;
  }
}
</style>

