<template>
  <vue-slider
    class="vue-slider"
    :class="{'none': value === 0}"
    v-model="sliderValue"
    :min="min"
    :max="max"
    :marks="marks"
    :disabled="disabled"
    tooltip="none"
    :contained="true"
    lazy
  />
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'notched-slider',
    components: {
      VueSlider
    },
    props: {
    value: {},
    state: {},
    id: {},
    min: {},
    max: {},
    marks: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sliderValue: this.value
    }
  },
  methods: {
    change(value) {
      this.$emit('change', value)
    },
    update(value) {
      this.sliderValue = value
    },
  },
  watch: {
    'sliderValue': 'change',
    'value': 'update',
  }
}
</script>

<style lang="scss">
@import 'src/styles/component';

.vue-slider {
  margin-bottom: 1.5rem!important;

  &.none {
    .vue-slider-mark:first-child .vue-slider-mark-label {
      opacity: 1;
    }
  }
  
  .vue-slider-dot-handle {
    background-color: $link;
    box-shadow: none;
  }
  .vue-slider-mark:last-child .vue-slider-mark-label {
    margin-left: -.75rem;
  }
  .vue-slider-mark:first-child .vue-slider-mark-label {
    margin-left: .75rem!important;
    color: $text;
    opacity: .5;
  }
  .vue-slider-rail {
    background-color: $n3;
  }
  .vue-slider-process {
    background-color: $link;
  }
  .vue-slider-mark-label {
    font-weight: $thin;
    font-size: .7rem;
    cursor: pointer;
    color: $text;
    margin-top: 8px;

    &.vue-slider-mark-label-active {
      font-weight: $regular;
    }
  }
  .vue-slider-mark-step {
    transform: scale(2);
    background-color: $n3;

    &.vue-slider-mark-step-active:not(.vue-slider-mark-step-selected) {
      background-color: $link;
    }
  }
}

</style>

