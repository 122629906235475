<template>
  <b-form
    @submit.prevent="submit"
    @reset.prevent="reset"
    :class="{ onlyShowActive, search: fieldSearch }"
    @click="handlePageClick"
  >
    <div
      class="form-field"
      :class="[
        field.state,
        `last-${index === lastFieldIndex}`,
        `tab-${field.tab}`,
        `disabled-${field.state.disabled}`,
        (field.width && field.width === 'full') || field.id === 'ids-search-sec' ? 'form-field-full-width' : ''
      ]"
      v-for="(field, index) in displayCorrectLabels(fields)"
      :key="field.id"
      :id="`${id}-${field.id}`"
    >
    <!-- Horizontal Divider -->
    <div v-if="field.id == 'funding-nci-fundType'" class="divider-container">
      <div>
        <span aria-label="OR">
            OR
        </span>
      </div>
      <br>
    </div>

      <!-- wrapper -->
      <template>
        <label v-if="field.id === 'study-participant-char-age'" id="studies-filters-eligibility-criteria">Eligibility Criteria</label>
        <component
          :is="`${$h.get(field, 'wrapper.name', 'default')}-wrapper`"
          v-bind="field.wrapper"
          :model="field.value"
          :key="updateKey"
        >
          <!-- group -->
          <b-form-group
            v-bind="field.group"
            :state="field.state.validation === null"  
            :class="[
              field.hideField ? 'form-field-hide' : 'form-field-show'
            ]"
            :label-for="`${id}-${field.id}-input`"
            :invalid-feedback="field.state.validation"
            :dkey="JSON.stringify(field.state)"
          >
            <!-- field -->
            <component
              class="form-generator-field"
              :id="`${id}-${field.id}-input`"
              :is="`${field.type}`"
              :ref="field.id"
              v-model="field.value"
              v-bind="field.input"
              :state="!!field.state.validation ? false : null"
              :disabled="field.state.disabled"
              :alwaysCallMounted="field.state.active"
              :form-values="model"
              :open="open"
              @change="(value) => $nextTick(() => updateValue(field.id, value))"
              @update="(context) => onUpdate(field, context)"
            >
            <div v-if="searchTypeWarning" class="search-type-err">
              <p>Warning: The selected SEC filters(s) need to be deleted and reapplied when you switch between the Eligibility and Portfolio Trial Searches.</p>
            </div>
          </component>

            <div
              class="clear-field-btn"
              v-if="field.hasClear && field.state.active"
              @click="resetField(field)"
              v-tooltip.hover="`Reset ${field.group.label}`"
            >
              <icon icon="ban"></icon>
            </div>
          </b-form-group>
        </component>
      </template>
    </div>

    <div class="form-actions" v-if="!noActions">
      <btn
        type="reset"
        variant="header"
        v-if="!noReset"
        v-html="resetText"
        @click="$emit('cancel')"
      ></btn>
      <btn
        type="submit"
        :variant="submitVariant"
        class="submit"
        :block="blockSubmit"
        :disabled="disabled"
      >
        <span v-if="submitText === 'camera'">
          <icon icon="download"></icon>Download
        </span>
        <span v-else v-html="submitText"></span>
      </btn>
    </div>
  </b-form>
</template>

<script>
import Fields from "./fields";
import Wrappers from "./wrappers";
import bForm from "bootstrap-vue/es/components/form/form";
import bFormGroup from "bootstrap-vue/es/components/form-group/form-group";
import merge from "lodash/merge";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { EventBus } from "@/event-bus.js";
import { truthy } from "../../services/CommonsService";

export default {
  name: "form-builder",
  components: { ...Fields, ...Wrappers, bForm, bFormGroup },
  beforeCreate() {
    this.$options.components.FormBuilder = require("./FormBuilder.vue");
  },
  props: {
    searchedFields:{
      type: Array,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
    values: {
      type: Object,
      default: () => ({}),
    },
    bindFields: {
      type: Array,
      default: () => [],
    },
    lastFieldIndex: {
      type: Number,
      default: -1,
    },
    submitOnChange: {
      type: Boolean,
      default: false,
    },
    submitOnMount: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    persist: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyShowActive: {
      type: Boolean,
      default: false,
    },
    blockSubmit: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    submitVariant: {
      type: String,
      default: "success",
    },
    resetText: {
      type: String,
      default: "Cancel",
    },
    fieldSearch: {
      type: String,
      default: "",
    },
    noReset: {
      type: Boolean,
      default: false,
    },
    showActiveOnMount: {
      type: Boolean,
      default: false,
    },
    isFormActive: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    alwaysCallMounted: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      default: "global",
    },
  },
  data() {
    return {
      model: {},
      states: {},
      fields: [],
      dSubmit: null,
      dActive: null,
      dCallMounted: null,
      isFieldsActive: false,
      searchTypeWarning: false,
      updateKey: 0,
    };
  },
  created() {
    this.dCallMounted = debounce(this.callMounted, 150);
    this.dSubmit = debounce(this.submit, 10);
    this.dActive = debounce(() => {
      this.$emit(
        "active",
        this.fields.map((field) => ({
          field: field,
          tab: field.tab,
          active: field.state.active,
        }))
      );
    }, 10);
    this.setUpFields();
  },
  mounted() {
    // Call the 'searchType-field-event' for 'seachType' when the page is mounted, inorder to display the 
     // correct associated labels 
     if (truthy(this.fields)) {
       this.fields.forEach((field) => {
         if (field.id === 'searchType') {
           EventBus.$emit("searchType-field-event", field.value);
         }
       })
     }
     
    // Event subscribed that is thrown from SimpleMultiFormField
    // Update the "fields" object for for the SEC name select field or SEC code text field where the hideElement attribute 
    // is updated to hide/show those elements respectively upon change event of the sec name/code select field
    EventBus.$on("sec-criteria-change", (data, Changedvalue) => {
      for (var i in this.schema) {

        if (this.schema[`${i}`]['id'] === 'sec_type') {
          this.handleSecCriteriaChange(data, Changedvalue);
        }
      }
    });
    EventBus.$on("sec-field-change", (data) => {
      if (this.isEligibilitySECFields(data)) {
        data.filter((updatedData) => updatedData['field-type'] === 'sec' && !updatedData.dynamicFieldMapping).forEach((secCodeNameField) => {
          this.fields.forEach((field) => {
            if (field['field-type'] === 'sec' && !field.dynamicFieldMapping) {
              if (field['type'] === secCodeNameField['type']) {
                field['id'] = secCodeNameField['id']
                if (secCodeNameField['hideField']) {
                  field['hideField'] = true
                } else {
                  delete field['hideField']
                }
              }
            }
          })
        });
      }
    });

    if (this.submitOnMount) {
      this.dSubmit();
    }
    this.dCallMounted();
  },
  methods: {
    handlePageClick(){
      this.searchTypeWarning = false;
    },
    handleSecCriteriaChange(data, Changedvalue) {
      const field = this.fields.find((f) => f.id === 'sec_type');

      if (!field) {
        return;
      }

      const label0 = 'Unspecified';
      const label1 = Changedvalue === 'portfolio' ? 'Inclusion' : 'Focus';
      const label2 = Changedvalue === 'portfolio' ? 'Exclusion' : 'Eligible';

      field.input.options[0].label = label0;
      field.input.options[1].label = label1;
      field.input.options[2].label = label2;
      this.schema.find((s) => s.id === 'sec_type').input.options[0].label = label0;
      this.schema.find((s) => s.id === 'sec_type').input.options[1].label = label1;
      this.schema.find((s) => s.id === 'sec_type').input.options[2].label = label2;

      //Below code changes tooltip message whenever Select Trial Search Type is changed.
      /* eslint-disable */
      for (let i =0;i <this.$el.length; i++) {
        if (this.$el[`${i}`]['id'].includes('simple-multiform-form-sec_type-input__BV_option_0_') && this.$el[`${i}`].labels.length !=0 && this.$el[`${i}`].labels[0].innerText.includes('Unspecified') && Changedvalue == 'default') {
          this.$el[`${i}`]['labels'][0]['innerHTML'] = '<span>Unspecified</span><span class="info-icon-st-right" data-title="Will return trials with term regardless of eligibility criteria type (i.e., exists on trial)">ⓘ</span>';
        } else if (this.$el[`${i}`]['id'].includes('simple-multiform-form-sec_type-input__BV_option') && this.$el[`${i}`].labels.length !=0 &&this.$el[`${i}`]['labels'][0]['innerText'].includes('Unspecified') && Changedvalue == 'portfolio'){
          this.$el[`${i}`]['labels'][0]['innerHTML'] = '<span>Unspecified</span><span class="info-icon-st-right" data-title="will return trials with term regardless of eligibility criteria type (i.e., exists on trial)">ⓘ</span>';
        }
        if (this.$el[`${i}`]['id'].includes('simple-multiform-form-sec_type-input__BV_option_1_') && this.$el[`${i}`].labels.length !=0 && /(Focus|Inclusion)/.test(this.$el[`${i}`]['labels'][0]['innerText']) && Changedvalue == 'default') {
          this.$el[`${i}`]['labels'][0]['innerHTML'] = '<span>Focus</span><span class="info-icon-st-right" data-title="Will return trials with term only if the eligibility criteria type is specified as inclusion (i.e., required to enroll)">ⓘ</span>';
        }
        else if (this.$el[`${i}`]['id'].includes('simple-multiform-form-sec_type-input__BV_option_1_') && this.$el[`${i}`].labels.length !=0 && /(Focus|Inclusion)/.test(this.$el[`${i}`]['labels'][0]['innerText']) && Changedvalue == 'portfolio'){
        this.$el[`${i}`]['labels'][0]['innerHTML'] = '<span>Inclusion</span><span class="info-icon-st-right" data-title="Returns all trials with terms listed as Inclusion Criteria">ⓘ</span>';
        }
        if (this.$el[`${i}`]['id'].includes('simple-multiform-form-sec_type-input__BV_option_2_') && this.$el[`${i}`].labels.length !=0 && /(Eligible|Exclusion)/.test(this.$el[`${i}`]['labels'][0]['innerText']) && Changedvalue == 'default') {
        this.$el[`${i}`]['labels'][0]['innerHTML'] = '<span>Eligible</span><span class="info-icon-st-left" data-title="Will return everything BUT trials with term specified as exclusion">ⓘ</span>';
        } else if (this.$el[`${i}`]['id'].includes('simple-multiform-form-sec_type-input__BV_option_2_') && this.$el[`${i}`].labels.length !=0 && /(Eligible|Exclusion)/.test(this.$el[`${i}`]['labels'][0]['innerText']) && Changedvalue == 'portfolio') {
        this.$el[`${i}`]['labels'][0]['innerHTML'] = '<span>Exclusion</span><span class="info-icon-st-left" data-title="Returns all trials with terms listed as Exclusion Criteria">ⓘ</span>';
        }
    } 
    /* eslint-enable */

    },
    isEligibilitySECFields(data) {
      return data.filter((field) => field['field-type'] === 'sec').length > 0;
    },
    displayCorrectLabels(fields){
      // This function makes sure sex, age, and prior therapy filters group are always showing on top of each other in A-Z tab. 
      let correctOrder = [];
      fields.forEach((field)=> {
        if(field.id === "study-participant-char-age"){
          field.group.label = "Age"
        }else if(field.id === "study-participant-char-sex"){
          field.group.label = "Sex"
        }else if(field.id === "study-participant-char-priorTherapy"){
          field.group.label = "Eligibility Criteria"
        }
        correctOrder.push(field);
      });
      return correctOrder;
    },

    //This flag is set to true only when coming from "Apply My Filters"
    setApplyFilterFlag(flag) {
      this.applyFiltersFlag = flag;
    },

    // setup
    setUpFields() {
      const storeModel = this.persist
        ? this.$store.getters["form/getForm"](this.id)
        : {};
      const propsModel = this.$h.cloneDeep(this.values);
      this.buildFields(merge({}, propsModel, storeModel));
    },
    buildFields(model) {
      this.fields = [];
      /* Adding the applyFiltersFlag check here, so that if the request is coming from Apply MY Filters (Saved Filters)
         then we want it to go to the else block to run buildFieldsFromScratch() */
        if (!this.applyFiltersFlag &&
             truthy(this.searchedFields) && (this.$route.name === "studies" || this.$route.name === "participants")) {
        this.searchedFields.forEach((f)=>{
          this.model[f.id] = f.value; // This line helps to make sure the filters applied are retained when switching between studies and participants page
        })
        this.fields = this.searchedFields;
      }else{
        this.schema.forEach((schemaField) => {
        // checking for optional 'filter', 'api' values from schema
        let use_filter = this.$h.get(schemaField, "filter", () => true)();
        let use_api = this.$h.get(schemaField, "api", () => true)();
        // checking for optional 'context' param
        // if none exists, or if value is other than "global", set to "global"
        let ctx = ["global", "filter", "api"].includes(this.context) ? this.context : "global";
        // if the context is "global", that means the same rules should apply everywhere; hence, no action is needed here.
        if (ctx !== "global") {
          // if the use_filter value is false, and if the context passed in is "filter", return and field is skipped.
          // schema fields have an "overrideShow" property that could be set here instead of returning
          // that property is checked below as part of previously existing logic.
          if (!use_filter && ctx === "filter") {
            return;
          }
          if (!use_api && ctx === "api") {
            return;
          }
        }
        //else continue as usual
        let show = this.$h.get(schemaField, "show", () => true)();
        let override = this.$h.get(schemaField, "overrideShow", false);
        if (show || (!show && override)) {
          // set field
          let field = this.$h.cloneDeep(schemaField);

          // get field value
          const value = Object.prototype.hasOwnProperty.call(model, schemaField.id)
            ? model[schemaField.id]
            : this.$h.get(schemaField, "default", undefined);

          field.value = value;
          field.state = getState(field);

          this.model[field.id] = value;
          this.fields.push(field);
          this.updateFieldActive(field);
          if (this.showActiveOnMount) {
            this.updateFieldState(field.id, "visible", field.state.active);
          }
          }
       });
      }

      this.$emit("update:bindFields", this.fields);
      this.$emit("update:bindValues", this.model);
    },
    callMounted() {
      this.fields.forEach((field) => {
        if (
          Object.prototype.hasOwnProperty.call(field, "mounted") &&
          (this.open || this.alwaysCallMounted || field.state.active)
        ) {
          field.mounted(
            this.model,
            this.fields,
            this.resetFields,
            this.updateFieldState
          );
        }
      });
    },

    // form actions
    submit() {
      const areFieldsValid = this.areFieldsValid();
      this.$emit("update:isFormActive", areFieldsValid);
      if (areFieldsValid) {
        const query = this.formatModel();
        this.$emit("submit", query);
        this.saveModel(query);
      }
    },
    reset() {
      this.$emit("cancel");
    },
    resetFields() {
      this.fields.forEach(this.resetField);
    },

    // field actions

    /**
     * Invoker:
     * Invoked when user edits the value of an existing saved field.
     * 
     * Purpose:
     * Updates the form with the value being edited, emits change event with the changed form data
     * @param {id} field id of the custom element (Example: id-selector for SimpleMultiFormField)
     * @param {value} field value of the custom element (Example: {label: 'Organization CTEP ID', value: 'organizations.ctep_id'})
     * @param {shouldChange} flag to update the fields list
     */
    updateValue(id, value, shouldChange = true) {
      let field = this.findField(id);
      if (field) {
        // Pre-update processing
        if ((field.id === "searchType")) {
          if(this.findField("ids-search-sec").value && this.findField("ids-search-sec").value.length > 0){
            // Flip back the value to prevent a value change on SEC Trial Search Type if at least one sec has been selected.
            field.value = field.value === "default" ? "portfolio" : "default";
            this.searchTypeWarning = true;
            return;
          }else{
            EventBus.$emit("searchType-field-event", value);
          }
        }
        if (["min-age-input", "max-age-input"].includes(field.id) && 
        (value.length === 0 || parseInt(value) < 0 || parseInt(value) > 999)) {
          value = field.id === "min-age-input" ? '0' : '999';
        }
        if (!value && field.id === "sec_criteria") {
          value = field.default
        }
        if (field.id === "_keyword_form" && value) {
          // when keyword is updated through the grid view filter textbox on Studies page,
          // the target keyword search values are retained
          if (value._keyword && field.value && field.value.keyword_search_type) {
              value = {
                  _keyword: value._keyword,
                  keyword_search_type: field.value.keyword_search_type || [],
                }
          }
          // this ensures that the Open Summary Filter bar will not list Keyword Search
          // when keyword is deleted in the grid view filter textbox on Studies page or when the keyword filter is removed
          // check if keyword is undefined - this happens when keyword_search_type is slected but no keyword is entered from filter popup input box
          if (value._keyword === "" || value._keyword == undefined) {
            value = null
          }
        }

        // Set field
        this.$set(this.model, field.id, value);
        field.value = value;

        // Update field
        if (shouldChange) {
          this.updateField(field);
        }
        if (field['field-type'] === 'sec' && field['id'] === 'id-selector') {
          if (value['value'] === 'code') {
            this.model['toggle-code-sec'] = true
          } else {
            this.model['toggle-code-sec'] = false
          }
        } else {
          delete this.model['toggle-code-sec']
        }

        // Emit event
        this.$emit("change", this.model);
        this.$emit("update:bindValues", this.model);
      }
    },
    updateField(field) {
      this.updateFieldActive(field);
      this.updateFieldChanged(field);
      if (this.submitOnChange) {
        this.dSubmit();
      }
      this.$emit("update:bindFields", this.fields);
    },
    updateFieldValidation(field) {
      const validation = this.getFieldValidation(field);
      this.$set(field.state, "validation", validation);
      return validation;
    },
    updateFieldChanged(field) {
      if (Object.prototype.hasOwnProperty.call(field, "changed")) {
        field.changed(
          this.model,
          this.fields,
          this.resetField,
          this.updateFieldState,
          this.updateValue
        );
      }
    },
    updateFieldActive(field) {
      field.state.active = Object.prototype.hasOwnProperty.call(field, "isActive")
        ? field.isActive()
        : this.$h.truthy(field.value);

      this.dActive();
    },
    updateFieldState(id, state, payload) {
      let field = this.findField(id);
      this.$nextTick(() => {
        if (field) {
          field.state[state] = payload;
          this.$set(field.state, state, payload);
        }
      });
    },
    getFieldValidation(field) {
      if (!field.hideField && Object.prototype.hasOwnProperty.call(field, "validations")) {
        const validations = this.$h.cloneDeep(field.validations);
        const validationResult = validations
          .map((rule) => rule(field.value, this.model))
          .filter((result) => result !== null)
          .pop();

        return validationResult === undefined ? null : validationResult;
      } else {
        return null;
      }
    },
    resetField(field) {
      const value = Object.prototype.hasOwnProperty.call(field, "default") ? field.default : undefined;
      this.updateValue(field.id, value, false);
    },

    // utils
    formatModel() {
      let model = {};
      const currentModel = this.$h.cloneDeep(this.model);
      if(this.fields){
        this.fields.forEach((field) => {
        const currentValue = currentModel[field.id];
        if (Object.prototype.hasOwnProperty.call(field, "get")) {
          field.get(model, field, this.fields, this.model);
        } else {
          model[field.id] = currentValue;
        }
      });
      }
      return model;
    },
    saveModel(query) {
      if (this.persist) {
        this.$store.dispatch("form/saveForm", {
          query,
          id: this.id,
          model: this.$h.cloneDeep(this.model),
        });
      }
    },
    findField(id) {
      return this.fields.find((field) => field.id === id);
    },
    areFieldsValid() {
      const result = this.fields.map((field) => {
        return this.updateFieldValidation(field) === null;
      });
      return result.every((r) => r);
    },
    onUpdate(field, context) {
      if (Object.prototype.hasOwnProperty.call(field, "update")) {
        field.update(context);
      }
    },
    searchForFields(search) {
      if (search !== "") {
        this.fields.forEach((field) => {
          const label = this.$h.get(field, "group.label", "");
          field.state.visible = label
            .toLowerCase()
            .includes(search.toLowerCase());
        });
      }
    },
  },
  updated() {
    // Below event  gets fired in case user opens new STRAP tab.
    // check if values is defined / available
    if ((this.values) && (this.values['eligibility-criteria'] && this.values['eligibility-criteria'].searchType)) {
      const refSearchType = this.values['eligibility-criteria'].searchType
      EventBus.$emit("refreshed-searchType-field-event", refSearchType);
    }
  },
  watch: {
    fieldSearch: "searchForFields",
    fields() {
      this.dCallMounted();
    },
    values(){
      // update component with "updateKey" only 
      // Ex. Keyword filter update in grid view should be reflected in the keyword filter in filters modal as well.
      this.updateKey++; 
    },
  },
};

function getState(field) {
  const validations = get(field, "validations", []);
  return {
    active: false,
    visible: true,
    disabled: false,
    validation: "",
    required: validations
      ? validations.some((rule) => rule.name === "required")
      : false,
  };
}
</script>

<style lang="scss">
@import "src/styles/component";

form.onlyShowActive {
  > .form-field:not(.active) {
    display: none;
  }
}
.b-form-group {
  position: relative;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
  .submit {
    svg {
      margin-right: 0;
    }
  }
  button:not(:first-child) {
    margin-left: 0.75rem;
  }
}
.clear-field-btn {
  position: absolute;
  top: 1.8rem;
  right: -48px;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  color: darken($n1, 40);
  border: solid 2px transparent;

  display: none;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: fade-out($danger, 0.9);
    border-color: fade-out($danger, 0.85);
    color: $danger;
  }
  &:active {
    background-color: fade-out($danger, 0.8);
    color: darken($danger, 5);
  }
}
small {
  font-weight: $thin;
}

.divider-container {
  padding: 3rem 0rem;
  margin: -6%;
  div {
    width: 100%;
    height: 15px;
    border-bottom: 1px dashed #cccfd4;
    text-align: center;
    span {
      font-size: 20px;
      background-color: #ffffff;
      color: #000000;
      padding: 4px;
    }
  }
}

.divider-container {
  padding: 3rem 0rem;
  margin: -6%;
  div {
    width: 100%;
    height: 15px;
    border-bottom: 1px dashed #cccfd4;
    text-align: center;
    span {
      font-size: 20px;
      background-color: #ffffff;
      color: #000000;
      padding: 4px;
    }
  }
}
.form-field-sec_criteria, .form-field-sec_type {
  width: 100%;
}
.sub-header-container {
  div {
    width: 100%;
    height: 15px;
    margin-left: -4px;
    font-size: 18px;
    color: #000000;
    padding: 4px;
    font-weight: bold;
  }
}
.form-field-hide {
  display: none !important
}

#simple-multiform-form-sec_criteria, #simple-multiform-form-sec_type, .form-field-full-width {
  width: 100%
}

#form-field-sec_text {
  margin-right: 200px;
  margin-top: 3rem;
  font-weight: bolder;
}

.search-type-err {
  font-weight: normal;
  font-size: 80%;
  color: #e74c3c;
}
</style>
