<template>
  <transition name="slide-down" appear>
    <div class="form-summary-bar" :class="variant" v-if="open">
      <slot></slot>
      <div class="form-summary-bar-content" v-if="!$slots.default">
        <span v-if="!$h.truthy(activeFilter)">
          Filtering {{ title }} by
          <b class="mr-1">{{
            formatFields(fields).map((field) => field.group.label)
              .splice(0, 2)
              .join(", ")
          }}</b>
          <b v-if="formatFields(fields).length > 2">
            <i>and</i>
            {{ formatFields(fields).length - 2 }} more
          </b>
          <a v-if="hideOpen === undefined" href="#" @click.prevent="openSummary"
            >(Open Summary)</a
          >
        </span>
        <span v-else>
          <span class="text mr-1">Filtering {{ title }} by my filter:</span>
          <b class="text" v-text="activeFilter.label"></b>
          <a href="#" @click.prevent="openSummary">(Open Summary)</a></span
        >
        <div
          v-if="hideReset === undefined"
          href="#"
          class="reset-btn"
          @click.prevent="$emit('resetFilters')"
        >
          <icon class="mr-1" icon="ban"></icon>
          <span>{{ clearText || "Clear Filters" }}</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { truthy } from '../../../services/CommonsService';
import { showAge } from '../schemas/AgeSchema';
import { hasSECFilterValues } from '../utils/EligibilityCriteria';

export default {
  name: "form-summary-bar",
  props: [
    "open",
    "title",
    "fields",
    "values",
    "variant",
    "hideReset",
    "activeFilter",
    "hideOpen",
    "clearText",
  ],
  methods: {
    openSummary() {
      this.$store.dispatch("modal/openFormSummary", [
        this.values,
        this.variant,
        this.fields,
      ]);
    },
    formatFields(fields){
      /* 
        Check truthy is needed here because age, sex, and prior therapy filters are in a group. 
        All three filters (age, sex, and prior therapy) will be added and displayed in open summary bar if 
        any of them is selected. We do not want to include the ones that are not selected but get added 
        because it is in the group. We can exclude the empty ones by including the ones that have a truthy field.value.
      */
      return fields.filter(this.checkTruthy);
    },
    checkTruthy(field) {
      if (field.id === 'study-participant-char-age') {
        return (showAge(this.values[field.id],'min') || showAge(this.values[field.id],'max'))
      } else if (field.id === 'study-participant-char-sex') {
        return truthy(this.values[field.id]['sex_criteria'])
      } else if (field.id === 'study-participant-char-priorTherapy') {
        return hasSECFilterValues(this.values[field.id],field.id)
      }
      return truthy(field.value);
    },
  }
};
</script>

<style lang="scss">
@import "src/styles/component";

.form-summary-bar {
  width: 100%;
  top: 0;
  height: 2.75rem;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: $thin;
  font-size: 0.9rem;

  &.info {
    font-size: 0.9rem;
    background-color: $n1;
    color: $text;
    border-bottom: solid thin $n3;

    a {
      font-weight: $thin;
      margin-left: 0.25rem;
      text-decoration: underline !important;
      cursor: pointer;
    }

    &.warn {
      // font-weight: $regular;
      // background-color: $warn-bg-color;
      // color: $warn-text-color;
      // border-bottom: solid thin $warn-border-color;
      font-size: 0.9rem;
      background-color: white;
      color: $text;
      border-bottom: solid thin $n3;
      font-weight: $regular;
    }
  }
}
.form-summary-bar-content {
  width: 100%;
  padding: 0.35rem 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: $thin;
  font-size: 0.9rem;

  b {
    font-weight: $bold;
  }
  i {
    font-style: normal;
    font-weight: $thin;
  }
  a {
    font-weight: $thin;
    margin-left: 0.25rem;
    text-decoration: underline;
  }
  span {
    position: relative;
  }
  .text {
    color: $text;
  }
  .reset-btn {
    display: block;
    font-weight: $regular;
    text-decoration: unset;

    position: absolute;
    right: 2.25rem;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover span {
      text-decoration: underline;
    }
  }
}
@each $name, $color in $theme-colors {
  .form-summary-bar.#{$name} {
    background-color: mix($color, white, 7);
    border-bottom: solid thin fade-out($color, 0.5);
    color: darken(saturate($color, 15), 15);
    a {
      color: darken($color, 0);
      &:hover {
        color: darken($color, 10);
      }
    }
  }
}
</style>
