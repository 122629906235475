/* istanbul ignore file */
export default {
  get: (state) => (id) => state.tables[id],
  total: (state) => {
    if (
      state.tables.race &&
      state.tables.race.response &&
      state.tables.race.response.total
    ) {
      return state.tables.race.response.total;
    } 
    else {
    return undefined;
    }
  },
  homeTotal: (state) => {
    return state.tables["participants-home-total"];
  },
};
