import { render, staticRenderFns } from "./AppFooterBuildInfo.vue?vue&type=template&id=7f02adcc&scoped=true"
import script from "./AppFooterBuildInfo.vue?vue&type=script&lang=js"
export * from "./AppFooterBuildInfo.vue?vue&type=script&lang=js"
import style0 from "./AppFooterBuildInfo.vue?vue&type=style&index=0&id=7f02adcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f02adcc",
  null
  
)

export default component.exports