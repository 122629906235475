export default function getSteps() {
  return [
    {
      element: '#automated-reports-widget-actions-help',
      intro: `This is the Automated Reports page. Click on the report name from the list in the main window or select from the dropdown list.<br><a style="font-size: .9rem;" href="/documentation/docs/otherreports/" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg',
      highlightClass: 'special-highlight-bg'
    },
    {
      element: 'div.page.page-reports > div.page-header > div.header-content.delay-7 > div.report-select-btn',
      intro: `Select a specific report from the dropdown list.<br><a style="font-size: .9rem;" href="/documentation/docs/datatable4/" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg',
      highlightClass: 'special-highlight-bg'
    }
  ];
}