export default {
  studyKeys: [
    "funding",
    "age",
    "organization",
    "investigator",
    "sex_criteria",
    "interventions",
    "ids-search-trial",
    "ids-search-org",
    "trial-type",
    "current_trial_status",
    "lead_organizations.id",
    "participating_sites",
    "principal_investigators.name._raw",
    "site_principal_investigators.name",
    "date-range",
    "agent-intervention",
    "other-intervention",
    "disease",
    "va_associated",
    "primary_purpose",
    "study_source",
    "phase",
    "groups.interventions.type",
    "lead-disease",
    "outcome_measures.analysis_method._fulltext",
    "nci_programs.program",
    "division_department_id",
    "m_cid",
    "center-org",
    "study_type",
    "current_trial_status_grouping",
    "biomarker",
    "registration-date-range",
    "_keyword",
    "_keyword_form",
    "study-participant-char-priorTherapy", 
    "study-participant-char-sex", 
    "study-participant-char-age"
  ],
};
