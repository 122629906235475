<template>
  <e-charts ref="chart" :options="$activeOptions" :auto-resize="false" @click="$click"></e-charts>
</template>

<script>
  import ECharts from 'vue-echarts/components/ECharts'
  import 'echarts/lib/chart/line'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/legendScroll'
  import 'echarts/lib/component/dataZoom'
  import ChartMixin from '@/mixins/ChartMixin'
  import { abbreviate, localeString } from '@/services/FilterService'

  const text = '#3F546C'
  const textLight = '#93A1B2'

  // format label and value
  const labelFormatter = label => abbreviate(label, 15)
  const valueFormatter = label => localeString(label)
  const defaultFormatter = label => abbreviate(label, 25)

  const labelAxisNameTextStyle = { color: text }
  const valueAxisNameTextStyle = { color: textLight }

  export default {
    name: 'stacked-line-chart',
    components: { ECharts },
    mixins: [ ChartMixin ],
    props: {
      rotate: {
        type: Number,
        default: 70
      },
      labelAxisName: {
        type: String
      },
      valueAxisName: {
        type: String
      },
      gridConfig: {
        type: Object
      }
    },
    data() {
      return {
        defaultOptions: {},
        expandedOptions: {}
      }
    },
    created() {
      const { defaultOptions, expandedOptions } = this.createChart()
      this.defaultOptions = defaultOptions
      this.expandedOptions = expandedOptions
    },
    methods: {
      createChart() {
        let series = this.$optionDefault('series', [])
        // let grid = { top: '50px', left: '40px', right: '48px', bottom: this.labelAxisName ? '108px' : '48px', containLabel: true }
        let gridConfig = this.$h.cloneDeep(this.gridConfig)
        let grid = gridConfig.default
        let extraGrid = gridConfig.expanded
        let dataZoom = { show: true, start: 0, end: 100, bottom: '20px' }
        let legend = { type: 'scroll', padding: [ 10, 40 ] }

        // set up axis
        const axises = this.getAxisData()
        const axisesExpanded = this.getAxisData(true)

        return {
          defaultOptions: {
            grid,
            series,
            ...axises,
            legend,
            tooltip: { backgroundColor: 'grey' }
          },
          expandedOptions: {
            dataZoom,
            ...axisesExpanded,
            grid: this.$optionMerge(grid, { ...extraGrid })
          }
        }
      },
      getAxisData(expanded = false) {
        let labelAxis = this.$optionDefault('xAxis.0', {})
        let valueAxis = this.$optionDefault('yAxis', {})

        return {
          xAxis: {
            ...labelAxis,
            axisLabel: {
              formatter: this.getFormatter('label', expanded),
              textStyle: labelAxisNameTextStyle,
              rotate: this.rotate
            },
            name: this.labelAxisName,
            nameLocation: 'middle',
            nameGap: expanded ? this.gridConfig.nameGap.labelAxis[0] : this.gridConfig.nameGap.labelAxis[1],
            nameTextStyle: { color: text, fontSize: 11, fontWeight: 600, padding: [0, 0, 0, 0] }
          },
          yAxis: {
            ...valueAxis,
            axisLabel: {
              formatter: this.getFormatter('value', expanded),
              textStyle: valueAxisNameTextStyle
            },
            name: this.valueAxisName,
            nameLocation: 'middle',
            nameGap: expanded ? this.gridConfig.nameGap.valueAxis[0] : this.gridConfig.nameGap.valueAxis[1],
            nameTextStyle: {
              color: text,
              fontWeight: 600,
              fontSize: 11,
              padding: [0, 0, 0, 0]
            }
          }
        }
      },
      getFormatter(type, expanded = false) {
        if (type === 'label') {
          return expanded ? defaultFormatter : labelFormatter
        } else {
          return valueFormatter()
        }
      }
    }
  }
</script>