import get from 'lodash/get'
import Resource from './ResourceService'
import { truthy } from './CommonsService'
import store from '@/store'

export function getExportQuery(
  query,
  resource,
  params,
  headers = [],
  title,
  summaryObject
) {
  const visibleColumns = get(params, 'columns', false)
  if (visibleColumns) {
    // query.params['i'] = headers.filter((h) => !h.hide).map((h) => h.exportField)
    headers
      .filter((h) => !h.hide)
      .forEach((header, i) => {
        let colQuery
        if(header.exportField == 'lead_diseases.name' && !store.getters['settings/useOriginalDiseases'] ) {
          colQuery = `${pad(i, 3)}_lead_diseases2.name_exp_col`
        } else {
          colQuery = `${pad(i, 3)}_${header.exportField}_exp_col`
        }
        query.params[colQuery] = header.exportName
      })
  } else {
    if(resource === 'studies') {
      // If export studies using the "Export data from all columns" option,
      // use "all_columns" as the parameter to avoid URL request exceed the char limit
      query.params["all_columns"] = "studies" ;
    } else {
      headers
        .filter(h => {
          if (h.render === undefined) {
            return true
          }
          else {
            return h.render()
          }
        })
        .forEach((header, i) => {
          let colQuery
          if(header.exportField === 'lead_diseases.name' && !store.getters['settings/useOriginalDiseases'] ) {
            colQuery = `${pad(i, 3)}_lead_diseases2.name_exp_col`
          } else {
            colQuery = `${pad(i, 3)}_${header.exportField}_exp_col`
          }
          query.params[colQuery] = header.exportName
        })
    }
    let accrualHeader = headers.find(h => h.field === 'total_accruals')
    let hideAccruals = true
    if (accrualHeader !== undefined && accrualHeader.render !== undefined) {
      hideAccruals = !accrualHeader.render()
    }

    if (resource === 'studies' && !hideAccruals) {
      query.params['merge_accruals'] = 'total_accruals'
      if (!store.getters['settings/showSummaryPatients']) {
        query.params['merge_accruals_sex_not'] = 'SYSTEM_GENERATED'
      }
    }
  }

  query.params.title_exp = title
  query.params.export = true
  // dis_maintype_ind is responsible to pass disease maintype Indicator to export lambda query tab
  query.params.dis_maintype_ind = store.getters['settings/useOriginalDiseases']
  query.params['size'] = 100000
  delete params['includes']

  let exportQuery = {}
  if (!Object.prototype.hasOwnProperty.call(query, "baseURL")) {
    exportQuery = Resource.queries[resource](query)
  } else {
    exportQuery = query
  }

  // add query body
  if (truthy(summaryObject)) {
    exportQuery.data = {
      query_filter: summaryObject,
    }
  }

  exportQuery.method = 'POST'
  exportQuery.endpoint = exportQuery.endpoint + '/email-export'

  return exportQuery
}

export function serveFile(file, params) {
  let actualFileType =
    params.type === 'xlsx'
      ? 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : params.type
  let mimeType =
    (actualFileType === 'pdf' ? 'text/' : 'application/') + actualFileType
  let blob = null

  if (
    actualFileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    blob = new Blob([file], { type: mimeType })
  } else {
    blob = new Blob(
      ['\ufeff', actualFileType === 'json' ? JSON.stringify(file) : file],
      { type: mimeType }
    )
  }

  // create dynamic element for download
  let link = document.createElement('a')
  if (link.download !== undefined) {
    let url = window.URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', params.name + '.' + params.type)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return link
}

export function getLocalData(rows, headers) {
  return rows.map((row) => {
    const returnObject = {}
    headers.forEach((header) => {
      returnObject[header.headerName] = Object.prototype.hasOwnProperty.call(header, "valueFormatter")
        ? header.valueFormatter({ value: row[header.field] })
        : row[header.field]
    })
    return returnObject
  })
}

function pad(n, length) {
  var len = length - ('' + n).length
  return (len > 0 ? new Array(++len).join('0') : '') + n
}
