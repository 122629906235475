<template>
  <div class="chart-observer" ref="observer" :class="state">
    <!-- chart backdrop -->
    <transition name="fade-appear-half" appear>
      <div class="chart-backdrop" v-if="state.open" @click="close"></div>
    </transition>

    <!-- chart -->
    <async-data-wrapper
      :query="activeQuery"
      :resource="resource"
      @resolve="setTotal"
      @state="update"
    >
      <template v-slot="{ _state, _reload }">
        <div class="chart-wrapper" :class="[wrapperClass, state, expandSize]">
          <form-summary-bar
            class="chart-form-bar"
            :title="title"
            :fields="summaryFields"
            :variant="variant"
            :values="summaryValues"
            :open="(state.open || alwaysExpanded) && summaryFields.length"
            hide-reset
            hide-open
          ></form-summary-bar>

          <div
            class="chart-screenshot-container"
            ref="chart"
            :class="{ screenshoting }"
          >
            <!-- chart-header -->
            <widget-header
              :id="`${id}-widget-header`"
              :key="state.open"
              :state="_state"
              :title="title"
              :total="total"
              :open="state.open"
              :count-label="countType"
              :hide-expand="noExpand"
              :disabled="empty || !seriesTotal || _state.loading"
              :hideExpand="!(!state.open || alwaysExpanded)"
              :showClose="state.open && !alwaysExpanded"
              :show-guide="!state.open"
              :show-help="true"
              :hide-actions="state.screenshot"
              :intro-configs="introConfigs"
              @expand="expand"
              @close="close"
              :dataSettingsSummary="dataSettingsSummary"
            >
              <!-- Title -->
              <template #content>
                <slot name="content" :_state="_state"></slot>
              </template>
              <template #title>
                <slot name="title" :_state="_state"></slot>
              </template>

              <template #actions>
                <!-- Screenshot -->
                <btn
                  v-if="state.open || alwaysExpanded"
                  v-tooltip="'Screenshot'"
                  class="widget-action"
                  variant="outline-circle"
                  :disabled="empty || _state.loading"
                  @click="state.screenshot = true"
                >
                  <icon icon="camera" />
                </btn>

                <!-- Summary in expand -->
                <btn
                  v-if="state.open && !alwaysExpanded && !noSummary"
                  v-tooltip.hover="
                    state.summary
                      ? 'Hide Search Summary'
                      : 'Show Search Summary'
                  "
                  class="widget-action"
                  variant="outline-circle"
                  :disabled="empty || _state.loading"
                  @click="state.summary = !state.summary"
                >
                  <icon icon="filter" />
                </btn>

                <!-- Summary at front for studies -->
                <btn
                  v-if="
                    !state.open &&
                    !noSummary &&
                    (summaryFields.length ||
                      filterPersistent ||
                      (!noFiltersSummary &&
                        $store.getters['settings/containerEnabled']))
                  "
                  v-tooltip.hover="'Show Search Summary'"
                  class="widget-action"
                  variant="outline-circle"
                  :disabled="empty || _state.loading"
                  @click="openSummary"
                >
                  <icon icon="filter" />
                </btn>
              </template>
            </widget-header>

            <form-builder
              v-if="state.screenshot"
              id="chart-screenshot"
              class="chart-screenshot-form no-highlight"
              submit-text="camera"
              :submit-variant="'header'"
              :context="context"
              block-submit
              no-persist
              :schema="screenshotSchema"
              :values="screenshotValues"
              @submit="screenshotChart"
              @cancel="state.screenshot = false"
            ></form-builder>

            <!-- body -->
            <div class="chart-body" ref="chart">
              <state-handler
                v-bind="_state"
                @reload="_reload"
                no-transition
                :loading-title="countType"
                :error-size="expandSize === 'wide' ? 'lg' : 'md'"
                :spinnerVariant="variant"
              >
                <template #response>
                  <div class="chart-response">
                    <!-- no data -->
                    <error
                      class="chart-empty"
                      size="md"
                      icon="exclamation-triangle"
                      variant="warning"
                      no-links
                      v-if="empty"
                    >
                      <template #title>
                        <div>No data found</div>
                      </template>
                      <template #error>
                        <div v-html="emptyMessage"></div>
                      </template>
                    </error>

                    <error
                      class="chart-empty"
                      size="md"
                      icon="exclamation-triangle"
                      variant="warning"
                      no-links
                      v-else-if="!seriesTotal"
                    >
                      <template #title>
                        <div>The chart is not available at this time</div>
                      </template>
                    </error>

                    <!-- chart -->
                    <div class="chart-container" v-else>
                      <slot
                        class="chart"
                        :_chartState="{
                          options: format(
                            $h.get(_state, `response.${path}`, {})
                          ),
                          expanded: state.open,
                          total: total,
                        }"
                      ></slot>
                      <div
                        class="chart-query-summary"
                        v-if="state.open && state.summary"
                      >
                        <div class="header">
                          <h5
                            v-html="`Searching for ${titleCase(countType)}...`"
                          ></h5>
                        </div>

                        <div
                          class="extra-text"
                          v-if="$store.getters['settings/containerEnabled']"
                        >
                          <div>
                            {{ dataSettingsSummarySelection }} :
                            <strong>{{
                              $store.getters["settings/activeContainer"].name
                            }}</strong>
                          </div>
                        </div>

                        <div class="body">
                          <form-summary
                            :variant="variant"
                            :values="summaryValues"
                            :schema="updatedSummaryFields"
                          ></form-summary>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </state-handler>
            </div>
          </div>
        </div>
      </template>
    </async-data-wrapper>
  </div>
</template>

<script>
import domtoimage from "dom-to-image";
import FormBuilder from "@/components/form/FormBuilder";
import Error from "@/components/presentation/state-handler/DefaultError";
import ScreenshotSchema from "@/components/form/schemas/ScreenshotScehma";
import FormSummaryBar from "@/components/form/summary/FormSummaryBar";
import FormSummary from "@/components/form/summary/FormSummary";
import { titleCase } from "@/services/FilterService";
import set from "lodash/set";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import WidgetHeader from "@/components/presentation/widgets/WidgetHeader";

export default {
  name: "chart-wrapper",
  components: {
    FormSummaryBar,
    FormBuilder,
    Error,
    FormSummary,
    WidgetHeader,
  },
  props: {
    id: {
      required: true,
    },
    query: {
      type: Object,
    },
    resource: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "chart_options",
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    exportResponseId: {
      type: String,
      default: "",
    },
    noExpand: {
      type: Boolean,
      default: false,
    },
    alwaysExpanded: {
      type: Boolean,
      default: false,
    },
    warningCondition: {
      type: Boolean,
      default: false,
    },
    noTotal: {
      type: Boolean,
      default: false,
    },
    formatRaceData: {
      type: Boolean,
      default: false,
    },
    noSummary: {
      type: Boolean,
      default: false,
    },
    countType: {
      type: String,
      default: "studies",
    },
    emptyMessage: {
      type: String,
      default: "Adjust search filters to see chart",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    chartData: {
      type: Object,
    },
    chartProps: {
      type: Object,
      default: () => ({}),
    },
    summaryValues: {
      type: Object,
      default: () => ({}),
    },
    summarySchema: {
      type: Array,
      default: () => [],
    },
    summaryFields: {
      type: Array,
      default: () => [],
    },
    // helpLink: {
    //   type: String,
    //   default: ''
    // },
    expandSize: {
      type: String,
      default: "box",
    },
    variant: {
      type: String,
      default: "studies",
    },
    totalKey: {
      type: String,
      default: "total",
    },
    // guidePath: {
    //   type: String,
    //   default: ''
    // },
    introConfigs: {
      type: Array,
      default: () => [],
    },
    dataSettingsSummary: {
      type: Boolean,
      default: false,
    },
    filterPersistent: {
      type: Boolean,
      default: false,
    },
    noFiltersSummary: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      default: "global"
    }
  },
  data() {
    return {
      state: {
        open: false,
        screenshot: false,
        summary: false,
        title: !this.hideDetail,
        context: ''
      },
      render: true,
      screenshoting: false,
      total: 0,
      seriesTotal: undefined,
      screenshotSchema: ScreenshotSchema,
      activeQuery: {},
      screenshotValues: {
        filename: this.title
          .replace(/([a-z])([A-Z])/g, "$1-$2")
          .replace(/\s+/g, "-")
          .toLowerCase(),
      },
    };
  },
  computed: {
    empty() {
      return this.total === 0;
    },
    dataSettingsSummarySelection() {
      return this.$store.getters["settings/memberInformation"]
        ? "Only Cancer Center data "
        : "All study data for a Cancer Center ";
    },
    isContainDateRange() {
      return !!this.summaryFields.filter((field) => field.id == "date-range")
        .length;
    },
    updatedSummaryFields() {
      if (this.id === "trials-over-time" && !this.isContainDateRange) {
        return [
          {
            id: "date-range", //append this to trigger render default "Studies Over Time" "data-range"
          },
          ...this.summaryFields,
        ];
      }
      return this.summaryFields;
    },
  },
  methods: {
    openSummary() {
      this.$store.dispatch("modal/openFormSummary", [
        this.summaryValues,
        this.variant,
        this.updatedSummaryFields,
      ]);
    },
    updateQuery(newQuery, oldQuery) {
      if (!isEqual(newQuery, oldQuery)) {
        this.activeQuery = newQuery;
      }
    },
    setTotal(response) {
      this.total = this.$h.get(response, this.totalKey, 0);
      this.seriesTotal = this.$h.get(
        response,
        "chart_options.series.0.data",
        []
      ).length;
      this.$emit("total", this.total);
    },
    expand() {
      if (!this.state.open) {
        this.state.open = true;
        document.body.className = "hide-overflow";
      }
    },
    close() {
      if (this.state.open) {
        this.state.screenshot = false;
        this.state.open = false;
        document.body.className = "";
      }
    },
    screenshotChart(options) {
      this.screenshoting = true;
      this.$store.dispatch("toaster/start", {
        message: "Complete! Check your Downloads",
        variant: "success",
        icon: "check",
        time: 5000,
      });
      domtoimage
        .toJpeg(this.$refs.chart, { bgcolor: "white" })
        .then((url) => {
          serveScreenshot(url, options.filename);
          this.screenshoting = false;
        })
        .catch(() => {
          this.screenshoting = false;
        });

      this.$nextTick(() => {
        this.state.screenshot = false;
      });
    },
    responseClick(e) {
      e = e || window.event;
      let target = e.target || e.srcElement;
      if (target === this.$refs.response) {
        this.close();
      }
    },
    forceResize() {
      this.$store.dispatch("forceResize");
    },
    update(state) {
      if (this.exportResponseId) {
        let newState = this.$h.cloneDeep(state);

        if (this.id === "age") {
          set(
            newState,
            "response.chart_options",
            formatAgeData(get(newState, "response.chart_options"))
          );
        } else if (this.id === "ethnicity") {
          set(
            newState,
            "response.chart_options",
            formatEthnicityData(get(newState, "response.chart_options"))
          );
        } else if (this.id === "race") {
          set(
            newState,
            "response.chart_options",
            formatRaceData(get(newState, "response.chart_options"))
          );
        }
        this.$store.dispatch("chartTables/set", {
          id: this.exportResponseId,
          state: newState,
        });
      }
    },
    format(data) {
      if (this.formatRaceData) {
        return formatRaceData(data);
      }
      if (!this.exportResponseId) {
        return data;
      }
      if (this.id === "age") {
        return formatAgeData(data);
      } else if (this.id === "race") {
        return formatRaceData(data);
      } else if (this.id === "ethnicity") {
        return formatEthnicityData(data);
      } else {
        return data;
      }
    },
    titleCase,
  },
  beforeDestroy() {
    this.close();
  },
  watch: {
    query: {
      handler: "updateQuery",
      deep: true,
      immediate: true,
    },
    state: {
      handler: "forceResize",
      deep: true,
    },
  },
};

function serveScreenshot(url, title) {
  let link = document.createElement("a");
  link.download = `${title}.jpeg`;
  link.href = url;
  link.click();
}
function formatRaceData(data) {
  let categories = {
    "American Indian or Alaska Native": {
      name: "American Indian or Alaska Native",
      value: 0,
      itemStyle: { color: "#933a00" },
    },
    Asian: {
      name: "Asian",
      value: 0,
      itemStyle: { color: "#a14606" },
    },
    "Black or African American": {
      name: "Black or African American",
      value: 0,
      itemStyle: { color: "#b0520c" },
    },
    "Native Hawaiian or Other Pacific Islander": {
      name: "Native Hawaiian or Other Pacific Islander",
      value: 0,
      itemStyle: { color: "#c56315" },
    },
    White: {
      name: "White",
      value: 0,
      itemStyle: { color: "#db751e" },
    },
    "More Than one Race": {
      name: "More Than one Race",
      value: 0,
      itemStyle: { color: "#e98428" },
    },
    "Unknown or Not Reported": {
      name: "Unknown or Not Reported",
      value: 0,
      itemStyle: { color: "#f29539" },
    },
  };
  get(data, "series.0.data", []).forEach((item) => {
    if (item.name) {
      let checkName = item.name.toLowerCase();
      let checkNameArray = checkName.split(";");
      if (
        (checkNameArray.length <= 2 &&
          (checkName.includes("unknown") ||
            checkName.includes("not reported"))) ||
        item.name === null
      ) {
        categories["Unknown or Not Reported"].value += item.value;
      } else if (checkName.includes(",") || checkName.includes(";")) {
        categories["More Than one Race"].value += item.value;
      } else if (checkName === "white") {
        categories["White"].value += item.value;
      } else if (checkName === "american indian") {
        categories["American Indian or Alaska Native"].value += item.value;
      } else if (checkName === "asian") {
        categories["Asian"].value += item.value;
      } else if (checkName === "black") {
        categories["Black or African American"].value += item.value;
      } else if (checkName === "hawaiian") {
        categories["Native Hawaiian or Other Pacific Islander"].value +=
          item.value;
      }
    }
  });
  return {
    legend: {
      data: [
        "American Indian or Alaska Native",
        "Asian",
        "Black or African American",
        "Native Hawaiian or Other Pacific Islander",
        "White",
        "More Than one Race",
        "Unknown or Not Reported",
      ],
    },
    series: [
      {
        data: Object.values(categories),
        name: "Races String",
        type: "pie",
      },
    ],
    tooltip: data ? data.tooltip : "",
  };
}
function formatEthnicityData(data) {
  let categories = {
    "Hispanic or Latino": {
      name: "Hispanic or Latino",
      value: 0,
      itemStyle: { color: "#933a00" },
    },
    "Not Hispanic or Latino": {
      name: "Not Hispanic or Latino",
      value: 0,
      itemStyle: { color: "#b0520c" },
    },
    "Unknown or Not Reported": {
      name: "Unknown or Not Reported",
      value: 0,
      itemStyle: { color: "#e98428" },
    },
  };
  get(data, "series.0.data", []).forEach((item) => {
    if (item.name) {
      let checkName = item.name.toLowerCase();
      if (checkName.includes("unknown") || checkName.includes("not reported")) {
        categories["Unknown or Not Reported"].value += item.value;
      } else if (checkName === "hispanic") {
        categories["Hispanic or Latino"].value += item.value;
      } else if (checkName === "not hispanic") {
        categories["Not Hispanic or Latino"].value += item.value;
      }
    }
  });
  return {
    legend: {
      data: [
        "Hispanic or Latino",
        "Not Hispanic or Latino",
        "Unknown or Not Reported",
      ],
    },
    series: [
      {
        data: Object.values(categories),
        name: "Ethnicity",
        type: "pie",
      },
    ],
    tooltip: data ? data.tooltip : "",
  };
}
function formatAgeData(data) {
  let xAxis = get(data, "xAxis.data", []);
  let series = get(data, "series.0.data", []);
  let sdArray = [];
  let ageTotal = 0;
  let categories = {
    "<=18 Years": 0,
    "Between 18 and 65 Years": 0,
    ">=65 Years": 0,
    "Unknown, Not Reported, or Invalid": 0,
  };
  series.forEach((item, i) => {
    if (xAxis[i] < 0 || xAxis[i] > 120) {
      categories["Unknown, Not Reported, or Invalid"] += item;
      return;
    } else if (xAxis[i] <= 18) {
      categories["<=18 Years"] += item;
      ageTotal += xAxis[i];
      sdArray.push(xAxis[i]);
    } else if (xAxis[i] <= 65) {
      categories["Between 18 and 65 Years"] += item;
      ageTotal += xAxis[i];
      sdArray.push(xAxis[i]);
    } else if (xAxis[i] >= 65) {
      categories[">=65 Years"] += item;
      ageTotal += xAxis[i];
      sdArray.push(xAxis[i]);
    } else {
      categories["Unknown, Not Reported, or Invalid"] += item;
    }
  });

  let mean;
  let sd;
  let range = {
    to: undefined,
    from: undefined,
  };

  if (ageTotal !== 0) {
    mean = (ageTotal / sdArray.length).toFixed(2);
    sd = getStandardDeviation(sdArray).toFixed(2);
    range = {
      from: sdArray[0],
      to: sdArray[sdArray.length - 1],
    };
  }

  return {
    _raw: data,
    extra: { mean, sd, range },
    series: [
      {
        data: Object.values(categories).reverse(),
        type: "bar",
      },
    ],
    xAxis: {
      data: Object.keys(categories).reverse(),
    },
    yAxis: data?.yAxis,
  };
}
function getStandardDeviation(array = []) {
  const n = array.length;
  if (n === 0) {
    return;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
}
</script>

<style lang="scss">
@import "src/styles/component";

.chart-observer {
  z-index: $z-index-chart;

  &.open {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.open.screenshot {
    .chart-wrapper {
      border-color: transparent !important;
    }
    .chart-form-bar {
      opacity: 0;
    }
    .title-form-dropdown-content {
      pointer-events: none;
      svg {
        display: none;
      }
    }
    .chart-query-summary {
      flex: 0 0 250px;
    }
  }
}
.chart-wrapper.open {
  z-index: $z-index-chart;
  box-shadow: none;

  .action-button {
    opacity: 1 !important;
  }
  .action-button.open {
    order: 3;
    font-size: 1.2rem;
  }
  .chart-header .chart-title {
    font-size: 1.25rem;
    color: $text;
  }
  .open-btn {
    padding: 0.5rem 0.9rem !important;
    font-size: 1.25rem !important;
  }
}
.chart-wrapper.open {
  width: 87vw;
  height: 80vh;
  max-height: 85vh;
  margin-top: -5vh;
}
.chart-wrapper.open.box {
  width: 87vw;
  height: 85vh;
  max-width: 950px;
  max-height: 900px;
  margin-top: -3vh;
}
// .chart-wrapper.open.wide {
//   max-width: 1200px;
//   height: 80vh;
//   max-height: 800px;
// }
// .chart-wrapper.open.box {
// max-width: 850px;
// height: 90vh;
// max-height: 800px;
// }

// backdrop
.chart-backdrop {
  background-color: fade-out(darken($link, 60), 0.5);
  background-color: #000;
  transition: opacity 0.25s;
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-index-chart;
}
// wrapper
.chart-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.chart-expanded-actions {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 2rem;

  padding: 1rem 0;

  .default-actions,
  .screenshot-open-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .checkboxes {
    display: flex;
    align-items: center;
    padding-right: 1.5rem;

    .custom-checkbox {
      margin-left: 1rem;
      .custom-control-label {
        font-weight: $regular !important;
        color: $text;
      }
    }
  }
  .expanded-close {
    flex: 0 0 auto;
    margin-right: 1rem;
  }
  &.summary {
    top: 3.5rem;
  }
}
.chart-screenshot-form {
  position: absolute;
  right: 2rem;
  top: 2.2rem;
  display: flex;
  background-color: white;

  #chart-screenshot-filename label {
    display: none !important;
  }
  #chart-screenshot-filename input {
    height: 40px;
    width: 250px;
  }
  .invalid-feedback {
    display: none !important;
  }
  .btn {
    margin: 0 0 0 0.5rem;
  }
  .btn[type="reset"] {
    order: 2;
  }
  .btn[type="submit"] {
    order: 1;

    svg {
      margin-right: 0.35rem;
    }
  }
  .form-group {
    margin: 0;
  }
}
.chart-screenshot-container {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .chart-query-summary {
    overflow: auto;
    height: calc(80vh - 120px);
  }
  .extra-text {
    padding: 0rem 1.5rem 1.5rem;
  }
  .title-for-capture {
    display: none;
  }

  &.screenshoting {
    .widget-actions {
      display: none;
    }
    svg[data-icon="caret-down"] {
      display: none;
    }
    .chart-query-summary {
      height: auto;
      overflow: visible;
      h5 {
        display: block;
        width: 100%;
      }
    }

    .title-form {
      display: block;
      .title,
      .title-form-dropdown {
        display: none;
      }
    }
    .title-for-capture {
      display: block;
    }
  }
}
.open .chart-container .echarts {
  height: calc(80vh - 130px) !important;
}
.chart-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 2.5rem 2.5rem 0;

  min-height: 6rem;
  &.no-count {
    min-height: 4rem;
  }
}
.chart-title-container {
  flex: 0 1 100%;
  h4 {
    font-weight: $bold;
    font-size: 1.1rem;
    color: $text;
  }
  h6 {
    font-weight: $thin;
    font-size: 1rem !important;
    color: $text-light;

    svg {
      font-size: 0.8rem;
      margin-right: 0.3rem;
    }
  }
}
.chart-actions-slot {
  margin-right: 0.75rem;
}
.chart-actions {
  display: flex;
  .btn-link {
    font-size: 1.1rem;
    margin-left: 0.25rem;
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:active {
      background-color: $n1 !important;
      color: $link !important;
      box-shadow: none !important;
    }
    &.disabled {
      background-color: $n1 !important;
      border-color: transparent !important;
    }
  }
  .btn,
  .btn a {
    color: $text-light;
  }
}
.chart-body {
  flex: 0 1 100%;
  position: relative;
}
.chart-response {
  width: 100%;
  height: 100%;
  position: relative;
}
.chart-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  .chart {
    flex: 0 1 100%;
    width: 100%;
    height: 100%;
    position: relative;
  }
}
.chart-query-summary {
  flex: 0 0 320px;
  border-left: solid thin $n3;
  margin-top: 0.5rem;

  > .header {
    padding: 1rem 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    svg {
      color: lighten($dark, 30);
    }
    h5 {
      color: $text;
      font-size: 1rem;
    }
  }
  > .body {
    padding: 0 1.5rem;
  }
  .form-summary {
    padding-left: 0 !important;
  }
}
.default-error.chart-empty {
  background-color: white !important;
}
// error
.chart-error {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  > .error {
    background-color: white !important;
    background: white;
    border-radius: $border-radius;
    font-size: 1rem;
  }
}

@include media-breakpoint-down(sm) {
  .chart-title-container {
    h4 {
      font-size: 1.1rem;
    }
    h6 {
      font-size: 0.9rem;
    }
  }
}
</style>