export default {
  setEnabled({ commit }, enabled) {
    commit("setEnabled", enabled);
    if (!enabled) {
      commit("setContainer", {});
      commit("setMemberInformation", false);
    }
  },
  setContainer({ commit }, container) {
    commit("setContainer", container);
  },
  setMemberInformation({ commit }, enabled) {
    commit("setMemberInformation", enabled);
  },
  setShowSummaryPatients({ commit }, showSummaryPatients) {
    commit("setShowSummaryPatients", showSummaryPatients);
  },
  setUseOriginalDiseases({ commit }, useOriginalDiseases) {
    commit("setUseOriginalDiseases", useOriginalDiseases);
  },
  setSkipCache({ commit }, skipCache) {
    commit("setSkipCache", skipCache);
  },
  reset({ commit }) {
    commit("setShowSummaryPatients", false);
    commit("setSkipCache", false);
    commit("setMemberInformation", false);
    commit("setEnabled", false);
    commit("setContainer", {});
  },
};
