<template>
  <div class="global-search">
    <async-data-wrapper
      v-if="open && active"
      resource="studies"
      :query="trialQuery"
      :debounce="600"
      @resolve="resolve('studies', $event)"
      @reject="reject('studies', $event)"
    />
    <async-data-wrapper
      v-if="open && active"
      resource="organizations"
      :query="organizationsQuery"
      :debounce="600"
      @resolve="resolve('organizations', $event)"
      @reject="reject('organizations', $event)"
    />
    <async-data-wrapper
      v-if="open && active"
      resource="investigators"
      :query="investigatorsQuery"
      :debounce="600"
      @resolve="resolve('investigators', $event)"
      @reject="reject('investigators', $event)"
    />
    <async-data-wrapper
      v-if="open && active"
      resource="participants"
      :query="participantsQuery"
      :debounce="600"
      @resolve="resolve('participants', $event)"
      @reject="reject('participants', $event)"
    />

    <search-input
      :bind-open.sync="open"
      v-model="search"
      :disable-help="loading"
    >
      <state-handler
        :loading="loading"
        :error="error"
        transition="slide-fade-appear-2"
        ignore-error
      >
        <template #error>
          <div key="error" class="search-loading">
            <span>An error occurred</span>
          </div>
        </template>
        <template #loading>
          <div key="loading" class="search-loading">
            <span>
              Searching for
              <b>{{ search | abbreviate(50) }}</b>
            </span>
            <spinner class="icon" :radius="50" :strokeWidth="4" />
          </div>
        </template>
        <template #response>
          <div key="response">
            <search-menu
              v-if="!active"
              @search="search = $event"
              @close="open = false"
            />
            <search-results
              v-else
              v-bind="entities"
              :search="search"
              @close="open = false"
            />
          </div>
        </template>
      </state-handler>
    </search-input>
  </div>
</template>

<script>
import SearchInput from "./search/SearchInput";
import SearchResults from "./search/SearchResults";
import SearchMenu from "./search/SearchMenu";
import Spinner from "@/components/utils/Spinner";

function defaultState() {
  return {
    response: [],
    loading: true,
    error: "",
    total: 0,
  };
}

export default {
  name: "global-search",
  components: { SearchInput, SearchResults, SearchMenu, Spinner },
  data() {
    return {
      search: "",
      open: false,
      entities: {
        studies: defaultState(),
        organizations: defaultState(),
        investigators: defaultState(),
        participants: defaultState(),
      },
    };
  },
  computed: {
    active() {
      return this.$h.truthy(this.search);
    },
    loading() {
      return (
        this.active &&
        (this.entities.organizations.loading ||
          this.entities.investigators.loading ||
          this.entities.studies.loading)
      );
    },
    error() {
      return (
        this.$h.get(this.entities, "studies.error", "") +
        this.$h.get(this.entities, "organizations.error", "") +
        this.$h.get(this.entities, "investigators.error", "")
      );
    },
    trialQuery() {
      return {
        params: {
          include: ["public_title", "nci_id", "nct_id", "lead_organization_id"],
          _keyword: this.search,
          _keyword_field: [],
          size: 18,
        },
      };
    },
    organizationsQuery() {
      return {
        params: {
          include: ["id", "name"],
          _keyword: this.search,
          _keyword_field: ["id._auto", "ctep_id", "name._auto", "aliases"],
          size: 18,
        },
      };
    },
    investigatorsQuery() {
      return {
        params: {
          include: ["id", "name"],
          _keyword: this.search,
          _keyword_field: ["id._auto", "ctep_id", "name._auto"],
          size: 18,
        },
      };
    },
    participantsQuery() {
      return {
        params: {
          _keyword: this.search,
          include: "none",
          _keyword_field: [
            "ethnicity._fulltext",
            "races",
            "races_string",
            "sex",
            "country",
            "evs_ctrp_display_name._fulltext",
            "assigned_id._auto",
            "disease_preferred_name",
            "disease_code",
          ],
        },
      };
    },
  },
  methods: {
    resolve(entity, response) {
      this.entities[entity].loading = false;
      this.entities[entity].response = this.$h.get(response, "data", []);
      this.entities[entity].total = this.$h.get(response, "total", 0);
    },
    reject(entity, error) {
      this.entities[entity].loading = false;
      this.entities[entity].error = error;
    },

    reset() {
      this.$set(this.entities, "studies", defaultState());
      this.$set(this.entities, "organizations", defaultState());
      this.$set(this.entities, "investigators", defaultState());
    },
  },
  watch: {
    search: "reset",
  },
};
</script>

<style lang="scss">
@import "src/styles/component";

.search-loading {
  padding: 1.5rem;
  font-weight: $thin;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 590px;

  .icon {
    font-size: 1.1rem;
    color: $link;
  }
}
</style>
