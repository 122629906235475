var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"accordion-header"}},[_c('div',{staticClass:"accordion-container",attrs:{"id":"accordion"}},[_vm._l((_vm.getGroupsFilter()),function(group,i){return _c('div',{key:i,class:[
            'accordion',
            {
                'is-open':
                    _vm.isOpen.includes(i) ? _vm.isOpen.includes(i) : group.isGroupActive,
            },
        ],attrs:{"id":"study-group-title","role":"button"},on:{"click":function($event){return _vm.toggleAccordion(i)}}},[_c('span',[_vm._v(" "+_vm._s(group.groupTitle)+" "),_c('span',{staticClass:"group-icon"},[(_vm.isOpen.includes(i) && (group.isGroupActive || group.isGroupVisible))?_c('icon',{staticClass:"plus-minus",attrs:{"icon":'chevron-down'}}):_c('icon',{staticClass:"plus-minus",attrs:{"icon":!_vm.isOpen.includes(i) ? 'chevron-up' : 'chevron-down'}})],1)]),_c('div',{staticClass:"accordion-body"},[_c('ul',{ref:"scroll",refInFor:true,staticClass:"study-groupfilters-list default",attrs:{"id":"study-group-subtitle"}},_vm._l((group.filterInGroup.filter(function(item){
                    return item != null && item != undefined;
                })),function(field,index){return ((field.id === 'actual_enrollment' || field.id === 'target_enrollment') ? false:true)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.$h.get(field, 'show2', true)),expression:"$h.get(field, 'show2', true)"}],key:index,staticClass:"advanced-filter-list-item",class:[field.state, field.group.label, `tab-${field.tab}`],attrs:{"id":`aside-${field.id}`},on:{"click":function($event){return _vm.toggleFieldAndExpandAccordion(field, i)}}},[_c('span',{domProps:{"innerHTML":_vm._s(field.groupingFilterDisplayLabel ? field.groupingFilterDisplayLabel.label : field.group.label)}}),_c('span',{staticClass:"icons"},[(field.state.active)?_c('icon',{key:"check",staticClass:"plus-minus",attrs:{"icon":'check'}}):_c('icon',{key:field.state.visible,staticClass:"plus-minus",attrs:{"icon":field.state.visible ? 'minus' : 'plus'}})],1)]):_vm._e()}),0)])])}),_c('div',_vm._l((_vm.noSubfilterGroup),function(field,index){return _c('div',{key:index,attrs:{"id":"study-group-title","role":"button"},on:{"click":function($event){return _vm.callfromSubFilterGroup(field)}}},[(field)?_c('span',[_vm._v(" "+_vm._s(field.studyFilterGroup.label)+" "),_c('span',{staticClass:"group-icon"},[(field.state.active)?_c('icon',{key:"check",staticClass:"plus-minus",attrs:{"icon":'check'}}):_c('icon',{key:field.state.visible,staticClass:"plus-minus",attrs:{"icon":field.state.visible ? 'minus' : 'plus'}})],1)]):_vm._e()])}),0),_c('ul',{ref:"scroll",staticClass:"data-view-filters-filter-list default"},[(_vm.advancedSearch != null && _vm.advancedSearch.length)?_c('li',{staticClass:"advanced-filter-list-item clear",on:{"click":function($event){$event.preventDefault();return _vm.clearSearch.apply(null, arguments)}}},[_c('a',{attrs:{"href":"#"}},[_vm._v("Clear Search")])]):_vm._e()])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }