<template>
  <div :class="`page-header ${headerClass} ${size} ${variant} pad-${padding}`">
    <transition :name="noTransition ? 'none' : 'slide-fade-appear'" appear>
      <div class="header-logo-name" v-if="!hideLogo">
        <!-- logo -->
        <div class="entity-logo" :class="variant" v-if="icon">
          <icon :icon="icon"></icon>
        </div>

        <!-- name -->
        <div
          class="name header-name-container"
          :key="title"
          :class="{ stacked: subTitle }"
        >
          <!-- title -->
          <h1>
            {{ title }}
            <div class="total-count" v-if="!hideTotal">
              <state-handler
                transition-name="fade"
                keep-response-alive
                transition="none"
                :loading="loading"
                ignore-error
              >
                <template #loading>
                  <spinner
                    key="loading"
                    class="nudge-up"
                    :variant="variant"
                    :radius="27"
                    :strokeWidth="6"
                  />
                </template>
                <template #response>
                  <span key="response" :id="`${title}-page-header-total`">{{
                    total | localeString
                  }}</span>
                </template>
              </state-handler>
            </div>

            <!-- helper link -->
            <a
              v-if="
                docURL && !$store.getters['auth/roles'].includes('DEVELOPER')
              "
              v-tooltip="'Open Documentation link'"
              class="header-helper-link"
              :href="docURL"
              target="_blank"
            >
              <icon icon="book-reader"></icon>
            </a>
          </h1>

          <!-- sub title -->
          <h2>
            <span v-if="subtitleLoading">
              Loading
              <icon size="sm" icon="circle-notch" spin />
            </span>
            <span v-else>{{
              subTitle | abbreviate(longSubTitle ? 10000 : 60)
            }}</span>
          </h2>
        </div>

        <!-- extra name options -->
        <div v-if="showExtraNameOptions" id="page-header-extra-name-options">
          <!-- summary study flag -->
          <slot name="summary-study-flag"></slot>
          <slot name="name-message"></slot>
        </div>
      </div>
    </transition>

    <transition :name="noTransition ? 'none' : 'slide-fade-appear'" appear>
      <div class="header-content delay-7" :key="title">
        <slot></slot>
        <div
          :id="`${id}-widget-actions`"
          class="widget-actions"
          v-if="showActionBtns"
        >
          <!-- Inline Help -->
          <btn
            :id="`${id}-widget-actions-help`"
            v-if="['DEVELOPER', 'ADMINISTRATOR', 'RESEARCHER_ACCRUAL', 'RESEARCHER_TRIAL'].some((role) => role == $store.getters['auth/roles'])"
            v-tooltip="'Open Help Guide'"
            class="widget-action"
            variant="outline-circle"
            :disabled="disabled"
            @click="openHelpGuide"
          >
            <icon icon="question" />
          </btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { startIntro } from "@/services/IntroService";
import Spinner from "@/components/utils/Spinner";

export default {
  name: "page-header",
  components: {
    Spinner,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    title: {
      default: "",
    },
    icon: {
      default: "",
    },
    total: {
      default: undefined,
    },
    hideLogo: {
      default: false,
    },
    hideTotal: {
      type: Boolean,
      default: false,
    },
    local: {
      default: false,
    },
    loading: {
      default: false,
    },
    subtitleLoading: {
      default: false,
    },
    longSubTitle: {
      default: false,
    },
    variant: {
      default: "",
    },
    subTitle: {
      default: "",
    },
    size: {
      default: "",
    },
    padding: {
      default: "",
    },
    headerClass: {
      default: "",
    },
    showActionBtns: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noTransition: {
      type: Boolean,
      default: false,
    },
    showExtraNameOptions: {
      type: Boolean,
      default: false,
    },
    docURL: {
      type: String,
      default: "",
    },
    guidePath: {
      type: String,
      default: "",
    },
    introSteps: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openHelpGuide() {
      if (this.guidePath) {
        this.startIntro([this.guidePath]);
      } else if (this.$h.truthy(this.introSteps)) {
        this.startIntro(this.introSteps);
      }
    },
    startIntro,
  },
};
</script>