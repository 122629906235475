import toasterActions from "./actions";
import toasterMutations from "./mutations";
import toasterGetters from "./getters";

export default {
  namespaced: true,

  state: {
    open: true,
    message: "",
    icon: "chart-bar",
    variant: "trials",
  },

  getters: toasterGetters,

  mutations: toasterMutations,

  actions: toasterActions,
};
