<template>
  <div :class="{ loading }">
    <multiselect
      class="multiselect"
      :class="[error, invalid, empty, `options-${options.length}`]"
      :value="value"
      :options="options"
      v-bind="[$attrs]"
      :loading="loading"
      :disabled="error || disabled"
      :placeholder="activePlaceholder"
      selectLabel
      selectedLabel
      deselectLabel
      tag-position="bottom"
      :label="textField"
      :trackBy="valueField"
      :limit="10000"
      :options-limit="10000"
      @input="onInput"
      @tag="onTag"
      @open="onOpen"
      @close="(value, id) => $emit('close', (value, id))"
      @search-change="dSearchChange"
    >
      <template #caret v-if="!options.length">
        <div></div>
      </template>
      <template v-slot:option="props" v-if="optionSlot !== undefined">
        <span v-html="optionSlot(props)"></span>
      </template>
      <template #noResult v-if="noResultText !== undefined">
        <span v-html="noResultText"></span>
      </template>
    </multiselect>

    <span class="text-helper" v-if="showWarning">
      <icon class="icon-blue" icon="info-circle"></icon>
      This disease is being used by another filter</span
    >
    <button
      class="select-all-button"
      :disabled="loading"
      v-if="showSelectAll"
      @click.prevent="selectAll"
    >
      Select All {{ itemLabel }}
    </button>
    <button
      class="select-all-button"
      :disabled="loading"
      v-if="showSelectAll && $h.truthy(value)"
      @click.prevent="clear"
    >
      Clear
    </button>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import debounce from "lodash/debounce";
import countBy from "lodash/countBy";

export default {
  name: "select-field",
  components: { Multiselect },
  model: {
    event: "change",
  },
  props: {
    value: {},
    placeholder: {
      type: String,
    },
    textField: {
      type: String,
      default: "label",
    },
    valueField: {
      type: String,
      default: "value",
    },
    disabled: {
      type: Boolean,
    },
    state: {
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: String,
    },
    error: {
      type: String,
      default: "",
    },
    addTag: {
      type: Function,
      default: (tag) => ({ label: tag, value: tag }),
    },
    searchChange: {
      type: Function,
      default: () => {},
    },
    optionSlot: {
      type: Function,
    },
    noResultText: {
      type: String,
    },
    clearBtn: {
      type: Boolean,
      default: true,
    },
    isLeadDisease: {
      type: Boolean,
    },
  },
  data() {
    return {
      dSearchChange: null,
    };
  },
  computed: {
    activePlaceholder() {
      let placeholder = this.placeholder;

      if (this.loading) {
        placeholder = "Loading..";
      } else if (this.error) {
        placeholder = this.error;
      }

      return placeholder;
    },
    invalid() {
      return this.state === false ? "invalid" : "";
    },
    empty() {
      return !this.$h.truthy(this.value) ? "empty" : "";
    },
    id() {
      return this.$attrs.id;
    },
    showWarning() {
      // if (!this.$h.truthy(this.value)) {
      //   return false;
      // } else if (this.id === "multiform-form-maintype-input") {
      //   let form = this.$store.getters["form/getForm"]("studies-filters");
      //   if (this.isLeadDisease) {
      //     return false;
      //   } else {
      //     if (this.$h.truthy(form["lead-disease"])) {
      //       console.log(form);
      //     } else {
      //       return false;
      //     }
      //   }
      // } else {
      //   return false;
      // }
      return false;
    },
  },
  created() {
    this.dSearchChange = debounce(this.searchChanged, 250);
  },
  methods: {
    onInput(option) {
      this.$emit("change", option);
    },
    selectAll() {
      this.$emit("change", this.options);
    },
    clear() {
      this.$emit("change", []);
    },
    onTag(tag) {
      this.onInput(this.addTag(tag));
    },
    onOpen(id) {
      this.$emit("open", id);
    },
    searchChanged(partial) {
      this.$emit("update", partial);
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/component";

.text-helper {
  font-size: 0.85rem;
  margin-top: 0.5rem;
  font-weight: $thin;
}
.multiselect {
  .multiselect__tags,
  .multiselect__single,
  .multiselect__spinner {
    color: $text;
    background-color: $text-input-bg;
    border-color: $text-border-color !important;
    font-weight: $thin;
    border-radius: $border-radius-sm;

    input::placeholder {
      color: $text-placeholder-color;
    }
    :-ms-input-placeholder.form-control {
      color: $text-placeholder-color;
    }
    input::-ms-input-placeholder {
      color: $text-placeholder-color;
    }
  }
  .multiselect__content-wrapper {
    border-radius: $border-radius-sm;
    border-color: $link !important;
  }

  .multiselect__tag {
    border-radius: $border-radius-sm;
    color: $text;
    background-color: $n1;
    border-color: $n3;
  }

  .multiselect__placeholder {
    font-size: 1rem;
    font-weight: $thin;
    color: $text-placeholder-color;
  }

  // option
  .multiselect__option {
    font-size: 0.9rem;
    font-weight: $thin !important;
    color: $text-light;
  }
  .multiselect__option.multiselect__option--selected {
    background-color: $n1;
    color: $link;
    font-weight: $bold !important;
  }
  .multiselect__element:hover .multiselect__option {
    background-color: $n1;
  }
  .multiselect__option.multiselect__option--highlight {
    background-color: white;
  }

  // open
  &.multiselect--active {
    border-radius: $border-radius-sm;
    .multiselect__tags,
    .multiselect__single {
      background-color: white;
      border-bottom-color: $n3;
    }
  }

  // above
  .multiselect__content-wrapper {
    margin-top: 0;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &.multiselect--above {
    .multiselect__content-wrapper {
      margin-bottom: 1rem;
      border-radius: $border-radius-sm;
      border-bottom: solid thin $link;
    }
  }
  .multiselect__spinner {
    border-color: transparent !important;
    background-color: transparent !important;
  }

  // empty
  &.empty {
    .multiselect__tags,
    .multiselect__single,
    .multiselect__spinner {
      color: $text-placeholder-color;
    }
  }

  // open and no options
  &.options-0.multiselect--active {
    .multiselect__content-wrapper {
      opacity: 0;
    }
  }

  // invalid
  &.invalid {
    .multiselect__tags {
      border-color: $danger !important;
    }
  }
}
.icon-blue {
  color: $link;
}
.select-all-button {
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: 0.5rem;
  color: $link;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.loading {
  .multiselect__content {
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: fade-out(white, 0.5);
    }
  }
}
</style>