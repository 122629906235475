export default  {
    dataViewOpen: (state) => state.dataView,
    dataSettingsOpen: (state) => state.dataSettings,
    formSummaryOpen: (state) => state.formSummary,
    exportOpen: (state) => state.export,
    featureOpen: (state) => state.feature,

    exportModel: (state) => state.exportModel,
    formSummaryValues: (state) => state.formSummaryValues,
    formSummaryVariant: (state) => state.formSummaryVariant,
    formSummarySchema: (state) => state.formSummarySchema,
  };