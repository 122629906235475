<template>
  <div>
    <div class="disease-maintype-indicator-backdrop" v-if="dataSettingsPopoverShow" @click.self="onClickOutside"></div>
    <button :id="`${id}`" class="btn btn-outline header-dropdown" type="button" @click="openDataSettingsPopover" :disabled="isIndicatorInOpenSummary()"><icon icon="cogs"></icon> 
      <!-- 'h-text-md' makes DiseaseMaintypeIndicator responsive and only shows the gear icon when screen size is small -->
      <span :class="isIndicatorInDataViewHeader()? 'h-text-md' : ''" >Disease Group: {{ useOriginalDiseases ? "Search" : "Portfolio" }}</span>
    </button>
    <popover :target="`${id}`" :show.sync="dataSettingsPopoverShow" triggers="click" placement="bottom">
        <!-- disease maintype indicator is only a visual indicator in open summary and should not trigger the data settings -->
        <data-settings-popover v-if="!isIndicatorInOpenSummary()" @close-data-settings="closeDataSettingsPopover"></data-settings-popover>
      </popover>
  </div>
</template>

<script>
import DataSettingsPopover from '@/components/presentation/data-view/utils/DataSettingsPopover.vue';
import Popover from "bootstrap-vue/es/components/popover/popover";


export default {
  name: "disease-maintype-indicator",
  components: { DataSettingsPopover, Popover },
  props:[
    "id",
  ],
  data() {
    return {
      options: {},
      dataSettingsPopoverShow: false,
    };
  },
  computed:{
    useOriginalDiseases(){
      return this.$store.getters['settings/useOriginalDiseases'];
    }
  },
  methods: {
    onClickOutside() {
      this.dataSettingsPopoverShow = false;
    },
    openDataSettingsPopover(){
      this.dataSettingsPopoverShow = true;
    },
    closeDataSettingsPopover(){
      this.dataSettingsPopoverShow = false;
    },
    isIndicatorInOpenSummary(){
      return this.id === "open-summary-disease-maintype-indicator";
    },
    isIndicatorInDataViewHeader(){
      return this.id === "data-view-header-disease-maintype-indicator";
    }
  },
  watch: {

  },
};
</script>

<style lang="scss">
#open-summary-disease-maintype-indicator{
  opacity: 1;
}

.disease-maintype-indicator-backdrop{
  position: absolute;
  width: 140vw;
  height: 120vh;
  top: -20%;
  left: -40%;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 1060;
}
</style>
