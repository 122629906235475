import { Options } from '../utils'

export default [
	// ------------ current study status group ------------
  {
    type: 'checkbox-field',
    id: 'current_trial_status_group',
    default: [],
    group: {
      label: '',
    },
    input: {
      textField: 'label',
      valueField: 'value',
      options: Options.getOptions('current_trial_status_groups'),
    },
		mounted(model, fields, reset, update) {
			update('current_trial_status', 'disabled', this.value.length !== 0)
		},
		changed(model, fields, reset, update, updateValue) {
			update('current_trial_status', 'disabled', this.value.length !== 0)
			if (this.value.length !== 0) {
				updateValue('current_trial_status', getValue(this.value), false)
			}
			else {
				reset(fields[1])
			}
		}
  },
  // ------------ current study status ------------
  {
    type: 'select-field',
    id: 'current_trial_status',
    value: undefined,
    group: {
      label: '',
    },
    input: {
      multiple: true,
      placeholder: 'Select one or more current study statuses',
      closeOnSelect: false,
			preserveSearch: false,
      maxHeight: 420,
      options: Options.getOptions('current_trial_status'),
    },
  },
]


function getValue(input) {
	let value = []
	input.forEach(group => {
		value = value.concat({
			not_open: getItems(["Not Yet Recruiting"]),
			open: getItems([
				"Recruiting",
				"Suspended",
				"Enrolling by Invitation",
				"Available",
				"Temporarily not available"
			]),
			closed: getItems([ 
				"Active, Not Recruiting"
			]),
			terminal: getItems([          
				"Terminated",
				"Completed",
				"Withdrawn",
				"Approved For Marketing",
				"No Longer Available"
			]),
		}[group])
	})
	return value
}

function getItems(items) {
	let statusOptions = Options.getOptions('current_trial_status')
	return items.map(item => statusOptions.find(v => v.value === item))
}