export default {
  study_source: [
    { show: true, intro: `This is the Studies by Study Source chart. Click on an individual pie wedge (both regular and expanded view) to view a data grid of the records included in that wedge. <br><a style="font-size: .9rem;" href="/documentation/docs/charts/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this icon to show the chart in an expanded view. The expanded view contains buttons that provide screenshot functionality (camera icon) and the option to show the search criteria (funnel icon).' }
  ],
  primary_purpose: [
    { show: true, intro: `This is the Studies by Primary Purpose chart. Click on the down-arrow next to the chart title to toggle the chart to the Studies by Current Study Status chart. Click on an individual bar (both regular and expanded view) to view a data grid of the records included in that bar. <br><a style="font-size: .9rem;" href="/documentation/docs/charts/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this icon to show the chart in an expanded view. The expanded view contains buttons that provide screenshot functionality (camera icon) and the option to show the search criteria (funnel icon)..' }
  ],
  current_trial_status: [
    { show: true, intro: `This is the Studies by Current Study Status chart. Click on an individual bar (both regular and expanded view) to view a data grid of the records included in that bar. <br><a style="font-size: .9rem;" href="/documentation/docs/charts/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this icon to show the chart in an expanded view. The expanded view contains buttons the provide screenshot functionality (camera icon) and the option to show the search criteria (funnel icon).' }
  ],
  phase: [
    { show: true, intro: `This is the Studies by Phase chart. Click on the down-arrow next to the chart title to toggle the chart to the Studies by Study Source chart. Click on an individual pie wedge (both regular and expanded view) to view a data grid of the records included in that wedge. <br><a style="font-size: .9rem;" href="/documentation/docs/charts/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this icon to show the chart in an expanded view. The expanded view contains buttons that provide screenshot functionality (camera icon) and the option to show the search criteria (funnel icon).' }
  ],
  aggregation: [
    { show: true, intro: `This is the Studies Over Time aggregation chart. Click on the 'By Month' and 'Bar Chart' buttons to toggle the time span and type of chart. Note that clicking on the 'Click here to add/remove permutations' area will open a popup window to configure advanced filtering on the chart. <br><a style="font-size: .9rem;" href="/documentation/docs/charts/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this icon to show the chart in an expanded view. The expanded view contains buttons that provide screenshot functionality (camera icon) and the option to show the search criteria (funnel icon).' }
  ]
};