import { truthy } from '@/services/CommonsService'
//import { Options } from '../utils'

let ageOptions = [
  { label: 'Years', value: 'years' },
  { label: 'Months', value: 'months' },
  { label: 'Days', value: 'days' },
  { label: 'Hours', value: 'hours' },
  { label: 'Minutes', value: 'minutes' }
]

export function getAgeSchema () {
  return [
    /* min age */
    {
      type: 'text-field',
      id: 'min-age-input',
      group: { label: 'Min >= Age:' },
      input: {
        autocomplete: 'off',
        placeholder: '0',
        type: 'number'
      },
      default: 0,
      //validations: [Validations.required],
      mounted (model, fields) {
        const minAgeSelect = getField(fields, 'min-age-selector')
        minAgeSelect.state.active = truthy(this.value)
      },
      isActive () {
        return false
      }
    },
    {
      type: 'select-field',
      id: 'min-age-selector',
      default: { label: 'Years', value: 'years' },
      group: {},
      input: {
        allowEmpty: false,
        closeOnSelect: true,
        preserveSearch: false,
        options: ageOptions
      },
      isActive () {
        return false
      }
    },
    /* max age */
    {
      type: 'text-field',
      id: 'max-age-input',
      group: { label: 'Max <= Age:' },
      input: {
        autocomplete: 'off',
        placeholder: '999',
        type: 'number'
      },
      default: 999,
      //validations: [Validations.required],
      mounted (model, fields) {
        const minAgeInput = getField(fields, 'max-age-input')
        minAgeInput.state.active = truthy(this.value)
      },
      isActive () {
        return false
      }
    },
    {
      type: 'select-field',
      id: 'max-age-selector',
      default: { label: 'Years', value: 'years' },
      group: {},
      input: {
        allowEmpty: false,
        closeOnSelect: true,
        preserveSearch: false,
        options: ageOptions
      },
      isActive () {
        return false
      }
    }
  ]
}

function getField (fields, id) {
  return fields.find(field => field.id === id)
}

export function convertInYears (value, currentUnit) {
  if (currentUnit === 'days') {
    return value / 365
  } else if (currentUnit === 'months') {
    return value / 12
  } else if (currentUnit === 'hours') {
    return value / (365 * 24)
  } else if (currentUnit === 'minutes') {
    return value / (365 * 24 * 60)
  } else {
    return value
  }
}

export function validateAge(values) {
  if (!truthy(values)) {
    return 'valid'
  }

  let minAgeInYears = convertInYears(
    values['min-age-input'],
    values['min-age-selector'].value
  )
  let maxAgeInYears = convertInYears(
    values['max-age-input'],
    values['max-age-selector'].value
  )
  if (
    minAgeInYears < 0 ||
    maxAgeInYears > 999 ||
    minAgeInYears - maxAgeInYears > 0
  ) {
    values['min-age-input'] = 0
    values['max-age-input'] = 999
    values['min-age-selector'] = { label: 'Years', value: 'year' }
    values['max-age-selector'] = { label: 'Years', value: 'year' }
    return false
  } else if (minAgeInYears == 0 && maxAgeInYears == 999) {
    return 'default'
  }
  return 'valid'
}


// This method converts the age input value in years and builds the returnObject which is the params for the API request
// Add functions to get filter input values for Age
export function getAgeValueObj(returnObject, values) {
 if (validateAge(values) === "valid") {
   if (truthy(values["min-age-input"])) {
      returnObject["min_age_in_years_gte"] = convertInYears(
        values["min-age-input"],
        values["min-age-selector"].value
      );
    }
    if (truthy(values["max-age-input"])) {
      returnObject["max_age_in_years_lte"] = convertInYears(
        values["max-age-input"],
        values["max-age-selector"].value
      );
    }
  }
}

export function showAge(values, minOrMax) {
  if (!truthy(values)) {
    return false;
  }

  if (minOrMax === 'min' && values['min-age-input'] == 0) {
    return false;
  }

  if (minOrMax === 'max' && values['max-age-input'] == 999) {
    return false;
  }

  return true;
}
