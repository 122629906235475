import Btn from 'bootstrap-vue/es/components/button/button'
import TextInput from 'bootstrap-vue/es/components/form-input/form-input'
import Card from 'bootstrap-vue/es/components/card/card'

import StateHandler from '@/components/presentation/state-handler/StateHandler2'
import LoadingBar from '@/components/utils/LoadingBar'

import AsyncDataWrapper from '@/components/data/AsyncDataWrapper'
import LocalDataWrapper from '@/components/data/LocalDataWrapper'


export default {
  AsyncDataWrapper,
  LocalDataWrapper,
  StateHandler,
  LoadingBar,
  TextInput,
  Card,
  Btn,
}
