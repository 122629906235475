export default {
  data() {
    return {
      PaginationMixin_page: 0,
      PaginationMixin_size: 20,
      PaginationMixin_total: 0,
    }
  },
  computed: {
    $pageState() {
      let page, size;
      if (this.preservePagination) { 
        page = this.$store.state[this.id + 'Page'].currentPage;
        size = this.$store.state[this.id + 'Page'].size;
      } else {
        page = this.PaginationMixin_page;
        size = this.PaginationMixin_size;
      }
      return {
        page: page,
        size: size,
        total: this.PaginationMixin_total,
      }
    }
  },
  methods: {
    $getPagination() {
      //const page = this.PaginationMixin_page
      const page = this.$pageState.page
      const size = this.$pageState.size
      const from = page * size

      return { size, from }
    },
    $pageChange(page) {
      if (this.preservePagination) { 
        this.$store.commit('setCurrentPage', {
          id: this.id + 'Page',
          page: page
        });
      } else {
        this.PaginationMixin_page = page
      }
    },
    $pageSizeChange(size) {
      if (this.preservePagination) { 
        this.$store.commit('setPageSize', {
          id: this.id + 'Page',
          size: size
        });
      } else {
        this.PaginationMixin_size = size
      }
      this.$pageChange(0)
    },
    $setPaginationTotal(total) {
      this.PaginationMixin_total = total
    },
  }
}
