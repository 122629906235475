<template>
  <component
    :is="`${local ? 'local' : 'async'}-data-wrapper`"
    v-bind="wrapperProps"
    @resolve="onResolve"
    @reject="$emit('reject')"
  >
    <template v-slot="{ _state, _reload }">
      <div class="page data-view" ref="view" :class="dataViewClass" :key="updateDataViewKey">
        <!-- header -->
        <data-view-header
          ref="header"
          v-if="!noHeader"
          v-bind="$props"
          :title="title"
          :activeTabs="activeTabs"
          :activeTabIcons="activeTabIcons"
          :activeTab="tab"
          :headers="headers"
          :pageState="$pageState"
          :exporting="exporting"
          :bindValues="bindValues"
          :bindFields="bindFields"
          :bindParams="bindParams"
          :hideTabs="hideTabs"
          :loading="_state.loading"
          :activeFields="activeFields"
          :filtersActive.sync="filtersActive"
          :filtersOpen.sync="filtersOpen"
          :showParticiantCount="showParticiantCount"
          @toggleColumn="toggleColumn"
          @download="download"
          @changeTab="(newTab) => $emit('update:tab', newTab)"
          @updateKeyword="updateKeyword"
          @goToParticipants="$emit('goToParticipants')"
          @open-my-filters="applyFiltersOpen = true"
        >
          <slot name="extra-actions"></slot>
        </data-view-header>

        <filters
          ref="filters"
          :originalBindFields = "bindFields"
          :open.sync="filtersOpen"
          phase
          :bindValues.sync="bindValues"
          :bindFields.sync="bindFields"
          :bindParams.sync="bindParams"
          :bindActiveFilter.sync="activeFilter"
          :preservePagination="preservePagination"
          :validateRegistrationDateValueIsNotNull="validateRegistrationDateValueIsNotNull"
          @filtersSubmit="filtersSubmit"
          :context.sync="context"
          v-bind="{
            id,
            variant,
            headers,
            title,
            icon,
            schema,
            resource,
            noPersist,
            initialValues,
            defaultValues,
            query,
            activeFields,
            'show-action-btns': showFilterActionBtns,
            'intro-configs': filterIntroConfigs,
          }"
        ></filters>
        
        <apply-filters-modal
          v-model="applyFiltersOpen"
          :variant="variant"
          :schema="schema"
          :resource="resource"
          :active-filter="activeFilter"
          @applyFilterFlag="setApplyFilterFlag"
          @submit="setActiveFilter"
        />

        <modal
          class="modal-no-padding too-long-modal"
          style="z-index: 10000"
          size="sm"
          hide-header
          hide-footer
          v-model="queryLengthErrorOpen"
        >
          <error
            size="logout"
            variant="warning"
            icon="exclamation-triangle"
            class="session-warning-error"
            :title="`Too many active filters!`"
            error="Please reduce the number of active filters"
            no-links
          ></error>
          <btn
            class="mt-3"
            variant="warning"
            @click="queryLengthErrorOpen = false"
          >
            Close
          </btn>
        </modal>

        <div v-if="$route.name === 'participants'">
          <form-summary-bar
            :title="title"
            :fields="activeFields.filter((f) => f.study !== true)"
            :variant="'participants'"
            :values="bindValues"
            :bindFields="bindFields"
            :activeFilter="activeFilter"
            :open="
              barMessage !== '' ||
              (activeFields.filter((f) => f.study !== true).length && !noBar)
            "
            clearText="Clear Participant Filters"
            @resetFilters="resetParticipantOrStudyFilters(true)"
          >
            <span v-if="barMessage !== ''">
              <icon icon="info-circle" class="mr-1"></icon>
              {{ barMessage }}
            </span>
          </form-summary-bar>
          <form-summary-bar
            :title="title"
            :fields="activeFields.filter((f) => f.study === true)"
            :variant="'studies'"
            clearText="Clear Study Filters"
            :values="bindValues"
            :open="
              barMessage !== '' ||
              (activeFields.filter((f) => f.study === true).length && !noBar)
            "
            @resetFilters="resetParticipantOrStudyFilters(false)"
          >
            <span v-if="barMessage !== ''">
              <icon icon="info-circle" class="mr-1"></icon>
              {{ barMessage }}
            </span>
          </form-summary-bar>
        </div>

        <form-summary-bar
          v-else
          :title="title"
          :fields="activeFields"
          :variant="barMessage !== '' ? 'info warn' : variant"
          :values="bindValues"
          :activeFilter="activeFilter"
          :open="barMessage !== '' || (checkForDisplay(activeFields) && !noBar)"
          @resetFilters="resetFilters()"
        >
          <span v-if="barMessage !== ''">
            <icon icon="info-circle" class="mr-1"></icon>
            {{ barMessage }}
          </span>
        </form-summary-bar>

        <transition mode="out-in" name="slide-down">
          <div class="data-view-export-banner" v-if="showExportBanner">
            <icon class="mr-2" icon="check"></icon>Data Export for:
            <b v-text="exportBannerName"></b> has been initiated. The export
            file will be available to download by clicking on the download icon
            ( <icon icon="download"></icon>) in the header when ready
            <btn class="close" variant="icon" @click="showExportBanner = false">
              <icon icon="times"></icon>
            </btn>
          </div>
        </transition>

        <!-- body -->
        <tabs
          :class="`data-view-body bar-active-${filtersActive || barMessage !== ''} no-bar-${noBar}`"
          ref="body"
          nav-wrapper-class="no-nav"
          :value="tab"
        >
          <!-- grid view -->
          <tab title="Grid" :class="`view view-${id} grid`">
            <!-- body -->
            <div class="grid-body">
              <!-- local grid -->
              <grid
                v-if="local"
                :class="`allow-x-overflow`"
                ref="grid"
                :data="$h.get(_state, 'response', [])"
                :context="gridParams"
                :title="title"
                :loading="_state.loading"
                :headers="headers"
                auto-height
                resize-on-change
                :resize-on-mount="!noResizeOnMount"
                :asyncSort="true"
                @rowClicked="rowClicked"
                @sort="sort"
              ></grid>

              <!-- async grid -->
              <state-handler
                keep-response-alive
                no-transition
                v-bind="_state"
                @reload="_reload"
                ignore-loading
                v-else-if="!disabledDataView"
              >
                <template #response>
                  <grid
                    :class="`allow-x-overflow`"
                    ref="grid"
                    :data="$h.get(_state, 'response.data', [])"
                    :context="gridParams"
                    :headers="headers"
                    :variant="variant"
                    :loading="_state.loading || extraLoading"
                    :title="title"
                    auto-height
                    async-sort
                    resize-on-change
                    @rowClicked="(row) => $emit('rowClicked', row)"
                    @sort="sort"
                  ></grid>
                </template>
              </state-handler>

              <container-warning v-else></container-warning>
            </div>

            <!-- pagination -->
            <div
              class="pagination-controls"
              v-if="!_state.error && tab === 0 && !filtersOpen && !noPagination"
            >
              <page-size-controller
                class="page-size"
                v-bind="$pageState"
                :key="`page-size-${_state.loading}`"
                @pageSizeChange="$pageSizeChange"
              ></page-size-controller>
              <pagination-controller
                class="page-number"
                v-bind="$pageState"
                :limit="paginationLimit"
                :key="`page-${_state.loading}`"
                @pageChange="$pageChange"
              ></pagination-controller>
              <div class="total-limit-warning" v-if="$pageState.total > 50000">
                <icon icon="exclamation-triangle"></icon>Only showing first
                50,000 results
              </div>
            </div>
          </tab>

          <!-- other views -->
          <tab
            :title="tabView"
            v-for="tabView in tabs"
            :key="tabView"
            :class="`view view-${id} ${tabView}`"
          >
            <slot v-if="tab !== 0" :name="`${tabView}`" tabView></slot>
          </tab>
        </tabs>
      </div>
    </template>
  </component>
</template>

<script>
import PaginationMixin from "@/mixins/PaginationMixin";
import { getExportQuery, getLocalData } from "@/services/DownloadService";
import { ExportToCsv } from "export-to-csv";

import DataViewHeader from "./DataViewHeader";
import Grid from "@/components/presentation/grid/Grid";
import Tabs from "bootstrap-vue/es/components/tabs/tabs";
import Modal from "bootstrap-vue/es/components/modal/modal";
import Tab from "bootstrap-vue/es/components/tabs/tab";
import PaginationController from "@/components/data/controls/PaginationController";
import PageSizeController from "@/components/data/controls/PageSizeController";
import FormSummaryBar from "@/components/form/summary/FormSummaryBar";
import ContainerWarning from "@/views/utils/ContainerWarning";
import Filters from "@/components/presentation/data-view/DataViewFilters";
import { EventBus } from "@/event-bus.js";
import ApplyFiltersModal from "@/components/presentation/data-view/utils/ApplyFiltersModal";
import Error from "@/components/presentation/state-handler/DefaultError";
import { stringify } from "querystring";
import { truthy } from "@/services/CommonsService";
import StudyKeys from "@/constants/StudyKeys.js";
import { hasSECFilterValues, containsECFilter } from "../../form/utils/EligibilityCriteria";
import { showAge } from "../../form/schemas/AgeSchema";

export default {
  name: "data-view",
  components: {
    ApplyFiltersModal,
    Filters,
    FormSummaryBar,
    DataViewHeader,
    Modal,
    Tabs,
    Tab,
    Grid,
    Error,
    PaginationController,
    PageSizeController,
    ContainerWarning,
  },
  mixins: [PaginationMixin],
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    variant: {
      type: String,
      default: "dark",
    },
    resource: {
      type: String,
      default: "",
    },
    barMessage: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    schema: {
      type: Array,
      default: () => [],
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    initialValues: {
      type: Object,
      default: () => ({}),
    },
    defaultValues: {
      type: String,
    },
    noColumnToggle: {
      type: Boolean,
      default: false,
    },
    appliedLDFilter: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
    noKeyword: {
      type: Boolean,
      default: false,
    },
    noSorting: {
      type: Boolean,
      default: false,
    },
    extraLoading: {
      type: Boolean,
      default: false,
    },
    canFilter: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    showKeywordSearch: {
      type: Boolean,
      default: true,
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
    paginationLimit: {
      type: Number,
    },
    local: {
      type: Boolean,
      default: false,
    },
    noName: {
      type: Boolean,
      default: false,
    },
    tabFilters: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Array,
      default: () => [],
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    tabIcons: {
      type: Array,
      default: () => [],
    },
    keywordFields: {
      type: Array,
      default: () => [],
    },
    bindActiveFields: {
      type: Array,
      default: () => [],
    },
    noPersist: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    noBar: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      default: 0,
    },
    persist: {
      type: Boolean,
      default: false,
    },
    headerSize: {
      type: String,
      default: "",
    },
    headerSubTitle: {
      type: String,
    },
    noResizeOnMount: {
      type: Boolean,
      default: false,
    },
    rowHover: {
      type: Boolean,
      default: false,
    },
    exportTitle: {
      type: String,
      default: "",
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "page",
    },
    showExtraNameOptions: {
      type: Boolean,
      default: false,
    },
    nameSlot: {
      type: String,
      default: "summary-study-flag",
    },
    showActionBtns: {
      type: Boolean,
      default: false,
    },
    showFilterActionBtns: {
      type: Boolean,
      default: false,
    },
    introConfigs: {
      type: Array,
      default: () => [],
    },
    filterIntroConfigs: {
      type: Array,
      default: () => [],
    },
    context: {
      type: String,
      default: "global",
    },
    showParticiantCount: Boolean,
    preservePagination: Boolean,
  },
  data() {
    return {
      activeFilter: {},
      bindValues: {},
      bindParams: {},
      bindFields: [],
      activeModel: [],
      sortModel: {
        sort: "",
        order: "",
      },
      activeTab: 0,
      exporting: false,
      filtersOpen: false,
      applyFiltersOpen: false,
      queryLengthErrorOpen: false,
      filtersActive: false,
      showExportBanner: false,
      exportBannerName: "",
      activeWrapperProps: {},
      downloadActiveQuery: {},
      initialLoad: false,
      updateDataViewKey: 0,
    };
  },
  mounted() {
    // Listen to the event.
    EventBus.$on("export-started", (name) => {
      this.showExportBanner = true;
      this.exportBannerName = name;
    });
    EventBus.$on("dataview-reload", () => {
      this.reloadDataViewComponent();
    });
  },
  computed: {
    activeQuery() {
      let query = this.$h.cloneDeep(this.query);
      let params = this.$h.cloneDeep(this.bindParams);
      let sort = this.$h.cloneDeep(this.sortModel);
      let pagination = this.noPagination ? {} : this.$getPagination();
      return {
        ...query,
        params: { ...params, ...query.params, ...pagination, ...sort },
      };
    },
    headerQuery() {
      let query = this.$h.cloneDeep(this.activeQuery);
      let include = this.headers.map((header) => header.field);
      if (this.id == "studies") {
        include.push("grants", "min_age", "max_age");
      }
      return {
        ...query,
        params: { ...query.params, include },
      };
    },
    gridParams() {
      let query = this.$h.cloneDeep(this.query);
      let params = this.$h.cloneDeep(this.bindParams);
      return { ...query.params, ...params };
    },

    // data wrapper props
    wrapperProps() {
      if (this.local) {
        return {
          model: this.model,
          keywordFields: this.keywordFields,
          query: this.activeQuery,
        };
      } else {
        return {
          resource: this.resource,
          query: this.headerQuery,
          debounce: 20,
        };
      }
    },
    activeTabs() {
      return ["Grid"].concat(this.tabs);
    },
    activeTabIcons() {
      return ["th-list"].concat(this.tabIcons);
    },
    activeFields() {
      return this.$h.cloneDeep(this.bindFields).filter((field) => {
        if (!field || !field.state) {
          return false;
        }
        // if keyword is empty or undefined, then make filters inactive to not show in summary bar
        if (field.id === "_keyword_form" && !field.value?._keyword) {
          field.state = {}
        }
        let isActiveField = field.state.active || truthy(field.value) ? true : false;
        // Need to check Registration Date since it is a special field that always has non-empty field.value
        isActiveField = field.id != "p-date-range" ? isActiveField : this.validateRegistrationDateValueIsNotNull(field);

        // Need to check Dates fields since it is a special field that always has non-empty field.value
        isActiveField = field.id != "date-range" ? isActiveField : this.validateDateValueIsNotNull(field);

        // Need to check Record Verification Date fields since it is a special field that always has non-empty field.value
        isActiveField = field.id != "registration-date-range" ? isActiveField : this.validateRecordVerfDateValueIsNotNull(field);
        
        // Need to check Investigator field since that field sometimes has non-empty field.value
        isActiveField = field.id != "investigator" ? isActiveField : this.validateInvestigatorValueIsNotNull(field);

        // Need to check Organization field, since that field sometimes has non-empty field.value
        isActiveField = field.id != "organization" ? isActiveField : this.validateOrganizationValueIsNotNull(field);
        
        let isNotMultipleLeadDisease = true;
        if (field.id === "lead-disease") {
          isNotMultipleLeadDisease =
            (field.value && Array.isArray(field.value))  &&
            field.value.filter(
              (item) => item.maintype !== "Multiple Lead Diseases" && item.maintype !== "None"
            ).length > 0;
        }
        return isNotMultipleLeadDisease && isActiveField;
      });
    },

    dataViewClass() {
      return `tab-${this.activeTabs[this.tab]} filters-${
        this.filtersOpen
      } noHeader-${this.noHeader} rowHover-${this.rowHover} noSorting-${
        this.noSorting
      } height-${this.height} auto-height-${this.autoHeight}`;
    },
    disabledDataView() {
      return (
        this.resource === "participants" &&
        this.$store.getters["settings/containerEnabled"]
      );
    }
  },
  methods: {
    reloadDataViewComponent(){
      this.updateDataViewKey += 1;
    },
    rowClicked(row) {
      const dataKeys = Object.keys(row.data);
      // If this event is called due to a row click on the Grid component from the "Participants by Disease" modal, then fire this event
      // to bypass the usual behavior of page refresh with new data to the specific page
      if (dataKeys.length === 3 && dataKeys.includes("key") && dataKeys.includes("doc_count") && dataKeys.includes("evs_id")) {
        EventBus.$emit("participant-disease-selected-from-modal", row.data);
      } else {
        this.$emit("rowClicked", row);
      }
    },
    localResolve(model) {
      this.activeModel = this.$h.cloneDeep(model);
      this.$setPaginationTotal(this.activeModel.length);
      this.$emit("updateModel", model);
    },
    asyncResolve(params) {
      this.$setPaginationTotal(params.total);
      this.$emit("resolve", params);
    },
    onResolve(payload) {
      this.local ? this.localResolve(payload) : this.asyncResolve(payload);
    },
    filtersSubmit(params) {
      this.$emit("update:bindFields", this.$h.cloneDeep(this.bindFields));
      this.$emit("update:bindValues", this.$h.cloneDeep(this.bindValues));
      this.$emit("update:bindParams", this.$h.cloneDeep(this.bindParams));
      this.$emit(
        "update:bindActiveFields",
        this.$h.cloneDeep(this.activeFields)
      );
      this.$emit("filtersSubmit", this.bindParams);
      if (this.$refs["header"]) {
        // Any keyword filter applied is also shown in the grid view filter textbox on each entity page
        if (this.id === "studies" && this.bindValues._keyword_form && this.bindValues._keyword_form._keyword) {
          this.$refs["header"].filtersSubmit(this.bindValues._keyword_form._keyword);
        } else {
          this.$refs["header"].filtersSubmit(this.bindValues._keyword);
        }
      }

      if (!this.local) {
        this.queryLengthErrorOpen =
          stringify(filterParams(this.headerQuery.params)).length >= 4050;
      }

      if (!this.preservePagination || this.initialLoad) {
        this.$pageChange(0);
      } else {
        this.initialLoad = true;
      }
    },
    sort(sortModel) {
      this.sortModel = sortModel;
    },
    toggleFilters(flag) {
      this.filtersOpen = flag;
    },
    toggleColumn(header) {
      header.hide = !header.hide;
      if (this.$refs.grid) {
        this.$refs.grid.toggleColumnVisible(header.field);
      }
    },
    stretchColumns() {
      if (this.$refs.grid) {
        this.$refs.grid.stretchColumns();
      }
    },
    download(params) {
      this.$root.$emit("bv::hide::popover", `${this.id}-export`);
      if (this.local) {
        this.exporting = true;
        this.localDownload(params);
      } else {
        this.asyncDownload(params);
        this.exporting = false;
      }
    },
    localDownload(params) {
      if (this.activeModel.length) {
        const csvExporter = new ExportToCsv({
          filename: params.name,
          title: params.name,
          showLabels: true,
          headers: this.headers.map((h) => h.headerName),
        });
        csvExporter.generateCsv(
          getLocalData(this.$h.cloneDeep(this.activeModel), this.headers)
        );
        this.$store.dispatch("toaster/start", {
          message: "Complete! Check your Downloads",
          variant: "success",
          icon: "check",
        });
      }
      this.exporting = false;
    },
    asyncDownload(params) {
      this.exporting = true;

      let query = this.$h.cloneDeep(this.query);
      let bindParams = this.$h.cloneDeep(this.bindParams);
      let activeQuery = {
        ...query,
        params: { ...bindParams, ...query.params, size: 500000 },
      };
      this.$store.dispatch("modal/openExport", {
        title: this.title,
        query: getExportQuery(
          activeQuery,
          this.resource,
          params,
          this.headers,
          this.exportTitle
        ),
      });
    },
    modelChange(model = []) {
      this.activeModel = model;
      this.$emit("update:activeModel", model);
      this.$setPaginationTotal(model.length);
      this.$pageChange(0);
    },
    updateKeyword(word) {
      if (this.$refs["filters"]) {
        if (this.id === "studies") {
          // Set _keyword_form._keyword value from grid view filter textbox on Studies page
          // Current keyword_search_type value will be retained unless the keyword has been deleted from the grid view filter textbox
          // See updateValue method in src/components/form/FormBuilder.vue
          this.$refs["filters"].setFormValue("_keyword_form", {_keyword: word, keyword_search_type: []})
        } else {
          // Set _keyword value from grid view filter textbox on all other entity pages
          this.$refs["filters"].setFormValue("_keyword", word)
        }
      }
    },
    resetFilters() {
      if (this.$refs["filters"]) {
        this.$refs["filters"].resetFilters();
      }
      if(this.id === "studies"){
        // When clearing filters on studies page, remove any study filters but keep participants filters
        let model = this.$store.getters["form/getForm"]("participants-filters");
        if(model !== undefined){
          let newModel = {};
          // save participants filters and remove study filters
          Object.keys(model).forEach((key) => {
            // if the key doesnt exist in studyKeys, then it is a participant filter and should be saved
            if (!StudyKeys.studyKeys.includes(key) || key==="_keyword") {
              newModel[key] = model[key];
            }
          });

          // update participants-filters form in the store
          this.$store.dispatch("form/saveForm", {
            id: "participants-filters",
            model: newModel,
          });
        }
      }
    },
    resetParticipantOrStudyFilters(flag) {
      // When clearing filters on studies page, remove any study filters but keep participants filters
      let participantModel = this.$store.getters["form/getForm"]("participants-filters");
      let newModel = {};
      let studyFilters = {}; // used to keep study filters on participants page when clearing panticipants filters
      
      if (flag) {
        /* clear participants filters when flag is true */
        // remove any participants filters from the "participants-filters" form in store but keep studies filters
        Object.keys(participantModel).forEach((key) => {
          // if the key exists in studyKeys, then it is a study filter and should be saved
          // also keep the studies keyword filter 
          if (StudyKeys.studyKeys.includes(key) === true || key === "_keyword_form") {
            studyFilters[key] = participantModel[key];
          }
        });
        // remove participants keyword from filters
        if ("_keyword" in studyFilters) {
          studyFilters["_keyword"] = '';
        }
         // save studies filters on 'participants-filter' in store
          this.$store.dispatch("form/saveForm", {
          id: "participants-filters",
          model: studyFilters,
          });

        // update or save studies filters in store to match studies filters applied on 'participants page'
        this.$store.dispatch("form/saveForm", {
          id: "studies-filters",
          model: studyFilters,
        });
        //participants filters cleared, only submit study filters 
        this.$emit("filtersSubmit", studyFilters);
      }
      else { 
        /* clear study filters when flag is false */

        // remove any study filters from the "participants-filters" form in store but keep participants filters
        Object.keys(participantModel).forEach((key) => {
          // if the key doesnt exist in studyKeys, then it is a participant filter and should be saved
          // also keep the participant keyword filter 
          if (StudyKeys.studyKeys.includes(key)===false || key === "_keyword") {
            newModel[key] = participantModel[key];
          }
        });
        this.$store.dispatch("form/saveForm", {
          id: "participants-filters",
          model: newModel,
        });
        // clear studies-filters in store
        this.$store.dispatch("form/saveForm", {
          id: "studies-filters",
          model: {},
        });
        // study filters cleared, only submit participants filter
        this.$emit("filtersSubmit", newModel);
      }   
      this.$nextTick(() => {
        this.reloadDataViewComponent()
      });
    },
    setApplyFilterFlag(flag) {
      this.$refs.filters.setApplyFilterFlag(flag);
    },

    setActiveFilter(filter) {
      this.$refs.filters.setActiveFilter(filter);
      this.applyFiltersOpen = false;
    },
    validateRegistrationDateValueIsNotNull(field){
      // Registration date (p-date-range) is a special field that always have value as not null. 
      // Check if registration date value is really null, do not display it on the right hand panel if it is.
      if (field.id === "p-date-range") {
        return (
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.p-date-range-key`)
          ) &&
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.p-date-range-from`)
          ) &&
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.p-date-range-to`)
          )
        );
      }else{
        return true;
      }
    },
    checkForDisplay(activeFields) {
      if (activeFields.length != 0) {
        let containECFLtr = containsECFilter(activeFields)
        let containsNOnEC = this.containsNonECFilters(activeFields)
        let activeECFilters = this.containsActiveECFilters(activeFields)

        //there are filters, but absolutely no EC filters
        if (!containECFLtr) return true

        //if there are filters, and has active ONLY NON EC filter. 
        if (containsNOnEC) return true
        
        //ther are filters, and yes active EC filters
        if (containECFLtr && activeECFilters) return true
        
        //there are filters, but with only NON active EC filters
        if (containECFLtr && !activeECFilters) return false
      } 
      return false
    },
    //Checks if there are any ACTIVE Elig Criteria filters
    containsActiveECFilters(activeFields) {
      for (let i = 0; i < activeFields.length; i++) {
        if (truthy(activeFields[i])) {
          let check = activeFields[i].id
          if ((activeFields[i].id === 'study-participant-char-sex' && (truthy(activeFields[i].value['sex_criteria']) && activeFields[i].value['sex_criteria'].length != 0))) return true;
          if ((activeFields[i].id === 'study-participant-char-age') && (showAge(activeFields[i].value, 'min') || showAge(activeFields[i].value, 'max'))) return true;
          if ((activeFields[i].id === 'study-participant-char-priorTherapy') && hasSECFilterValues(activeFields[i].value, activeFields[i].id)) return true;
        }
      }
      return false;
    },
    //Checks if there are any NON Elig Criteria filters
    containsNonECFilters(activeFields) {
      for(let i = 0; i < activeFields.length; i++){
        if (truthy(activeFields[i])) {
          if (((activeFields[i].id !== 'study-participant-char-sex')) && 
            (activeFields[i].id !== 'study-participant-char-age') && 
            (activeFields[i].id !== 'study-participant-char-priorTherapy') )
            return true;
          }
      }
      return false;
    },
    validateDateValueIsNotNull(field){
      // Date (date-range) is a special field that always has values as not null. 
      // Check if date field values are really null
      if (field.id === "date-range") {
        let key = field.value["date-range-key"]
        let dateFrom = field.value["date-range-from"]
        let dateTo = field.value["date-range-to"]
        return truthy(key) && truthy(dateFrom) && truthy(dateTo)
      }else{
        return true;
      }
    },
    validateRecordVerfDateValueIsNotNull(field){
      // Record Verification Date (date-range) is a special field that always has values as not null. 
      // Check if date field values are really null
      if (field.id === "registration-date-range") {
        let dateFrom = field.value["registration-date-range-from"]
        let dateTo = field.value["registration-date-range-to"]
        return truthy(dateFrom) && truthy(dateTo)
      }else{
        return true;
      }
    },
    validateInvestigatorValueIsNotNull(field){
      // Investigator field can sometimes have a value as not null. 
      // Check if investigator value is really null
      if (field.id === "investigator") {
        let type = field.value["combine-investigator-type"]
        let name = field.value["combine_investigator.name"]
        return truthy(type) && truthy(name)
      }else{
        return true;
      }
    },
    validateOrganizationValueIsNotNull(field){
      // Organization field can sometimes have a value as not null if an option is selected but no org name is provided 
      if (field.id === "organization") {
        let type = field.value["combine-organization-type"]
        let name = field.value["combine_organizations.id"]
        return truthy(type) && truthy(name)
      }else{
        return true;
      }
    }
  },
  watch: {
    model: {
      handler: "modelChange",
      deep: true,
    },
    "$pageState.total"(total) {
      this.$emit("total", total);
    },
    tab() {
      this.$store.dispatch("forceResize");
    },
  },
};

function filterParams(params) {
  for (let key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key) && !truthy(params[key])) {
      delete params[key]; // delete empty values
    }
  }
  return params;
}
</script>