<template>
  <span class="title-form">
    <div class="title" v-if="title" v-html="title"></div>
    <div class="title-for-capture">{{ title }} {{ value.label }}</div>

    <dropdown class="title-form-dropdown" no-caret ref="menu">
      <template #button-content>
        <div class="title-form-dropdown-content">
          {{ value ? value.label : "" }}
          <icon class="icon" icon="caret-down"></icon>
        </div>
      </template>
      <dropdown-item
        v-for="(opt, index) in options"
        :key="index"
        v-if="opt.label !== value.label"
        @click="select(opt)"
      >
        <span class="title-form-item" v-html="opt.label"></span>
      </dropdown-item>
    </dropdown>
  </span>
</template>

<script>
import Dropdown from "bootstrap-vue/es/components/dropdown/dropdown";
import DropdownItem from "bootstrap-vue/es/components/dropdown/dropdown-item";
import Options from "@/components/form/utils/Options";

export default {
  name: "title-form",
  components: { Dropdown, DropdownItem },
  props: {
    title: String,
    option: String,
    value: Object,
  },
  data() {
    return {
      options: {},
    };
  },
  methods: {
    getOptions(option) {
      this.options = Options.getOptions(option);
    },
    select(option) {
      this.$emit("input", option);
    },
  },
  watch: {
    option: {
      handler: "getOptions",
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/component";

.title-form {
  display: flex;
  align-items: center;

  .title {
    color: $text;
    font-size: 1.1rem;
    font-weight: $bold;
  }
}
.title-form-dropdown {
  margin-left: 0.5ch;

  &.show > .btn-secondary.dropdown-toggle {
    background: transparent;
    color: $text;
    border-color: transparent;
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background: transparent;
    color: $text;
    border-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .dropdown-toggle {
    display: block;
    height: auto;
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    color: $text;
    font-size: 1.1rem;
    font-weight: $bold;
  }
  .dropdown-toggle[aria-expanded="true"] {
    .title-form-dropdown-content {
      color: $link !important;
    }
  }
  .title-form-dropdown-content:hover {
    color: $link !important;
  }
  .btn-secondary:hover {
    background: transparent;
    color: $text;
    border-color: transparent;
  }
  .btn-secondary:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}
.title-form-dropdown-content {
}
</style>
