let steps = [];

export default function getSteps() {
  steps = [
    {
      element: '#trial-analytics-page-header-widget-actions-help',
      intro: `This is the Analytics tab of the Study landing page. This tab contains charts of data for the selected Study. <br><a style="font-size: .9rem;" href="/documentation/docs/landing-studies/#study-landing-page-analytics-section" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg',
      highlightClass: 'special-highlight-bg'
    }
  ];
  return steps;
}