import settingsGetters from "./getters";
import settingsMutations from "./mutations";
import settingsActions from "./actions";

export default {
  namespaced: true,

  state: {
    activeContainer: {},
    containerEnabled: false,
    memberInformation: false,
    showSummaryPatients: false,
    skipCache: false,
    useOriginalDiseases: false,
  },

  getters: settingsGetters,
  mutations: settingsMutations,
  actions: settingsActions,
};
