<template>
  <div class="app-footer">
    <div class="version">
      <span class="ui">
        <h6>Build Date</h6>
        <span v-html="buildTime"></span>
      </span>
      <span
        class="api"
        v-if="
          $route.name !== 'login' &&
          $store.getters['auth/roles'].includes('DEVELOPER')
        "
        ><build-info></build-info
      ></span>
    </div>
    <div class="policy">
      <div class="column">
        <a rel="noopener noreferrer" href="http://www.hhs.gov" target="_blank"
          >U.S. Department of Health and Human Services</a
        >
        <a rel="noopener noreferrer" href="http://www.nih.gov" target="_blank"
          >National Institutes of Health</a
        >
        <a
          rel="noopener noreferrer"
          href="https://www.cancer.gov"
          target="_blank"
          >National Cancer Institute</a
        >
        <a rel="noopener noreferrer" href="http://www.usa.gov" target="_blank"
          >USA.gov</a
        >
      </div>
      <div class="column">
        <a
          rel="noopener noreferrer"
          href="https://www.cancer.gov/policies/disclaimer"
          target="_blank"
          >Disclaimer Policy</a
        >
        <a
          rel="noopener noreferrer"
          href="https://www.cancer.gov/policies/accessibility"
          target="_blank"
          >Accessibility</a
        >
        <a
          rel="noopener noreferrer"
          href="https://www.cancer.gov/policies/foia"
          target="_blank"
          >FOIA</a
        >
        <a
          rel="noopener noreferrer"
          href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html"
          target="_blank"
          >HHS Vulnerability Disclosure</a
        >
      </div>
    </div>
    <div class="contact">
      <h6>Help and Support</h6>
      <a href="mailto:ctrp_support@mail.nih.gov">ctrp_support@mail.nih.gov</a>
    </div>
  </div>
</template>

<script>
import BuildInfo from "./AppFooterBuildInfo";

export default {
  name: "app-footer",
  components: { BuildInfo },
  data() {
    return {
      buildTime: process.env.VUE_APP_BUILD_TIME,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.app-footer {
  width: 100%;
  height: auto;
  padding: 5rem 3rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  background-color: $dark;
  color: white;

  a {
    color: white;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: $thin;

    &:hover {
      text-decoration: underline;
    }
  }
  h6 {
    color: white;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
  }
}

.version {
  flex: 0 0 30%;
  padding-right: 7rem;
}
.policy {
  flex: 0 1 80%;

  display: flex;

  > .column {
    display: flex;
    flex-direction: column;

    a {
      font-weight: $regular;
    }

    &:first-child {
      margin-right: 4rem;
    }
  }
}
.contact {
  flex: 0 0 auto;
  text-align: right;
  a {
    text-decoration: underline;
  }
}
</style>
