const lightGrey = '#CFD4DA'
const darkGrey = '#868E96'

const xAxis = {
  axisLine: {
    show: false
  },
  axisTick: {
    alignWithLabel: true,
    length: 4,
    lineStyle: {
      color: [lightGrey]
    }
  },
  splitLine: {
    lineStyle: {
      color: [lightGrey]
    }
  },
  axisLabel: {
    textStyle: {
      color: darkGrey,
    },
  },
}

const yAxis = {
  axisLine: {
    show: false
  },
  axisTick: {
    alignWithLabel: true,
    length: 4,
    lineStyle: {
      color: [lightGrey]
    }
  },
  splitLine: {
    lineStyle: {
      color: [lightGrey]
    }
  },
}

const grid = {
  top: '10px',
  left: '5px',
  right: '5px',
  bottom: '50px',
  containLabel: true
}

const tooltip = {
  axisPointer: {
    type: 'shadow'
  },
  extraCssText: 'box-shadow: 0 1px 3px rgba(0, 0, 0, .4)',
  backgroundColor: 'white',
  confine: true,
  textStyle: {
    color: 'black',
    fontSize: 12
  }
}

const axisLabel = {
  // textStyle: {
  //   color: '#999999'
  // }
}

const legend = {
  textStyle: {
    color: '#3F546C',
  }
}

export default {
  grid,
  tooltip,
  axisLabel,
  xAxis,
  yAxis,
  legend,
}
