export default {

  // Home page
  home: {
    primary_purpose: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 168, 126 ],
        valueAxis: [ 45, 45 ]
      }
    },
    current_trial_status: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 182, 126 ],
        valueAxis: [ 45, 45 ]
      }
    },
    study_source: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 182, 126 ],
        valueAxis: [ 45, 45 ]
      }
    },
    phase: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 104, 104 ],
        valueAxis: [ 45, 45 ]
      }
    },
    intervention_type: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 126, 126 ],
        valueAxis: [ 45, 45 ]
      }
    },
    races_string: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        valueAxis: [ 45, 45 ]
      }
    },
    cancer_center_container: {
      studies: {
        default: {
          top: '32px',
          left: '60px',
          right: '48px',
          bottom: '58px',
          containLabel: true
        },
        expanded: {
          top: '32px',
          left: '60px',
          right: '48px',
          bottom: '98px',
          containLabel: true
        },
        nameGap: {
          labelAxis: [ 195, 135 ],
          valueAxis: [ 55, 55 ]
        }
      },
      participants: {
        default: {
          top: '32px',
          left: '60px',
          right: '48px',
          bottom: '58px',
          containLabel: true
        },
        expanded: {
          top: '32px',
          left: '60px',
          right: '48px',
          bottom: '98px',
          containLabel: true
        },
        nameGap: {
          labelAxis: [ 195, 135 ],
          valueAxis: [ 65, 65 ]
        }
      }
    },
    ethnicity: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        valueAxis: [ 45, 45 ]
      }
    },
    sex: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        valueAxis: [ 45, 45 ]
      }
    }
  },

  // Container page
  container: {
    studies: {
      default: {
        top: '32px',
        left: '60px',
        right: '48px',
        bottom: '58px',
        containLabel: true
      },
      expanded: {
        top: '32px',
        left: '60px',
        right: '48px',
        bottom: '98px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 195, 135 ],
        valueAxis: [ 55, 55 ]
      }
    },
    participants: {
      default: {
        top: '32px',
        left: '60px',
        right: '48px',
        bottom: '58px',
        containLabel: true
      },
      expanded: {
        top: '32px',
        left: '60px',
        right: '48px',
        bottom: '98px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 195, 135 ],
        valueAxis: [ 65, 65 ]
      }
    }
  },

  // Study Analytics view
  trialAnalyticsView: {
    primary_purpose: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '92px',
        containLabel: true
      },
      nameGap: {
        valueAxis: [ 45, 45 ]
      }
    },
    current_trial_status: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '92px',
        containLabel: true
      },
      nameGap: {
        valueAxis: [ 45, 45 ]
      }
    }
  },

  // Study Analytics tab
  trialAnalyticsTab: {
    races: {
      default: {
        top: '32px',
        left: '60px',
        right: '48px',
        bottom: '58px',
        containLabel: true
      },
      expanded: {
        top: '32px',
        left: '60px',
        right: '48px',
        bottom: '58px',
        containLabel: true
      },
      nameGap: {
        valueAxis: [ 55, 55 ]
      }
    },
    age: {
      default: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '72px',
        containLabel: true
      },
      expanded: {
        top: '8px',
        left: '60px',
        right: '98px',
        bottom: '62px',
        containLabel: true
      },
      nameGap: {
        labelAxis: [ 55, 55 ],
        valueAxis: [ 45, 45 ]
      }
    }
  }

};