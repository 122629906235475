<template>
  <div
    class="filters-popover popover-pad-lg"
  >
    <div class="filters-popover__header">
      <h3>Data Settings</h3>
    </div>

    <ul class="data-settings-body">
        <!-- count history -->
        <li class="data-setting count-history" v-section="'participants'" 
        v-if="['DEVELOPER', 'ADMINISTRATOR', 'RESEARCHER_ACCRUAL', 'RESEARCHER_TRIAL'].some((role) => role == $store.getters['auth/roles'])">
          <div class="data-settings-dropdown-body">
            <checkbox v-model="showSummaryPatients"
              >Include summary participants in all participant related
              reports</checkbox
            >
          </div>
        </li>

        <!-- skip cache -->
        <li
          class="data-setting skip-cache"
          v-section="'participants'"
          v-if="$store.getters['auth/roles'].some(role => role === 'DEVELOPER' || role === 'ADMINISTRATOR')"
        >
          <div class="data-settings-dropdown-body">
            <checkbox v-model="skipCache">Do not use cached results</checkbox>
          </div>
        </li>
        
        <!-- search (original) or portfolio (v2) maintype -->
        <li class="data-setting count-history">
          <div class="data-settings-dropdown-body">
            <h4>Select a maintype</h4>
            <label>
              <input type="radio" v-model="useOriginalDiseasesLocal" :value=true :disabled="isDiseaseSearchTrue">
              Use Search Disease Maintypes
            </label>
            <br>
            <label>
              <input type="radio" v-model="useOriginalDiseasesLocal" :value=false :disabled="isDiseaseSearchTrue">
              Use Portfolio Disease Maintypes
            </label>
            <!-- Show warning message if disease search is true -->
            <div v-if="isDiseaseSearchTrue" class="disease-er-message">
              <p>Warning: The selected filter(s) for Lead Disease and/or Disease/Conditions needs to be deleted </p>
              <p>and reapplied when you switch between the Disease Maintypes (Search vs Portfolio).</p>
            </div>
          </div>
        </li>
      </ul>

      <!-- footer -->
      <div class="data-settings-footer">
        <btn variant="outline" @click="closeDataSettings">Cancel</btn>
        <btn variant="success" @click="submit">
          <icon class="mr-2" icon="check"></icon>Apply Changes
        </btn>
      </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Checkbox from "bootstrap-vue/es/components/form-checkbox/form-checkbox";
import { startIntro } from "@/services/IntroService";
import dataSettingsIntroSteps from "@/steps/components/homeDataSettings";


export default {
  name: "data-settings-popover",
  components: { Checkbox },
  props: {
  },
  data() {
    return {
      selectedDataOption: "all",
      submitted: false,
      showSummaryPatients: false,
      useOriginalDiseasesLocal: true, //set default value
      skipCache: false,
      activeContainer: undefined,
      radioOptions: [
        { label: "All data", value: "all" },
        { label: "All study data for a Cancer Center", value: "cc" },
        { label: "Only Cancer Center data", value: "member" },
      ],
      containerQuery: {
        baseURL: "containers",
        endpoint: "/containers",
        params: {
          include: ["name", "id"],
          size: 100,
          sort: "name",
          order: "asc",
        },
      },
      dataSettingsIntroSteps,
    };
  },
  computed: {
    ...mapGetters({
      active: "settings/activeContainer",
      containerEnabled: "settings/containerEnabled",
      memberInformation: "settings/memberInformation",
      countHistory: "settings/showSummaryPatients",
      useOriginalDiseases: "settings/useOriginalDiseases",
      currentSkipCache: "settings/skipCache",
    }),
    invalid() {
      return (
        this.selectedDataOption !== "all" &&
        !this.$h.truthy(this.activeContainer)
      );
    },
    roles() {
      return this.$store.getters["auth/roles"];
    },
    isDiseaseSearchTrue() {
      return this.$store.getters['search/diseaseConditionSearch'] === true || this.$store.getters['search/leadDiseaseSearch'] === true || this.$store.getters['search/participantDiseaseConditionSearch'] === true
  },
  },
  created() {
    this.selectedDataOption = this.containerEnabled ? "cc" : "all";
    if (this.memberInformation) {
      this.selectedDataOption = "member";
    }

    this.activeContainer = this.containerEnabled ? this.active : undefined;
    this.showSummaryPatients = this.countHistory;
    this.skipCache = this.currentSkipCache;
  },
  mounted() {
    this.useOriginalDiseasesLocal = this.useOriginalDiseases;
  },
  methods: {
    ...mapActions({
      setEnabled: "settings/setEnabled",
      setContainer: "settings/setContainer",
      setShowSummaryPatients: "settings/setShowSummaryPatients",
      setSkipCache: "settings/setSkipCache",
      setUseOriginalDiseases: "settings/setUseOriginalDiseases",
      setMemberInformation: "settings/setMemberInformation",
    }),
    submit() {
      this.submitted = true;
      if (!this.invalid) {
        const containerEnabled =
          this.selectedDataOption === "member" ||
          this.selectedDataOption === "cc";
        this.setUseOriginalDiseases(this.useOriginalDiseasesLocal);
        this.setShowSummaryPatients(this.showSummaryPatients);
        this.setSkipCache(this.skipCache);
        this.setMemberInformation(this.selectedDataOption === "member");
        this.setEnabled(containerEnabled);
        this.setContainer(containerEnabled ? this.activeContainer : {});
        this.closeDataSettings();
      }
    },
    openHelpGuide() {
      this.startIntro(this.dataSettingsIntroSteps);
    },
    closeDataSettings(){
      this.$emit('close-data-settings');
    },
    startIntro,
  },
  watch: {
    $route: "hidden",
  },
};
</script>


<style lang="scss">
@import "src/styles/component";


.data-settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem 0;

  h2 {
    flex: 0 0 270px;
    font-size: 1.4rem;
    color: $text;
  }
  .btn-close {
    flex: 0 0 auto;
  }
  .data-settings-help-guide {
    flex: 0 0 auto;
  }
}
.data-settings-body {
  margin: 1rem 0 0;
  padding: 0;
  padding: 0 0;
}
.data-settings-dropdown-body {
  .custom-control-label {
    // color: saturate($warn-text-color, 15);
    font-weight: $regular;

    span {
      font-weight: $thin;
    }
  }
  .body {
    margin-top: 1rem;
  }

  .response {
    padding: 1rem 0 0;
  }
}
.data-settings-footer {
  padding: 0 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.75rem;

    &.btn-success {
      width: 200px;
    }
  }
}
.data-setting {
  display: block;
  padding: 0 2rem 1.5rem;
  margin: 0 0 1.5rem;
  border-bottom: solid thin $n2;

  &:last-child {
    border-bottom: none;
  }

  .title {
    font-size: 1rem;
    color: $text;
    margin-bottom: 0.25rem;
  }
  .description {
    color: $text;
    font-weight: $thin;
    font-size: 0.9rem;
  }
  .member-checkbox {
    margin-top: 0.5rem;
    margin-left: 0.5rem;

    .custom-control-label {
      // color: $warn-text-color;
      font-weight: $thin;
    }
  }
}
.dropdown-loading {
  width: 100%;
  // color: darken($warning, 15);
  text-align: center;
  padding: 1.5rem 0 0.5rem;
}
.response {
  .warning-message {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: darken($warning, 25);

    display: flex;
    align-items: flex-start;

    background-color: $warn-bg-color;
    border: solid thin $warn-border-color;
    padding: 1rem;
    border-radius: $border-radius-sm;

    svg {
      margin-right: 0.5rem;
      margin-top: 0.2rem;
    }
  }
}
.disease-er-message {
  display: block;
  font-weight: normal;
  font-size: 80%;
  color: #e74c3c;
}
.disease-er-message p {
  margin: 0;
  line-height: 1.2;
}
</style>
