import BarChart from './BarChart';
import StackedColumnChart from './StackedColumnChart';
import StackedLineChart from './StackedLineChart';
import LineChart from './LineChart';
import PieChart from './PieChart';

export default {
  BarChart,
  StackedColumnChart,
  LineChart,
  StackedLineChart,
  PieChart
};