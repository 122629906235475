import { truthy } from '@/services/CommonsService'
import { Getters, Options, Validations } from '../utils'
import { apiGet } from '@/services/DataService'
import { abbreviate } from '@/services/FilterService'
import get from 'lodash/get'

export function getSECSearchSchema (type) {
  return [
    {
      type: 'select-field',
      id: 'sec_criteria',
      width: 'full',
      hasClear: true,
      default: { label: 'Prior Therapy', value: 'PRIOR_THERAPY' },
      group: {
        label: 'Criteria'
      },
      input: {
        searchable: false,
        options: Options.getOptions('sec_criteria')
      }
    },
    {
      type: 'select-field',
      id: 'id-selector',
      'field-type': 'sec',
      showField: true,
      default: { label: 'Name', value: 'name' },
      dynamicFieldMapping: true,
      input: {
        allowEmpty: false,
        closeOnSelect: true,
        options: Options.getOptions(type)
      },
      isActive () {
        return false
      }
    },
    {
      type: 'select-field',
      id: 'id-input',
      'field-type': 'sec',
      default: [],
      showField: true,
      hasClear: true,
      group: {
        label: '',
        description: 'Enter 3 or more characters to search'
      },
      input: {
        placeholder: 'Select one or more name/code',
        multiple: true,
        width: 'full',
        textField: 'name',
        valueField: 'name',
        internalSearch: false,
        preserveSearch: false,
        clearOnSelect: true,
        closeOnSelect: false,
        maxHeight: 300,
        loading: false,
        options: [],
        optionSlot: secOptionSlot
      },
      validations: [Validations.required],
      update (partial) {
        // update helper text
        //sorting is done on name
        updateTypeaheadDescription(this.group, partial)
        typeaheadUpdate(this, partial, {
          endpoint: 'structured-eligibility-criteria',
          params: {
            sort: 'name.keyword',
            order: 'asc',
            name: partial,
            include: ['name', 'synonyms', 'code'],
            branch_not: 'Retired Concept',
            size: 5000 
          }
        })
      },
      get (model, field, fields, values) {
        // this gets called when SEC is added in the form, to build the query

        const names = get(this, 'value', []).map(v => v.name)

        if (names.length) {
          model['id-input'] = values['id-input']
        }
      }
    },
    {
      type: 'text-field',
      id: 'id-text-input',
      'field-type': 'sec',
      default: '',
      showField: false,
      hideField: true,
      group: {},
      input: {
        autocomplete: 'off',
        width: 'full',
        placeholder: 'Enter Code',
        maxLength: 1800 // to show 125 ids
      },
      validations: [Validations.required],
      update (partial) {
        typeaheadUpdateSEC(this, partial)
      },
      get (model, field, fields, values) {
        let inputArr = [];
        /* make sure only include the ccodes user is searching for, 
          and not any ccode entered during type ahead */
        if(values['id-input'] && typeof values['id-input'] === "string" && values['id-input'].length > 0){
          // Make sure ccodes are unique and avoid duplicates.
          let uniqueArr = [...new Set(values['id-input'].toUpperCase().replaceAll(","," ").split(/\s+/))];
          values['id-input'] = uniqueArr.toString();
          uniqueArr.forEach(input => {
            input = input.toUpperCase().trim();
            inputArr.push(input);
          });

          // append valid sec_name
          let usedCcode = new Set();
          if(this.sec_name && this.sec_name.size > 0){
            let secs = []
            this.sec_name.forEach(sec => {
              let ccode = sec.code.toUpperCase().trim();
              if(!usedCcode.has(ccode) && inputArr.includes(ccode)){
                secs.push(sec);
                usedCcode.add(ccode);
              }
            });
            /* 
            Convert sec_name to an Array instead of an Object.
            Object will not be saved after refreshing the page. 
            */
            model['sec_name'] = secs;
          }
        }

        model['id-input'] = values['id-input'];
      },
      mounted (model, fields) {
        const idSelect = getField(fields, 'id-selector')
        idSelect.state.active = truthy(this.value)
      },
      isActive () {
        return false
      }
    },
    {
      type: 'radio-field',
      id: 'sec_type',
      default: 'unspecified',
      group: {
        label: 'Type'
      },
      input: {
        textField: 'label',
        valueField: 'value',
        width: 'full',
        options: Options.getOptions('sec_type')
      }
    }
  ]
}

export function getSecCriteria(value){
  return value['sec_criteria'] ? `${value["sec_criteria"].label} ` : "";
}

export function getSecType(value,secSearchType){
  var arr = [];
  value['sec_type'].split().map(type => {
    if (secSearchType === "default") {
      if (type === "inclusion") {
        type = "Focus";
      }
      else if (type === "exclusion") {
        type = "Eligible";
      }
      else {
        type = "Unspecified";
      }
      }
    type = type.charAt(0).toUpperCase() + type.substring(1);
    arr.push(type);
  });
  return arr.length > 0 ? `<br> Type: <strong>${arr.join(", ")}</strong>` : "";
}

export function secNameCodeLabel (value) {
  if (!value['id-selector']) {
    return
  }
  if (value['id-input']) {
    var arr = [];
    value['id-input'].map(sec => arr.push(`${sec.name} (${sec.code})`));
    return arr.join(", ");
  }
}

export function secNameCodeLabelSearchByCode(value){
  if (!value['id-selector']) {
    return;
  }
  if (value['sec_name'] && value['sec_name'].length > 0) {
    var arr = [];
    var ccodes = new Set();
    value['sec_name'].forEach(sec => {
      let ccode = sec.code.toUpperCase();
      // avoid duplicate
      if(!ccodes.has(ccode)){
        arr.push(`${sec.name} (${ccode})`);
        ccodes.add(ccode);
      }
    });
    // make sure the display order remains the same
    arr.sort()
    // include ccodes that are searched but no result found
    value['id-input'].replaceAll(","," ").split(/\s+/).forEach(ccode => {
      ccode = ccode.toUpperCase().trim();
      if(ccode.length > 0 && !ccodes.has(ccode)){
        // display invalid ccode
        arr.push(`(${ccode})`);
        ccodes.add(ccode);
      }
    });
    return arr.join(", ");
  }else{
    // if all ccode are invalid, display them
    return `(${value['id-input']})`;
  }
}

export async function getSECNameBasedOnCode(field, ccode, query) {
  try {
    let response = await apiGet(query)
    let ret = await get(response, 'data', []);
    if(ret.length > 0){
      if(field.sec_name){
        if(!field.sec_code.has(ccode)){
          field.sec_name.add(ret[0])
        }
      }else{
        field.sec_name = new Set()
        field.sec_name.add(ret[0])
      }

      if(field.sec_code){
        field.sec_code.add(ccode)
      }else{
        field.sec_code = new Set()
        field.sec_code.add(ccode)
      }
    }
  } catch (error) {
                    // eslint-disable-next-line
                    console.log(error);
                  }
}

async function getOptions (field, partial, query) {
  try {
    field.input.loading = true
    let response = await apiGet(query)
    field.input.options = get(response, 'data', [])
  } catch (error) {
                    field.input.placeholder = "Failed to load options";
                    // eslint-disable-next-line
                    console.log(error);
                  } finally {
    field.input.loading = false
  }
}

function typeaheadUpdateSEC (field, partial) {
  // No c-code has less than 3 characters, so only proceed with more than 2 chars
  if (partial.length > 2) {
    let ccodes = partial.replaceAll(","," ").split(/\s+/)
    ccodes.forEach(ccode => {
      ccode = ccode.trim();
      if(ccode.length > 0){
        getSECNameBasedOnCode(field, ccode, {
          endpoint: 'structured-eligibility-criteria',
          params: {
            sort: 'count',
            order: 'desc',
            code: ccode,
            include: ['name', 'code']
          }
        });
      }
    })
  }
}

export function typeaheadUpdate (field, partial, query) {
  if (partial.length > 2) {
    getOptions(field, partial, query)
  } else {
    field.input.options = []
  }
}

function updateTypeaheadDescription (group, partial) {
  if (partial.length < 3) {
    group.description = `Enter ${
      3 - partial.length
    } or more characters to search`
  } else {
    group.description = ``
  }
}

function secOptionSlot (props) {
  let name = get(props, 'option.name')
  let synonyms = abbreviate(get(props, 'option.synonyms', []).join(', '), 60)
  return `<b>${name}</b><br/><span class="synonyms">${synonyms}</span>`
}

function getField (fields, id) {
  return fields.find(field => field.id === id)
}

export function getSEC (returnObject, value) {
  // This method builds the returnObject which is the params for the API request
  // for the SEC filter search

  //-- Here is where we add the SEC request params
  if (!value) {
    return
  }

  let paramPrefix = ''
  let count = 0
  let eligibility_criterion_param = ''
  let name_codes_val = []
  let sec_code_param = ''
  let codesVal = ''
  value.forEach((val) => {
    count++

    if (count > 1) {
      paramPrefix += 'sec_embed_and_'
    }

    eligibility_criterion_param = paramPrefix + 'sec.pt.type'

    returnObject[eligibility_criterion_param] = val['sec_type']

    if (val['id-selector'].value === 'name') {
      name_codes_val = val['id-input'].map((nameItem) => {
        return nameItem.code
      })
      sec_code_param = paramPrefix + 'sec.pt.id'
      returnObject[sec_code_param] = name_codes_val
    } else if (val['id-selector'].value === 'code') {
      codesVal = val['id-input'].replace(/;/g, ' ')
      codesVal = val['id-input']
        .split(/[ ,]+/)
        .map((entry) => entry.trim())
        .filter((entry) => entry !== 'None')
        .filter((entry) => entry !== '')

      sec_code_param = paramPrefix + 'sec.pt.id'
      returnObject[sec_code_param] = codesVal
    }
  })
}

export function getSECSearchTypeObj(returnObject, value) {
  //stremlined SearchType keyword
  if (value === "portfolio") {
    returnObject["sec.pt.st"] = value;
  }
}
