import { Options } from '@/components/form/utils';
import { titleCase } from '@/services/FilterService';
// import ContainerOptions from '@/../static/data/ContainerReturn'

const checkboxFields = ['primary_purpose', 'study_source', 'phase', 'groups.interventions.type']
const textFields = ['age', 'm_cid']
const icons = {
  studies: 'notes-medical',
  participants: 'users',
}

export default {
  methods: {

    $createChartQuery(agg_field, chart_type = 'pie', baseURL = 'studies', endpoint = 'trials', params = {}) {
      const query = this.$h.get(this, 'currentQuery', {})
      const container = this.$store.getters['settings/activeContainer']
      const showSummaryPatients = this.$store.getters['settings/showSummaryPatients']
      return {
        baseURL,
        endpoint,
        params: {
          ...query,
          include: 'none',
          chart_type,
          agg_field,
          container,
          count_history_flag: endpoint === 'patients' ? showSummaryPatients : null,
          ...params
        }
      }
    },

    $chartClick(event, key, title = 'studies', params = {}) {

      if (this.disableClicks) {
        return 
      }

      let name
      let subKey

      if (key === 'ethnicity' && event.name) {
        name = event.name
      } else {
        name = event
      }


      let value = undefined
      let formValues = this.$h.get(this, 'values')

      // figure out the value
      if (checkboxFields.includes(key)) {
        value = setCheckbox(key, name)
      }
      else if (textFields.includes(key)) {
        if (key === 'm_cid') {
          // let container = ContainerOptions.find(o => o.name === name)
          let container = this.containerState.data.find(o => o.name === name)
          if (container) {
            value = container.id
          }
        } else {
          value = name
        }
      }
      else if (key !== 'ethnicity') {
        value = setSelect(key, name, key === 'races_string')
      }

      if (key === 'current_trial_status') {
        subKey = 'current_trial_status_grouping'
        value = {
          current_trial_status: value
        }
      }
      else if (value === undefined) {
        value = { value: '' }
      }

      let tempKey = ''
      if (value.value === 'More_Than_One_Race' && key === 'races_string') {
        return 
      }
      if (value.value === 'None' || name === 'None') {
        tempKey = 'missing'
        value.value = ''
      }
      if (value.value === 'American_Indian_Or_Alaska_Native' || value.value === 'American Indian Or Alaska Native' && key === 'races_string') {
        value.value = 'AMERICAN_INDIAN'
      }
      else if (value.value === 'Native_Hawaiian_Or_Other_Pacific_Islander' && key === 'races_string') {
        value.value = 'Hawaiian'
      }
      else if (value.value === 'Unknown_Or_Not_Reported' && key === 'races_string') {
        value.value = ['Not Reported', 'Unknown', 'not Reported', 'unknown']
      }
      else if (value.value === 'Black_Or_African_American' && key === 'races_string') {
        value.value = 'black'
      }
      else if ((name === 'Hispanic or Latino' || name === 'Hispanic') && key === 'ethnicity') {
        value = [{
          "label": "Hispanic",
          "value": "HISPANIC"
        }]
      }
      else if ((name === 'Not Hispanic or Latino' || name === 'Not Hispanic') && key === 'ethnicity') {
        value = [{
          "label": "Not Hispanic",
          "value": "NOT_HISPANIC"
        }]
      }
      else if (name === 'Unknown or Not Reported' && key === 'ethnicity') {
        value = [{
          "label": "Unknown",
          "value": "UNKNOWN"
        }, {
          "label": "Not Reported",
          "value": "NOT_REPORTED"
        }]
      }
      else if (value.value === 'More_Than_one_Race') {
        return
      }

      // open the data view
      this.$store.dispatch('view/set', {
        id: key,
        title: titleCase(title),
        variant: title,
        icon: icons[title],
        resource: title,
        height: 'modal',
        initialValues: { 
          ...formValues,
          ...params,
          [subKey || key]: value,
        },
        query: key === 'm_cid' ? { params: { m_type: 'organization' } } : tempKey ? { params: { missing: key } } : undefined
      })
      this.$store.dispatch('modal/openDataView')
    },

  }
}

function setCheckbox(key, label) {
  let option = Options.getOption(key, label)
  return [option.value]
}
function setSelect(key, label, flag = false) {
  if (flag) {
    return { label, value: label.split(', ').map(name => name.replace(/ /g, '_')).join(';') }
  } else {
    let option = Options.getOption(key, label)
    return [option]
  }
}