<template>
  <div class="tab-switcher" :class="{ disabled }">
    <btn
      class="tab-switcher-btn"
      v-for="(tab, index) in tabs"
      :ref="`tab-${index}`"
      :key="index"
      :variant="variant"
      :class="{ 'tab-active': value === index }"
      @click="$emit('change', index)"
    >
      <slot :name="tab"></slot>
    </btn>

    <div class="border-slider" :style="borderStyle" :class="variant"></div>
  </div>
</template>

<script>
export default {
  name: "tab-switcher",
  props: {
    value: Number,
    update: Number,
    tabs: Array,
    icons: Array,
    disabled: Boolean,
    variant: String,
  },
  model: {
    event: "change",
  },
  data() {
    return {
      borderStyle: "",
    };
  },
  mounted() {
    this.moveBorder(this.value);
  },
  methods: {
    moveBorder(tab) {
      let check = this.$refs[`tab-${tab}`];
      if (check) {
        const tabEl = check[0];
        this.borderStyle = `
        left: ${tabEl.offsetLeft}px;
        width: ${tabEl.offsetWidth}px;
      `;
      }
    },
  },
  watch: {
    value: "moveBorder",
    disabled: "moveBorder",
    update() {
      this.moveBorder(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.tab-switcher {
  height: $page-header-height;
  display: flex;
  overflow: visible;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  @each $name, $theme-color in $theme-colors {
    .#{$name}.border-slider {
      background-color: $theme-color;
    }
    .btn-#{$name}.tab-active {
      border-bottom-color: $theme-color;
      color: $theme-color !important;
      background-color: fade-out($n1, 1);
      font-weight: $bold !important;
    }
    .btn-#{$name}:hover:not(.tab-active) {
      color: $theme-color !important;
      background-color: fade-out($theme-color, 0.95);
    }
  }
}
.tab-switcher-btn {
  height: calc(100%);
  font-size: 1rem;
  font-weight: $bold;
  min-width: unset;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0.25rem 1rem 0;
  margin-right: 0.5rem;

  color: $text !important;
  box-shadow: none !important;

  svg {
    font-size: 1.1rem;
    margin-right: 0.5rem;
    margin-bottom: -0.1rem;
  }
  &:active {
    background-color: fade-out($n2, 0) !important;
  }
  &:active,
  &:focus {
    outline: none;
    color: $text !important;
  }
}
.border-slider {
  position: fixed;
  bottom: 0;
  height: 3px;

  // transition:
  //   width .1s,
  //   left .25s;

  // default
  left: 32px;
  width: 120px;
}
</style>
