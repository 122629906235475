import { Options, Utils } from '../utils'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { truthy } from '@/services/CommonsService'
import moment from 'moment'
import store from '@/store'

let dSetFutureDateRistriction = debounce(setFutureDateRistriction, 100)
let dSetRangeDisabled = debounce(setRangeDisabled, 100)

const DateTypeSchema = [
  {
    type: 'select-field',
    id: 'date-range-key',
    hasClear: true,
    group: {
      label: '',
    },
    input: {
      placeholder: 'Select a date',
      searchable: false,
      options: getDatesList(),
    },
    mounted(model, fields) {
      dSetRangeDisabled(fields, this.value)
      dSetFutureDateRistriction(fields, this.value)
    },
    changed(model, fields, resetField) {
      dSetRangeDisabled(fields, this.value)
      dSetFutureDateRistriction(fields, this.value)

      resetField(fields[2])
      resetField(fields[3])
    },
    isActive: () => false,
  },
  {
    type: 'radio-field',
    id: 'date-range-interval',
    default: 'month',
    group: {
      label: 'Select a time interval',
    },
    input: {
      textField: 'label',
      valueField: 'value',
      options: Options.getOptions('interval'),
    },
    mounted(model, fields) {
      setDateInterval(this.value, fields)
    },
    changed(model, fields) {
      setDateInterval(this.value, fields)
    },
    isActive: () => false,
  },
  {
    type: 'date-field',
    id: 'date-range-from',
    default: undefined,
    group: {
      label: 'Select a date range',
    },
    input: {
      placeholder: 'From',
      interval: 'month',
      minDate: '1978-1-1',
      openDate: new Date('2018-1-1'),
      inline: false,
      clearButton: true,
      maxDate: moment().format('YYYY-MM-DD'),
    },
    changed(model, fields) {
      if (this.value !== undefined) {
        fields[3].input.minDate = moment(this.value).format('YYYY-MM-DD')
      }
    },
    // validations: [dateValid],
    isActive: () => false,
  },
  {
    type: 'date-field',
    id: 'date-range-to',
    default: undefined,
    group: {},
    input: {
      placeholder: 'To',
      interval: 'month',
      inline: false,
      minDate: '1978-1-1',
      maxDate: moment()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    },
    changed(model, fields) {
      if (this.value !== undefined) {
        let fromDate = Utils.getField('date-range-from', fields)
        fromDate.input.maxDate = moment(this.value).format('YYYY-MM-DD')
      }
    },
    // validations: [dateValid],
    isActive: () => false,
  },
]

export default [
  {
    type: "form-field",
    id: "date-range",
    default: {},
    studyFilterGroup: {
      label: 'Dates',
    },
    groupingFilterDisplayLabel: {
      label: "Study Dates",
    }, // label name in groups filter tab
    group: {
      label: "Dates",
    },
    input: {
      schema: DateTypeSchema,
    },
    isActive() {
      const key = get(this, "value.date-range-key.value");
      const from = get(this, "value.date-range-from");
      const to = get(this, "value.date-range-to");
      return truthy(key) && from !== undefined && to !== undefined;
    },
    validations: [dateValid],
    get(model, field, fields, values) {
      const key = get(values, "date-range.date-range-key.value");
      const interval = get(values, "date-range.date-range-interval");
      const from = get(values, "date-range.date-range-from");
      const to = get(values, "date-range.date-range-to");

      if (truthy(key) && from !== undefined && to !== undefined) {
        const dates = normalizeDate(from, to, interval, key);
        model[`${key}_begin`] = dates.begin;
        model[`${key}_end`] = dates.end;
      }
    },
  },
];

function getDatesList() {
  let list = cloneDeep(Options.getOptions('range_type'))
  if (store && store.getters['auth/roles'].includes('DEVELOPER')) {
    list.push({
      label: 'STRAP Publication Date',
      value: 'strap_publication_date',
    })
  }
  // if (!store.getters['auth/roles'].includes('DEVELOPER')) {
  //   list.splice(2, 1)
  // }
  return list
}

function normalizeDate(from, to, interval, key) {
  const begin = moment(from)
  const end = moment(to)
  const format = 'YYYY-MM-DD'
  let returnObject = {}

  if (interval === 'month') {
    returnObject['begin'] = begin.startOf('month').format(format)
    returnObject['end'] =
      end.month() === moment().month() && !useFutureDates(key)
        ? getCurrentDate(end)
        : end.endOf('month').format(format)
  } else if (interval === 'year') {
    returnObject['begin'] = begin.startOf('year').format(format)
    returnObject['end'] =
      end.year() === moment().year() && !useFutureDates(key)
        ? moment().format(format)
        : end.endOf('year').format(format)
  } else {
    returnObject['begin'] = begin.format(format)
    returnObject['end'] = end.format(format)
  }

  return returnObject
}
function dateValid(value) {
  const from = get(value, 'date-range-from')
  const to = get(value, 'date-range-to')

  if (truthy(from) && truthy(to)) {
    return moment(from) - moment(to) <= 0
      ? null
      : 'The start date should be less than the end date'
  } else {
    return null
  }
}

function getCurrentDate(date) {
  let newDate = ''
  if (date.month() < moment().month()) {
    newDate = date.endOf('month').format('YYYY-MM-DD')
  } else {
    newDate = moment().format('YYYY-MM-DD')
  }
  return newDate
}

function setRangeDisabled(fields, value) {
  let disabled = !truthy(value)
  let interval = Utils.getField('date-range-interval', fields)
  let from = Utils.getField('date-range-from', fields)
  let to = Utils.getField('date-range-to', fields)
  interval.state.disabled = disabled
  from.state.disabled = disabled
  to.state.disabled = disabled
}
function setDateInterval(value, fields) {
  let dateFrom = Utils.getField('date-range-from', fields)
  let dateTo = Utils.getField('date-range-to', fields)
  dateFrom.input.interval = value
  dateTo.input.interval = value
}

const allowFutureDates = [
  'primary_completion_date_value',
  'completion_date_value',
]

function setFutureDateRistriction(fields, value) {
  if (truthy(value) && useFutureDates(value.value)) {
    let dateFrom = Utils.getField('date-range-from', fields)
    let dateTo = Utils.getField('date-range-to', fields)
    dateFrom.input.maxDate = ''
    dateTo.input.maxDate = ''
  }
}
function useFutureDates(value) {
  return truthy(value) && allowFutureDates.includes(value)
}
