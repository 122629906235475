import { Validations } from '../utils'
import { apiGet } from '@/services/DataService'
import get from 'lodash/get'
import { truthy } from '@/services/CommonsService'
import store from '@/store'

export default isLeadDisease => {
  let schema = [
    {
      // maintype
      type: 'select-field',
      id: 'maintype',
      group: {
        label: 'Select a maintype'
      },
      input: {
        textField: 'name',
        valueField: 'name',
        leadDisease: isLeadDisease,
        placeholder: 'Primary Cancer Type/Condition',
        options: []
      },
      validations: [Validations.required],
      mounted (model, fields) {
        getOptions(this.input, {
          endpoint: 'trials/diseases',
          params: {
            type: 'maintype',
            type_not: 'subtype'
          }
        })
        updateDisease(model, fields)
      },
      changed (model, fields) {
        model.subtype = undefined
        fields[1].value = undefined
        model.stage = undefined

        if (fields[2]) {
          fields[2].value = undefined
        }

        updateDisease(model, fields)
      }
    },
    {
      // subtype
      type: 'select-field',
      id: 'subtype',
      group: {
        label: 'Select a subtype'
      },
      input: {
        textField: 'name',
        valueField: 'name',
        placeholder: 'Disease Subtype',
        options: []
      }
    }
  ]

  if (!isLeadDisease) {
    schema.push({
      // stage
      type: 'select-field',
      id: 'stage',
      group: {
        label: 'Select a stage'
      },
      input: {
        textField: 'name',
        valueField: 'name',
        placeholder: 'Disease Stage',
        options: []
      }
    })
  }

  return schema
}

function updateDisease (model, fields) {
  // fields[2].state.visible = store.getters['settings/useOriginalDiseases']

  const ancestor_ids = get(model, 'maintype.codes', [])
  if (truthy(ancestor_ids)) {
    if (store.getters['settings/useOriginalDiseases']) {
      getOptions(fields[1].input, {
        endpoint: 'trials/diseases',
        params: {
          type: 'subtype',
          ancestor_ids
        }
      })
      if (fields[2]) {
        getOptions(fields[2].input, {
          endpoint: 'trials/diseases',
          params: {
            type: 'stage',
            type_not: 'subtype',
            ancestor_ids
          }
        })
      }
    } else {
      getOptions(fields[1].input, {
        endpoint: 'trials/diseases',
        params: {
          nearest_maintype_ancestor: ancestor_ids.map(a => a.toLowerCase()),
          type: 'subtype'
        }
      })
      if (fields[2]) {
        getOptions(fields[2].input, {
          endpoint: 'trials/diseases',
          params: {
            type: 'stage',
            type_not: 'subtype',
            nearest_maintype_ancestor: ancestor_ids.map(a => a.toLowerCase())
          }
        })
      }
    }
  }
  fields[1].state.disabled = !truthy(ancestor_ids)
  if (fields[2]) {
    fields[2].state.disabled = !truthy(ancestor_ids)
  }
}

// options
async function getOptions (input, query) {
  let newQuery
  if (!store.getters['settings/useOriginalDiseases']) {
    let newParams = { ...query.params, version: 2 }
    newQuery = Object.assign(query, { params: newParams })
  } else {
    newQuery = query
  }

  try {
    input.loading = true
    let response = await apiGet(newQuery)
    input.options = get(response, 'data', [])
  } catch (error) {
                    // eslint-disable-next-line
                    console.log(error);
                  } finally {
    input.loading = false
  }
}
