<template>
  <data-view
    v-if="renderKey"
    class="page trials-page"
    id="studies"
    ref="trials"
    :context="'filter'"
    default-values="trial"
    icon="notes-medical"
    :resource="resource"
    title="Studies"
    variant="studies"
    :headers="headers"
    :schema="schema"
    :initial-values="values"
    :bind-active-fields.sync="bindActiveFields"
    :tabs="tabs"
    :tab-icons="['chart-bar']"
    :tab.sync="tab"
    :auto-height="false"
    :intro-configs="introConfigs"
    :filter-intro-configs="studyFilterIntroConfigs"
    can-download
    complex-filters
    can-filter
    persist
    portal
    show-action-btns
    show-filter-action-btns
    show-particiant-count
    @filtersSubmit="filtersSubmit"
    @goToParticipants="goToParticipants"
    :preservePagination="true"
  >
    <template v-slot:Analytics="tab">
      <trial-analytics-view
        v-bind="analyticsProps"
        :key="renderKeyAnalytics"
        :active="tab === 1"
        @openFilters="openFilters"
      ></trial-analytics-view>
    </template>
  </data-view>
</template>

<script>
import DataView from "@/components/presentation/data-view/DataView";
import TrialAnalyticsView from "@/views/entities/trial/views/TrialAnalyticsView";

// data
import Schema from "@/components/form/schemas/TrialSchema";
import Headers from "@/components/presentation/grid/headers/TrialHeaders";
import pageIntroConfigs from "@/steps/pages/pages";
import filterIntroConfigs from "@/steps/components/filters";
import { truthy } from "@/services/CommonsService.js";
import StudyKeys from "@/constants/StudyKeys.js";

export default {
  name: "trials-page",
  components: {
    DataView,
    TrialAnalyticsView,
  },
  props: {
    initialValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resource: "studies",
      renderKey: false,
      refresh: true,
      renderKeyAnalytics: 0,
      tab: 0,
      values: {},
      bindActiveFields: [],
      context: '',
      headers: this.$h.cloneDeep(Headers),
      schema: this.$h.cloneDeep(Schema),
      defaultIntroConfigs: pageIntroConfigs.studies,
      studyFilterIntroConfigs: filterIntroConfigs.studies,
      appliedLDFilter: false
    };
  },
  computed: {
    analyticsProps() {
      return {
        headers: this.headers,
        schema: this.schema,
        summaryFields: this.bindActiveFields,
      };
    },
    globalKeyword() {
      return this.$store.getters["form/globalKeyword"];
    },
    introConfigs() {
      let defaultIntroConfigs = this.$h.cloneDeep(this.defaultIntroConfigs);
      if (this.tab === 1) {
        defaultIntroConfigs.splice(3, 1, { show: false, intro: "" });
      }
      return defaultIntroConfigs;
    },
    developer() {
      return this.$store.getters["auth/roles"]?.includes("DEVELOPER");
    },
    tabs() {
      return this.developer ? ["Analytics"] : ["Analytics"];
    },
    savedHeaders: {
      get() {
        return this.$store.getters["headers/headers"];
      },
      set(headers) {
        this.$store.dispatch("headers/set", headers);
      },
    },
  },
  created() {
    this.updateAndGetStudiesFilters();
  },
  mounted() {
    this.$nextTick(() => {
      // get persisted
      if (this.$h.truthy(this.savedHeaders) && this.refresh) {
        let initHeaders = this.$h.cloneDeep(Headers);
        this.savedHeaders.forEach((header) => {
          let newHeader = initHeaders.find((h) => h.field === header.field);
          newHeader.hide = header.hide;
        });
        this.headers = this.$h.cloneDeep(initHeaders);
      } else {
        this.savedHeaders = [];
      }

      this.renderKey = true;
    });
  },
  methods: {
    openFilters() {
      this.$refs.trials.toggleFilters(true);
    },
    updateKeyword(_keyword) {
      this.values = { _keyword };
      this.renderKey = false;
      this.$nextTick(() => {
        this.renderKey = true;
      });
    },
    filtersSubmit() {
      this.$nextTick(() => {
        this.values = this.$store.getters["form/getForm"](
          "studies-filters"
        );

        // store participants filters
        let participantsFilters = this.$store.getters["form/getForm"]("participants-filters") ? this.$store.getters["form/getForm"]("participants-filters") : {};
        Object.keys(this.values).forEach((key) => {
          if(StudyKeys.studyKeys.includes(key) && this.values[key] !== undefined) {
            participantsFilters[key] = this.values[key];
          }else if(StudyKeys.studyKeys.includes(key)){
            // (StudyKeys.studyKeys.includes(key) && this.values[key] === undefined) means 
            // a study filter is deselected from the study page.
            participantsFilters[key] = undefined;
          }
        });

        this.$store.dispatch("form/saveForm", {
          id: "participants-filters",
          model: participantsFilters,
        });
      });
      this.renderKeyAnalytics++;
    },
    goToParticipants() {
      //-- When the participants page is loaded, pass the flag as to whether the lead disease filter is applied
      //-- as a param. This is essential to load the participants accordingly
      this.$router.push({
        name: `participants`,
        params: {
          appliedLDFilter: this.appliedLDFilter
        },
      });
    },
    formatForParticipants(params) {
      //-- Prepare the params to be passed for the Participants page
      this.bindActiveFields.forEach( (filter, index, thisBindFields) => {
        if (filter.id === "lead-disease" && filter.applyFilter) {
          this.appliedLDFilter = true;
        }
      });

      let returnParams = {};

      Object.keys(params).forEach((key) => {
        if (truthy(params[key])) {
          returnParams[key] = params[key];
        }
      });

      // special param
      if (returnParams["trial-type"] === -2) {
        delete returnParams["trial-type"];
      }

      return returnParams;
    },
    updateAndGetStudiesFilters(){
      // get any study filters appied on the participants page
      let newModel = this.$store.getters["form/getForm"]("studies-filters");

      // check if lead disease filter is applied from Home page 'Studies by Lead Diseases'
      if(this.initialValues["lead-disease"] !== undefined &&
       Object.keys(this.initialValues["lead-disease"]).length > 0) {
        this.applyLeadDiseaseFromHomeDashboard();
        newModel["lead-disease"] = this.initialValues["lead-disease"];
      }

      // store study filters
      this.$store.dispatch("form/saveForm", {
        id: "studies-filters",
        model: newModel,
      });

      // Get study filters from the store and push the update to the DOM
      this.$nextTick(() => {
        this.values = this.$store.getters["form/getForm"](
          "studies-filters"
        );
      });
    },
    applyLeadDiseaseFromHomeDashboard(){
      // Turn on leadDiseaseSearchHomeDashboard flag when user has applied LD filters from home page
      this.$store.commit('search/setLeadDiseaseSearchHomeDashboard', true);
      this.$store.commit('search/setLeadDiseaseSearch', false);
    },
  },
  watch: {
    globalKeyword: "updateKeyword",
    headers: {
      handler(headers) {
        this.savedHeaders = this.$h.cloneDeep(headers);
        let header = headers.find((h) => h.field === "total_accruals");
        this.resource = header?.hide ? "studies" : "studiesTotalAccrual";
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.refresh = false;
    });
  },
};

</script>

<style lang="scss">
.introjs-fixParent {
  z-index: 9999 !important;
}
</style>