<template>
  <div>
    <form-builder
      id="form-field"
      no-persist
      no-actions
      submit-on-change
      submit-on-mount
      :schema="schema"
      :values="value"
      @change="formSubmit"
    ></form-builder>

    <div class="age-err">{{errMsg}}</div>
  </div>
</template>

<script>
import {convertInYears} from '@/components/form/schemas/AgeSchema'
import { truthy } from '../../../services/CommonsService';

export default {
  name: "form-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: {
    event: "change",
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
  },
  data() {
    return {
      values: {},
      update: false,
      errMsg: '',
    };
  },
  methods: {
    formSubmit(values) {
      this.validateAge(values);
      this.$emit("change", values);
    },
    validateAge(values) {
      let errMsg= '';
      if(truthy(values)){
        let minAgeInYears = convertInYears(values['min-age-input'], values['min-age-selector'].value);
        let maxAgeInYears = convertInYears(values['max-age-input'], values['max-age-selector'].value);
        if (minAgeInYears < 0 || minAgeInYears > 999 || minAgeInYears.length === 0) {
          this.errMsg = 'Min. age must be between 0 - 999 years'
        } else if (maxAgeInYears < 0 || maxAgeInYears > 999 || maxAgeInYears.length === 0) {
          this.errMsg =  'Max. age must be between 0 - 999 years'
        } else if ((minAgeInYears - maxAgeInYears) > 0) {
          this.errMsg =  'Max. age can not be less than min. age'
        } else {
          this.errMsg = '';
        }
      }
    }
  },
  computed: {
  },
  mounted() {
    this.validateAge(this.value);
  }, 
  watch: {
    // this.update = !this.update; // update on reset
  },
};
</script>

<style lang="scss">
#studies-filters-age-input, #participants-filters-study-participant-char-age {
  form {
    display: flex;
    label {font-size: 14px;}
  }
}

#form-field-min-age-selector,
#form-field-max-age-selector {
  flex: 0 0 auto;
  padding-top: 29px;
  .multiselect__tags,
  .multiselect__single,
  .multiselect__spinner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-group {margin-bottom: 0.5rem;}
}
#form-field-max-age-input {margin-left: 50px;}
#form-field-min-age-input,
#form-field-max-age-input {
  flex: 0 1 100px;
  .form-group {margin-bottom: 0.5rem;}
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
  }
}
.age-err {
  font-weight: normal;
  font-size: 80%;
  color: #e74c3c;
}
</style>