<template>
  <div class="filter-action-group" :class="{ focus }">
    <!-- keyword -->
    <span
      v-tooltip="
        showKeywordSearch
          ? ''
          : 'This feature is presently unavailable when a container is selected'
      "
    >
      <div
        class="keyword-container"
        :class="{
          active: keyword,
          'keyword-disabled': !showKeywordSearch,
          'no-filter': !canFilter,
        }"
      >
        <icon class="search-icon" icon="filter"></icon>
        <text-input
          class="keyword-input"
          type="text"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          :placeholder="`Filter ${title}...`"
          :value="formatKeyword(keyword)"
          :disabled="!showKeywordSearch"
          @input="$emit('keyword', $event)"
          @focus="focus = true"
          @blur="focus = false"
        ></text-input>
      </div>
    </span>

    <!-- filters -->
    <span
      v-tooltip="
        showFilter
          ? ''
          : 'This feature is presently unavailable when a container is selected'
      "
    >
      <btn
        class="filter-btn"
        v-show="canFilter"
        variant="header"
        :class="{ active: fields }"
        id="filters"
        :disabled="disabled || !showFilter"
      >
        Filter Options
        <icon class="ml-1" icon="chevron-down"></icon>
        <!-- <span v-if="fields">({{fields}})</span> -->
      </btn>
    </span>
    <popover
      id="no-pad"
      target="filters"
      triggers="click blur"
      placement="bottom"
    >
      <ul class="filter-btn-popover">
        <li>
          <btn variant="link" class="filter-popover-btn" @click="$emit('open')"
            >Open Filter Selections Window</btn
          >
        </li>
        <li>
          <btn
            variant="link"
            class="filter-popover-btn"
            @click="$emit('open-my-filters')"
            >Apply My Filters</btn
          >
        </li>
      </ul>
    </popover>

    <div class="outline-wrapper" :class="{ focus }"></div>
  </div>
</template>

<script>
import Popover from "bootstrap-vue/es/components/popover/popover";
import { truthy } from "../../../../services/CommonsService";

export default {
  name: "filter-action-group",
  components: { Popover },
  props: {
    fields: Number,
    canFilter: Boolean,
    showFilter: Boolean,
    showKeywordSearch: Boolean,
    disabled: Boolean,
    keyword: [String, Object],
    title: String,
  },
  data() {
    return {
      focus: false,
    };
  },
  methods: {
    formatKeyword(keyword){
      // make sure keyword is a string before passing to text-input to avoid console errors.
      let res = "";
      if(typeof keyword === 'string'){
        res = keyword;
      }else if(typeof keyword === 'object' && truthy(keyword)){
        res = JSON.stringify(keyword);
      }
      return res;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/component";

$input-height: 44px;
$input-width: 200px;

.filter-action-group {
  position: relative;

  &:hover .outline-wrapper {
    border-color: $link;
  }
  &.focus {
    .form-control,
    .filter-btn {
      background-color: white !important;
    }
  }
}
.outline-wrapper {
  position: absolute;
  border: solid thin $n3;
  border-radius: $border-radius-sm;
  border-radius: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  pointer-events: none;
  transition: box-shadow 0.2s ease;

  &.focus {
    border-color: $link;
    box-shadow: $btn-shadow !important;
  }
}
.filter-popover-btn {
  text-decoration: none !important;

  &:hover {
    background-color: $n1;
  }
  &:active,
  &:focus {
    background-color: $n2 !important;
    color: $link !important;
  }
}
.keyword-container {
  position: relative;

  &.no-filter {
    overflow: hidden;
    border-radius: 25px;
  }
  &.keyword-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .search-icon {
    position: absolute;
    margin-top: 1rem;
    left: 1rem;
    color: $text;
    font-size: 0.8rem;
  }
  .form-control {
    border: none;
    background-color: $n1;
    height: $input-height;
    width: $input-width;
    padding-left: 2.25rem;
    color: $text;
    transition: background-color 0.2s ease;
    border-radius: 25px 0 0 25px !important;
    border-right: solid thin $n3;
    &:focus {
      box-shadow: none !important;
      // width: 275px;
    }
  }
}
.filter-btn {
  border-radius: 0 25px 25px 0 !important;
  height: $input-height;
  font-size: 0.9rem;
  font-weight: $regular;
  padding-left: 0.75rem;
  padding-right: 0.5rem;
  color: $link;
  position: relative;
  border: none !important;
  background-color: white !important;
  border-left: solid thin $n3;

  &:hover {
    color: darken($link, 10);
  }
  &:active {
    background-color: $n3 !important;
    color: darken($link, 15);
  }
  &:focus {
    color: darken($link, 20);
    box-shadow: none !important;
  }
  &:active {
    &:before {
      box-shadow: 0 0 0 0.2rem rgba(100, 149, 237, 0.25);
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.filter-btn-popover {
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
    display: block;

    &:first-child button {
      border-radius: 6px 6px 0 0;
    }
    &:last-child button {
      border-radius: 0 0 6px 6px;
    }

    button {
      background-color: transparent;
      border-color: transparent;
      color: $text;
      width: 100%;
      text-align: left;
      padding: 1rem 1.5rem;
      border-radius: 0;

      &:active,
      &:focus {
        color: $link;
        outline: none;
        box-shadow: none !important;
      }
    }
    &:first-child {
      border-bottom: solid thin $border-color;
    }
  }
}
@include media-breakpoint-down(sm) {
  .keyword-container input:focus {
    width: $input-width !important;
  }
}
</style>
