let steps = [];

export default function getSteps() {
  steps = [
    {
      element: '#organization-detail-header > div.entity-title-row > div.entity-detail-title',
      intro: `Show a specific organization's name.<br><a style="font-size: .9rem;" href="/documentation/docs/landing-org/" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: '#organization-detail-header > div.entity-title-row > div.entity-detail-ids',
      intro: 'Show Organization ID and CTEP Organization ID if any.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: '#organization-detail-header > ul.entity-contact-row',
      intro: 'Show contact information.',
      tooltipClass: 'regular-tooltip-bg'
    }
  ];
  return steps;
}