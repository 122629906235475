<template>
  <pagination
    ref="pg"
    style="z-index: 0"
    :limit="limit"
    :value="page + 1"
    :disabled="total === 0"
    :total-rows="activeTotal"
    :per-page="size"
    :key="loading"
    @change="(newPage) => $emit('pageChange', newPage - 1)"
  ></pagination>
</template>

<script>
import Pagination from "bootstrap-vue/es/components/pagination/pagination";

export default {
  name: "pagination-controller",
  components: { Pagination },
  props: {
    page: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 10,
    },
    ignoreMax: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
  },
  computed: {
    activeTotal() {
      return this.total >= 50000 && !this.ignoreMax ? 50000 : this.total;
    },
  },
  methods: {
    pgGoto(pageNum) {
      this.$refs.pg.currentPage = pageNum + 1;
    },
  },
  watch: {
    loading(loading) {
      // eslint-disable-next-line
      console.log(loading);
    },
  },
};
</script>

<style lang="scss">
.pagination {
  li.disabled .page-link {
    background-color: fade-out(black, 0.975);
    color: #5f5e5e;
  }
}
</style>

