import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// global components
import GlobalComponents from '@/components/global';
for (const component in GlobalComponents) {
  Vue.component(component, GlobalComponents[component]);
}

// filters
import * as Filters from '@/services/FilterService';
for (const filter in Filters) {
  Vue.filter(filter, Filters[filter]);
}

// directives
import Directives from '@/services/DirectiveService';
for (const directive in Directives) {
  Vue.directive(directive, Directives[directive]);
}

// helpers
import Helpers from '@/services/HelperService';
Vue.use({
  install() {
    Vue.prototype.$h = Helpers;
  }
});

// portal
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

// icons
import '@/services/IconService';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('icon', FontAwesomeIcon);

// worker
import VueWorker from 'vue-worker';
Vue.use(VueWorker);

import 'intro.js/introjs.css';
// import 'intro.js/themes/introjs-nazanin.css'; // another template

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
