import { Options, Utils } from '../utils'
import get from 'lodash/get'
import set from 'lodash/set'
import { truthy } from '@/services/CommonsService'
import moment from 'moment'

const DateTypeSchema = [
  {
    type: 'radio-field',
    id: 'registration-date-range-interval',
    default: 'month',
    group: {
      label: 'Select a time interval',
    },
    input: {
      textField: 'label',
      valueField: 'value',
      options: Options.getOptions('interval'),
    },
    mounted(model, fields) {
      setDateInterval(this.value, fields)
    },
    changed(model, fields) {
      setDateInterval(this.value, fields)
    },
    isActive: () => false,
  },
  {
    type: 'date-field',
    id: 'registration-date-range-from',
    default: undefined,
    group: {
      label: 'Select a date range',
    },
    input: {
      placeholder: 'From',
      interval: 'month',
      minDate: '1978-1-1',
      openDate: new Date('2018-1-1'),
      inline: false,
      clearButton: true,
      maxDate: moment().format('YYYY-MM-DD'),
    },
    changed(model, fields) {
      if (this.value !== undefined) {
        let fromDate = Utils.getField('registration-date-range-to', fields)
        fromDate.input.minDate = moment(this.value).add(1, 'day').format('YYYY-MM-DD')
      }
    },
    mounted(model, fields) {
      if (this.value !== undefined) {
        let fromDate = Utils.getField('registration-date-range-to', fields)
        fromDate.input.minDate = moment(this.value).add(1, 'day').format('YYYY-MM-DD')
      }
    },
    isActive: () => false,
  },
  {
    type: 'date-field',
    id: 'registration-date-range-to',
    default: undefined,
    group: {},
    input: {
      placeholder: 'To',
      interval: 'month',
      inline: false,
      minDate: '1978-1-1',
      maxDate: moment()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    },
    changed(model, fields) {
      if (this.value !== undefined) {
        let fromDate = Utils.getField('registration-date-range-from', fields)
        fromDate.input.maxDate = moment(this.value).format('YYYY-MM-DD')
      }
    },
    mounted(model, fields) {
      if (this.value !== undefined) {
        let fromDate = Utils.getField('registration-date-range-from', fields)
        fromDate.input.maxDate = moment(this.value).format('YYYY-MM-DD')
      }
    },
    isActive: () => false,
  },
]

export default [
  {
    type: "form-field",
    id: "registration-date-range",
    default: {},
    studyFilterGroup: {
      label: "Dates",
    },
    group: {
      label: "Record Verification Date",
    },
    input: {
      schema: DateTypeSchema,
    },
    isActive() {
      const from = get(this, "value.registration-date-range-from");
      const to = get(this, "value.registration-date-range-to");
      return from !== undefined && to !== undefined;
    },
    get(model, field, fields, values) {
      const interval = get(
        values,
        "registration-date-range.registration-date-range-interval"
      );
      const from = get(
        values,
        "registration-date-range.registration-date-range-from"
      );
      const to = get(
        values,
        "registration-date-range.registration-date-range-to"
      );

      if (from !== undefined && to !== undefined) {
        const dates = normalizeDate(from, to, interval);
        model[`record_verification_date_begin`] = dates.begin;
        model[`record_verification_date_end`] = dates.end;
      }
    },
  },
];

function normalizeDate(from, to, interval) {
  const begin = moment(from)
  const end = moment(to)
  const format = 'YYYY-MM-DD'
  let returnObject = {}

  if (interval === 'month') {
    returnObject['begin'] = begin.startOf('month').format(format)
    returnObject['end'] =
      end.month() === moment().month()
        ? getCurrentDate(end)
        : end.endOf('month').format(format)
  } else if (interval === 'year') {
    returnObject['begin'] = begin.startOf('year').format(format)
    returnObject['end'] =
      end.year() === moment().year()
        ? moment().format(format)
        : end.endOf('year').format(format)
  } else {
    returnObject['begin'] = begin.format(format)
    returnObject['end'] = end.format(format)
  }

  return returnObject
}
function getCurrentDate(date) {
  let newDate = ''
  if (date.month() < moment().month()) {
    newDate = date.endOf('month').format('YYYY-MM-DD')
  } else {
    newDate = moment().format('YYYY-MM-DD')
  }
  return newDate
}
function setDateInterval(value, fields) {
  let dateFrom = Utils.getField('registration-date-range-from', fields)
  let dateTo = Utils.getField('registration-date-range-to', fields)
  dateFrom.input.interval = value
  dateTo.input.interval = value
}