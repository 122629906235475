let steps = [];

export default function getSteps() {
  steps = [
    {
      element: '#investigator-detail-header > div.entity-title-row > div.entity-detail-title',
      intro: `Show a specific investigator's name.<br><a style="font-size: .9rem;" href="/documentation/docs/landing-investigator/" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: '#investigator-detail-header > div.entity-title-row > div.entity-detail-ids',
      intro: 'Show Investigator ID and CTEP Investigator ID if any.',
      tooltipClass: 'regular-tooltip-bg'
    }
  ];
  return steps;
}