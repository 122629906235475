<template>
  <div class="search-results">
    <div class="search-container-warning" v-if="activeContainerName">
      <icon class="icon" icon="info-circle"></icon>
      <span class="message"
        >Showing data for the
        <b :title="activeContainerName">{{
          activeContainerName | abbreviate(50)
        }}</b></span
      >
    </div>

    <div class="search-results-empty" v-if="total === 0">
      <!-- No results found for <b>{{search | abbreviate(55)}}</b> -->
      <span
        v-html="
          emptyMessage || 'No studies, organizations, or investigators found'
        "
      ></span>
    </div>

    <div class="search-results-body">
      <div class="search-results-scroll-container">
        <ul class="search-results-list" v-if="total !== 0 && !hide">
          <!-- entities -->
          <li
            v-for="(entity, index) in rankedEntities"
            :key="`entity-${index}`"
            :class="`result ${entity.type} delay-${index}`"
          >
            <!-- studies -->
            <div v-if="entity.type === 'studies' && show.studies">
              <router-link
                :to="`/${entity.type}/${entity.id}`"
                tabindex="0"
                :class="`entity-card ${entity.type} linkable entity-card-g`"
                v-if="!emitClick"
                :title="entity.name"
              >
                <div class="icon" v-tooltip="'Study'">
                  <icon icon="notes-medical"></icon>
                </div>
                <div class="body">
                  <div class="title" v-html="entity.displayName"></div>
                  <div class="id" v-html="entity.displayId"></div>
                  <div
                    class="id"
                    v-if="entity.nct_id"
                    v-html="entity.nct_id"
                  ></div>
                  <div
                    class="id no-bullet"
                    v-if="entity.lead_organization_id"
                    v-html="entity.lead_organization_id"
                  ></div>
                </div>
              </router-link>
              <div
                @click.prevent="$emit('click', entity)"
                tabindex="0"
                :class="`entity-card ${entity.type} linkable entity-card-g`"
                v-else
                :title="entity.name"
              >
                <div class="icon" v-tooltip="'Study'">
                  <icon icon="notes-medical"></icon>
                </div>
                <div class="body">
                  <div class="title" v-html="entity.displayName"></div>
                  <div class="id" v-html="entity.displayId"></div>
                  <div
                    class="id"
                    v-if="entity.nct_id"
                    v-html="entity.nct_id"
                  ></div>
                  <div
                    class="id no-bullet"
                    v-if="entity.lead_organization_id"
                    v-html="entity.lead_organization_id"
                  ></div>
                </div>
              </div>
            </div>

            <!-- organizations -->
            <div v-if="entity.type === 'organizations' && show.organizations">
              <router-link
                :to="`/${entity.type}/${entity.id}`"
                tabindex="0"
                :class="`entity-card ${entity.type} linkable entity-card-g`"
                v-if="!emitClick"
              >
                <div class="icon" v-tooltip="'Organization'">
                  <icon icon="hospital"></icon>
                </div>
                <div class="body">
                  <div class="title" v-html="entity.displayName"></div>
                  <div class="id" v-html="entity.displayId"></div>
                </div>
              </router-link>
              <div
                @click.prevent="$emit('click', entity)"
                tabindex="0"
                :class="`entity-card ${entity.type} linkable entity-card-g`"
                v-else-if="
                  entity.type === 'organizations' && show.organizations
                "
              >
                <div class="icon" v-tooltip="'Organization'">
                  <icon icon="hospital"></icon>
                </div>
                <div class="body">
                  <div class="title" v-html="entity.displayName"></div>
                  <div class="id" v-html="entity.displayId"></div>
                </div>
              </div>
            </div>

            <!-- investigators -->
            <router-link
              :to="`/${entity.type}/${entity.id}`"
              tabindex="0"
              :class="`entity-card ${entity.type} linkable entity-card-g`"
              v-if="entity.type === 'investigators' && show.investigators"
            >
              <div class="icon" v-tooltip="'Investigator'">
                <icon icon="user-md"></icon>
              </div>
              <div class="body">
                <div class="title" v-html="entity.displayName"></div>
                <div class="id" v-html="entity.displayId"></div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="search-results-footer" v-if="!noFooter">
      <btn
        :disabled="studies.total === 0"
        class="badge studies"
        variant="studies"
        @click="goToEntity('studies')"
        ><icon icon="notes-medical"></icon>{{ studies.total | localeString }}
        <span>studies</span></btn
      >
      <btn
        :disabled="organizations.total === 0"
        class="badge organizations"
        variant="organizations"
        @click="goToEntity('organizations')"
        ><icon icon="hospital"></icon>{{ organizations.total | localeString }}
        <span>organizations</span></btn
      >
      <btn
        :disabled="investigators.total === 0"
        class="badge investigators"
        variant="investigators"
        @click="goToEntity('investigators')"
        ><icon icon="user-md"></icon>{{ investigators.total | localeString }}
        <span>investigators</span></btn
      >
      <btn
        v-if="$h.hasAccess('participants')"
        :disabled="participants.total === 0"
        class="badge participants"
        variant="participants"
        @click="goToEntity('participants')"
        ><icon icon="users"></icon>{{ participants.total | localeString }}
        <span>participants</span></btn
      >
    </div>
  </div>
</template>

<script>
import { hightlightText } from "@/services/CommonsService";
import { EventBus } from "@/event-bus.js";

const rankRules = {
  studyId: 1,
  orgId: 2,
  invId: 3,
  orgName: 5,
  invName: 6,
  studyTitle: 4,
  default: 7,
};

export default {
  name: "search-results",
  components: {  },
  props: {
    studies: Object,
    organizations: Object,
    investigators: Object,
    participants: Object,
    emptyMessage: String,
    search: String,
    noFooter: Boolean,
    emitClick: Boolean,
    studyAbbreviation: {
      default: 63,
    },
  },
  data() {
    return {
      hide: true,
      rankedEntities: [],
      show: {
        studies: true,
        organizations: true,
        investigators: true,
      },
    };
  },
  mounted() {
    this.hide = true;
    setTimeout(this.rankEntities, 100);
    window.addEventListener("keydown", this.keyboardNavigation);
  },
  computed: {
    total() {
      return (
        this.$h.get(this.studies, "total", 0) +
        this.$h.get(this.organizations, "total", 0) +
        this.$h.get(this.investigators, "total", 0)
      );
    },
    activeContainer() {
      return this.$store.getters["settings/activeContainer"];
    },
    activeContainerName() {
      return this.$h.truthy(this.activeContainer)
        ? this.activeContainer.name
        : "";
    },
  },
  methods: {
    rankEntities(size = 6) {
      const rankedStudies = this.$h
        .get(this.studies, "response", [])
        .map(this.rankStudy);
      const rankedOrganizations = this.$h
        .get(this.organizations, "response", [])
        .map(this.rankOrganization);
      const rankedInvestigators = this.$h
        .get(this.investigators, "response", [])
        .map(this.rankInvestigator);

      let rankedEntities = [
        ...rankedStudies.slice(0, size),
        ...rankedOrganizations.slice(0, size),
        ...rankedInvestigators.slice(0, size),
      ];
      const maxSize = 18;

      if (rankedEntities.length < maxSize) {
        rankedEntities = rankedEntities.concat(
          rankedStudies.slice(size, maxSize - rankedEntities.length + size)
        );
      }
      if (rankedEntities.length < maxSize) {
        rankedEntities = rankedEntities.concat(
          rankedOrganizations.slice(
            size,
            maxSize - rankedEntities.length + size
          )
        );
      }
      if (rankedEntities.length < maxSize) {
        rankedEntities = rankedEntities.concat(
          rankedInvestigators.slice(
            size,
            maxSize - rankedEntities.length + size
          )
        );
      }

      this.rankedEntities = rankedEntities.sort((a, b) => a.rank - b.rank);
      this.hide = false;
    },

    goToEntity(entity) {
      this.$store.dispatch("form/clearForm", `${entity}-filters`);
      this.updateStudyOrParticpantFilter(entity);
      this.$router.push({
        name: entity,
        params: {
          initialValues: {
            _keyword: this.search,
          },
        },
      });
      if (this.$route.name === entity) {
        this.$store.dispatch("form/updateKeyword", this.search);
        EventBus.$emit("dataview-reload");
      }

      this.$emit("close");
    },

    rankStudy(study) {
      let rank = rankRules.default;
      let id = study.nci_id;
      let nctID = study.nct_id;
      let leadOrgID = study.lead_organization_id;
      let name = study.public_title;

      if (match(id, this.search)) {
        rank = rankRules.studyId;
      } else if (match(name, this.search)) {
        rank = rankRules.studyTitle;
      }

      return {
        rank,
        id,
        name,
        type: "studies",
        displayId: this.highlightStudy(id),
        displayName: this.highlightStudy(name),
        nct_id: this.highlightStudy(nctID),
        lead_organization_id: this.highlightStudy(leadOrgID),
      };
    },
    rankOrganization(organization) {
      let rank = rankRules.default;
      let id = organization.id;
      let name = organization.name;

      if (match(id, this.search)) {
        rank = rankRules.orgId;
      } else if (match(name, this.search)) {
        rank = rankRules.orgName;
      }

      return {
        rank,
        id,
        name,
        type: "organizations",
        displayId: this.highlightOrganization(id),
        displayName: this.highlightOrganization(name),
      };
    },
    rankInvestigator(investigator) {
      let rank = rankRules.default;
      let id = investigator.id;
      let name = investigator.name;

      if (match(id, this.search)) {
        rank = rankRules.invId;
      } else if (match(name, this.search)) {
        rank = rankRules.invName;
      }

      return {
        rank,
        id,
        name,
        type: "investigators",
        displayId: this.highlightInvestigator(id),
        displayName: this.highlightInvestigator(name),
      };
    },

    highlightStudy(value) {
      return hightlightText(
        value,
        this.search,
        "studies",
        this.studyAbbreviation
      );
    },
    highlightOrganization(value) {
      return hightlightText(value, this.search, "organizations", 57);
    },
    highlightInvestigator(value) {
      return hightlightText(value, this.search, "investigators", 57);
    },

    keyboardNavigation(e) {
      const entities = document.getElementsByClassName("entity-card-g");
      const activeElement = document.activeElement;
      e = e || window.event;

      if (this.$h.truthy(entities)) {
        if (e.keyCode == "38") {
          arrowUp(activeElement, entities);
          e.preventDefault();
        } else if (e.keyCode == "40") {
          arrowDown(activeElement, entities);
          e.preventDefault();
        }
      }
    },

    updateStudyOrParticpantFilter(entity){
      /* Update the study keyword filter when 
       searching for study keyword in search bar */
      if (entity === "studies") { 
        this.$store.dispatch("form/saveForm", {
          id: "studies-filters",
          model: {'_keyword_form':{'_keyword': this.search, keyword_search_type: []}},
        });
      }
      /* Update the participant keyword filter when 
      searching for participant keyword in search bar */
      else if (entity === "participants") { 
        this.$store.dispatch("form/saveForm", {
          id: "participants-filters",
          model: {'_keyword':this.search},
        });
      }
    },
  },
  watch: {
    $route() {
      this.$emit("close");
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyboardNavigation);
  },
};

function arrowUp(activeElement, entities) {
  if (activeElement.classList.contains("entity-card-g")) {
    let prev = findPrevTabStop(activeElement, entities);
    if (prev !== undefined) {
      prev.focus();
    } else {
      let input = document.getElementById("global-search-input");
      setTimeout(() => {
        input.select();
      }, 10);
    }
  }
}
function arrowDown(activeElement, entities) {
  if (activeElement.classList.contains("entity-card-g")) {
    let next = findNextTabStop(activeElement, entities);
    if (next !== undefined) {
      next.focus();
    }
  } else {
    entities[0].focus();
  }
}
function findNextTabStop(el, entities) {
  let list = Array.prototype.filter.call(entities, function (item) {
    return item.tabIndex >= "0";
  });
  let index = list.indexOf(el);
  return list[index + 1] || undefined;
}
function findPrevTabStop(el, entities) {
  let list = Array.prototype.filter.call(entities, function (item) {
    return item.tabIndex >= "0";
  });
  let index = list.indexOf(el);
  return list[index - 1] || undefined;
}
function match(value, search) {
  let regEx = new RegExp(search, "ig");
  if (typeof value === "string") {
    return value.search(regEx) !== -1;
  } else {
    return false;
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.search-results {
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
.search-container-warning {
  background-color: #fdf8ec;
  color: $warn-text-color;
  border-bottom: solid thin darken($warn-border-color, 10);
  text-align: center;
  padding: 0.5rem 0;

  .icon {
    font-size: 1rem;
    margin-right: 0.75rem;
  }
  .message {
    font-weight: $regular;
    font-size: 0.9rem;
    span {
      font-weight: $thin;
    }
  }
}
.search-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;
  margin: 0 0 1rem;

  flex: 0 0 auto;
}
.search-results-empty {
  padding: 1.5rem 1.5rem 0.5rem;
  margin: 0 0 1rem;
  font-weight: $thin;
}
.search-results-body {
  flex: 0 0 calc(100% - 55px);
}
.search-results-scroll-container {
  max-height: 155px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
  max-height: 70vh;
}
.search-results-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 55px;
  padding: 0 0.75rem;
  border-top: solid thin $n3;
  pointer-events: auto;
  background-color: white;
}
.btn.badge {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  padding: 0.4rem 0.75rem 0.3rem;
  border-radius: 25px;
  font-weight: $bold;
  border: solid 1px;
  color: white;

  svg {
    margin-right: 0.45rem;
    display: none;
  }
  span {
    font-weight: $thin;
  }
}
.header {
  font-size: 0.9rem;
  font-weight: $thin;
}
.search-results-list {
  margin: 0;
  padding: 0;
  li {
    display: block;
    margin: 0;
    padding: 0;
  }
}
.result {
  pointer-events: auto;
}
</style>
