<template>
  <div
    class="filters-popover popover-pad-lg"
    v-if="!filterSelected"
    :class="{ loading }"
  >
    <div class="filters-popover__header">
      <h3>Manage My Filters</h3>
      <btn
        :disabled="loading"
        class="filters-popover__refresh"
        variant="outline-circle"
        icon
        @click="$emit('reload')"
        ><icon size="sm" icon="sync"></icon
      ></btn>
      <btn
        class="filters-popover__close popover-action"
        variant="outline-circle"
        @click="$emit('close')"
        ><icon icon="times"></icon
      ></btn>
    </div>

    <table class="filters-popover__table">
      <tr style="width: 350px" class="filters-popover__table-header">
        <th class="filename">File Name</th>
        <th style="width: 50px">
          <div>View</div>
          <div>(Edit/Apply)</div>
        </th>
        <!-- <th>
        <div>Export</div>
        <span>(All Columns)</span>
      </th> -->
        <th style="width: 50px">Delete</th>
      </tr>

      <!-- pre defined filters -->
      <tr
        class="filters-popover__table-row info"
        v-if="preDefinedFilters.length !== 0"
      >
        <td colspan="4">
          SYSTEM PRE-DEFINED FILTERS ({{ preDefinedFilters.length }})
        </td>
      </tr>
      <tr
        class="filters-popover__table-row pre-defined"
        v-for="(filter, i) in preDefinedFilters"
        :key="i"
      >
        <td style="width: 350px" class="filename" v-text="filter.label"></td>
        <td style="width: 50px">
          <btn variant="link" class="button" @click="selectFilter(filter)"
            ><icon icon="pencil-alt"></icon
          ></btn>
        </td>
        <td style="width: 50px" colspan="2">
          <!-- <btn variant="link" class="button" @click="download(filter)"
          ><icon icon="download"></icon
        ></btn> -->
        </td>
      </tr>

      <!-- user saved filters -->
      <tr class="filters-popover__table-row info">
        <td colspan="4">MY SAVED FILTERS</td>
      </tr>

      <tr
        class="filters-popover__table-row user-defined"
        v-for="(filter, i) in formattedFilters"
        :key="`filter-${i}`"
      >
        <td
          style="width: 350px"
          class="filename"
          v-text="filter.filter_name"
        ></td>
        <td style="width: 50px">
          <btn variant="link" class="button" @click="selectMyFilter(filter)"
            ><icon icon="pencil-alt"></icon
          ></btn>
        </td>
        <!-- <td>
                <btn variant="link" class="button" @click="download(filter)"
                  ><icon icon="download"></icon
                ></btn>
              </td> -->
        <td style="width: 50px">
          <btn
            variant="danger"
            class="button delete"
            @click="deleteFilter(filter)"
            ><icon icon="trash"></icon
          ></btn>
        </td>
      </tr>
      <div
        class="list-loading loading"
        v-if="loading && formattedFilters.length === 0"
      ></div>
      <div class="list-loading error" v-else-if="$h.truthy(error)"></div>
      <div
        class="list-loading empty"
        v-else-if="formattedFilters.length === 0"
      ></div>
    </table>
  </div>
  <div class="selected" v-else>
    <div class="selected__header">
      <h3 v-text="selectedFilter.label"></h3>
      <div class="flex"></div>
      <btn class="btn mr-3" :variant="variant" @click="cancelFilter"
        ><icon class="mr-2" icon="times"></icon>Cancel</btn
      >
      <btn class="btn mr-3" :variant="variant" @click="editFilter"
        ><icon class="mr-2" icon="pencil-alt"></icon>Edit</btn
      >
      <btn class="btn" :variant="variant" @click="applyFilter"
        ><icon class="mr-2" icon="check"></icon>Apply</btn
      >
    </div>
    <div class="selected__body">
      <h3>Searching for Studies...</h3>
      <!-- query summary -->
      <form-summary
        class="form-summary"
        :variant="variant"
        :values="selectedFilter.values"
        :schema="schema"
      ></form-summary>
    </div>
  </div>
</template>

<script>
/* istanbul ignore file */
import FormSummary from "@/components/form/summary/FormSummary";
import Defaults from "@/components/form/defaults";
import { resourceFilters } from "@/services/UserFilterService";

export default {
  name: "filter-popover",
  components: { FormSummary },
  props: [
    "filters",
    "error",
    "variant",
    "schema",
    "id",
    "filterKey",
    "headers",
    "resource",
    "activeFields",
    "query",
    "open",
    "loading",
  ],
  data() {
    return {
      filterQuery: {
        baseURL: "users",
        endpoint: "/user/filters",
        params: {},
      },
      selectedFilter: {},
      preDefinedFilters: this.$h.get(Defaults, this.id, []),
    };
  },
  computed: {
    formattedFilters() {
      return resourceFilters(this.filters, this.resource).sort((a, b) => {
        let nameA = a.filter_name[0].toLowerCase();
        let nameB = b.filter_name[0].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    filterSelected() {
      return this.$h.truthy(this.selectedFilter);
    },
  },
  methods: {
    selectMyFilter(filter) {
      this.selectFilter({
        id: filter.filter_id,
        label: filter.filter_name,
        values: filter.filter_object,
      });
    },
    selectFilter(filter) {
      this.selectedFilter = {
        id: "default",
        ...filter,
      };
    },
    cancelFilter() {
      this.selectedFilter = {};
    },
    applyFilter() {
      this.$emit("apply", this.selectedFilter);
      this.selectedFilter = {};
    },
    editFilter() {
      this.$emit("edit", this.selectedFilter);
      this.selectedFilter = {};
    },
    deleteFilter(filter) {
      this.cancelFilter();
      this.$emit("delete", filter.filter_id);
    },
    download(filter) {
      // TODO
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/component";
.filters-popover {
  width: auto;

  &.loading:after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: fade-out(white, 0.5);
  }

  &__header {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  &__refresh {
    position: absolute;
    top: 1rem;
    right: 3.75rem;

    &:disabled {
      animation: spin 2s linear infinite;
    }
  }
  &__table {
    border: none;

    &-header {
      background-color: fade-out(black, 0.95);
      th {
        padding: 0.5rem;
        text-align: center;

        &:first-child {
          padding-left: 1rem;
        }
        &:last-child {
          padding-right: 1rem;
        }
      }
    }
    &-row {
      &:nth-child(even) {
        background-color: $n1;
      }
      td {
        padding: 0.25rem;

        &:first-child {
          padding-left: 1rem;
        }
        &:last-child {
          padding-right: 1rem;
        }
      }
      &.info {
        padding: 0.25rem !important;
        background-color: fade-out($blue, 0.85);
        font-weight: $regular;
      }
    }

    .filename {
      min-width: 250px;
      text-align: left;
    }
    .button {
      border-color: transparent;
      background-color: transparent;
      color: $link;
      padding: 0.25rem 0.65rem;
      font-size: 1rem;

      &:not(.delete) {
        transform: translateX(1.5rem);
      }

      &:active,
      &:focus {
        background-color: transparent !important;
        color: darken($link, 10) !important;
      }

      &.delete {
        color: $danger;

        &:active,
        &:focus {
          color: darken($danger, 10) !important;
        }
      }
    }
  }
}
.selected {
  width: 638px;
  overflow: hidden;
  border-radius: $border-radius;

  &__header {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: fade-out($yellow, 0.5);

    .flex {
      flex: 0 1 100%;
    }
    .btn,
    h3 {
      flex: 0 0 auto;
    }
  }
  &__body {
    padding: 2rem;

    .form-summary {
      margin-top: 1rem;
      padding: 0 0.75rem;
    }
  }
}

.list-loading {
  position: relative;
  padding: 0.75rem;

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    text-align: center;

    width: 590px;
    font-size: 0.9rem;

    padding: 0.5rem 0;
  }
  &.error:after {
    content: "Failed to load filters, try again in a few minutes";
  }
  &.empty:after {
    content: "No Saved Filters";
  }
  &.loading:after {
    content: "Loading filters...";
  }
}
</style>