let steps = [];

export default function getSteps() {
  steps = [
    {
      element: '#trial-eligibility-page-header-widget-actions-help',
      intro: `This is the Eligibility Criteria tab of the Study landing page.<br><a style="font-size: .9rem;" href="/documentation/docs/landing-studies/#study-landing-page-eligibility-section" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg',
      highlightClass: 'special-highlight-bg'
    },
    {
      element: 'div.page.trial-eligibility > div.badges-container > div.badges-card',
      intro: 'This bar displays special criteria for the selected Study.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: 'div.trialSecDataTable',
      intro: 'This table displays Structured Eligibility Criteria (SEC) for the study.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      element: 'div.page.trial-eligibility > div.data-view-eligibility-container > div.page.trial-eligibility-view',
      intro: 'Click on the tabs to toggle the Descriptive Criteria section between Grid view and List view.',
      tooltipClass: 'regular-tooltip-bg'
    }
  ];
  return steps;
}