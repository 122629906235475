import mapActions from './actions';
import mapMutations from './mutations';
import mapGetters from './getters';

export default {

  namespaced: true,

  state: {

    // other
    maps: {},
    page: 'cluster',
    pageHistory: [],
    stateSelection: [],
    excludedStates: [],
    stateCounts: [],
    selectedOrganization: {},
    selectedStudy: {},
    zoom: 4,
    center: { lat: 39.8097343, lng: -98.5556199 },
    rendered: false,
    cluster: false,
    loading: false,
    collapsed: false,
    distance: false,
    error: false,
    minMax: {
      min: 0,
      max: 0,
    }

  },
  
  getters: mapGetters,
  mutations: mapMutations,
  actions: mapActions

};

