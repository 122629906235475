<template>
  <div>
    <div class="age-err">{{ errMsg }}</div>
    <form-builder
      id="form-field"
      no-persist
      no-actions
      submit-on-change
      submit-on-mount
      :schema="schema"
      :values="value"
      :key="updateKey"
      @change="formSubmit"
    ></form-builder>
  </div>
</template>

<script>
/* istanbul ignore file */
import { convertInYears } from "@/components/form/schemas/AgeSchema";

export default {
  name: "form-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: {
    event: "change",
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
  },
  data() {
    return {
      values: {},
      update: false,
      errMsg: "",
      updateKey: 0,
    };
  },
  methods: {
    formSubmit(values) {
      this.validateAge(values);
      this.$emit("change", values);
    },
    validateAge(values) {
      if (!this.$h.truthy(values)) {
        return;
      }

      let errMsg = "";
      let minAgeInYears = convertInYears(
        values["min-age-input"],
        values["min-age-selector"].value
      );
      let maxAgeInYears = convertInYears(
        values["max-age-input"],
        values["max-age-selector"].value
      );
      if (
        minAgeInYears < 0 ||
        minAgeInYears > 999 ||
        minAgeInYears.length === 0
      ) {
        this.errMsg = "Min. age must be between 0 - 999 years";
      } else if (
        maxAgeInYears < 0 ||
        maxAgeInYears > 999 ||
        maxAgeInYears.length === 0
      ) {
        this.errMsg = "Max. age must be between 0 - 999 years";
      } else if (minAgeInYears - maxAgeInYears > 0) {
        this.errMsg = "Max. age can not be less than min. age";
      } else {
        this.errMsg = "";
      }
    },
  },
  computed: {},
  mounted() {
    this.validateAge(this.value);
    let ageErrEle = this.$el.querySelector(".age-err");
    let sexEle = this.$el.querySelector("#form-field-sex_criteria");

    sexEle.parentNode.insertBefore(ageErrEle, sexEle);
  },
  watch: {
    value(value) {
      if (isEmpty(value)) {
        this.updateKey++; // update on reset
      }
    },
  },
};
function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
</script>

<style lang="scss">
#studies-filters-study-participant-char-sex,
#participants-filters-study-participant-char-sex {
  form {
    display: flex;
    label {
      font-size: 14px;
    }
    flex-wrap: wrap;
  }
  #form-field-sex_criteria {
    width: 100%;
  }
}
#studies-filters-study-participant-char-age-input,
#participants-filters-study-participant_age-input {
  form {
    display: flex;
    label {
      font-size: 14px;
    }
    flex-wrap: wrap;
  }
#form-field-min-age-selector,
#form-field-max-age-selector {
  flex: 0 0 auto;
  padding-top: 29px;
  .multiselect__tags,
  .multiselect__single,
  .multiselect__spinner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
}
#form-field-max-age-input {
  margin-left: 50px;
}
#form-field-min-age-input,
#form-field-max-age-input {
  flex: 0 1 100px;
  .form-group {
    margin-bottom: 0.5rem;
  }
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
  }
}
}
label[for="studies-filters-study-participant-char-age-input"].d-block {
  display: none !important;
}

label[for="participants-filters-study-participant-char-age-input"].d-block {
  display: none !important;
}
label[for="studies-filters-study-participant-char-sex-input"].d-block {
  display: none !important;
}
label[for="participants-filters-study-participant-char-sex-input"].d-block {
  display: none !important;
}

label[for="studies-filters-study-participant-char-priorTherapy-input"].d-block {
  display: none !important;
}
label[for="participants-filters-study-participant-char-priorTherapy-input"].d-block {
  display: none !important;
}

.age-err {
  font-weight: normal;
  font-size: 80%;
  color: #e74c3c;
}

#form-field-sex_criteria {
  margin-top: 1rem;
}
</style>