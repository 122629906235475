<template>
  <div class="content">

    <!-- Recent Searches -->
    <div class="section" v-if="recentSearches.length === 0">
      <h3 class="header">Suggested searches</h3>
      <ul class="content-list">
        <li class="item" v-for="(search, index) in suggestedSearches" :key="`s-${index}`" tabindex="0" @click="$emit('search', search)" @keydown.enter="$emit('search', search)">
          <icon icon="search"></icon><span v-html="search"></span>
        </li>
      </ul>
    </div>

    <!-- Suggested Searches -->
    <div class="section" v-else>
      <h3 class="header">Recent searches</h3>
      <a href="#" tabindex="-1" class="clear-history" v-if="recentSearches.length" @click.prevent="clearSearches">Clear</a>
      <ul class="content-list">
        <li class="item" v-for="(search, index) in recentSearches" :key="`r-${index}`" tabindex="0" @click="$emit('search', search)" @keydown.enter="$emit('search', search)">
          <icon icon="search"></icon><span>{{search | abbreviate(70)}}</span>
        </li>
      </ul>
    </div>

    <!-- Recently visited entities -->
    <div class="section" v-if="recentEntities.length !== 0">
      <h3 class="header">Recently visited</h3>
      <a href="#" tabindex="-1" class="clear-history" v-if="recentEntities.length" @click.prevent="clearEntities">Clear</a>
      <ul class="content-list">
        <router-link 
          :to="`/${entityMap[entity.entity]}/${entity.id}`" 
          tabindex="0"
          tag="li"
          :class="`entity-card ${entityMap[entity.entity]} linkable`"
          v-for="(entity, index) in recentEntities" 
          :key="`e-${index}`"
          :title="entity.title"
        >
          <div class="icon"><icon :icon="entityIcons[entity.entity]" /></div>
          <div class="body">
            <div class="title">{{entity.title | abbreviate(65)}}</div>
            <div class="id no-bullet" v-html="entity.id"></div>
          </div>
        </router-link>
      </ul>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'search-menu',
  computed: {
    ...mapGetters({
      recentSearches: 'search/recentSearches',
      recentEntities: 'search/recentEntities',
    })
  },
  data() {
    return {
      suggestedSearches: [
        'NCI-2015-00054',
        'Dana-Farber Cancer Institute',
        'Keith Flaherty',
      ],
      entityMap: {
        study: 'studies',
        site: 'organizations',
        investigator: 'investigators',
      },
      entityIcons: {
        study: 'notes-medical',
        site: 'hospital',
        investigator: 'user-md',
      }
    }
  },
  methods: {
    clearSearches() {
      this.$store.commit('search/setRecentSearches', [])
    },
    clearEntities() {
      this.$store.commit('search/setRecentEntities', [])
    },
  },
  watch: {
    '$route'() {
      this.$emit('close')
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.content {
  padding: 1.5rem 0;

  .header {
    padding: 0 1.5rem 0;
    font-size: .8rem;
    font-weight: $thin;
    margin-bottom: .5rem;
  }
}
.section {
  position: relative;
  
  &:hover a.clear-history {
    opacity: 1;
  }
}
.section:not(:last-child) {
  margin-bottom: 1.5rem;
}
.content-list {
  margin: 0; padding: 0;
}
.entity-card {
  padding: .8rem 1.5rem .7rem;
}
.item {
  margin: 0; padding: .4rem 1.5rem;
  display: block;
  cursor: pointer;
  pointer-events: auto;
  outline: none!important;

  &:hover {
    background-color: $n1;
  }
  &:active, &:focus {
    background-color: $link;

    span, svg {
      color: white;
    }
  }

  svg {
    font-size: .8rem;
    margin-right: .75rem;
    color: $n4;
  }
  span {
    font-size: 1rem;
    font-weight: $regular;
  }
}
a.clear-history {
  font-size: .8rem;
  position: absolute;
  right: 1.5rem;
  top: 0;
  pointer-events: auto;
  opacity: 0;
  transition: opacity .5s;
}

</style>
