export function addTitle(field) {
  setTimeout(() => {
    const targets = document.getElementsByClassName(
      `add-header-class-${field.colDef.field}`
    )
    for (let target of targets) {
      target.setAttribute('title', `${field.colDef.headerName}`)
    }
  }, 0)

  return `add-header-class-${field.colDef.field}`
}
