import { Getters, Options, Utils } from '../utils'
import { apiGet } from '@/services/DataService'
import { abbreviate } from '@/services/FilterService'
import { truthy } from '@/services/CommonsService'

import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

import BiomarkerSchema from './BiomarkerSchema'
import DiseaseSchema from './DiseaseSchema'
import StatusSchema from './StatusSchema'
import DateTypeSchema from './DateTypeSchema'
import RegistrationDateTypeSchema from './RegistrationDateTypeSchema'
import { getIDSearchSchema, getID } from './IDSearchSchema'
import { getSEC, getSECSearchTypeObj } from './SECSearchSchema'
import { getSECSchema } from "./SECSchema";
import store from '@/store'
import { getAgeSchema,convertInYears, getAgeValueObj } from './AgeSchema'
import { getSexSchema, getSexValueObj } from './SexSchema'
import { getFundingSchema } from './FundingSchema'


export default [
  // search funding info
  {
    type: "form-field",
    id: "funding",
    group: {
      label: "Funding Information",
    },
    studyFilterGroup: {
      label: "Study Funding Information",
    },
    input: {
      name: "FundingMechanism",
      schema: [...cloneDeep(getFundingSchema())],
    },
    default: {},
    get(returnObject) {
      if (truthy(this.value["funding-mechanism"])) {
        returnObject["resourcing.funding_mechanism"] =
          this.value["funding-mechanism"].code;
      }
      if (truthy(this.value["funding-institute"])) {
        returnObject["resourcing.nih_institute"] =
          this.value["funding-institute"].code;
      }
      if (truthy(this.value["funding-serial"])) {
        returnObject["resourcing.serial_number"] = this.value["funding-serial"];
      }
      if (truthy(this.value["funding-program-id"])) {
        returnObject["resourcing.nci_division_program"] =
          this.value["funding-program-id"].value;
      }
      if (this.value["funding-nci-fundType"]) {
        returnObject["nci_grant_provided"] = this.value["funding-nci-fundType"];
      }
      if (
        truthy(this.value["funding-mechanism"]) ||
        truthy(this.value["funding-institute"]) ||
        this.value["funding-serial"] ||
        truthy(this.value["funding-program-id"])
      ) {
        returnObject["resourcing.active"] = true;
      }
    },
    isActive() {
      return (
        truthy(this.value["funding-mechanism"]) ||
        truthy(this.value["funding-institute"]) ||
        this.value["funding-serial"] ||
        truthy(this.value["funding-program-id"]) ||
        truthy(this.value["funding-nci-fundType"])
      );
    },
  },
  /*-- Eligibility Criteria - Age Fiiter*/
  {
    type: "age-form-field",
    id: "study-participant-char-age",
    group: {
      label: "Eligibility Criteria A Age",
    },
   show: () => false,
   overrideShow: true,
    studyFilterGroup: {
      label: "Participant Characteristics",
    },
    input: {
      name: "Age",
      schema: [...cloneDeep(getAgeSchema())],
      valid: false,
    },
    default: {},
    get(returnObject) {
      getAgeValueObj(returnObject, this.value);
    },
    isActive() {
      if (
        !truthy(this.value["min-age-selector"]) ||
        !truthy(this.value["max-age-selector"])
      ) {
        return false;
      } else {
        let minYears = convertInYears(
          this.value["min-age-input"],
          this.value["min-age-selector"].value
        );
        let maxYears = convertInYears(
          this.value["max-age-input"],
          this.value["max-age-selector"].value
        );
        return parseInt(minYears) !== 0 || parseInt(maxYears) !== 999;
      }
    },
  },
  /*-- Eligibility Criteria - Sex Fiiter*/
  {
    type: "form-field",
    id: "study-participant-char-sex",
    show: () => false,
    overrideShow: true,
    group: {
      label: "Eligibility Criteria B Sex",
    },
    studyFilterGroup: {
      label: "Participant Characteristics",
    },
    input: {
      name: "Sex",
      schema: [...cloneDeep(getSexSchema())],
      valid: false,
    },
    default: {},
    get(returnObject) {
      getSexValueObj(returnObject, this.value["sex_criteria"]);
    },
    isActive() {
      if (truthy(this.value["sex_criteria"])) {
        return true;
      }
    },
  },
  /*-- Eligibility Criteria - Prior Therapy / SEC Fiiter*/
  {
    type: "form-field",
 //   show: () => false,
 //   overrideShow: true,
    id: "study-participant-char-priorTherapy",
    group: {
      label: "Eligibility Criteria C prior therapy",
    },
    groupingFilterDisplayLabel:{
      // label name in groups filter tab
      label: "Prior Therapy",
    },
    studyFilterGroup: {
      label: "Participant Characteristics",
    },
    input: {
      name: "Prior Therapy",
      schema: [...cloneDeep(getSECSchema())],
      valid: false,
    },
    default: {},
    get(returnObject) {
      getSECSearchTypeObj(returnObject, this.value["searchType"]);
      getSEC(returnObject, this.value["ids-search-sec"]);
    },
    isActive() {
      if (truthy(this.value["ids-search-sec"])) {
        return true;
      }
    },
  },
  // ------------ Biomarker ------------
  {
    type: "form-field",
    id: "biomarker",
    group: {
      label: "Biomarker",
    },
    studyFilterGroup: {
      label: "Participant Characteristics",
    },
    input: {
      name: "Biomarker",
      schema: cloneDeep(BiomarkerSchema(false)),
    },
    default: {},
    get(returnObject) {
      if (truthy(this.value["biomarkers-ctrp-names"])) {
        // if(!truthy(this.value['biomarkers-branch']) && !truthy(this.value['biomarkers-semantic-types']) && !truthy(this.value['biomarkers-use']) && !truthy(this.value['biomarkers-purpose'])&& !truthy(this.value['biomarkers-assay-type'])&& !truthy(this.value['biomarkers-evaluation-type'])&& !truthy(this.value['biomarkers-tissue-specimen-type'])) {
        //   returnObject['biomarkers.ctrp_names'] = this.value['biomarkers-ctrp-names'].key;
        // } else {
        //   returnObject['_or'] = `(biomarkers.ctrp_names=${this.value['biomarkers-ctrp-names'].key})`;
        // }
        returnObject["biomarkers.ctrp_names"] =
          this.value["biomarkers-ctrp-names"].key;
      }
      if (truthy(this.value["biomarkers-branch"])) {
        returnObject["biomarkers.branch"] = this.value["biomarkers-branch"].key;
      }
      if (truthy(this.value["biomarkers-semantic-types"])) {
        returnObject["biomarkers.semantic_types"] =
          this.value["biomarkers-semantic-types"].key;
      }
      if (truthy(this.value["biomarkers-use"])) {
        returnObject["biomarkers.use"] = this.value["biomarkers-use"].key;
      }
      if (truthy(this.value["biomarkers-purpose"])) {
        returnObject["biomarkers.purpose"] =
          this.value["biomarkers-purpose"].key;
      }
      if (truthy(this.value["biomarkers-assay-type"])) {
        returnObject["biomarkers.assay_type"] =
          this.value["biomarkers-assay-type"].key;
      }
      if (truthy(this.value["biomarkers-evaluation-type"])) {
        returnObject["biomarkers.evaluation_type"] =
          this.value["biomarkers-evaluation-type"].key;
      }
      if (truthy(this.value["biomarkers-tissue-specimen-type"])) {
        returnObject["biomarkers.tissue_specimen_type"] =
          this.value["biomarkers-tissue-specimen-type"].key;
      }
      if (
        truthy(this.value["biomarkers-ctrp-names"]) ||
        truthy(this.value["biomarkers-branch"]) ||
        truthy(this.value["biomarkers-semantic-types"]) ||
        truthy(this.value["biomarkers-use"]) ||
        truthy(this.value["biomarkers-purpose"]) ||
        truthy(this.value["biomarkers-assay-type"]) ||
        truthy(this.value["biomarkers-evaluation-type"]) ||
        truthy(this.value["biomarkers-tissue-specimen-type"])
      ) {
        returnObject["biomarkers.inclusion_indicator"] = "TRIAL";
      }
    },
    isActive() {
      return (
        truthy(this.value["biomarkers-ctrp-names"]) ||
        truthy(this.value["biomarkers-branch"]) ||
        truthy(this.value["biomarkers-semantic-types"]) ||
        this.value["biomarkers-use"] ||
        truthy(this.value["biomarkers-purpose"]) ||
        truthy(this.value["biomarkers-assay-type"]) ||
        truthy(this.value["biomarkers-evaluation-type"]) ||
        truthy(this.value["biomarkers-tissue-specimen-type"])
      );
    },
  },
  // // ------------ Accepts Healthy Volunteers ------------
  // {
  //   type: "",
  //   id: "accepts-healthy-volunteers",
  //   default: [],
  //   studyFilterGroup: {
  //     label: "Participant Characteristics",
  //   },
  //   group: {
  //     label: "Accepts Healthy Volunteers",
  //   },
  //   input: {},
  // },
  // // ------------ Brain metastases ------------
  // {
  //   type: "",
  //   id: "brain-metastases",
  //   default: [],
  //   studyFilterGroup: {
  //     label: "Participant Characteristics",
  //   },
  //   group: {
  //     label: "Brain metastases",
  //   },
  //   input: {},
  // },
  // ------------ Cancer Center ------------
  {
    type: "text-field",
    id: "m_cid",
    show: () => false,
    overrideShow: true,
    group: {
      label: "Cancer Center",
    },
    input: {},
    get(model, field, fields, values) {
      let centerValue = get(values, "center-org", []);
      if (!truthy(centerValue)) {
        model["m_cid"] = get(values, "m_cid");
      }
    },
  },
  {
    type: "select-field",
    id: "center-org",
    show: () => !store.getters["settings/containerEnabled"],
    studyFilterGroup: {
      label: "Study Location",
    },
    group: {
      label: "Cancer Center",
    },
    input: {
      multiple: true,
      placeholder: "Select one or more cancer centers",
      closeOnSelect: false,
      preserveSearch: false,
      textField: "name",
      valueField: "id",
      maxHeight: 520,
      loading: false,
      showSelectAll: true,
      itemLabel: "Cancer Centers",
      options: [],
      optionSlot(org) {
        return get(org, "option.name");
      },
    },
    mounted() {
      getContainerOptions(this.input);
    },
    // beforeUpdate() {
    //   return true
    // },
    get(model, field, fields, values) {
      if (!store.getters["settings/containerEnabled"]) {
        let value = get(values, "center-org", []);
        if (value.length) {
          model["m_type"] = "organization";
          model["m_cid"] = value.map((option) => option["id"]);
        } else {
          model["m_cid"] = get(values, "m_cid");
        }
      } else {
        model["m_type"] = undefined;
        model["m_cid"] = undefined;
      }
    },
  },
  // ------------ Clinical Research Category ------------
  {
    type: "checkbox-field",
    id: "study_type",
    default: [],
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Clinical Research Category",
    },
    input: {
      textField: "label",
      valueField: "value",
      stacked: true,
      options: Options.getOptions("study_type"),
    },
    get(returnObject) {
      returnObject["study_type"] = this.value;
    },
  },
  // ------------ Current Study Status ------------
  {
    type: "form-field",
    id: "current_trial_status_grouping",
    default: {},
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Current Study Status",
    },
    input: {
      name: "Current Study Status",
      schema: cloneDeep(StatusSchema),
      options: Options.getOptions("current_trial_status"),
    },
    get(returnObject) {
      if (this.value && this.value.current_trial_status !== undefined) {
        returnObject["current_trial_status"] =
          this.value.current_trial_status.map((v) => v.value);
      }
      if (this.value && this.value.trial_type !== undefined) {
        returnObject["trial_type"] = this.value.trial_type;
      }
    },
    isActive() {
      return truthy(this.value.current_trial_status);
    },
  },
  // ------------ Dates ------------
  DateTypeSchema[0],
  // ------------ Disease/Condition ------------
  {
    type: "multi-form-field",
    id: "disease",
    studyFilterGroup: {
      label: "Participant Characteristics",
    },
    group: {
      label: "Disease/Condition",
    },
    input: {
      name: "Disease/Condition",
      labelFormatter(value) {
        return diseaseLabel(value);
      },
      schema: cloneDeep(DiseaseSchema(false)),
    },
    get(returnObject) {
      diseaseGet(returnObject, this.value, "diseases.id");
    },
  },
  // // ------------ HIV Status ------------
  // {
  //   type: "",
  //   id: "hiv-status",
  //   default: [],
  //   studyFilterGroup: {
  //     label: "Participant Characteristics",
  //   },
  //   group: {
  //     label: "HIV Status",
  //   },
  //   input: {},
  // },
  // ------------ Intervention ------------
  {
    type: "form-field",
    id: "interventions",
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Interventions",
    },
    input: {
      name: "Interventions",
      schema: [
        ///// Drug/Drug Family
        {
          type: "select-field",
          id: "agent-intervention",
          hasClear: true,
          group: {
            label: "Drug/Drug Family",
            description: "Enter 3 or more characters to search",
          },
          input: {
            placeholder: "Select one or more drug/drug family",
            multiple: true,
            textField: "name",
            valueField: "name",
            internalSearch: false,
            preserveSearch: false,
            clearOnSelect: true,
            closeOnSelect: false,
            maxHeight: 500,
            loading: false,
            options: [],
            optionSlot: interventionOptionSlot,
          },
          update(partial) {
            // update helper text
            updateTypeaheadDescription(this.group, partial);
            typeaheadUpdate(this, partial, {
              endpoint: "trials/interventions",
              params: {
                sort: "count",
                order: "desc",
                name: partial,
                category: ["agent", "agent category"],
                include: ["name", "synonyms"],
              },
            });
          },
          get(model, field, fields, values) {
            const agentInterventions = get(this, "value", []).map(
              (v) => v.name
            );
            const otherInterventions = get(
              values,
              "other-intervention",
              []
            ).map((v) => v.name);
            const result = agentInterventions.concat(otherInterventions);
            if (result.length) {
              model["groups.interventions.name"] = result;
            }
          },
        },
        ///// intervention type
        {
          type: "checkbox-field",
          default: [],
          id: "groups.interventions.type",
          group: {
            label: "Intervention Type",
          },
          input: {
            textField: "label",
            valueField: "value",
            stacked: true,
            options: Options.getOptions("groups.interventions.type"),
          },
        },
        ///// other-intervention
        {
          type: "select-field",
          id: "other-intervention",
          hasClear: true,
          group: {
            label: "Other Treatments",
            description: "Enter 3 or more characters to search",
          },
          input: {
            placeholder: "Select one or more other treatments",
            multiple: true,
            textField: "name",
            valueField: "name",
            internalSearch: false,
            preserveSearch: false,
            clearOnSelect: true,
            closeOnSelect: false,
            maxHeight: 300,
            loading: false,
            options: [],
            optionSlot: interventionOptionSlot,
          },
          update(partial) {
            // update helper text
            updateTypeaheadDescription(this.group, partial);
            typeaheadUpdate(this, partial, {
              endpoint: "trials/interventions",
              params: {
                sort: "count",
                order: "desc",
                name: partial,
                category: "other",
                include: ["name", "synonyms"],
              },
            });
          },
          get() {},
        },
      ],
    },
    default: {},
    get(returnObject) {
      let names = [];
      if (this.value["agent-intervention"]) {
        names = names.concat(
          this.value["agent-intervention"].map((v) => v.name)
        );
      }
      if (this.value["groups.interventions.type"]) {
        returnObject["groups.interventions.type"] =
          this.value["groups.interventions.type"];
      }
      if (this.value["other-intervention"]) {
        names = names.concat(
          this.value["other-intervention"].map((v) => v.name)
        );
      }
      if (names.length > 0) {
        returnObject["groups.interventions.name"] = names;
      }
    },
    isActive() {
      return (
        truthy(this.value["agent-intervention"]) ||
        truthy(this.value["groups.interventions.type"]) ||
        truthy(this.value["other-intervention"])
      );
    },
  },
  // ------------ keyword ------------
  {
    type: "keyword-form-field",
    id: "_keyword_form",
    group: {
      label: "Keyword Search",
    },
    studyFilterGroup: {
      label: "Keyword",
    },
    input: {
      name: "Keyword Search",
      schema: [
        {
          type: "text-field",
          id: "_keyword",
          group: {
            label: "Keyword Search",
          },
          input: {
            autocomplete: "off",
            placeholder: "Enter a keyword",
          },
        },
        {
          type: "checkbox-field",
          id: "keyword_search_type",
          default: [],
          group: {
            label: "Target Keyword Searches",
          },
          input: {
            textField: "label",
            valueField: "value",
            stacked: true,
            options: Options.getOptions("keyword_search_type"),
          },
          showCheckbox: false,
          displayCheckbox: false,
        },
      ],
    },
    get(returnObject) {
      if (truthy(this.value)) {
        returnObject["_keyword"] = this.value._keyword;
      }
      if (
        truthy(this.value) &&
        this.value.keyword_search_type.includes(
          "only_in_eligibility_criteria"
        ) &&
        this.value.keyword_search_type.includes("only_in_outcome_measure")
      ) {
        returnObject["_keyword_field"] = [
          "eligibility_criteria.description",
          "outcome_measures.measure._fulltext",
          "outcome_measures.analysis_method._fulltext",
        ];
      } else if (
        truthy(this.value) &&
        this.value.keyword_search_type.includes("only_in_eligibility_criteria")
      ) {
        returnObject["_keyword_field"] = "eligibility_criteria.description";
      } else if (
        truthy(this.value) &&
        this.value.keyword_search_type.includes("only_in_outcome_measure")
      ) {
        returnObject["_keyword_field"] = [
          "outcome_measures.measure._fulltext",
          "outcome_measures.analysis_method._fulltext",
        ];
      }
    },
  },
  // // ------------ Laboratory values ------------
  // {
  //   type: "",
  //   id: "laboratory-values",
  //   default: [],
  //   studyFilterGroup: {
  //     label: "Participant Characteristics",
  //   },
  //   group: {
  //     label: "Laboratory Values",
  //   },
  //   input: {},
  // },
  // ------------ lead disease ------------
  {
    type: "multi-form-field",
    id: "lead-disease",
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Lead Disease",
    },
    input: {
      name: "Lead Disease",
      leadDisease: true,
      labelFormatter(value) {
        return leadDiseaseLabel(value);
      },
      schema: cloneDeep(DiseaseSchema(true)),
    },
    applyFilter: false,
    get(returnObject) {
      const mldOrNoneLeadDiseases = getMLDorNoneLeadDiseases(this.value);
      const isMLDorNoneLD = mldOrNoneLeadDiseases.length > 0;
      const isNoneLD = mldOrNoneLeadDiseases.includes("None");
      let appliedLDFromHomeDashboard = store.getters["search/leadDiseaseSearchHomeDashboard"]

      //-- As the ParticipantsPage loads this schema, the applyFilter is verified to ensure
      //-- the right params for Studies
      //-- These will be later mapped for patients service
      if (!appliedLDFromHomeDashboard && !isMLDorNoneLD) {
        //-- If the method is invoked when user applied filters on the page
        if (store.getters["settings/useOriginalDiseases"]) {
          getLeadDiseaseName(
            returnObject,
            this.value,
            "lead_diseases.name._raw"
          );
        } else {
          getLeadDiseaseName(
            returnObject,
            this.value,
            "lead_diseases2.name._raw"
          );
        }
      } else {
        if (store.getters["settings/useOriginalDiseases"]) {
          getLeadDiseaseName(returnObject, this.value, "lead_disease._raw");
        } else {
          getLeadDiseaseName(returnObject, this.value, "lead_disease2");
        }

        if (this.value && this.value.length > 0 && !isNoneLD) {
          if (store.getters["settings/useOriginalDiseases"]) {
            returnObject["lead_diseases.type"] = "maintype";
          }
        }
      }
    },
  },
  // ------------ NCI Division/Department ------------
  {
    type: "checkbox-field",
    id: "division_department_id",
    // show: () => store.getters['auth/roles'].includes('DEVELOPER'),
    default: [],
    group: {
      label: "NCI Division/Department",
    },
    studyFilterGroup: {
      label: "Study Funding Information",
    },
    input: {
      textField: "label",
      valueField: "value",
      stacked: true,
      options: Options.getOptions("division_department_id"),
    },
    get(returnObject) {
      returnObject["division_department_id"] = this.value;
    },
  },
  // ------------ NCI Program ID ------------
  {
    type: "checkbox-field",
    id: "nci_programs.program",
    default: [],
    group: {
      label: "NCI Program ID",
    },
    studyFilterGroup: {
      label: "Study Funding Information",
    },
    input: {
      textField: "label",
      valueField: "value",
      stacked: true,
      options: Options.getOptions("nci_programs.program"),
    },
    get(returnObject) {
      returnObject["nci_programs.program"] = this.value;
    },
  },
  // ------------ Organization  ------------
  {
    type: "form-field",
    id: "organization",
    group: {
      label: "Organization",
    },
    studyFilterGroup: {
      label: "Study Location",
    },
    input: {
      name: "Organization",
      schema: [
        {
          type: "checkbox-field",
          id: "sponsor",
          default: [],
          input: {
            textField: "label",
            valueField: "value",
            options: [
              {
                label: "Only NCI Sponsored Studies",
                value: false,
              },
            ],
          },
        },
        {
          type: "radio-field",
          id: "combine-organization-type",
          default: "ALL",
          group: {
            label: "",
          },
          input: {
            textField: "label",
            valueField: "value",
            options: [
              {
                label: "All",
                value: "ALL",
              },
              {
                label: "Lead Organization",
                value: "LEAD_ORGANIZATION",
              },
              {
                label: "Participating Site",
                value: "PARTICIPATING_SITE",
              },
            ],
          },
          changed(model, fields) {
            setOrgType(this.value, fields);
          },
        },
        {
          type: "select-field",
          id: "combine_organizations.id",
          value: undefined,
          hasClear: true,
          group: {
            label: "",
            description: "Enter 3 or more characters to search",
          },
          input: {
            placeholder:
              "Select one or more lead organizations and participating sites",
            multiple: true,
            internalSearch: false,
            preserveSearch: false,
            clearOnSelect: true,
            closeOnSelect: false,
            textField: "name",
            valueField: "id",
            maxHeight: 500,
            loading: false,
            options: [],
            type: "ALL",
            optionSlot(org) {
              return `<b>${get(org, "option.name")}</b>`;
            },
          },
          update(partial) {
            // update helper text
            updateTypeaheadDescription(this.group, partial);

            let prepareRoles;
            if (this.input.type === "ALL") {
              prepareRoles = ["LEAD_ORGANIZATION", "PARTICIPATING_SITE"];
            } else {
              prepareRoles = this.input.type;
            }
            typeaheadUpdate(this, partial, {
              endpoint: "trials/organizations",
              params: {
                roles: prepareRoles,
                include: [
                  "id",
                  "name",
                  "state_province_territory",
                  "city_municipality",
                ],
                name: partial,
              },
            });
          },
          get: Getters.array("id"),
        },
      ],
    },
    default: {},
    get(returnObject) {
      if (this.value["combine_organizations.id"]) {
        if (get(this.value, "sponsor", []).length !== 0) {
          returnObject["sponsor"] = "National Cancer Institute";
        }

        const orgType = this.value["combine-organization-type"];
        if (orgType === "ALL") {
          // var newValue = this.value['combine_organizations.id'].map((v) => {
          //   return `(lead_organizations.id=${v.id})(participating_sites=${v.id})`
          // });
          // if (returnObject['_or'] && returnObject['_or'].length > 0) {
          //   returnObject['_or'].push(newValue.join(','))
          // } else {
          //   returnObject['_or'] = [newValue.join(',')]
          // }
          returnObject["organizations_all"] = this.value[
            "combine_organizations.id"
          ].map((v) => v.id);
        } else if (orgType === "LEAD_ORGANIZATION") {
          returnObject["lead_organizations.id"] = this.value[
            "combine_organizations.id"
          ].map((v) => v.id);
        } else if (orgType === "PARTICIPATING_SITE") {
          returnObject["participating_sites"] = this.value[
            "combine_organizations.id"
          ].map((v) => v.id);
        }
      }
    },
    isActive() {
      if (this.value["combine_organizations.id"]) {
        return this.value["combine_organizations.id"].length > 0 ? true : false;
      }
    },
  },
  // ------------ Organization ID ------------
  {
    type: "simple-multi-form-field",
    id: "ids-search-org",
    idType: "study-org",
    // show: () => store.getters['auth/roles'].includes('DEVELOPER'),
    group: {
      label: "Organization ID",
    },
    studyFilterGroup: {
      label: "Study Location",
    },
    input: {
      name: "Organization ID",
      labelFormatter(value) {
        return IDLabel(value);
      },
      schema: cloneDeep(getIDSearchSchema("ids-org")),
    },
    get(returnObject) {
      getID("ids-org", this.value, returnObject);
    },
  },
  // ------------ Outcome Measure ------------
  {
    type: "textarea-field",
    id: "outcome_measures.analysis_method._fulltext",
    group: {
      label: "Outcome Measure",
    },
    input: {
      autocomplete: "off",
      placeholder: "Enter an analysis method...",
    },
  },
  // // ------------ Performance Status ------------
  // {
  //   type: "",
  //   id: "performance-status",
  //   default: [],
  //   studyFilterGroup: {
  //     label: "Participant Characteristics",
  //   },
  //   group: {
  //     label: "Performance Status",
  //   },
  //   input: {},
  // },
  // ------------ Phase ------------
  {
    type: "checkbox-field",
    id: "phase",
    default: [],
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Phase",
    },
    input: {
      textField: "label",
      valueField: "value",
      stacked: true,
      options: Options.getOptions("phase"),
    },
  },
  // ------------ Primary Purpose ------------
  {
    type: "checkbox-field",
    id: "primary_purpose",
    default: [],
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Primary Purpose",
    },
    input: {
      textField: "label",
      valueField: "value",
      stacked: true,
      options: Options.getOptions("primary_purpose"),
    },
  },
  // ------------ Target Enrollment ------------
  {
    type: "",
    id: "target_enrollment",
    default: [],
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Target Enrollment",
    },
    input: {},
  },
  // ------------ Actual Enrollment ------------
  {
    type: "",
    id: "actual_enrollment",
    default: [],
    studyFilterGroup: {
      label: "Study Design & Status",
    },
    group: {
      label: "Actual Enrollment",
    },
    input: {},
  },
  // ------------ Investigator ------------
  {
    type: "form-field",
    id: "investigator",
    group: {
      label: "Investigator",
    },
    studyFilterGroup: {
      label: "Study Location",
    },
    input: {
      name: "Investigator",
      schema: [
        {
          type: "radio-field",
          id: "combine-investigator-type",
          default: "ALL",
          group: {
            label: "",
          },
          input: {
            textField: "label",
            valueField: "value",
            stacked: false,
            options: [
              {
                label: "All",
                value: "ALL",
              },
              {
                label: "Principal Investigator",
                value: "PRINCIPAL_INVESTIGATOR",
              },
              // {
              //   'label': 'Site Principal Investigator',
              //   'value': 'SITE_PRINCIPAL_INVESTIGATOR'
              // },
              {
                label: "Site Investigator",
                value: "SITE_INVESTIGATOR",
              },
            ],
          },
          changed(model, fields) {
            setInvestigatorType(this.value, fields);
          },
        },
        // ------------ Investigator ------------
        {
          type: "select-field",
          id: "combine_investigator.name",
          value: undefined,
          hasClear: true,
          group: {
            label: "",
            description: "Enter 3 or more characters to search",
          },
          input: {
            placeholder: "Select one or more investigators",
            multiple: true,
            internalSearch: false,
            preserveSearch: false,
            clearOnSelect: true,
            closeOnSelect: false,
            textField: "name",
            valueField: "name",
            maxHeight: 500,
            loading: false,
            options: [],
            type: "ALL",
            optionSlot(person) {
              return `<b>${get(person, "option.name")}</b>`;
            },
          },
          update(partial) {
            // update helper text
            updateTypeaheadDescription(this.group, partial);

            if (this.input.type === "ALL") {
              typeaheadUpdate(this, partial, {
                endpoint: "trials",
                params: {
                  _or: `(principal_investigators.name._auto=${partial})(site_principal_investigators.name._auto=${partial})(site_sub_investigators.name._auto=${partial})`,
                  merge_list_fields: `(principal_investigators.name=${partial})(site_principal_investigators.name=${partial})(site_sub_investigators.name=${partial})`,
                  include: [
                    "principal_investigators.name",
                    "site_principal_investigators.name",
                    "site_sub_investigators.name",
                  ],
                  // roles: ['STUDY_INVESTIGATOR'],
                  // include: ['name'],
                  // name: partial,
                },
              });
            } else if (this.input.type == "PRINCIPAL_INVESTIGATOR") {
              typeaheadUpdate(this, partial, {
                endpoint: "trials/persons",
                params: {
                  roles: ["STUDY_PRINCIPAL_INVESTIGATOR"],
                  include: ["name"],
                  name: partial,
                },
              });
            } else if (this.input.type == "SITE_INVESTIGATOR") {
              typeaheadUpdate(this, partial, {
                endpoint: "trials",
                params: {
                  _or: `(site_principal_investigators.name._auto=${partial})(site_sub_investigators.name._auto=${partial})`,
                  merge_list_fields: `(site_principal_investigators.name=${partial})(site_sub_investigators.name=${partial})`,
                  include: [
                    "site_principal_investigators.name",
                    "site_sub_investigators.name",
                  ],
                  // roles: ['STUDY_SITE_INVESTIGATOR'],
                  // include: ['name'],
                  // name: partial,
                },
              });
            }
          },
          get: Getters.array("name"),
        },
      ],
    },
    default: {},
    get(returnObject) {
      if (this.value["combine_investigator.name"]) {
        const investigatorType = this.value["combine-investigator-type"];
        if (investigatorType === "ALL") {
          // var newValue = this.value['combine_investigator.name'].map((v) => {
          //   return `(principal_investigators.name._raw=${v.name})(site_principal_investigators.name._raw=${v.name})(site_sub_investigators.name._raw=${v.name})`
          // });
          // if (returnObject['_or'] && returnObject['_or'].length > 0) {
          //   returnObject['_or'].push(newValue.join(','))
          // } else {
          //   returnObject['_or'] = [newValue.join(',')]
          // }
          returnObject["investigators.name._raw"] = this.value[
            "combine_investigator.name"
          ].map((v) => v.name);
        }

        // PRINCIPAL_INVESTIGATOR
        else if (investigatorType === "PRINCIPAL_INVESTIGATOR") {
          returnObject["principal_investigators.name._raw"] = this.value[
            "combine_investigator.name"
          ].map((v) => v.name);
        }

        // SITE_INVESTIGATOR
        else if (investigatorType === "SITE_INVESTIGATOR") {
          // var newValue = this.value['combine_investigator.name'].map((v) => {
          //   return `(site_principal_investigators.name._raw=${v.name})(site_sub_investigators.name._raw=${v.name})`
          // });
          // if (returnObject['_or'] && returnObject['_or'].length > 0) {
          //   returnObject['_or'].push(newValue.join(','))
          // } else {
          //   returnObject['_or'] = [newValue.join(',')]
          // }
          returnObject["site_investigators.name._raw"] = this.value[
            "combine_investigator.name"
          ].map((v) => v.name);
        }
      }
    },
    isActive() {
      return truthy(this.value["combine_investigator.name"]) &&
        this.value["combine_investigator.name"].length > 0
        ? true
        : false;
    },
  },
  // ------------ Record verification Date ------------
  RegistrationDateTypeSchema[0],
  // ------------ Study ID ------------
  // {
  //   type: 'id-field',
  //   id: 'ids-search-trial',
  //   idType: 'study',
  //   group: {
  //     label: 'Study ID',
  //   },
  //   input: {
  //     name: 'Study ID',
  //     labelFormatter(value) {
  //       return IDLabel(value);
  //     },
  //     schema: cloneDeep(getIDSearchSchema('ids-trial')),
  //   },
  //   get(returnObject) {
  //     getID('ids-trial', this.value, returnObject);
  //   },
  // },
  // ------------ ids-search-trial ------------
  {
    type: "simple-multi-form-field",
    id: "ids-search-trial",
    idType: "study",
    group: {
      label: "Study ID",
    },
    studyFilterGroup: {
      label: "Study ID",
    },
    input: {
      name: "Study ID",
      labelFormatter(value) {
        return IDLabel(value);
      },
      schema: cloneDeep(getIDSearchSchema("ids-trial")),
    },
    get(returnObject) {
      getID("ids-trial", this.value, returnObject);
    },
  },
  // ------------ Study Source ------------
  {
    type: "checkbox-field",
    id: "study_source",
    default: [],
    group: {
      label: "Study Source",
    },
    studyFilterGroup: {
      label: "Study Funding Information",
    },
    input: {
      textField: "label",
      valueField: "value",
      stacked: true,
      options: Options.getOptions("study_source"),
    },
  },
  // ------------ VA Studies ------------
  {
    type: "checkbox-field",
    id: "va_associated",
    default: [],
    group: {
      label: "VA Studies",
    },
    groupingFilterDisplayLabel:{
      // label name in groups filter tab
      label: "VA",
    },
    studyFilterGroup: {
      label: "Study Location",
    },
    input: {
      textField: "label",
      valueField: "value",
      options: [
        {
          label: "Search VA Studies Only",
          value: true,
        },
      ],
    },
    get(returnObject) {
      if (this.value && !!this.value.length) {
        returnObject["va_associated"] = true;
      }
    },
  },
  // ------------ all ids ------------
  // {
  //   type: 'text-field',
  //   id: 'all_ids',
  //   group: {
  //     label: 'All Study IDs',
  //     validFeedback: 'Filters by NCI ID, NCT ID, DCP ID, and CCR ID'
  //   },
  //   input: {
  //     autocomplete: 'off',
  //     placeholder: 'Enter an ID',
  //     description: 'test'
  //   },
  //   get(returnObject) {
  //     if (truthy(this.value)) {
  //       [
  //         'nci_id',
  //         'nct_id',
  //         'dcp_id',
  //         'ccr_id',
  //       ].forEach(key => {
  //         returnObject[key] = this.value
  //       })
  //     }
  //   },
  // },
];

// general
function typeaheadUpdate(field, partial, query) {
  if (partial.length > 2) {
    getOptions(field, partial, query)
  } else {
    field.input.options = []
  }
}

async function getContainerOptions(input) {
  input.options = get(
    store.getters['form/getOptions']('containers'),
    'data',
    []
  )

  if (!truthy(input.options) && Array.isArray(input.options)) {
    try {
      input.loading = true
      // get container options
      await store.dispatch('form/setOptions', {
        id: 'containers',
        query: {
          baseURL: 'containers',
          endpoint: 'containers',
          params: {
            include: ['name', 'id'],
            size: 100,
            sort: 'name',
            order: 'asc'
          }
        }
      })
      input.options = get(
        store.getters['form/getOptions']('containers'),
        'data',
        []
      )
    } catch (error) {
                      input.placeholder = "Failed to load options";
                      // eslint-disable-next-line
                      console.log(error);
                    } finally {
      input.loading = false
    }
  }
}

async function getOptions(field, partial, query) {
  try {
    field.input.loading = true
    let response = await apiGet(query)
    //field.input.options = get(response, 'data', []);
    if (field.id === 'combine_organizations.id') {
      let preData = get(response, 'data', [])
      let combineState = preData.map(org => {
        let city =
          org.city_municipality !== null && org.city_municipality !== 'UNKNOWN'
            ? org.city_municipality + ' '
            : ''
        let state = org.state_province_territory
          ? org.state_province_territory
          : ''
        return {
          ...org,
          name: org.name + ' - ' + city + state
        }
      })
      field.input.options = combineState.filter(org => {
        return org.name.toLowerCase().includes(query.params.name.toLowerCase())
      })
    } else {
      /* else if (field.id == 'combine_investigator.name' && field.input.type == 'ALL') {
      response = parseInvestigatorResponse(response, partial);
      field.input.options = get(response, 'data', []);
    } */
      field.input.options = get(response, 'data', [])
    }
  } catch (error) {
                    field.input.placeholder = "Failed to load options";
                    // eslint-disable-next-line
                    console.log(error);
                  } finally {
    field.input.loading = false
  }
}

// intervention
function interventionOptionSlot(props) {
  let name = get(props, 'option.name')
  let synonyms = abbreviate(get(props, 'option.synonyms', []).join(', '), 60)
  return `<b>${name}</b><br/><span class="synonyms">${synonyms}</span>`
}

// IDs
function IDLabel(value) {
  if (!value['id-selector']) {
    return
  }
  return `<span>${value['id-selector'].label}</span> <h4>${value['id-input']}</h4>`
}

// disease
function diseaseLabel(value) {
  if (value.stage) {
    return value.stage.name
  } else if (value.subtype) {
    return value.subtype.name
  } else {
    return value.maintype.name
  }
}

function diseaseGet(returnObject, value, key) {
  if (truthy(value) && Array.isArray(value)) {
    let codes = []

    value.forEach(disease => {
      let localCodes = get(disease, 'maintype.codes', [])
      let subtype = get(disease, 'subtype.codes', [])
      let stage = get(disease, 'stage.codes', [])

      if (stage.length) {
        localCodes = stage
      } else if (subtype.length) {
        localCodes = subtype
      }
      codes = codes.concat(localCodes)
    })

    returnObject[key] = codes
  }
}

function leadDiseaseLabel(value) {
  let label = ''
  if (value.subtype) {
    label = value.subtype.name
  } else {
    label = value.maintype.name
  }
  if (!label) {
    label = value.maintype
  }
  return label
}

function leadDiseaseGet(returnObject, value, key) {
  if (value !== undefined) {
    let codes = []

    value.forEach(lead_disease => {
      let localCodes = get(lead_disease, 'maintype.codes', [])
      let subtype = get(lead_disease, 'subtype.codes', [])

      if (subtype.length) {
        localCodes = subtype
      }
      codes = codes.concat(localCodes)
    })

    returnObject[key] = codes
  }
}

//-- Ensure both subtypes and maintypes are added for each lead disease in that order
//-- If subtype was specified, then do not add it's maintype
function getLeadDiseaseName(returnObject, value, key) {
  if (value !== undefined && Array.isArray(value)) { // check  if value is an Array.
    let maintype = ''
    let subtype = ''
    let maintypes = []
    let subtypes = []

    value.forEach(lead_disease => {
      subtype = lead_disease.subtype
        ? lead_disease.subtype.name
          ? lead_disease.subtype.name
          : lead_disease.subtype
        : undefined
      if (!subtype) {
        maintype = lead_disease.maintype.name
          ? lead_disease.maintype.name
          : lead_disease.maintype
      }
      if (subtype) {
        subtypes.push(subtype)
      } else {
        maintypes.push(maintype)
      }
    })

    if (maintypes.length > 0 && subtypes.length > 0) {
      const allLeadDiseases = maintypes.concat(subtypes)
      const uniqueLeadDiseases = allLeadDiseases.filter(onlyUnique)
      returnObject[key] = uniqueLeadDiseases
    } else if (maintypes.length > 0) {
      returnObject[key] = maintypes
    } else if (subtypes.length > 0) {
      returnObject[key] = subtypes
    }
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

function getMLDorNoneLeadDiseases(value) {
  if (value !== undefined && Array.isArray(value)) { // check if value is an Array
    let name = ''

    const otherLD = []
    value.forEach(lead_disease => {
      name = lead_disease.maintype.name
        ? lead_disease.maintype.name
        : lead_disease.maintype
      if (name === 'Multiple Lead Diseases' || name === 'None') {
        otherLD.push(name)
      }
    })

    return otherLD
  }
  return []
}

function setOrgType(value, fields) {
  let org = Utils.getField('combine_organizations.id', fields)
  org.input.type = value
  org.value = []
}

function setInvestigatorType(value, fields) {
  let investigatorType = Utils.getField('combine_investigator.name', fields)
  investigatorType.input.type = value
  investigatorType.value = []
}

/* function parseInvestigatorResponse(response, partial) {
  var tempArr = [];
  response.data.forEach((item) => {
    if (item.hasOwnProperty('principal_investigators')) {
      tempArr.push(...item.principal_investigators);
    }
    if (item.hasOwnProperty('site_principal_investigators')) {
      tempArr.push(...item.site_principal_investigators);
    }
  });
  return {
    data: uniqWith(tempArr, isEqual).filter(ig => {
      return ig.hasOwnProperty('name') ? ig.name.toLowerCase().includes(partial.toLowerCase()) : false
    })
  }
} */

function updateTypeaheadDescription(group, partial) {
  if (partial.length < 3) {
    group.description = `Enter ${3 - partial.length
      } or more characters to search`
  } else {
    group.description = ``
  }
}

