import get from 'lodash/get';

const default400 = 'Something went wrong. Please try again in a few minutes.';
const default500 = 'The application is temporarily unavailable. Please try again in a few minutes.';
const default403 = 'You are unauthorized from viewing this content. Please contact your administrator.';
const default504 = 'Your export request timed out because it took longer than 30 seconds. Please try again.';

// log bad return from Ajax call
export function onError(error, disabled = false) {

  // return if disabled
  if (disabled) { return; }

  // get message
  const status = get(error, 'response.status', 404);
  let defaultMessage = status >= 500 ? default500 : default400;
  if (status === 403) {
    defaultMessage = default403;
  }
  if (status === 504) {
    defaultMessage = default504;
  }

  return get(error, 'response.data.detail', defaultMessage);

}
