export default {
    setName (state, name) {
      state.name = name;
    },
    setEmail (state, email) {
      state.email = email;
    },
    setRoles (state, roles) {
      state.roles = roles;
    },
    setContainerAffiliation (state, containerAffiliation) {
      state.containerAffiliation = containerAffiliation;
    }
  };