var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{class:{ onlyShowActive: _vm.onlyShowActive, search: _vm.fieldSearch },on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)},"click":_vm.handlePageClick}},[_vm._l((_vm.displayCorrectLabels(_vm.fields)),function(field,index){return _c('div',{key:field.id,staticClass:"form-field",class:[
      field.state,
      `last-${index === _vm.lastFieldIndex}`,
      `tab-${field.tab}`,
      `disabled-${field.state.disabled}`,
      (field.width && field.width === 'full') || field.id === 'ids-search-sec' ? 'form-field-full-width' : ''
    ],attrs:{"id":`${_vm.id}-${field.id}`}},[(field.id == 'funding-nci-fundType')?_c('div',{staticClass:"divider-container"},[_c('div',[_c('span',{attrs:{"aria-label":"OR"}},[_vm._v(" OR ")])]),_c('br')]):_vm._e(),[(field.id === 'study-participant-char-age')?_c('label',{attrs:{"id":"studies-filters-eligibility-criteria"}},[_vm._v("Eligibility Criteria")]):_vm._e(),_c(`${_vm.$h.get(field, 'wrapper.name', 'default')}-wrapper`,_vm._b({key:_vm.updateKey,tag:"component",attrs:{"model":field.value}},'component',field.wrapper,false),[_c('b-form-group',_vm._b({class:[
            field.hideField ? 'form-field-hide' : 'form-field-show'
          ],attrs:{"state":field.state.validation === null,"label-for":`${_vm.id}-${field.id}-input`,"invalid-feedback":field.state.validation,"dkey":JSON.stringify(field.state)}},'b-form-group',field.group,false),[_c(`${field.type}`,_vm._b({ref:field.id,refInFor:true,tag:"component",staticClass:"form-generator-field",attrs:{"id":`${_vm.id}-${field.id}-input`,"state":!!field.state.validation ? false : null,"disabled":field.state.disabled,"alwaysCallMounted":field.state.active,"form-values":_vm.model,"open":_vm.open},on:{"change":(value) => _vm.$nextTick(() => _vm.updateValue(field.id, value)),"update":(context) => _vm.onUpdate(field, context)},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'component',field.input,false),[(_vm.searchTypeWarning)?_c('div',{staticClass:"search-type-err"},[_c('p',[_vm._v("Warning: The selected SEC filters(s) need to be deleted and reapplied when you switch between the Eligibility and Portfolio Trial Searches.")])]):_vm._e()]),(field.hasClear && field.state.active)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(`Reset ${field.group.label}`),expression:"`Reset ${field.group.label}`",modifiers:{"hover":true}}],staticClass:"clear-field-btn",on:{"click":function($event){return _vm.resetField(field)}}},[_c('icon',{attrs:{"icon":"ban"}})],1):_vm._e()],1)],1)]],2)}),(!_vm.noActions)?_c('div',{staticClass:"form-actions"},[(!_vm.noReset)?_c('btn',{attrs:{"type":"reset","variant":"header"},domProps:{"innerHTML":_vm._s(_vm.resetText)},on:{"click":function($event){return _vm.$emit('cancel')}}}):_vm._e(),_c('btn',{staticClass:"submit",attrs:{"type":"submit","variant":_vm.submitVariant,"block":_vm.blockSubmit,"disabled":_vm.disabled}},[(_vm.submitText === 'camera')?_c('span',[_c('icon',{attrs:{"icon":"download"}}),_vm._v("Download ")],1):_c('span',{domProps:{"innerHTML":_vm._s(_vm.submitText)}})])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }