export default [
  {
    element: '#global-search-help',
    intro: `This is the global keyword search box.<br><a style="font-size: .9rem;" href="/documentation/docs/search-studies/" target="_blank">Click for details</a>`,
    tooltipClass: 'regular-tooltip-bg'
  },
  {
    element: 'div.search-input.open > div.dropdown-container > div.slot-container > div.slot > div > div.content > div.section',
    intro: 'Search suggestions are listed here.',
    tooltipClass: 'regular-tooltip-bg'
  },
  {
    element: 'div.search-input.open > div.dropdown-container > div.slot-container > div.slot > div > div.content div.section:nth-child(2)',
    intro: 'Recent searches are shown here.',
    tooltipClass: 'regular-tooltip-bg'
  }
];