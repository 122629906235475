export default {
    name: (state) => state.name,
    email: (state) => state.email,
    roles: (state) => {
      if (process.env.VUE_APP_MOCK === 'true') {
        // For testing any role locally, replace DEVELOPER with the role you want to test
        // Ensure you switch it back to DEVELOPER, when you check in the code to your branch
        return ['DEVELOPER'];
      } else {
        return state.roles;
      }
    },
    containerAffiliation: (state) => state.containerAffiliation
  };