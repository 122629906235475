          // { label: 'Active', value: 'ACTIVE'},
          // { label: 'Approved', value: 'APPROVED'},
          // { label: 'Enrolling by Invitation', value: 'ENROLLING_BY_INVITATION'},
          // { label: 'In Review', value: 'IN_REVIEW'},
          // { label: 'Temporarily Closed to Accrual', value: 'TEMPORARILY_CLOSED_TO_ACCRUAL'},
          // { label: 'Temporarily Closed to Accrual and Intervention', value: 'TEMPORARILY_CLOSED_TO_ACCRUAL_AND_INTERVENTION'},


export default [
  {
    label: 'All Open Studies',
    values: {
      'current_trial_status_grouping': {
        'current_trial_status': [
          { label: "Recruiting", value: "Recruiting" },
          { label: 'Enrolling by Invitation', value: 'Enrolling by Invitation'},
          { label: 'Suspended', value: 'Suspended'},
          { label: "Available", value: "Available" },
          { label: "Temporarily not available", value: "Temporarily not available" },
        ]
      }
    }
  },
  {
    label: 'All Closed Studies',
    values: {
      'current_trial_status_grouping': {
        'current_trial_status': [
          { label: 'Active, not recruiting', value: 'Active, not recruiting'}
        ]
      }
    }
  },
  {
    label: 'Open Interventional Studies',
    values: {
      'current_trial_status_grouping': {
        'trial_type': 'Interventional',
        'current_trial_status': [
          { label: "Recruiting", value: "Recruiting" },
          { label: 'Enrolling by Invitation', value: 'Enrolling by Invitation'},
          { label: 'Suspended', value: 'Suspended'},
          { label: "Available", value: "Available" },
          { label: "Temporarily not available", value: "Temporarily not available" },
        ]
      }
    }
  },
]