/* istanbul ignore file */
import headersActions from "./actions";
import headersMutations from './mutations';
import headersGetters from './getters';

export default {
    namespaced: true,
  
    state() {
      return {
        headers: [],
      }
    },
  
    getters: headersGetters,
  
    mutations: headersMutations,
  
    actions: headersActions
  };