<template>
  <span class="spinner">
    <svg class="spinner-inner" viewBox="0 0 50 50" :style="{ width: radius/2 + 'px', height: radius/2 + 'px' }">
      <circle class="path" cx="25" cy="25" r="20" fill="none" :style="{ stroke: `#${colorMap[variant] || '6495ed'}` }" :stroke="strokeColor" :stroke-width="strokeWidth"></circle>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'spinner',
  props: {
    type: String,
    size: String,
    radius: {
      type: Number,
      default: 100
    },
    strokeWidth: {
      type: Number,
      default: 3
    },
    strokeColor: {
      type: String,
      default: '#EDF1F5'
    },
    variant: {
      type: String,
      default: 'link',
    }
  },
  data() {
    return {
      colorMap: {
        link: '6495ed',
        studies: '3FAFD1',
        organizations: '65C56F',
        investigators: '9b59b6',
        participants: 'e67e22',
        white: 'fffff',
      }
    }
  },
}
</script>

<style lang="scss">
.spinner-inner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  & .path {
    // stroke: red;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

</style>
