export default [
  {
    element: '#data-settings-help',
    intro: `Show data settings globally.<br><a style="font-size: .9rem;" href="/documentation" target="_blank">Click for details</a>`,
    tooltipClass: 'regular-tooltip-bg'
  },
  {
    element: 'ul.data-settings-body > li.cancer-center',
    intro: 'You could choose one of the following ways to view the data across STRAP application.',
    tooltipClass: 'regular-tooltip-bg'
  },
  {
    element: 'ul.data-settings-body > li.count-history',
    intro: 'Check if you expect to see summary patients included in all participant related reports.',
    tooltipClass: 'regular-tooltip-bg'
  },
  {
    element: 'ul.data-settings-body > li.skip-cache',
    intro: 'Check if you do not expect to use cached results.',
    tooltipClass: 'regular-tooltip-bg'
  }
];