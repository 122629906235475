<template>
  <div class="nav-bar-links">

    <!-- links -->
    <div class="nav-links">
      <router-link 
        v-for="(link, index) in activeLinks" :key="index"
        :to="`/${link.to}`"
        :id="`nav-link-${link.name}`"
        tag="a"
        :target="link.name === 'help' ? '_blank' : '_self'"
      >
        {{link.name | titleCase}}
      </router-link>
    </div>

    <!-- dropdown -->
    <div class="nav-dropdown" v-if="hiddenLinks.length">
      <dropdown text="More" class="nav-link-dropdown" no-caret ref="menu">
        <template #button-content>
          <div class="more-button-content">
            More <icon class="icon" icon="caret-down"></icon>
          </div>
        </template>
        <dropdown-item
          v-for="(link, index) in hiddenLinks"
          :key="index" :to="`/${link.to}`"
          :target="link.name === 'help' ? '_blank' : '_self'"
        >
          <span class="dropdown-link">{{link.name | titleCase}}</span>
        </dropdown-item>
      </dropdown>
    </div>

  </div>
</template>

<script>  
  import Dropdown from 'bootstrap-vue/es/components/dropdown/dropdown'
  import DropdownItem from 'bootstrap-vue/es/components/dropdown/dropdown-item'

  export default {
    name: 'app-nav-bar-links',
    components: { Dropdown, DropdownItem },
    data() {
      return {
        links: [
          {
            name: 'home',
            to: 'home',
            breakpoint: 0,
            hide: false
          },
          {
            name: 'studies',
            to: 'studies',
            breakpoint: 0,
            hide: false
          },
          {
            name: 'organizations',
            to: 'organizations',
            breakpoint: 1200,
            hide: false
          },
          {
            name: 'investigators',
            to: 'investigators',
            breakpoint: 1275,
            hide: false
          },
          {
            name: 'participants',
            to: 'participants',
            breakpoint: 1345,
            hide: false
          },
          {
            name: 'reports',
            to: 'reports',
            breakpoint: 1450,
            hide: false
          },
          {
            name: 'help',
            to: 'documentation/',
            breakpoint: 1450,
            hide: false
          }
        ]
      }
    },
    computed: {
      activeLinks() {
        return this.links.filter(link => link.hide)
      },
      hiddenLinks() {
        return this.links.filter(link => !link.hide)
      },
      pageWidth() {
        return this.$store.getters['width']
      },
    },
    mounted() {
      this.getLinks()
    },
    methods: {
      getLinks() {
        this.links = this.links.filter(link => this.$h.hasAccess(link.name))
      },
      resize(width) {
        this.links.forEach(link => {
          link.hide = link.breakpoint < width
        })
      },
    },
    watch: {
      'pageWidth': {
        handler: 'resize',
        immediate: true
      },
      '$route.name'() {
        if (this.$refs.menu) {
          this.$refs.menu.hide(true)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.nav-bar-links {
  flex: 0 1 100%;
  display: flex;
}
.nav-links {
  display: flex;
  
  >a {
    color: fade-out(white, .4);
    // color: white;
    margin: .1rem .5rem 0;
    font-size: .9rem;
    font-weight: $bold;
    position: relative;

    &:hover {
      color: fade-out(white, .15);
      text-decoration: none;
    }
    &.active, &.active:hover {
      font-weight: $bold;
      color: white;
    }
  }
}
.nav-dropdown {
  margin-top: -1px;
  
  .icon {
    font-size: .9rem
  }
  .dropdown-link {
    font-weight: $regular;
  }
}
.more-button-content {
  width: 55px;
}
</style>