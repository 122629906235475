import cloneDeep from 'lodash/cloneDeep'
import { abbreviate } from './FilterService'

export function truthy(data) {

  // number/boolean
  if (typeof (data) === 'number' || typeof (data) === 'boolean') {
    return true
  }

  // date
  if (data instanceof Date) {
    return !isNaN(data.valueOf())
  }

  // undefined
  if (typeof (data) === 'undefined' || data === null) {
    return false
  }

  // array
  if (typeof (data.length) !== 'undefined') {
    return data.length !== 0
  }

  // object
  let count = 0
  for (let i in data) {
    if (Object.prototype.hasOwnProperty.call(data, i)) {
      count++
    }
  }

  return count !== 0
}

export function deepOmit(o, deleteKeys) {
  const object = cloneDeep(o)

  for (const key in object) {
    if (deleteKeys.includes(key)) {
      delete object[key]
    } else if (typeof object[key] === 'object') {
      object[key] = deepOmit(object[key], deleteKeys)
    }
  }

  return object
}


export function hightlightText(value, keyword, variant, length) {

  if (truthy(value) && truthy(keyword) && search(value, keyword) && keyword.indexOf('.') === -1) {
    let start = abbreviate(value, length)
    let end = value.substring(length)

    return `
      ${replace(start, keyword, variant)} 
      ${search(end, keyword) ? `<b class="overflow-match ${variant}"></b>` : '' }
    `
  }
  else {
    return abbreviate(value, length)
  }
}
function replace(value, search, variant) {
  let regEx = new RegExp(search, "ig")
  return value.replace(regEx, match => `<span class="h-string ${variant}">${match}</span>`)
}
function search(value, search) {
  let regEx = new RegExp(search, "ig")
  return value.search(regEx) !== -1
}