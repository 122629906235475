import chroma from 'chroma-js'

const themeColors = {
  studies: {
    color: '0080A5',
    darken: 3,
    lighten: 1,
  },
  organization: {
    color: '32853C',
    darken: 3,
    lighten: 1,
  },   
  investigator: {
    color: '9b59b6',
    darken: 3,
    lighten: 1,
  },
  participants: {
    color: 'c25700',
    darken: 3,
    lighten: 1,
  },
}

export default function getColors(theme, length) {
  const color = chroma(themeColors[theme].color)
  const lighten = themeColors[theme].lighten
  const darken = themeColors[theme].darken
  return chroma.scale([color.darken(darken), color, color.brighten(lighten)]).colors(length)
}
