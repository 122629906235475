<template>
  <div class="grid-container">
    <!-- <grid-row-summary :data="summary" :headers="headers" :key="summaryKey"></grid-row-summary> -->
    <ag-grid-vue
      oncontextmenu="return false"
      ref="grid"
      class="strap-grid ag-theme-balham"
      :class="{ padding, empty: !data.length }"
      :row-data="data"
      :column-defs="activeHeaders"
      :grid-options="options"
      :quick-filter-text="search"
      :grid-ready="gridReady"
      :column-everything-changed="gridReady"
      :grid-size-changed="gridSizeChanged"
      :sortChanged="sortChanged"
      :sortingOrder="sortingOrder"
      :cellEditingStarted="cellEditingStarted"
      :rowClicked="rowClicked"
      :cellContextMenu="cellContextMenu"
      suppressColumnMoveAnimation
      suppressNoRowsOverlay
    ></ag-grid-vue>
    <grid-context-menu
      v-bind="contextMenu"
      :key="contextMenuKey"
      @summary="openSummary"
    ></grid-context-menu>
    <loading
      class="grid-container-loading"
      v-if="loading"
      :loadingTitle="title"
      :spinnerVariant="variant"
    ></loading>
    <error
      class="grid-container-loading"
      v-else-if="!data.length"
      variant="warning"
      :title="`No ${title || 'Data'} Found`"
      :error="errorMessage"
      :size="smallError ? 'sm' : ''"
      icon="exclamation-triangle"
      no-links
    ></error>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import debounce from "lodash/debounce";
import Loading from "@/components/presentation/state-handler/DefaultLoading";
import Error from "@/components/presentation/state-handler/DefaultError";
import GridContextMenu from "@/components/presentation/grid/GridContextMenu";

export default {
  name: "grid",
  components: { Error, Loading, AgGridVue, GridContextMenu },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: undefined,
    },
    resizeOnReady: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    resizeOnChange: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    expandRows: {
      type: Boolean,
      default: false,
    },
    asyncSort: {
      type: Boolean,
      default: false,
    },
    smallError: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
    },
  },
  data() {
    return {
      gridApi: undefined,
      columnApi: undefined,
      dResizeColumns: undefined,
      activeHeaders: this.$h.cloneDeep(this.headers),
      contextMenu: {},
      summary: {},
      contextMenuKey: 0,
      summaryKey: 100,
      options: {
        headerHeight: 50,
        rowHeight: 45,
        suppressMovableColumns: false,
        suppressCellSelection: true,
        suppressPropertyNamesCheck: true,
        suppressDragLeaveHidesColumns: true,
        enableSorting: true,
        enableServerSideSorting: this.asyncSort,
        enableColResize: true,
        animateRows: true,
        overlayLoadingTemplate: `<i/>`,
        overlayNoRowsTemplate: `No Data Found`,
        domLayout: this.autoHeight ? "autoHeight" : "normal",
        context: this.context,
        sortingOrder: null
      },
    };
  },
  computed: {
    columnWidth() {
      return this.$h.cloneDeep(this.headers).reduce((acc, value) => {
        return (acc += value.hide
          ? 0
          : value.width !== undefined
          ? value.width
          : 0);
      }, 0);
    },
    errorMessage(){
      // do not display error message on study detail page when no SEC is found.
      return this.title === "Structured Eligibility Criteria" ? '' : 'Adjust search parameters to return more data';
    }
  },
  beforeMount() {
    //Adding this here since this has to be defined before mounting. 
    //for reasons unknown, this won't work if you define it in the 'data' section above, it has to be done here.
    this.sortingOrder = ["asc", "desc"];
  },
  created() {
    this.dResizeColumns = debounce(this.resizeColumns, 10);
  },
  methods: {
    getColumnOrder() {
      return this.columnApi.getColumnState().map((row) => row.colId);
    },
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      if (this.resizeOnReady) {
        this.dResizeColumns();
      }

      this.resetRowHeights();
      //put "lead disease" at top by default for "Diseases/Conditions" sideTab
      if (this.title == "Diseases/Conditions") {
        var defaultSortModel = [
          {
            colId: "is_lead_disease",
            sort: "desc",
          },
        ];
        params.api.setSortModel(defaultSortModel);
      }
    },
    gridSizeChanged() {
      if (this.resizeOnChange) {
        this.dResizeColumns();
      }
    },
    toggleColumnVisible(colId) {
      const columnState = this.columnApi.getColumnState();
      const column = columnState.find((col) => col.colId === colId);
      this.columnApi.setColumnVisible(colId, column.hide);
      this.columnApi.autoSizeColumn(colId);
      this.dResizeColumns();
    },
    stretchColumns() {
      const columnState = this.columnApi.getColumnState();
      columnState.forEach((col) => this.columnApi.autoSizeColumn(col.colId));
    },
    resizeColumns() {
      // is the total of the columns greater than client width
      const grid = this.$h.get(this.$refs, "grid.$el");
      if (grid && this.gridApi) {
        this.$nextTick(() => {
          if (grid.offsetWidth > this.columnWidth) {
            this.gridApi.sizeColumnsToFit();
          }
          this.gridApi.resetRowHeights();
        });
      }
    },
    sortChanged(model) {
      const sortModel = model.api.getSortModel();
      const colId = this.$h.get(sortModel, "0.colId");
      const order = this.$h.get(sortModel, "0.sort");
      const column = this.activeHeaders.find(
        (header) => header.field === colId
      );
      const sort = this.$h.get(column, "sortKey", colId);
      this.$emit("sort", { sort, order });
    },
    cellEditingStarted() {
      const grid = this.$h.get(this.$refs, "grid.$el");
      if (grid) {
        grid.querySelector("input").readOnly = true;
      }
    },
    resetRowHeights() {
      setTimeout(this.gridApi.resetRowHeights());
    },
    toggleLoading(loading) {
      if (this.gridApi) {
        loading
          ? this.gridApi.showLoadingOverlay()
          : this.gridApi.hideOverlay();
      }
    },
    rowClicked(row) {
      this.$emit("rowClicked", row);
    },
    cellContextMenu(context) {
      this.contextMenuKey++;
      this.contextMenu = context;
    },
    openSummary({ data, colDef }) {
      this.summary = data;
      this.summaryKey++;
    },
  },
  watch: {
    loading: "toggleLoading",
  },
};
</script>

<style lang="scss">
@import "src/styles/component";
@import "node_modules/ag-grid-community/dist/styles/ag-grid";
@import "node_modules/ag-grid-community/dist/styles/ag-theme-balham";

.ag-row-context {
  z-index: 8;

  &:after {
    content: "";
    border: solid thin $link;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 1px;
    z-index: 9;
  }
}

.grid-container {
  position: relative;
  height: 100%;

  display: flex;
}
.strap-grid {
  width: 100%;
  flex: 0 1 100%;
}
.grid-row-summary {
  flex: 0 0 auto;
}
.grid-container-resize {
  background-color: fade-out(white, 0.15) !important;
  top: 50px !important;
  height: 100%;
}
.grid-container-loading {
  background-color: fade-out(white, 0.25) !important;
  top: 50px !important;
  height: 100%;
  padding-bottom: 40px;
}
.strap-grid.empty {
  .ag-body-container.ag-layout-auto-height {
    min-height: $min-height;
    margin: 0;
  }
}
</style>
