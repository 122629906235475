
function saveCurrentUser (currentUser) {
  if (currentUser) {
    window.localStorage.setItem('previousUser', currentUser);
  }
}
function comparePreviousUser (currentUser, dispatch) {
  const previousUser = window.localStorage.getItem('previousUser');
  if (currentUser !== previousUser) {
    dispatch('search/reset', null, { root: true });
    dispatch('form/clearForms', null, { root: true });
    dispatch('settings/reset', null, { root: true });
  }
}

export default  {
    setUser ({ commit, dispatch }, user) {
      comparePreviousUser(user.email, dispatch);
      commit('setName', user.name);
      commit('setEmail', user.email);
      commit('setRoles', user.roles);
      commit('setContainerAffiliation', user.containerAffiliation);
    },
    reset ({ commit, getters }) {
      saveCurrentUser(getters.email);
      commit('setName', '');
      commit('setEmail', '');
      // commit('setRoles', [])
      commit('setContainerAffiliation', []);
    }
  };


