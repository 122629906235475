<template>
  <ul class="form-summary" :class="variant">
    <li class="field open-summary-disease-maintype">
      <disease-maintype-indicator :id="`open-summary-disease-maintype-indicator`"></disease-maintype-indicator>
    </li>
    <li class="field" v-for="(field, index) in activeFields" :key="index">
      <!-- label -->

      <!-- Age Field-->
      <div v-if="field.id === 'study-participant-char-age'" 
        :class="showMinOrMax(values[field.id],'min') || showMinOrMax(values[field.id],'max')? 'label' : ''">
        <span v-if="showMinOrMax(values[field.id],'min') || showMinOrMax(values[field.id],'max')" >
          with the <code v-html="$h.get(field, 'group.label', '')" />
        </span>
      </div> 
      <!-- Sex Field-->
      <div v-else-if="field.id === 'study-participant-char-sex'" 
        :class="$h.truthy(values[field.id]['sex_criteria'])? 'label' : ''">
        <span v-if="$h.truthy(values[field.id]['sex_criteria'])" >
          with the <code v-html="$h.get(field, 'group.label', '')" />
        </span>
      </div> 
      <!-- SEC Field-->
      <span v-else-if="field.id === 'study-participant-char-priorTherapy'" 
      :class="hasSECFilterValues(field.id) ? 'label' : ''">
        <span v-if="hasSECFilterValues(field.id)">
          with the <code v-html="$h.get(field, 'group.label', '')" />
        </span>
      </span> 
      <!--Anything else-->
      <span v-else class="label">
        with the

        <code
          v-if="field.id === 'id-search' && field.type !== 'simple-multi-form-field'"
            v-html="$h.get(values, `${field.id}.id-selector.label`, '')" >
        </code>
        <code
          v-else-if="field.id === 'date-range'"
          v-html="$h.get(values, `${field.id}.date-range-key.label`, '')"
        ></code>
        <code v-else v-html="$h.get(field, 'group.label', '')"></code>
      </span>

      <!-- date range -->
      <span class="value date" v-if="isId(field, 'date-range')">
        <div class="interval" v-html="getLabel(values[field.id], field)"></div>
        <span v-html="formatDate(values[field.id]['date-range-from'])"></span>
        <i>to</i>
        <span v-html="formatDate(values[field.id]['date-range-to'])"></span>
      </span>
      <span class="value date" v-else-if="isId(field, 'p-date-range')">
        <div class="interval" v-html="getLabel(values[field.id], field)"></div>
        <span v-html="formatDate(values[field.id]['p-date-range-from'])"></span>
        <i>to</i>
        <span v-html="formatDate(values[field.id]['p-date-range-to'])"></span>
      </span>
      <span class="value date" v-else-if="isId(field, 'registration-date-range')">
        <div class="interval" v-html="getLabel(values[field.id], field)"></div>
        <span
          v-html="formatDate(values[field.id]['registration-date-range-from'])"
        ></span>
        <i>to</i>
        <span
          v-html="formatDate(values[field.id]['registration-date-range-to'])"
        ></span>
      </span>

      <!-- current_trial_status_grouping -->
      <span
        class="list"
        v-else-if="isId(field, 'current_trial_status_grouping')"
      >
        <!-- multi -->
        <span class="list">
          <span
            class="value comma"
            v-html="
              values[field.id].current_trial_status
                .map((value) => getLabel(value, field))
                .join(', ')
            "
          ></span>
        </span>
      </span>

      <!-- eligibility criteria -->
      <span class="list" v-else-if="isId(field, 'study-participant-char-age')">
        <span class="list">
          <span class="value" v-if="showMinOrMax(values[field.id], 'min')">
            <span class="thin mr-2">Min Age:</span>
            <span>{{values[field.id]["min-age-input"]}} {{values[field.id]["min-age-selector"].label}}</span>
          </span>
          <span class="value" v-if="showMinOrMax(values[field.id], 'max')">
            <span class="thin mr-2">Max Age:</span>
            <span>{{values[field.id]["max-age-input"]}} {{values[field.id]["max-age-selector"].label}}</span>
          </span>
        </span>
        </span>
        <span class="list" v-else-if="isId(field, 'study-participant-char-sex') && $h.truthy(values[field.id]['sex_criteria'])">
          <span class="value" v-if="getSexValue(values[field.id])">
            <span>{{
              getSexValue(values[field.id])
                .map((v) => v.label)
                .join(", ")
            }}</span>
          </span>
        </span>
        <div class="list" v-else-if="hasSECFilter(values[field.id], field.id) && $h.truthy(getSECData(values[field.id], field.id))">
          <!-- Structured Eligibility Criteria(s): -->
          <span>Structured Eligibility Criteria(s): {{ getSECSearchType(values[field.id]) }}</span>
          <div class="value" v-for="(value, index) in getSECData(values[field.id], field.id)" :key="index">
            <span>
              <span class="thin mr-2">{{value["criteria"].label}}:</span>
              <span class="padding-0"> {{value["criteria"].value}}</span>
            </span><br>

            <span>
              <span>
                <span class="thin mr-2">{{value["name"].label}}:</span>
                <span class="padding-0"> {{value["name"].value}}</span>
              </span>
            </span><br>

            <span v-if="hasSECType(value)">
              <span class="thin mr-2">{{value["type"].label}}:</span>
              <span class="padding-0"> {{value["type"].value}}</span>
              <br>
            </span>
          </div>
        </div>
      

      <!-- funding information -->
      <div class="list" v-else-if="isId(field, 'funding')">
        <span class="list">
          <span class="value" v-if="values[field.id]['funding-mechanism']">
            <span class="thin mr-2">Funding Mechanism:</span>
            <span>{{values[field.id]["funding-mechanism"].code}}</span>
          </span>
          <span class="value" v-if="values[field.id]['funding-institute']">
            <span class="thin mr-2">Institute Code:</span>
            <span>{{values[field.id]["funding-institute"].code}}</span>
          </span>
          <span class="value" v-if="values[field.id]['funding-serial']">
            <span class="thin mr-2">Serial Number:</span>
            <span>{{values[field.id]["funding-serial"]}}</span>
          </span>
          <span class="value" v-if="values[field.id]['funding-program-id']">
            <span class="thin mr-2">NCI Division/Program:</span>
            <span>{{values[field.id]["funding-program-id"].label}}</span>
          </span>
          <span class="value" v-if="values[field.id]['funding-nci-fundType']">
            <span class="thin mr-2">NCI funding?:</span>
            <span>{{values[field.id]["funding-nci-fundType"].join(", ")}}</span>
          </span>
        </span>
      </div>
      <!-- site location -->
      <span class="list" v-else-if="isId(field, 'site-location')">
        <span class="list">
          <span class="value" v-if="values[field.id]['c_country']">
            <span class="thin mr-2">Country:</span>
            <span>{{values[field.id]["c_country"].label}}</span>
          </span>

          <span class="value" v-if="values[field.id]['c_country'].code === 'USA' && values[field.id]['c_state_select']">
            <span class="thin mr-2">State:</span>
            <span>{{values[field.id]["c_state_select"].code}}</span>
          </span>
          <span v-else-if="values[field.id]['c_state_input']" class="value">
            <span class="thin mr-2">State/Province:</span>
            <span>{{values[field.id]["c_state_input"]}}</span>
          </span>

          <span class="value" v-if="values[field.id]['c_city']">
            <span class="thin mr-2">City:</span>
            <span>{{values[field.id]["c_city"]}}</span>
          </span>

          <span class="value" v-if="values[field.id]['c_zipcode']">
            <span class="thin mr-2">Zipcode:</span>
            <span>{{values[field.id]["c_zipcode"]}}</span>
          </span>
        </span>
      </span>

      <!-- interventions -->
      <span class="list" v-else-if="isId(field, 'interventions')">
        <!-- multi -->
        <span class="list">
          <span
            class="value"
            v-if="$h.truthy(values[field.id]['agent-intervention'])"
          >
            <span class="thin mr-2">Drug/Drug Family:</span>
            <span>{{
              values[field.id]["agent-intervention"]
                .map((v) => v.name)
                .join(", ")
            }}</span>
          </span>
          <span
            class="value"
            v-if="$h.truthy(values[field.id]['groups.interventions.type'])"
          >
            <span class="thin mr-2">Intervention Type:</span>
            <span>{{
              values[field.id]["groups.interventions.type"].join(", ")
            }}</span>
          </span>
          <span
            class="value"
            v-if="$h.truthy(values[field.id]['other-intervention'])"
          >
            <span class="thin mr-2">Other Treatments:</span>
            <span>{{
              values[field.id]["other-intervention"]
                .map((v) => v.name)
                .join(", ")
            }}</span>
          </span>
        </span>
      </span>

      <!-- combine organization -->
      <span class="list" v-else-if="isId(field, 'organization')">
        <span class="list">
          <span
            class="value"
            v-if="$h.truthy(values[field.id]['combine-organization-type'])"
          >
            <span class="thin mr-2" v-if="values[field.id]['combine-organization-type'] == 'LEAD_ORGANIZATION'">
              Lead Organization:
            </span>
            <span class="thin mr-2" v-else-if="values[field.id]['combine-organization-type'] == 'PARTICIPATING_SITE'">
              Participating Site:
            </span>
            <span class="thin mr-2" v-else-if="values[field.id]['combine-organization-type'] == 'ALL'">
              Organization:
            </span>
            <span>{{
              values[field.id]["combine_organizations.id"]
                .map((v) => v.name)
                .join(", ")
            }}</span>
          </span>
        </span>
      </span>

      <!-- combine investigator -->
      <span class="list" v-else-if="isId(field, 'investigator')">
        <span class="list">
          <span
            class="value"
            v-if="$h.truthy(values[field.id]['combine-investigator-type'])"
          >
            <span class="thin mr-2" v-if="values[field.id]['combine-investigator-type'] == 'PRINCIPAL_INVESTIGATOR'">
              Principal Investigator:
            </span>
            <span class="thin mr-2" v-else-if="values[field.id]['combine-investigator-type'] == 'SITE_PRINCIPAL_INVESTIGATOR'">
              Site Principal Investigator:
            </span>
            <span class="thin mr-2" v-else-if="values[field.id]['combine-investigator-type'] == 'ALL'">
              Investigator:
            </span>
            <span>{{
              values[field.id]["combine_investigator.name"]
                .map((v) => v.name)
                .join(", ")
            }}</span>
          </span>
        </span>
      </span>

      <!-- select -->
      <span class="list" v-else-if="isType(field, 'select')">
        <!-- multi -->
        <span class="list" v-if="$h.get(field, 'input.multiple', false)">
          <span
            class="value comma"
            v-if="values[field.id].length < 4"
            v-html="
              values[field.id].map((value) => getLabel(value, field)).join(', ')
            "
          ></span>
          <span
            class="value multi"
            v-else
            v-for="(value, index) in values[field.id]"
            :key="index"
            v-html="getLabel(value, field)"
          ></span>
        </span>

        <!-- single -->
        <span
          class="value single"
          v-html="getLabel(values[field.id], field)"
          v-else
        ></span>
      </span>

      <!-- checkbox -->
      <span class="list" v-if="isType(field, 'checkbox')">
        
        <span
          class="value comma"
          v-if="values[field.id].length < 4"
          v-html="
            values[field.id]
              .map((value) => getCheckboxLabel(value, field))
              .join(', ')
          "
        ></span>
        <span
          class="value"
          v-else
          v-for="(value, index) in values[field.id]"
          :key="index"
          v-html="getCheckboxLabel(value, field)"
        ></span>
      </span>

      <!-- radio -->
      <span
        class="value radio"
        v-else-if="isType(field, 'radio')"
        v-html="getCheckboxLabel(values[field.id], field)"
      ></span>

      <!-- biomarkers -->
      <div class="list" v-else-if="isId(field, 'biomarker')">
        <span class="list">
          <span class="value" v-if="values[field.id]['biomarkers-ctrp-names']">
            <span class="thin mr-2">Biomarker name:</span>
            <span>{{values[field.id]["biomarkers-ctrp-names"].key}}</span>
          </span>
          <span class="value" v-if="values[field.id]['biomarkers-branch']">
            <span class="thin mr-2">Biomarker branch:</span>
            <span>{{values[field.id]["biomarkers-branch"].key}}</span>
          </span>
          <span class="value" v-if="values[field.id]['biomarkers-semantic-types']">
            <span class="thin mr-2">Semantic type:</span>
            <span>{{values[field.id]["biomarkers-semantic-types"].key}}</span>
          </span>
          <span class="value" v-if="values[field.id]['biomarkers-use']">
            <span class="thin mr-2">Biomarkers use:</span>
            <span>{{values[field.id]["biomarkers-use"].key}}</span>
          </span>
          <span class="value" v-if="values[field.id]['biomarkers-purpose']">
            <span class="thin mr-2">Biomarkers purpose:</span>
            <span>{{values[field.id]["biomarkers-purpose"].key}}</span>
          </span>
          <span class="value" v-if="values[field.id]['biomarkers-assay-type']">
            <span class="thin mr-2">Biomarkers assay type:</span>
            <span>{{values[field.id]["biomarkers-assay-type"].key}}</span>
          </span>
          <span class="value" v-if="values[field.id]['biomarkers-evaluation-type']">
            <span class="thin mr-2">Biomarkers evaluation type:</span>
            <span>{{values[field.id]["biomarkers-evaluation-type"].key}}</span>
          </span>
          <span class="value" v-if="values[field.id]['biomarkers-tissue-specimen-type']">
            <span class="thin mr-2">Biomarkers specimen type:</span>
            <span>{{values[field.id]["biomarkers-tissue-specimen-type"].key}}</span>
          </span>
        </span>
      </div>

      <!-- disease -->
      <span class="list" v-else-if="isType(field, 'multi-form')">
        <span
          class="value"
          v-for="(value, index) in values[field.id]"
          :key="index"
          v-html="getDisease(value, field)"
        ></span>
      </span>

      <!-- id-selector -->
      <span class="value" v-else-if="isType(field, 'simple-multi-form')">
        <div
          v-if="$h.truthy(input)"
          v-for="(input, labelName) in getFormattedIDInputs(values[field.id],field.idType)"
          :key="`${labelName}-value`"
        >
          <span v-html="`${labelName}: `"></span>
          <span v-html="input.join(', ')"></span>
        </div>
      </span>
      <span
        class="value"
        v-else-if="isType(field, 'form')"
        v-html="values[field.id]['id-input']"
      ></span>

      <!-- races_string -->
      <span
        class="value"
        v-else-if="isType(field, 'text') && field.id === 'races_string'"
        v-html="getRacesLabel(values[field.id])"
      ></span>

      <!-- container name -->
      <div v-else-if="isType(field, 'text') && field.id === 'm_cid'">
        <async-data-wrapper
          :query="containerQuery"
          @resolve="containerResolve"
          @reject="containerReject"
        >
          <template v-slot="{ _state, _reload }">
            <state-handler
              @reload="_reload"
              v-bind="_state"
              error-size="sm"
              no-transition
              ignore-loading
            >
              <template #response>
                <span
                  class="value"
                  v-html="getContainerName(values[field.id])"
                ></span>
              </template>
            </state-handler>
          </template>
        </async-data-wrapper>
      </div>

      <!-- Keyword -->
      <div v-else-if="isType(field, 'keyword-form') && field.id === '_keyword_form'">
        <span class="value" v-html="values[field.id]._keyword"></span>
        <br><br>
        <div v-if="values[field.id].keyword_search_type.length > 0">
          <div class="label">targeting <code v-html="'Keyword Searches'"></code></div>
          <span>{{getKeywordSearchTarget(values[field.id].keyword_search_type)}}</span>
        </div>
      </div>

      <!-- Keyword -->
      <div v-else-if="field.id === '_keyword'">
        <span class="value" v-html="values[field.id]"></span>
      </div>

      <!-- Disease Name -->
      <div v-else-if="field.id === 'evs_ctrp_display_name'">
        <span class="value test" v-html="values[field.id]"></span>
      </div>

    </li>

    <li class="field empty" v-if="!activeFields.length">No Filters Active</li>
  </ul>
</template>

<script>
import { replaceUnderscores } from "@/services/FilterService";
import { Options } from "../utils";
import { showAge } from '@/components/form/schemas/AgeSchema';
import DiseaseMaintypeIndicator from "@/components/form/DiseaseMaintypeIndicator.vue";

const dateFormatMap = {
  day: "MMMM D, YYYY",
  month: "MMMM, YYYY",
  year: "YYYY",
};

// const hiddenFields = []

const idTypeMap = {
  study: Options.getOptions("ids-trial"),
  "study-org": Options.getOptions("ids-org"),
  investigator: Options.getOptions("investigator-ids"),
  organization: Options.getOptions("organization-ids"),
};

export default {
  name: "form-summary",
  components: {DiseaseMaintypeIndicator},
  props: ["values", "schema", "variant"],
  data() {
    return {
      containerQuery: {
        baseURL: "containers",
        endpoint: "/containers",
        params: {
          include: ["name", "id"],
          size: 100,
          sort: "name",
          order: "asc",
        },
      },
      containerState: {
        loading: true,
        error: "",
        data: [],
      },
    };
  },
  computed: {
    activeFields() {
      if (Object.keys(this.schema).length !== 0) {
        const active_field = this.schema.filter(this.isActiveField);
        return active_field
      } else {
        return []
      }
    },
  },
  methods: {
    isActiveField(field) {
      // If the lead disease filter is for "Multiple Lead Diseases" or "None", then do not show in the filter popup
      if (field.id === 'lead-disease') {
        const diseases = this.values[field.id] ? this.values[field.id].filter((leadDiseaseItem) => leadDiseaseItem.maintype !== 'Multiple Lead Diseases' && leadDiseaseItem.maintype !== 'None') : [];
        if (diseases.length === 0) {
          return false;
        }
      }

      // trial type set to all
      if (
        field.id === "trial-type" &&
        this.$h.get(this.values, field.id) === -2
      ) {
        return false;
      }
      // id-selector
      if (field.id === "ids-search-trial" || field.id === "ids-search-org" || field.id === "eligibility-criteria") {
        return this.$h.truthy(this.$h.get(this.values, `${field.id}`));
      }
      if (field.id === "id-search") {
        return this.$h.truthy(this.$h.get(this.values, `${field.id}.id-input`));
      }

      // hidden fields
      // if (hiddenFields.includes(field.id)) {
      //   return false
      // }

      // date-range
      if (field.id === "date-range") {
        return (
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.date-range-key`)
          ) &&
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.date-range-from`)
          ) &&
          this.$h.truthy(this.$h.get(this.values, `${field.id}.date-range-to`))
        );
      }
      if (field.id === "p-date-range") {
        return (
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.p-date-range-key`)
          ) &&
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.p-date-range-from`)
          ) &&
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.p-date-range-to`)
          )
        );
      }
      if (field.id === "interventions") {
        return (
          this.$h.truthy(
            this.$h.get(this.values, `interventions.agent-intervention`)
          ) ||
          this.$h.truthy(
            this.$h.get(
              this.values,
              `interventions["groups.interventions.type"]`
            )
          ) ||
          this.$h.truthy(
            this.$h.get(this.values, `interventions.other-intervention`)
          )
        );
      }
      if (field.id === "organization") {
        return this.$h.get(field.value, "combine_organizations.id", []).length > 0 ? true : false;
      }
      /* if (field.id === "organization") {
        return this.$h.get(field.value, "lead_organizations.id", []).length +
          this.$h.get(field.value, "participating_sites", []).length >
          0
          ? true
          : false;
      } */
      if (field.id === "investigator") {
        return this.$h.get(field.value, "combine_investigator.name", []).length > 0
          ? true : false;
      }
      if (field.id === "current_trial_status_grouping") {
        return this.$h.truthy(
          this.$h.get(
            this.values,
            `current_trial_status_grouping.current_trial_status`
          )
        );
      }
      if (field.id === "registration-date-range") {
        return (
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.registration-date-range-from`)
          ) &&
          this.$h.truthy(
            this.$h.get(this.values, `${field.id}.registration-date-range-to`)
          )
        );
      }
      // has value
      else if (this.$h.truthy(this.$h.get(this.values, field.id))) {
        return true;
      }
      // default
      else {
        return false;
      }
    },
    getSexValue(field){
      if(!field.value){
        return field["sex_criteria"];
      }
      return field.value["sex_criteria"];
    },
    getLabel(value, field) {
      let key = this.$h.get(field, "input.textField", "label");
      return value ? value[key] : {};
    },
    isType(field, type) {
      return field.type === `${type}-field`;
    },
    isId(field, id) {
      return field.id === id;
    },
    getKeywordSearchTarget(keyword_search_type){
      if (keyword_search_type.length == 2) {
        // Both Only in Eligibility Criteria and Only in Outcome Measure are selected
        return "In Outcome Measure, In Eligibility Criteria"
      } else if (keyword_search_type[0] === 'only_in_eligibility_criteria') {
        return "Only in Eligibility Criteria"
      } else if (keyword_search_type[0] === 'only_in_outcome_measure'){
        return "Only in Outcome Measure"
      }
    },
    hasSECFilter(field, id) {
      if (id === "study-participant-char-priorTherapy") {
        if ((field["ids-search-sec"] && field["ids-search-sec"].length > 0) ||
            (field["searchType"] && field["searchType"].length > 0)) {
          return true
        }
      }
      return false
    },
    hasSECType(value) {
      return value["type"]
    },
    getSECSearchType(field) {
      return field["searchType"] === "default" ? "Eligibility Trial Search" : "Portfolio Trial Search";
    },
    
    getSECData(field, id) {
      if (this.hasSECFilter(field,id)) {
        let secItems = [];
          if (field["ids-search-sec"] && field["ids-search-sec"].length > 0) {
            const test = field["ids-search-sec"][0]
            field["ids-search-sec"].map((currentSECItem) => {
            let secItem = {};
            let namesAndCodes = '';
            let type = '';

            /* Get sec criteria */
            secItem["criteria"] = {label: 'Criteria', value: currentSECItem["sec_criteria"].label};

            /* Get sec name and ccode */
            if (currentSECItem["id-selector"] && currentSECItem["id-selector"].value === "code") {
              // Search by codes
              let seen = new Set();
              let ccodes = [];
              // Add valid ccodes
              if(currentSECItem["sec_name"]){
                currentSECItem["sec_name"]
                .map((v) => {
                  seen.add(v.code);
                  ccodes.push(`${v.name} (${v.code})`);
                })
              }
              
              // Add invalid ccodes
              currentSECItem["id-input"].split(",").map((v) => {
                if(!seen.has(v)){
                  ccodes.push(v);
                }
              })
              namesAndCodes = ccodes.join(", ");

            } else {
              // Search by names
              namesAndCodes = currentSECItem["id-input"]
                .map((v) => `${v.name} (${v.code})`)
                .join(", ");
            }
            secItem["name"] = {label: 'Name(s)', value: namesAndCodes};

            /* Get sec type */
            if (currentSECItem["sec_type"] && currentSECItem["sec_type"].length > 0) {
              // Eligibility Trial Search
              if(field["searchType"] && field["searchType"] === "default") {
                type = currentSECItem["sec_type"] === "inclusion" ? "Focus" : currentSECItem["sec_type"] === "exclusion" ? "Eligible" : "Unspecified";
              }
              // Portfolio Trial Search
              else{
                type = currentSECItem["sec_type"].charAt(0).toUpperCase() + currentSECItem["sec_type"].slice(1);
              }
              secItem["type"] = {label: 'Type(s)', value: type};
            }

            secItems.push(secItem);
            })
          return secItems;
        }
      }
      return [];
    },

    //Checks if this is Structured Eligibility Criteria, and if it holds any non-blank values
    hasSECFilterValues(fieldId) {
      return this.hasSECFilter(this.values[fieldId], fieldId) && this.$h.truthy(this.getSECData(this.values[fieldId], fieldId))
    },
    getCheckboxLabel(value, field) {
      const options = this.$h.get(field, "input.options", []);
      let label = "";
      options.forEach((option) => {
        if (option.value === value) {
          label = option.label;
        }
      });
      return label;
    },
    getDisease(value) {
      if (value.stage) {
        return value.stage.name;
      } else if (value.subtype) {
        return value.subtype.name;
      } else if (value.maintype && !value.maintype.name && value.maintype.length > 0) {
        return value.maintype;
      } else if (value.maintype && value.maintype.name) {
        return value.maintype.name;
      } else if (value.name) {
        return value.name
      } else {
        return value
      }
    },
    getFormattedIDInputs(rawInputs, idType) {
      if (rawInputs !== undefined) {
        let allIdsObjectIndex = rawInputs.findIndex(
          (v) => v["id-label"] === "all"
        );
        if (allIdsObjectIndex !== -1) {
          return {
            "All Study IDs": [rawInputs[allIdsObjectIndex].value],
          };
        }

        let keyInputMap = {};
        idTypeMap[idType].forEach((obj) => {
          keyInputMap[`${obj.label}Inputs`] = [];
        });

        rawInputs.forEach((rawInput) => {
          let key = rawInput["id-selector"].label;
          let input = rawInput["id-input"];
          keyInputMap[`${key}Inputs`].push(input);
        });

        let returnObject = {};
        for (let prop in keyInputMap) {
          returnObject[prop.slice(0, -6)] = keyInputMap[prop];
        }

        return returnObject;
      }
    },
    formatDate(date) {
      const interval = this.$h.get(
        this.values,
        "date-range.date-range-interval",
        "day"
      );
      const format = dateFormatMap[interval];
      return this.$options.filters.date(date, format);
    },
    getRacesLabel(value) {
      return value
        .split(";")
        .map((value) => replaceUnderscores(value))
        .join(", ");
    },
    getContainerName(id) {
      const data = this.containerState.data;
      const targetContainer = data.find((obj) => obj.id === id);
      return this.$h.get(targetContainer, "name", "");
    },
    containerResolve(response) {
      this.containerState.loading = false;
      this.containerState.data = this.$h.get(response, "data", []);
    },
    containerReject(error) {
      this.containerState.loading = false;
      this.containerState.error = error;
    },

    showMinOrMax(criteriaObj, minOrMax) {
      return showAge(criteriaObj, minOrMax);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.form-summary {
  padding: 0;
  margin: 0;
  height: 100%;

  &.studies code {
    background-color: lighten($studies, 42);
    border: solid thin lighten($studies, 30);
    color: darken($studies, 20);
  }
  &.participants code {
    background-color: lighten($participants, 42);
    border: solid thin lighten($participants, 30);
    color: darken($participants, 20);
  }
  &.organizations code {
    background-color: lighten($organizations, 42);
    border: solid thin lighten($organizations, 30);
    color: darken($organizations, 20);
  }
  &.investigators code {
    background-color: lighten($investigators, 42);
    border: solid thin lighten($investigators, 30);
    color: darken($investigators, 20);
  }
}
.field {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 0 1.5rem;

  &:nth-child(n+3) {
    > .label:before {
      content: "and ";
    }
  }
  &.empty {
    font-size: 0.9rem;
    font-weight: $thin;
    color: $text-light;
  }
}
.label {
  font-weight: $thin;
  font-size: 0.9rem;
  line-height: 2;
  color: $text;
  margin-bottom: 0.5rem;

  code {
    font-weight: $bold;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-size: 0.85rem;
  }
}
.value {
  font-weight: $bold;
  font-size: 1rem;
  color: $text;

  .thin {
    font-weight: $thin;
  }

  &.date {
    i {
      font-style: normal;
      margin: 0 0.3rem;
      font-weight: $thin;
    }
  }
}
.list {
  display: flex;
  flex-direction: column;
  position: relative;

  .value:not(.comma):not(.single) {
    padding-left: 1rem;

    &:before {
      content: "";
      position: absolute;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: lighten($dark, 10);
      margin-left: -0.85rem;
      margin-top: 0.575rem;
    }
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  .multivalue {
    font-weight: $bold;
    font-size: 1rem;
    color: $text;

    .thin {
      font-weight: $thin;
    }
  }

  .multivalue:not(.comma):not(.single) {
    padding-left: 1rem;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  > .list:not(:first-child) {
    margin-top: 0.25rem;
  }
}

.padding-0 {
  padding-left: 0
}

.open-summary-disease-maintype{
  list-style-type: none; 
  margin-left: -15px;
}
</style>
