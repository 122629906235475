import get from 'lodash/get'
import Helpers from '@/services/HelperService'

/*
 * Format the params for the Participants page
 */
export function formatParams (params) {
  let newParams = {}
  if(params !== undefined){
    newParams = addPrefixToSECNestedParams(params);
    Object.keys(params).forEach(key => {
      // Add prefix 't_' to study params for participants endpoint.
      newParams[get(mapStudyToParticipantParams, key, key)] = params[key];
      if(key in mapStudyToParticipantParams){
        delete newParams[key]; //remove the original study params that do not have the prefix
      }
    })
    //parse "multiple id string" to array of "single id"
    parseMultipleId(newParams)
  }
  return newParams
}

/*
 * Format the params for the Studies page
 * {params} passed from TrialSchema
 * {applyFilter} - flag that determines if user has applied filters on the Studies page
 */
export function formatStudiesParams (params, applyFilter) {
  let newParams = {}
  Object.keys(params).forEach(key => {
    //since this is queries from the studies page, this shouldn't need the 't_' prefix
    newParams[key] = params[key]
  })
  //parse "multiple id string" to array of "single id"
  parseMultipleId(newParams)
  return newParams
}

const parseMultipleId = function (params) {
  const targetId = [
    't_ccr_id',
    't_ctep_id',
    't_dcp_id',
    't_ids.value',
    't_nci_id',
    't_nct_id'
  ]
  targetId.forEach(id => {
    let tempArr = []
    if (Array.isArray(params[id])) {
      params[id].forEach(value => {
        tempArr.push(...value.split(/[ ,]+/))
      })
      params[id] = tempArr
    }
  })
}

/*
 * When user has applied filters, we need to override the lead disease params specified
 * in the schema
 */
export function updateLDParamsForFilters (params) {
  //-- V1
  if (params['lead_disease._raw']) {
    const lead_disease_V1_mldnone_params = []
    const lead_disease_V1_ld_params = []
    let is_V1_ld_params = false
    let is_V1_mldnone_params = false
    let is_V1_None = false

    params['lead_disease._raw'].forEach(param => {
      if (param === 'Multiple Lead Diseases' || param === 'None') {
        lead_disease_V1_mldnone_params.push(param)
        is_V1_mldnone_params = true
        is_V1_None = param === 'None'
      } else {
        lead_disease_V1_ld_params.push(param)
        is_V1_ld_params = true
      }
    })

    if (is_V1_mldnone_params) {
      params['lead_disease._raw'] = lead_disease_V1_mldnone_params
    }
    if (is_V1_ld_params) {
      params['lead_diseases.name._raw'] = lead_disease_V1_ld_params
      if (!is_V1_mldnone_params) {
        delete params['lead_disease._raw']
        delete params['lead_diseases.type']
      }
    }

    if (is_V1_None) {
      delete params['lead_diseases.type']
    }
  }

  //-- V2
  if (params['lead_disease2']) {
    const lead_disease_V2_mldnone_params = []
    const lead_disease_V2_ld_params = []
    let is_V2_ld_params = false
    let is_V2_mldnone_params = false

    params['lead_disease2'].forEach(param => {
      if (param === 'Multiple Lead Diseases' || param === 'None') {
        lead_disease_V2_mldnone_params.push(param)
        is_V2_mldnone_params = true
      } else {
        lead_disease_V2_ld_params.push(param)
        is_V2_ld_params = true
      }
    })

    if (is_V2_mldnone_params) {
      params['lead_disease2'] = lead_disease_V2_mldnone_params
    }
    if (is_V2_ld_params) {
      params['lead_diseases2.name._raw'] = lead_disease_V2_ld_params
    }
    if (is_V2_ld_params && !is_V2_mldnone_params) {
      delete params['lead_disease2']
    }
  }
}

export const mapStudyToParticipantParams = {
  current_trial_status: 't_current_trial_status',

  // dates
  current_trial_status_date_begin: 't_current_trial_status_date_begin',
  current_trial_status_date_end: 't_current_trial_status_date_end',
  record_verification_date_begin: 't_record_verification_date_begin',
  record_verification_date_end: 't_record_verification_date_end',
  strap_publication_date_begin: 't_strap_publication_date_begin',
  strap_publication_date_end: 't_strap_publication_date_end',
  start_date_value_begin: 't_start_date_value_begin',
  start_date_value_end: 't_start_date_value_end',
  primary_completion_date_value_begin: 't_primary_completion_date_value_begin',
  primary_completion_date_value_end: 't_primary_completion_date_value_end',
  completion_date_value_begin: 't_completion_date_value_begin',
  completion_date_value_end: 't_completion_date_value_end',
  results_first_submitted_datetime_begin: 't_results_first_submitted_datetime_begin',
  results_first_submitted_datetime_end: 't_results_first_submitted_datetime_end',
  results_first_posted_datetime_begin: 't_results_first_posted_datetime_begin',
  results_first_posted_datetime_end: 't_results_first_posted_datetime_end',

  'biomarkers.ctrp_names': 't_biomarkers.ctrp_names',
  'biomarkers.branch': 't_biomarkers.branch',
  'biomarkers.semantic_types': 't_biomarkers.semantic_types',
  'biomarkers.use': 't_biomarkers.use',
  'biomarkers.purpose': 't_biomarkers.purpose',
  'biomarkers.assay_type': 't_biomarkers.assay_type',
  'biomarkers.evaluation_type': 't_biomarkers.evaluation_type',
  'biomarkers.tissue_specimen_type': 't_biomarkers.tissue_specimen_type',
  'biomarkers.inclusion_indicator': 't_biomarkers.inclusion_indicator',

  t_lead_disease2: 't_lead_disease2',
  'diseases.is_lead_disease': 't_diseases.is_lead_disease',
  'groups.interventions.name': 't_groups.interventions.name',
  'groups.interventions.type': 't_groups.interventions.type',
  organizations_all: 't_organizations_all',
  'lead_organizations.id': 't_lead_organizations.id',
  'nci_programs.program': 't_nci_programs.program',
  'principal_investigators.name._raw': 't_principal_investigators.name._raw',
  'site_investigators.name._raw': 't_site_investigators.name._raw',
  'investigators.name._raw': 't_investigators.name._raw',
  division_department_id: 't_division_department_id',
  participating_sites: 't_participating_sites',
  study_source: 't_study_source',
  study_type: 't_study_type',
  interventional: 't_interventional',
  va_associated: 't_va_associated',
  phase: 't_phase',
  primary_purpose: 't_primary_purpose',
  'outcome_measures.analysis_method._fulltext':
    't_outcome_measures.analysis_method._fulltext',
  sponsor: 't_sponsor',

  'resourcing.funding_mechanism': 't_resourcing.funding_mechanism',
  'resourcing.nih_institute': 't_resourcing.nih_institute',
  'resourcing.serial_number': 't_resourcing.serial_number',
  'resourcing.nci_division_program': 't_resourcing.nci_division_program',
  nci_grant_provided: 't_nci_grant_provided',
  'resourcing.active': 't_resourcing.active',

  //ids
  nci_id: 't_nci_id',
  'nci_id._raw': 't_nci_id._raw',
  nct_id: 't_nct_id',
  lead_organization_id: 't_lead_organization_id',
  ctep_id: 't_ctep_id',
  dcp_id: 't_dcp_id',
  ccr_id: 't_ccr_id',
  'organizations.id': 't_organizations.id',
  'organizations.id._raw': 't_organizations.id._raw',
  'organizations.ctep_id': 't_organizations.ctep_id',
  'organizations.ctep_id._raw': 't_organizations.ctep_id._raw',
  'diseases.id': 't_diseases.id',

  // container information
  m_cid: 't_m_cid',
  m_type: 't_m_type',

  lead_disease2: 't_lead_disease2._raw',
  lead_disease: 't_lead_disease._raw',

  'lead_disease._raw': 't_lead_disease._raw', //v1
  'lead_diseases.name': 't_lead_disease._raw',
  'lead_diseases.name._raw': 't_lead_diseases.name._raw',
  'lead_diseases.type': 't_lead_diseases.type',

  'lead_diseases2.name': 't_lead_disease2._raw', //V2
  'lead_diseases2.name._raw': 't_lead_diseases2.name._raw',

  // eligibility criteria
  'sec.pt.id': 't_sec.pt.id',
  'sec.pt.type': 't_sec.pt.type',
  'sec.pt.st': 't_sec.pt.st',
  'min_age_in_years_gte': 't_min_age_in_years_gte',
  'max_age_in_years_lte': 't_max_age_in_years_lte',
  'sex_criteria': 't_sex_criteria',
  'sex': 'sex'
}

/**
 * Maps the params from Studies to the Participant params
 * prior to making the /patients request
 * @param {Object} params
 * @returns
 */
export function mapToParticipantParams (params, onTrialPage = false) {
  const copyParams = Helpers.cloneDeep(params)

  let updatedParams = {};
  updatedParams = addPrefixToSECNestedParams(copyParams);
  for (const [key, value] of Object.entries(mapStudyToParticipantParams)) {
    if (copyParams[key]) {
      updatedParams[value] = copyParams[key]
      delete copyParams[key]
    }
  }
  if (!updatedParams['agg_field']) {
    updatedParams['agg_field'] = 'races_string'
  }
  // If on Trials Page and has trial "_keyword" filter applied, update the
  // "_keyword" and "_keyword_field" params. These params need to be applied
  // separately since "_keyword" filter also exists for participants.
  if (onTrialPage && "_keyword" in copyParams) {
    updatedParams["t__keyword"] = copyParams["_keyword"]
    delete copyParams["_keyword"]
    if ("_keyword_field" in copyParams) {
      updatedParams["t__keyword_field"] = copyParams["_keyword_field"]
      delete copyParams["_keyword_field"]
    }
  }
  return Object.fromEntries(
    Object.entries({ ...copyParams, ...updatedParams }).filter(
      ([key]) => key != 'include'
    )
  )
}

export function addPrefixToSECNestedParams(params){
  /* 
    Add prefix 't_' to all nested SEC params (e.g. sec_embed_and_sec_embed_and_sec.pt.type)
    for the Participants Endpoint request 
  */
  for(const key in params){
    if(key.includes("sec.pt.") && !key.includes("t_")){
      params["t_"+key] = params[key];
      delete params[key];
    }
  }
  return params;
}
