/* istanbul ignore file */
'use strict'

import axios from 'axios'
import router from '../router'
import token from '@/../token'
import { stringify } from 'querystring'
import { onError } from './LoggerService'
import { truthy } from './CommonsService'

const BASE_URLS = {
  users: process.env.VUE_APP_STRAP_USERS_API_URL,
  studies: process.env.VUE_APP_STRAP_STUDY_SERVICE_API_URL,
  investigators: process.env.VUE_APP_STRAP_PERSON_SERVICE_API_URL,
  persons: process.env.VUE_APP_STRAP_PERSON_SERVICE_API_URL,
  sites: process.env.VUE_APP_STRAP_ORGANIZATION_SERVICE_API_URL,
  organizations: process.env.VUE_APP_STRAP_ORGANIZATION_SERVICE_API_URL,
  participants: process.env.VUE_APP_STRAP_PARTICIPANT_API_URL,
  analytics: process.env.VUE_APP_STRAP_ANALYTICS_API_URL,
  containers: process.env.VUE_APP_STRAP_CONTAINER_API_URL,
  audits: process.env.VUE_APP_STRAP_AUDIT_API_URL,
  legacy: process.env.VUE_APP_LEGACY_API_URL,
}

export async function apiGet(query = {}) {
  if (
    (await router.app.$auth.isAuthenticated()) ||
    process.env.VUE_APP_MOCK === 'true'
  ) {
    const activeQuery = {
      ...(await defaultQuery()),
      ...query,
      endpoint: query.endpoint,
    }
    if (process.env.VUE_APP_API_MOCK === 'true') {
      activeQuery.baseURL = 'https://api.strap.trials-dev.nci.nih.gov/api/v2'
    }
    else if (process.env.VUE_APP_MOCK === 'true') {
      activeQuery.baseURL = 'http://localhost:3000/api/v2'
    }
    if (router.app.$store.getters['settings/skipCache']) {
      activeQuery.params['skip_cache'] = 1
    }

    return new Promise((resolve, reject) => {
      axios(activeQuery.endpoint, activeQuery)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          setTimeout(() => {
            // retry
            axios(activeQuery.endpoint, activeQuery)
              .then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                reject(onError(error))
              })
          }, 3000)
        })
    })
  }
}

axios.interceptors.request.use((query) => {
  if (Object.keys(BASE_URLS).includes(query.baseURL)) {
    query.baseURL = BASE_URLS[query.baseURL] // replace dummy baseURL
  }
  return query
})

axios.interceptors.response.use((response) => {
  response.headers['X-Content-Type-Options'] = 'nosniff'
  return response
})

// helper
async function defaultQuery() {
  return {
    baseURL: BASE_URLS.studies,
    endpoint: '',
    headers: {
      authorization: `Bearer ${process.env.VUE_APP_API_MOCK === 'true' ? token : await router.app.$auth.getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    params: {},
    data: {},
    paramsSerializer(params) {
      return stringify(filterParams(params))
    },
  }
}
function filterParams(params) {
  for (let key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key) && !truthy(params[key])) {
      delete params[key] // delete empty values
    }
  }
  return params
}
