<!-- eslint-disable-next-line -->
<template #error>
  <div class="default-error">
    <transition name="pop-in" appear>
      <div :class="`error-container ${size}`">
        <transition name="pop-in" appear>
          <div class="error-icon delay-3">
            <div :class="`entity-logo ${variant}`">
              <icon class="icon" :icon="icon"></icon>
            </div>
          </div>
        </transition>
        <transition appear name="slide-fade">
          <div class="header">
            <h2>
              <slot name="title"></slot>
              <span v-if="!$slots.title" v-html="title"></span>
            </h2>
          </div>
        </transition>
        <transition appear name="slide-fade">
          <div class="message">
            <p>
              <slot name="error"></slot>
              <span v-if="!$slots.error" v-html="error"></span>
            </p>
          </div>
        </transition>
        <transition appear name="slide-up">
          <div class="links" v-if="!noLinks">
            <a href="#" @click.prevent="reload">Try Again</a>
            <span>or</span>
            <a href="mailto:ctrp_support@mail.nih.gov">Contact Support</a>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "default-error",
  props: {
    reload: {},
    loadingTitle: {},
    error: {
      type: String,
      default: "",
    },
    noLinks: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "lg",
    },
    icon: {
      type: String,
      default: "exclamation",
    },
    variant: {
      type: String,
      default: "danger",
    },
    title: {
      type: String,
      default: "There was an error!",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/component";

.default-error {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: fade-out(white, 0);
}
.error-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;

  &.lg {
    .entity-logo {
      margin-bottom: -0.25rem;
      margin-right: 0.5rem;
      font-size: 0.9rem;
    }
  }
  &.md {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    margin-top: -3rem;

    .error-icon {
      grid-column: 1;
      grid-row: 1;
      padding-bottom: 1.5rem;

      .entity-logo {
        font-size: 1rem;
        margin-right: -2rem;
      }
    }
    .header {
      grid-column: 1;
      grid-row: 2;
      text-align: center;
    }
    .message {
      grid-column: 1;
      grid-row: 3;
      text-align: center;
    }
    .links {
      grid-column: 1;
      grid-row: 4;
      justify-content: center;
    }
  }
  &.sm {
    font-size: 0.9rem;
    grid-template-rows: auto auto;

    .error-icon {
      grid-row: 1 / span 2;
      .entity-logo {
        font-size: 0.8rem;
      }
    }
    .header {
      margin-bottom: 0.5rem;
      h2 {
        font-size: 1.1rem;
      }
    }
    .message {
      display: none;
    }
    .links {
      grid-row: 2;
    }
  }
  &.compact {
    font-size: 0.9rem;
    grid-template-rows: auto auto;

    .error-icon {
      grid-row: 1 / span 2;
      .entity-logo {
        font-size: 0.8rem;
      }
    }
    .header {
      margin-bottom: -0.5rem;
      h2 {
        font-size: 1.1rem;
      }
    }
    .message {
      display: none;
    }
    .links {
      grid-row: 2;
    }
  }
  &.logout {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    margin-top: -3rem;

    .error-icon {
      grid-column: 1;
      grid-row: 1;
      padding-top: 0.5rem;
      padding-bottom: 2rem;

      .entity-logo {
        margin-right: -2rem;
        font-size: 1.2rem;
      }
    }
    .header {
      grid-column: 1;
      grid-row: 2;
      text-align: center;
    }
    .message {
      grid-column: 1;
      grid-row: 3;
      text-align: center;
    }
    .links {
      grid-column: 1;
      grid-row: 4;
      justify-content: center;
    }
  }
}

// items
.error-icon {
  grid-column: 1;
  grid-row: 1 / 3;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 1.5rem;
}
.header {
  grid-column: 2;
  grid-row: 1;

  h2 {
    color: $text;
    font-size: 1.25rem;
  }
}
.message {
  grid-column: 2;
  grid-row: 2;

  p {
    color: $text-light;
    font-weight: $thin;
    font-size: 1rem;
    margin-bottom: 0;
    z-index: 2;
  }
}
.links {
  grid-column: 2;
  grid-row: 3;
  display: flex;
  margin-top: 0.5rem;

  span {
    margin: 0 0.5ch;
    color: $text-light;
  }
}
</style>
