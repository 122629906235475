export default {
  studies: [
    { show: true, intro: `This is the Filter Studies settings window.<br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-studies/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this button to clear the filter criteria and remove all filters from the window.' },
    { show: true, intro: 'Click on this button to close the settings window and apply the filters to the data.' },
    { show: true, intro: 'This typeahead textbox provides quick retrieval of a specific filter field.' },
    { show: true, intro: `This is the list of available filters. Click on the + next to the filter name to add it to the main window. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-studies/#add-search-criteria" target="_blank">Click for details</a>` },
    { show: true, intro: `Click on this box to toggle hiding/showing fields in the window that do not have any criteria selected or entered. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-studies/#hide-empty-filters" target="_blank">Click for details</a>` },
    { show: true, intro: `Click on this button for a dropdown picklist of available pre-defined filters.<br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-studies/#pre-defined-filters" target="_blank">Click for details</a>` }
  ],
  participants: [
    { show: true, intro: `This is the Filter Participants settings window.<br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-partic/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this button to clear the filter criteria and remove all filters from the window.' },
    { show: true, intro: 'Click on this button to close the settings window and apply the filters to the data.' },
    { show: true, intro: 'This typeahead textbox provides quick retrieval of a specific filter field.' },
    { show: true, intro: `This is the list of available filters. Click on the + next to the filter name to add it to the main window. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-partic/#add-search-criteria" target="_blank">Click for details</a>` },
    { show: true, intro: `Click on this box to toggle hiding/showing fields in the window that do not have any criteria selected or entered. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-partic/#hide-empty-filters" target="_blank">Click for details</a>` },
    { show: false, intro: '' }
  ],
  investigators: [
    { show: true, intro: `This is the Filter Investigators settings window.<br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-invest/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this button to clear the filter criteria and remove all filters from the window.' },
    { show: true, intro: 'Click on this button to close the settings window and apply the filters to the data.' },
    { show: true, intro: 'This typeahead textbox provides quick retrieval of a specific filter field.' },
    { show: true, intro: `This is the list of available filters. Click on the + next to the filter name to add it to the main window. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-invest/#add-search-criteria" target="_blank">Click for details</a>` },
    { show: true, intro: `Click on this box to toggle hiding/showing fields in the window that do not have any criteria selected or entered. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-invest/#hide-empty-filters" target="_blank">Click for details</a>` },
    { show: false, intro: '' }
  ],
  organizations: [
    { show: true, intro: `This is the Filter Organizations settings window.<br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-orgs/" target="_blank">Click for details</a>` },
    { show: true, intro: 'Click on this button to clear the filter criteria and remove all filters from the window.' },
    { show: true, intro: 'Click on this button to close the settings window and apply the filters to the data.' },
    { show: true, intro: 'This typeahead textbox provides quick retrieval of a specific filter field.' },
    { show: true, intro: `This is the list of available filters. Click on the + next to the filter name to add it to the main window. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-orgs/#add-search-criteria" target="_blank">Click for details</a>` },
    { show: true, intro: `Click on this box to toggle hiding/showing fields in the window that do not have any criteria selected or entered. <br><a style="font-size: .9rem;" href="/documentation/docs/search-advanced-orgs/#hide-empty-filters" target="_blank">Click for details</a>` },
    { show: false, intro: '' }
  ]
};