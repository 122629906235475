<script>
import DefaultLoading from './DefaultLoading'
import DefaultError from './DefaultError'
import { truthy } from '@/services/CommonsService'

export default {
  name: 'state-handler',
  functional: true,
  inheritAttrs: false,
  props: {
    response: [Object, Array],
    loading: Boolean,
    error: [String, Error],
    refresh: Function,
    ignoreLoading: Boolean,
    ignoreError: Boolean,
    keepResponseAlive: Boolean,
    noTransition: Boolean,
    transition: {
      type: String,
      default: 'fade'
    },
    variant: { type: String },
    loadingTitle: { type: String, default: undefined },
    errorSize: { type: String, default: undefined },
  },
  render(createElement, { props, slots, listeners }) {
    let child = slots().response

    // loading 
    if ((!props.ignoreLoading && props.loading) || (props.keepResponseAlive && truthy(props.data))) {
      child = slots().loading || createElement(DefaultLoading, { props: { 
          key: 'loading',
          spinnerVariant: props.variant,
          loadingTitle: props.loadingTitle,
        } 
      })
    }
    // error 
    else if (!props.ignoreError && truthy(props.error)) {
      child = slots().error || createElement(DefaultError, { props: { 
          key: 'error', 
          error: props.error, 
          size: props.errorSize, 
          reload: () => listeners.reload()
        } 
      })
    }

    return createElement(
      'transition', 
      { 
        props: {
          name: props.transitionName,
          appear: !props.noTransition,
          mode: 'out-in',
        } 
      },
      [ child ],
    )
  }
}
</script>