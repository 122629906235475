import introGetters from "./getters";
import introMutations from "./mutations";
import introActions from "./actions";

export default {
  namespaced: true,

  state: {
    pageName: "",
  },
  getters: introGetters,
  mutations: introMutations,
  actions: introActions,
};
