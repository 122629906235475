<template>
  <div :class="{ showState: showStateDD }">
    <form-builder
      id="form-field"
      no-persist
      no-actions
      submit-on-change
      submit-on-mount
      :schema="schema"
      :values="value"
      :key="updateKey"
      @change="formSubmit"
    ></form-builder>
  </div>
</template>

<script>
/* istanbul ignore file */
export default {
  name: "site-location-form-field",
  beforeCreate() {
    this.$options.components.FormBuilder = require("../FormBuilder").default;
  },
  model: {
    event: "change",
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
  },
  data() {
    return {
      values: {},
      update: false,
      showStateDD: true,
      updateKey: 0,
    };
  },
  methods: {
    formSubmit(values) {
      if (values.c_country.label === "United States") {
        this.showStateDD = true;
      } else {
        this.showStateDD = false;
      }
      this.$emit("change", values);
    },
    validateCountrySelection(values) {
      if (
        !values["c_country"] ||
        values["c_country"].label === "United States"
      ) {
        this.showStateDD = true;
      } else {
        this.showStateDD = false;
      }
    },
  },
  computed: {},
  mounted() {
    this.validateCountrySelection(this.value);
  },
  watch: {
    value(value) {
      if (isEmpty(value)) {
        this.updateKey++; // update on reset
      }
    },
  },
};

function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}
</script>

<style lang="scss">
#form-field-c_state_select {
  display: none;
}
#form-field-c_state_input {
  display: block;
}
.showState {
  #form-field-c_state_select {
    display: block;
  }
  #form-field-c_state_input {
    display: none;
  }
}
label[for="organizations-filters-site-location-input"].d-block {
  //display: none !important;
  font-size: 1.1rem;
  font-weight: 700;
}
</style>