export default {
  updateForm(state, form) {
      state.forms[form.id] = form.model;
    },
    updateQuery(state, form) {
      state.queries[form.id] = form.query;
    },
    clearAllForms(state) {
      state.forms = {};
      state.queries = {};
    },
    updateKeyword(state, keyword) {
      state.globalKeyword = keyword;
    },
    setOptions(state, { id, options }) {
      state.asyncOptions[id] = options;
    },
    clearOptions(state) {
      state.asyncOptions = {};
    },
    updateDisease(state, disease) {
      state.selectedDiseases[state] = disease;
    },
  };