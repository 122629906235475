import { apiGet } from '@/services/DataService'
import Vue from 'vue'

export default () => {
  return [
    {
      // Biomarker name
      type: 'select-field',
      id: 'biomarkers-ctrp-names',
      group: {
        label: 'Biomarker name',
        description: 'Enter 3 or more characters to search'
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        internalSearch: true,
        closeOnSelect: true,
        maxHeight: 500,
        loading: false,
        autocomplete: false,
        options: [],
      },
      mounted() {
        if (this.input.options.length === 0) {
          getOptions(this.input, {
            endpoint: 'trials',
            params: {
              agg_field: 'biomarkers.ctrp_names',
              'biomarkers.type': 'reference_gene',
            },
          }, "biomarkers.ctrp_names")
        }
      },
    },
    {
      // Branch
      type: 'select-field',
      id: 'biomarkers-branch',
      group: {
        label: 'Branch',
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        internalSearch: true,
        closeOnSelect: true,
        maxHeight: 500,
        loading: false,
        autocomplete: false,
        options: [],
      },
      mounted() {
        if (this.input.options.length === 0) {
          getOptions(this.input, {
            endpoint: 'trials',
            params: {
              agg_field: 'biomarkers.branch'
            },
          }, "biomarkers.branch")
        }
      },
    },
    {
      // Semantic type
      type: 'select-field',
      id: 'biomarkers-semantic-types',
      group: {
        label: 'Semantic type',
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        options: [],
      },
      mounted() {
        getOptions(this.input, {
          endpoint: 'trials',
          params: {
            agg_field: 'biomarkers.semantic_types',
          },
        }, "biomarkers.semantic_types")
      },
    },
    {
      // Biomarkers use
      type: 'select-field',
      id: 'biomarkers-use',
      group: {
        label: 'Biomarkers use',
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        options: [],
      },
      mounted(model, fields) {
        getOptions(this.input, {
          endpoint: 'trials',
          params: {
            agg_field: 'biomarkers.use'
          },
        }, "biomarkers.use")
      },
    },
    {
      // Biomarkers purpose
      type: 'select-field',
      id: 'biomarkers-purpose',
      group: {
        label: 'Biomarkers purpose',
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        options: [],
      },
      mounted(model, fields) {
        getOptions(this.input, {
          endpoint: 'trials',
          params: {
            agg_field: 'biomarkers.purpose',
          },
        }, "biomarkers.purpose")
      },
    },
    {
      // Biomarkers assay type
      type: 'select-field',
      id: 'biomarkers-assay-type',
      group: {
        label: 'Biomarkers assay type',
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        options: [],
      },
      mounted(model, fields) {
        getOptions(this.input, {
          endpoint: 'trials',
          params: {
            agg_field: 'biomarkers.assay_type',
          },
        }, "biomarkers.assay_type")
      },
    },
    {
      // Biomarkers evaluation type
      type: 'select-field',
      id: 'biomarkers-evaluation-type',
      group: {
        label: 'Biomarkers evaluation type',
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        options: [],
      },
      mounted(model, fields) {
        getOptions(this.input, {
          endpoint: 'trials',
          params: {
            agg_field: 'biomarkers.evaluation_type',
          },
        }, "biomarkers.evaluation_type")
      },
    },
    {
      // Biomarkers specimen type
      type: 'select-field',
      id: 'biomarkers-tissue-specimen-type',
      group: {
        label: 'Biomarkers specimen type',
      },
      input: {
        textField: 'key',
        valueField: 'key',
        placeholder: 'Select option',
        options: [],
      },
      mounted(model, fields) {
        getOptions(this.input, {
          endpoint: 'trials',
          params: {
            agg_field: 'biomarkers.tissue_specimen_type',
          },
        }, "biomarkers.tissue_specimen_type")
      },
    },
  ]
}

async function getOptions(input, query, fieldName) {
  let newQuery;
  let options = []
  let newParams = {...query.params}
  newQuery = Object.assign(query, {params: newParams})
  newQuery = query
  try {
    input.loading = true
    let response = await apiGet(newQuery)
    options = response.aggregations[fieldName]
    Vue.set(input, 'options', options)
  } catch (error) {
                    // eslint-disable-next-line
                    console.log(error);
                  } finally {
    input.loading = false
  }
}
