export function getField (id, model) {
  return model.find(field => field.id === id)
}

// Only use for debug purpose and remove reference to this method,
// when merging to master
// By using this method to print your console statements, it will not
// print the Observer object (Vue.js functionality to track data changes);
// instead will print the value of the object
export function getLog (item) {
  if (typeof item === 'object') {
    try {
      item = JSON.parse(JSON.stringify(item))
    } catch (e) {
                  // eslint-disable-next-line
                  console.error(e);
                }
  }
  return item
}

export default {
  getField,
  getLog
}
