<template>
  <div>
    <a
      :id="id"
      tabindex="0"
      class="btn btn-outline header-dropdown h-button-md pr-2"
      :class="[btnClass, { disabled }]"
      :disabled="disabled"
    >
      <icon class="icon" :icon="icon" size="sm"></icon>
      <span class="h-text-md">{{ title }}</span>
      <icon class="caret" icon="caret-down"></icon>
    </a>

    <popover :target="id" triggers="click blur" placement="bottom">
      <div class="popover-pad">
        <slot></slot>
      </div>
    </popover>
  </div>
</template>

<script>
import Popover from "bootstrap-vue/es/components/popover/popover";

export default {
  name: "action-popover",
  components: { Popover },
  props: {
    id: String,
    title: String,
    btnClass: [String, Array, Object],
    icon: String,
    disabled: Boolean,
  },
};
</script>