import navbar from './pages/navbar';
import globalSearch from './components/globalSearch';
import globalSearchResults from './components/globalSearchResults';
import home from './pages/home';
import study from './pages/study';
import site from './pages/site';
import investigator from './pages/investigator';
import containers from './pages/containers';
import reports from './pages/reports';
import general from './components/study/tabs/general';
import analytics from './components/study/tabs/analytics';
import eligibility from './components/study/tabs/eligibility';

export default {
  navbar,
  globalSearch,
  globalSearchResults,
  home,
  study,
  site,
  investigator,
  containers,
  reports,
  general,
  analytics,
  eligibility
};