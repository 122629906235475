import { truthy } from '@/services/CommonsService'

export function isECFilter(fieldId) {
  return ((fieldId === 'study-participant-char-age') || (fieldId === 'study-participant-char-sex') ||
  (fieldId === 'study-participant-char-priorTherapy'))
}

export function containsECFilter(fields) {
  if (truthy(fields)) {
    for(let i = 0; i < fields.length; i++){
      if (truthy(fields[i])) {
        if (fields[i].id === 'study-participant-char-sex' ||
          fields[i].id === 'study-participant-char-age'  ||
          fields[i].id === 'study-participant-char-priorTherapy')
          return true;
        }
    }
  }
  return false
}

export function hasSECFilterValues(field, fieldID) {
    return hasSECFilter(field, fieldID) && truthy(getSECData(field, fieldID))
}
  
export function hasSECFilter(field, id) {
    if (id === "study-participant-char-priorTherapy") {
      if ((field["ids-search-sec"] && field["ids-search-sec"].length > 0) ||
          (field["searchType"] && field["searchType"].length > 0)) {
        return true
      }
    }
    return false
}
  
export function getSECData(field, id) {
    if (hasSECFilter(field,id)) {
      let secItems = [];
        if (field["ids-search-sec"] && field["ids-search-sec"].length > 0) {
          const test = field["ids-search-sec"][0]
          field["ids-search-sec"].map((currentSECItem) => {
          let secItem = {};
          let namesAndCodes = '';
          let type = '';

          /* Get sec criteria */
          secItem["criteria"] = {label: 'Criteria', value: currentSECItem["sec_criteria"].label};

          /* Get sec name and ccode */
          if (currentSECItem["id-selector"] && currentSECItem["id-selector"].value === "code") {
            // Search by codes
            let seen = new Set();
            let ccodes = [];
            // Add valid ccodes
            if(currentSECItem["sec_name"]){
              currentSECItem["sec_name"]
              .map((v) => {
                seen.add(v.code);
                ccodes.push(`${v.name} (${v.code})`);
              })
            }
            
            // Add invalid ccodes
            currentSECItem["id-input"].split(",").map((v) => {
              if(!seen.has(v)){
                ccodes.push(v);
              }
            })
            namesAndCodes = ccodes.join(", ");

          } else {
            // Search by names
            namesAndCodes = currentSECItem["id-input"]
              .map((v) => `${v.name} (${v.code})`)
              .join(", ");
          }
          secItem["name"] = {label: 'Name(s)', value: namesAndCodes};

          /* Get sec type */
          if (currentSECItem["sec_type"] && currentSECItem["sec_type"].length > 0) {
            // Eligibility Trial Search
            if(field["searchType"] && field["searchType"] === "default") {
              type = currentSECItem["sec_type"] === "inclusion" ? "Focus" : currentSECItem["sec_type"] === "exclusion" ? "Eligible" : "Unspecified";
            }
            // Portfolio Trial Search
            else{
              type = currentSECItem["sec_type"].charAt(0).toUpperCase() + currentSECItem["sec_type"].slice(1);
            }
            secItem["type"] = {label: 'Type(s)', value: type};
          }

          secItems.push(secItem);
          })
        return secItems;
      }
    }
    return [];
  }