import StateCodes from "@/components/presentation/map/StateCodes";

function getAbbreviation(stateCode) {
  let state = "";
  for (let key in StateCodes) {
    if (StateCodes[key] === stateCode) {
      state = key;
    }
  }
  return state;
}

export default {
  setMapState({ commit }, { id, flag }) {
    commit("setState", { id, flag });
  },
  selectState({ commit, getters }, state) {
    let currentStateSelection = getters.stateSelection;
    currentStateSelection.push({
      ...state,
      abbreviation: getAbbreviation(state.id),
    });
    commit("setStateSelection", currentStateSelection);
  },
  removeState({ commit, getters, dispatch }, id) {
    let currentStateSelection = getters.stateSelection;
    if (currentStateSelection.length === 1) {
      dispatch("back");
    } else {
      currentStateSelection = currentStateSelection.filter(
        (state) => state.id !== id
      );
      commit("setStateSelection", currentStateSelection);
    }
  },
  clearStateSelection({ commit }) {
    commit("setStateSelection", []);
  },
  clearOrganizationSelection({ commit }) {
    commit("setState", { id: "selectedOrganization", flag: {} });
  },

  goTo({ commit }, page) {
    commit("goTo", page);
  },
  back({ getters, dispatch }) {
    let pageHistory = getters.pageHistory;
    dispatch("goTo", pageHistory.pop());
    dispatch("setPageHistory", pageHistory);
  },

  setZoom({ commit }, zoom) {
    commit("setZoom", zoom);
  },
  setCenter({ commit }, center) {
    commit("setCenter", center);
  },
  setMinMax({ commit }, minMax) {
    commit("setMinMax", minMax);
  },
  setStateCounts({ commit }, states) {
    commit("setStateCounts", states);
  },
  setPageHistory({ commit }, pageHistory) {
    commit("setState", { id: "pageHistory", flag: pageHistory });
  },
  toggleState({ commit, getters }, id) {
    let excludedStates = getters.excludedStates;
    let index = excludedStates.indexOf(id);
    if (index !== -1) {
      excludedStates.splice(index, 1);
    } else {
      excludedStates.push(id);
    }
    commit("setState", { id: "excludedStates", flag: excludedStates });
  },
  setStudy({ commit }, study) {
    commit("setState", { id: "selectedStudy", flag: study });
  },
  setOrganization({ commit }, organization) {
    commit("setState", { id: "selectedOrganization", flag: organization });
  },
};

