<template>
  <date-picker
    ref="picker"
    :class="`date-picker ${invalid} disabled-${disabled}`"
    :value="value"
    :minimum-view="view"
    :disabledDates="dateOptions.disabled"
    :clearButton="clearButton"
    :disabled="disabled"
    input-class="form-control date-input"
    :placeholder="placeholder"
    :format="format"
    @input="change"
  ></date-picker>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import moment from 'moment'

const dateFormatMap = {
  day: 'MMMM d, yyyy',
  month: 'MMMM, yyyy',
  year: 'yyyy',
}

export default {
  name: 'date-field',
  components: { DatePicker },
  model: {
    event: 'change'
  },
  props: {
    value: {},
    state: {},
    placeholder: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    clearButton: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: String,
      default: 'day'
    },
    openDate: {},
    minDate: {},
    maxDate: {},
  },
  data() {
    return {
      view: this.interval,
    }
  },
  computed: {
    dateOptions() {
      return {
        interval: this.interval,
        disabled: {
          from: new Date(moment(this.maxDate)),
          to: new Date(moment(this.minDate)),
        },
      }
    },
    invalid() {
      return this.state === false ? 'invalid' : ''
    },
    format() {
      return dateFormatMap[this.interval]
    }
  },
  methods: {
    change(date) {
      const formattedDate = this.formatDate(this.placeholder, moment(date))
      this.$emit('change', new Date(moment(formattedDate)))
    },
    formatDate(type, date) {
      return date.format('YYYY-MM-DD')
      // const flag = date.year() === moment().year()
      // let newDate = ''
      // if (this.interval === 'month') {
      //   newDate = type === 'From'
      //     ? date.startOf('month').format('YYYY-MM-DD')
      //     : (flag ? getCurrentDate(date) : date.endOf('month').format('YYYY-MM-DD'))
      // }
      // else if (this.interval === 'year') {
      //   newDate = type === 'From'
      //     ? date.startOf('year').format('YYYY-MM-DD')
      //     : (flag ? moment().format('YYYY-MM-DD') : date.endOf('year').format('YYYY-MM-DD'))
      // }
      // else {
      //   newDate = date.format('YYYY-MM-DD')
      // }
      // return newDate
    }
  },
  watch: {
    'interval'(interval) {
      this.view = interval
    },
  }
}

function getCurrentDate(date) {
  let newDate = ''
  if (date.month() < moment().month()) {
    newDate = date.endOf('month').format('YYYY-MM-DD')
  } else {
    newDate = moment().format('YYYY-MM-DD')
  }
  return newDate
}
</script>

<style lang="scss">
@import 'src/styles/component';

.vdp-datepicker {
  .cell {
    border-radius: $border-radius;
  }
  .cell:hover:not(.disabled):not(.selected) {
    background-color: fade-out($link, .9);
    border-color: $link;
  }
  .cell.selected {
    color: white;
    background-color: $link;
    &:hover {
      border-color: $link;
      background-color: $link;
    }
  }
  .form-control:read-only {
    background-color: white;
  }
  &.disabled-true {
    opacity: .4;
  }
}
</style>
