<template>
  <error 
    class="container-warning-message" 
    variant="warning" 
    title="A list of participants is not presently available, when a Cancer Center is selected"
    no-links
  ></error>
</template>

<script>
import Error from '@/components/presentation/state-handler/DefaultError'
export default {
  name: 'container-warning',
  components: { Error },
}
</script>