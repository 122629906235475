export default function getSteps() {
  return [
    // {
    //   id: 'start',
    //   element: '#intro-start',
    //   intro: `This is a tour guide for STRAP.<br><a style="font-size: .9rem;" href="/documentation" target="_blank">Click for details</a>`,
    //   tooltipClass: 'regular-tooltip-bg'
    // },
    // {
    //   id: 'user',
    //   element: '#intro-user',
    //   intro: 'Welcome to STRAP!',
    //   tooltipClass: 'regular-tooltip-bg',
    // },
    {
      id: 'start-1',
      element: '#intro-start',
      intro: 'Welcome to STRAP!',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      id: 'start-2',
      element: '#intro-start',
      intro: 'This guide provides a tour of the main features.',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      id: 'start-3',
      element: '#intro-start',
      intro: `Further details can be found on the STRAP documentation website.<br><a style="font-size: .9rem;" href="/documentation/" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      id: 'search',
      element: '#intro-search > div.global-search > div.search-input > div.dropdown-container',
      intro: `Use the global search to run a keyword search against data for the entities (Study, Organization, Investigator, Participant).<br><a style="font-size: .9rem;" href="/documentation/docs/othercomponents/#global-search-widget" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg'
      // open() {
      //   let backdrop = document.getElementById('global-search-backdrop')
      //   if (backdrop !== null) {
      //     backdrop.click()
      //   }
      // }
    },
    // {
    //   id: 'search-open',
    //   element: '#intro-search > div.global-search > div.search-input > div.dropdown-container',
    //   intro: 'Enter text to search.',
    //   tooltipClass: 'regular-tooltip-bg',
    //   highlightClass: 'regular-highlight-bg',
    //   open() {
    //     let backdrop = document.getElementById('global-search-backdrop')
    //     if (backdrop === null) {
    //       document.getElementById('global-search-input').click()
    //     }
    //   }
    // },
    {
      id: 'link-1',
      element: '#intro-link > div.overflow-container > div.nav-bar-links',
      intro: 'Clicking on these labels will direct you to other pages within the STRAP application.',
      tooltipClass: 'regular-tooltip-bg'
      // open() {
      //   let backdrop = document.getElementById('global-search-backdrop')
      //   if (backdrop !== null) {
      //     backdrop.click()
      //   }
      // }
    },
    {
      id: 'link-2',
      element: '#intro-link > div.overflow-container > div.nav-bar-links',
      intro: `The set of available pages varies based on your role. Please see the STRAP documentation for information about the pages that are available to each role.<br><a style="font-size: .9rem;" href="/documentation/docs/othercomponents/#global-search-widget" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      id: 'containers-1',
      element: '#intro-containers',
      intro: 'This button opens the data settings window. These settings allow you to define how you want to view your data (All data, All Study data for a Cancer Center, Only Cancer Center data).',
      tooltipClass: 'regular-tooltip-bg'
    },
    {
      id: 'containers-2',
      element: '#intro-containers',
      intro: `Once set, the data settings are applied throughout the application, but they can be changed using this button.<br><a style="font-size: .9rem;" href="/documentation/docs/othercomponents/#dashboard-settings" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg'
    }
  ];
}