import { Validations } from '../utils'

export default [
  {
    // file name
    type: 'text-field',
    id: 'name',
    group: {
      label: 'File Name',
    },
    input: {
      autocomplete: 'off',
      placeholder: 'Enter a file name',
    },
    validations: [Validations.maxLength(50), Validations.required],
  },
  {
    // columns
    type: 'radio-field',
    id: 'columns',
    default: true,
    group: {
      label: 'Column Options',
    },
    input: {
      textField: 'label',
      valueField: 'value',
      options: [
        { label: 'Export data from visible columns', value: true },
        { label: 'Export data from all columns', value: false },
      ],
    },
  },
  {
    // format
    type: 'radio-field',
    id: 'type',
    default: 'xlsx',
    group: {
      label: 'File Type',
    },
    input: {
      textField: 'label',
      valueField: 'value',
      options: [{ label: 'xlsx', value: 'xlsx' }],
    },
    mounted() {
      this.state.visible = false
    },
  },
  // {
  //   // email
  //   type: 'text-field',
  //   id: 'email',
  //   // default: store.getters['auth/email'],
  //   default: get(
  //     JSON.parse(localStorage.getItem('okta-token-storage')),
  //     'idToken.claims.email',
  //     ''
  //   ),
  //   group: { label: 'Email' },
  //   input: {
  //     autocomplete: 'off',
  //     placeholder: 'Enter a valid email address',
  //   },
  //   validations: [Validations.email, Validations.required],
  // },
]
