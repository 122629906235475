let steps = [];

export default function getSteps() {
  steps = [
    {
      element: 'div.entity-header-container.studies > div.entity-title-row > div.entity-detail-title',
      intro: `The public title and NCI ID of the selected trial are listed here.<br><a style="font-size: .9rem;" href="/documentation/docs/landing-studies/" target="_blank">Click for details</a>`,
      tooltipClass: 'regular-tooltip-bg'
    }
  ];
  return steps;
}