import merge from 'lodash/merge'
import { Defaults, Colors } from '@/components/presentation/charting/utils'

export default {
  props: {
    options: {},
    expanded: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'studies'
    },
    labelFilters: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => []
    },
    disableClick: {
      type: Boolean,
      default: false,
    },
    extraOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    abbreviate: {
      type: Number,
      default: -1
    },
    updateKey: String
  },
  computed: {
    // merge expanded options if expanded
    $activeOptions() {
      return this.expanded
        ? merge({}, this.defaultOptions, this.expandedOptions, this.extraOptions)
        : merge({}, this.defaultOptions, this.extraOptions)
    },
    $pageWidth() {
      return this.$store.getters['width']
    },
  },
  methods: {

    // merge two options of the same type
    $optionMerge(option, mergeOption) {
      return Array.isArray(option)
        ? option.map(o => Object.assign({}, o, mergeOption))
        : Object.assign({}, option, mergeOption)
    },

    // merge option with default value
    $optionDefault(key, defaultValue) {
      return this.$optionMerge(
        this.$h.get(this.options, key, defaultValue), 
        Defaults[key]
      )
    },

    // takes a series and adds color
    $colorData(data = []) {
      const colors = Colors(this.variant, data.length)
      const itemStyle = (index) => ({
        itemStyle: { color: colors[index] }
      })
      data.forEach((slice, index) => merge(slice, itemStyle(index)))
    },

    $colorBar(series) {
      if (series && series.data) {
        const colors = this.$getColors(series.data.length)
        const itemStyle = (index) => ({
          itemStyle: { color: colors[index] }
        })  
        series.data = series.data.map((slice, index) => ({
          value: slice,
          ...itemStyle(index),
        }))
      }
    },

    $getColors(length) {
      return Colors(this.variant, length)
    },

    // on chart click
    $click(model) {
      if (!this.disableClick) {
        this.$emit('click', model)
      }
    },

    $resize() {
      if (this.$refs.chart !== undefined) {
        this.$nextTick(this.$refs.chart.resize)
      }
    },

  },
  watch: {
    'activeOptions': '$resize',
    '$pageWidth': '$resize',
    'updateKey': '$resize',
  },
}
