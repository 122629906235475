export default {
  setOpen(state, open) {
    state.open = open;
  },
  setToaster(state, context) {
    state.message = context.message || "";
    state.icon = context.icon || "chart-bar";
    state.variant = context.variant || "studies";
  },
};
