import { truthy } from "@/services/CommonsService";

export default {
  addRecentSearch({ commit, getters }, search) {
    let searches = getters.recentSearches.reverse();
    if (truthy(search)) {
      if (searches.includes(search)) {
        let index = searches.indexOf(search);
        searches.splice(index, 1);
        searches.push(search);
      } else {
        searches.push(search);
        if (searches.length > 5) {
          searches.splice(0, 1);
        }
      }

      commit("setRecentSearches", searches.reverse());
    }
  },

  addRecentEntity({ commit, getters }, entity) {
    let entities = getters.recentEntities;
    if (!entities.some((e) => e.id === entity.id)) {
      entities.push(entity);
      if (entities.length > 3) {
        entities.splice(0, 1);
      }
      commit("setRecentEntities", entities);
    }
  },

  reset({ commit }) {
    commit("setRecentSearches", []);
    commit("setRecentEntities", []);
  },
  adddiseaseConditionSearch({ commit }) {
    commit("setDiseaseConditionSearch", false);
  },
  addLeaddiseaseSearch({ commit }) {
    commit("setLeadDiseaseSearch", false);
  },
  addParticipantDiseaseConditionSearch({ commit }) {
    commit("setParticipantDiseaseConditionSearch", false);
  },
};
