export default {
  setRecentSearches(state, recentSearches) {
    state.recentSearches = recentSearches;
  },
  setRecentEntities(state, recentEntities) {
    state.recentEntities = recentEntities;
  },
  setDiseaseConditionSearch(state, diseaseConditionSearch) {
    state.diseaseConditionSearch = diseaseConditionSearch;
  },
  setLeadDiseaseSearch(state, leadDiseaseSearch) {
    state.leadDiseaseSearch = leadDiseaseSearch;
  },
  setLeadDiseaseSearchHomeDashboard(state, leadDiseaseSearchHomeDashboard) {
    state.leadDiseaseSearchHomeDashboard = leadDiseaseSearchHomeDashboard;
  },
  setParticipantDiseaseConditionSearch(state, participantDiseaseConditionSearch) {
    state.participantDiseaseConditionSearch = participantDiseaseConditionSearch;
  },
};
