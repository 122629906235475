<template>
  <div
    class="aggregation-chart-outer card"
    :class="{ 'default-dates': !hasDate }"
  >
    <form-summary-bar
      class="aggregation-form-bar"
      :open="true"
      variant="info"
      hide-reset
      hide-open
    >
      <icon icon="info-circle" class="mr-2"></icon>
      <span
        >Showing
        <b>{{ $h.get(formValues, "date-range.date-range-key.label") }}</b>
        from
        <b>{{
          $h.get(formValues, "date-range.date-range-from")
            | date(dateFormatMap[selectedInterval.value])
        }}</b>
        to
        <b>{{
          $h.get(formValues, "date-range.date-range-to")
            | date(dateFormatMap[selectedInterval.value])
        }}</b>
      </span>
      <span v-if="!hasDate">
        . &nbsp;To change these dates<a @click.prevent="$emit('openFilters')"
          >add a date range to the filters</a
        >
      </span>
      <span v-else>
        . &nbsp;<a @click.prevent="$emit('openFilters')"
          >(Change the date range)</a
        >
      </span>
    </form-summary-bar>

    <!-- main -->
    <div class="aggregation-chart-container">
      <error
        class="agg-selectors-limit"
        v-if="reachAggLimit"
        icon="exclamation-triangle"
        variant="warning"
        no-links
      >
        <template #title>
          <div>Too many options selected</div>
        </template>
        <template #error>
          <div>
            Please limit your selections to
            <b class="limit" v-html="limit"></b> options
          </div>
        </template>
      </error>

      <chart-wrapper
        v-else
        id="trials-over-time"
        title="Studies Over Time"
        class="aggregation-chart"
        wrapper-class="card no-border"
        resource="studies2"
        :query="aggQuery"
        :summarySchema="schema"
        :summaryValues="formValues"
        :summaryFields="summaryFields"
        :help-link="getDocURL('chart.aggregationChart')"
        :intro-configs="introConfigs"
        expand-size="wide"
        filterPersistent
      >
        <template v-slot="{ _chartState }">
          <component
            :is="`${selectedType.value}-chart`"
            :key="stacks.length"
            v-bind="wrapperProps(_chartState)"
            @click="(value) => aggregationChartClick(value)"
          ></component>
        </template>
      </chart-wrapper>

      <div class="aggregation-settings-container">
        <div class="chart-selectors">
          <select-field
            id="chart-interval-button"
            class="select-btn interval"
            textField="label"
            valueField="value"
            :allow-empty="false"
            :searchable="false"
            hide-selected
            :disabled="intervalOptions.length === 1"
            :options="intervalOptions"
            v-model="selectedInterval"
          ></select-field>
          <select-field
            id="chart-type-button"
            class="select-btn type"
            textField="label"
            valueField="value"
            :allow-empty="false"
            :searchable="false"
            hide-selected
            :options="typeOptions"
            v-model="selectedType"
          ></select-field>
        </div>
        <div class="agg-selectors" v-if="$h.truthy(aggSchema)">
          <form-builder
            v-if="$h.truthy(aggSchema)"
            id="trial-agg"
            class="agg-form"
            no-actions
            no-persist
            submit-on-mount
            submit-on-change
            :schema="aggSchema"
            @submit="aggSubmit"
          ></form-builder>
        </div>
        <div
          class="agg-selectors-empty"
          @click.prevent="$emit('openFilters')"
          v-else
        >
          <icon class="plus" icon="plus"></icon>
          <p>Click here to add/remove permutations</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartWrapper from "@/components/presentation/charting/ChartWrapper";
import Error from "@/components/presentation/state-handler/DefaultError";
import BarChart from "@/components/presentation/charting/charts/BarChart";
import StackedColumnChart from "@/components/presentation/charting/charts/StackedColumnChart";
import StackedLineChart from "@/components/presentation/charting/charts/StackedLineChart";
import LineChart from "@/components/presentation/charting/charts/LineChart";
import FormBuilder from "@/components/form/FormBuilder";
import SelectField from "@/components/form/fields/SelectField";
import { Options } from "@/components/form/utils";
import { getDocURL } from "@/services/DocumentationService";
import FormSummaryBar from "@/components/form/summary/FormSummaryBar";
import moment from "moment";

const validAggParams = [
  "current_trial_status",
  "phase",
  "primary_purpose",
  "study_source",
  "groups.interventions.name",
];
const nameMap = {
  current_trial_status: "Current Study Status",
  phase: "Phase",
  primary_purpose: "Primary Purpose",
  study_source: "Study Source",
  intervention: "Intervention",
};
const itemStyle = {
  normal: {},
  emphasis: {
    barBorderWidth: 1,
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: "rgba(0,0,0,0.9)",
  },
};
const defaultDate = {
  "date-range": {
    "date-range-from": new Date(2004, 0, 1),
    "date-range-to": new Date(2019, 11, 31),
    "date-range-interval": "year",
    "date-range-key": {
      label: "Current Study Status Date",
      value: "current_trial_status_date",
    },
  },
};
const dateFormatMap = {
  day: "MMMM D, YYYY",
  month: "MMMM, YYYY",
  year: "YYYY",
};

export default {
  name: "aggregation-chart",
  props: ["values", "schema", "query", "introConfigs", "summaryFields"],
  components: {
    ChartWrapper,
    BarChart,
    StackedColumnChart,
    LineChart,
    StackedLineChart,
    FormBuilder,
    SelectField,
    Error,
    FormSummaryBar,
  },
  data() {
    return {
      hasDate: false,
      aggParams: {},
      limit: 10,
      stacks: [],
      selectedInterval: { label: "By Year", value: "year" },
      intervalOptions: [
        { label: "By Year", value: "year" },
        { label: "By Month", value: "month" },
        { label: "By Day", value: "day" },
      ],
      selectedType: { label: "Bar Chart", value: "bar" },
      typeOptions: [
        { label: "Bar Chart", value: "bar" },
        { label: "Line Chart", value: "line" },
      ],
      dateFormatMap,
      formValues: {},
      currentQuery: {},
    };
  },
  computed: {
    aggQuery() {
      return this.getQuery();
    },
    aggSchema() {
      let activeIds = validAggParams.filter((key) =>
        this.$h.truthy(this.currentQuery[key])
      );
      return activeIds.map((activeId) => {
        let id = "",
          options = [];

        if (activeId === "groups.interventions.name") {
          id = "intervention";
          options = this.currentQuery["groups.interventions.name"];
        } else {
          id = activeId;
          options = Options.getOptions(id).filter((option) =>
            this.currentQuery[id].includes(option.value)
          );
        }

        return {
          id,
          type: "checkbox-field",
          wrapper: {
            name: "collapse",
            label: nameMap[id],
          },
          default: undefined,
          input: {
            textField: "label",
            valueField: "value",
            stacked: false,
            options,
          },
        };
      });
    },
    reachAggLimit() {
      const aggCategories = Object.keys(this.aggParams);
      let amount = 1;
      aggCategories.forEach((aggCategory) => {
        if (this.$h.truthy(this.aggParams[aggCategory])) {
          amount *= this.aggParams[aggCategory].length;
        }
      });
      return amount > this.limit;
    },
  },
  mounted() {
    this.isDateComplete(this.values);
    this.setFormValues();
    if (this.hasDate) {
      this.setIntervalOptions();
    }
  },
  methods: {
    setFormValues() {
      let values = this.$h.cloneDeep(this.values);
      if (!this.hasDate) {
        values = {
          ...values,
          ...defaultDate,
        };
      }
      this.formValues = values;
      this.currentQuery = this.$h.cloneDeep(this.$h.get(this, "query", {}));
    },
    getQuery() {
      const rangeKey = this.$h.get(
        this.formValues,
        "date-range.date-range-key.value"
      );
      if (this.$h.truthy(rangeKey)) {
        const values = this.aggParams;
        let currentParams = this.$h.get(this, "currentQuery", {});
        let overrideParams = {};

        if (!this.hasDate) {
          currentParams["current_trial_status_date_begin"] = "2004-01-01";
          currentParams["current_trial_status_date_end"] = "2019-12-31";
        }

        // set up agg params
        Object.keys(values).forEach((key) => {
          if (this.$h.truthy(values[key])) {
            overrideParams[key] = values[key];
          }
        });

        this.stacks = Object.keys(overrideParams);

        return {
          endpoint: "trials",
          params: {
            ...currentParams,
            ...overrideParams,
            date_interval: this.selectedInterval.value,
            chart_type: this.getChartType(this.selectedType.value),
            date_histogram: rangeKey,
            agg_field: this.getAggField(overrideParams, currentParams),
            permutation: this.$h.cloneDeep(this.stacks).join(":"),
            include: "none",
            item_style: JSON.stringify(itemStyle),
          },
        };
      } else {
        return {};
      }
    },
    getChartType(chartType) {
      if (chartType === "stacked-column") {
        chartType = "bar";
      }
      if (chartType === "stacked-line") {
        chartType = "line";
      }

      return chartType;
    },
    wrapperProps(chartState) {
      let nameGap = [];
      if (this.selectedInterval.value === "year") {
        nameGap = { labelAxis: [65, 65], valueAxis: [55, 55] };
      } else if (this.selectedInterval.value === "month") {
        nameGap = { labelAxis: [85, 85], valueAxis: [55, 55] };
      } else {
        nameGap = { labelAxis: [105, 105], valueAxis: [55, 55] };
      }
      const chartStateTemp = {
        rotate: 70,
        vertical: true,
        gridConfig: {
          default: {
            top: "32px",
            left: "80px",
            right: "48px",
            bottom: "88px",
            containLabel: true,
          },
          expanded: {
            top: "32px",
            left: "80px",
            right: "48px",
            bottom: "108px",
            containLabel: true,
          },
          nameGap,
        },
        labelAxisName: `Time (${this.selectedInterval.value}s)`,
        valueAxisName: "Number of Studies",
        dataZoom: true,
        shadow: true,
        enableLabelSort: false,
      };

      Object.assign(chartState, chartStateTemp);

      return chartState;
    },
    isDateComplete(values) {
      const rangeKey = this.$h.get(values, "date-range.date-range-key.value");
      const from = this.$h.get(values, "date-range.date-range-from");
      const to = this.$h.get(values, "date-range.date-range-to");
      this.hasDate =
        this.$h.truthy(rangeKey) && to !== undefined && from !== undefined;
    },
    setIntervalOptions() {
      const currentIntervalValue = this.formValues["date-range"][
        "date-range-interval"
      ];
      let intervalOptions = this.$h.cloneDeep(this.intervalOptions);
      let toIndex = intervalOptions.findIndex(
        (option) => option.value === currentIntervalValue
      );
      intervalOptions.splice(0, toIndex);
      this.selectedInterval = intervalOptions[0];
      this.intervalOptions = intervalOptions;
    },
    setTypeOptions() {
      this.typeOptions[0].value = this.stacks.length ? "stacked-column" : "bar";
      this.typeOptions[1].value = this.stacks.length ? "stacked-line" : "line";
      if (this.selectedType.label === "Bar Chart") {
        this.selectedType.value = this.typeOptions[0].value;
      }
      if (this.selectedType.label === "Line Chart") {
        this.selectedType.value = this.typeOptions[1].value;
      }
    },
    aggSubmit(params) {
      if (Object.prototype.hasOwnProperty.call(params, "intervention")) {
        params["groups.interventions.name"] = params.intervention;
        delete params.intervention;
      }
      this.aggParams = params;
    },
    getAggField(overrideParams, currentParams) {
      let currentAggFields = validAggParams.filter((agg) =>
        this.$h.truthy(currentParams[agg])
      );
      return !this.$h.truthy(overrideParams) &&
        !this.$h.truthy(currentAggFields)
        ? "nci_id"
        : currentAggFields;
    },
    aggregationChartClick(value) {
      const formValues = this.$h.cloneDeep(this.formValues);

      delete formValues["date-range"]["date-range-interval"];
      const startDate = formatDate(value.name, this.selectedInterval.value);
      const endDate = formatDate(
        value.name,
        this.selectedInterval.value,
        false
      );
      let params = this.$h.cloneDeep(
        this.getCorrectActiveModel(value.seriesName)
      );

      // Make params as formValues
      let copiedFormValues = this.$h.cloneDeep(formValues);

      if (Object.prototype.hasOwnProperty.call(params, "groups.interventions.name")) {
        let interventionsArray = params["groups.interventions.name"];

        params["agent-intervention"] = [];
        params["other-intervention"] = [];

        interventionsArray.forEach((intervention) => {
          if (this.$h.truthy(copiedFormValues["agent-intervention"])) {
            let targetAgentIntervention = copiedFormValues[
              "agent-intervention"
            ].find(
              (obj) => obj.name.toLowerCase() === intervention.toLowerCase()
            );
            if (targetAgentIntervention) {
              params["agent-intervention"].push(targetAgentIntervention);
            }
          }

          if (this.$h.truthy(copiedFormValues["other-intervention"])) {
            let targetOtherIntervention = copiedFormValues[
              "other-intervention"
            ].find(
              (obj) => obj.name.toLowerCase() === intervention.toLowerCase()
            );
            if (targetOtherIntervention) {
              params["other-intervention"].push(targetOtherIntervention);
            }
          }
        });

        delete params["groups.interventions.name"];
      }

      // Format current_trial_status
      if (Object.prototype.hasOwnProperty.call(params, "current_trial_status")) {
        params["current_trial_status"] = params["current_trial_status"].map(
          (ele) => {
            return copiedFormValues["current_trial_status"].find(
              (obj) => obj.value === ele
            );
          }
        );
      }
      formValues["date-range"]["date-range-from"] = startDate;
      formValues["date-range"]["date-range-to"] = endDate;

      if (Object.prototype.hasOwnProperty.call(params, "lead_organizations.id")) {
        let formattedLeadOrgs = [];
        params["lead_organizations.id"].forEach((leadOrgId) => {
          formattedLeadOrgs.push(
            copiedFormValues["lead_organizations.id"].find(
              (obj) => obj.id === leadOrgId
            )
          );
        });
        params["lead_organizations.id"] = formattedLeadOrgs;
      }

      if (Object.prototype.hasOwnProperty.call(params, "participating_sites")) {
        let formattedParticipatingSites = [];
        params["participating_sites"].forEach((participatingSiteId) => {
          formattedParticipatingSites.push(
            copiedFormValues["participating_sites"].find(
              (obj) => obj.id === participatingSiteId
            )
          );
        });
        params["participating_sites"] = formattedParticipatingSites;
      }

      // Format principal_investigators.name._raw and site_principal_investigators.name
      if (Object.prototype.hasOwnProperty.call(params, "principal_investigators.name._raw")) {
        let formattedPI = [];
        params["principal_investigators.name._raw"].forEach((PIName) => {
          formattedPI.push(
            copiedFormValues["principal_investigators.name._raw"].find(
              (obj) => obj.name === PIName
            )
          );
        });
        params["principal_investigators.name._raw"] = formattedPI;
      }

      if (Object.prototype.hasOwnProperty.call(params, "site_principal_investigators.name")) {
        let formattedSitePI = [];
        params["site_principal_investigators.name"].forEach((sitePIName) => {
          formattedSitePI.push(
            copiedFormValues["site_principal_investigators.name"].find(
              (obj) => obj.name === sitePIName
            )
          );
        });
        params["site_principal_investigators.name"] = formattedSitePI;
      }

      // Format cancer center
      if (Object.prototype.hasOwnProperty.call(params, "m_cid") && Array.isArray(params["m_cid"])) {
        delete params["m_cid"];
        delete params["m_type"];
      }

      let queryParams = {};

      if (this.$store.getters["settings/containerEnabled"]) {
        queryParams["m_cid"] = this.$store.getters[
          "settings/activeContainer"
        ].id;
        queryParams["m_type"] = "organization";
      }

      this.$store.dispatch("view/set", {
        id: "",
        title: `Studies`,
        variant: "studies",
        height: "modal",
        query: {
          baseURL: "studies",
          endpoint: "trials",
          params: queryParams,
        },
        initialValues: {
          ...formValues,
          ...params,
        },
      });
      this.$store.dispatch("modal/openDataView");
    },
    getCorrectActiveModel(seriesName) {
      if (seriesName.split("").includes("0")) {
        return {};
      }

      let params = this.$h.cloneDeep(this.currentQuery);
      let seriesRaw = seriesName.split(" > ");

      let obj = {};

      for (let i = 0; i < this.stacks.length; i++) {
        if (this.stacks[i] === "groups.interventions.name") {
          obj = Object.assign(obj, {
            [this.stacks[i]]: [seriesRaw[i]],
          });
        } else {
          obj = Object.assign(obj, {
            [this.stacks[i]]: [
              Options.getOption(this.stacks[i], seriesRaw[i]).value,
            ],
          });
        }
      }

      params = {
        ...params,
        ...obj,
      };

      return params;
    },
    getDocURL,
  },
  watch: {
    stacks: "setTypeOptions",
    values: {
      handler: "isDateComplete",
      deep: true,
    },
  },
};

function formatDate(date, interval, first = true) {
  if (interval === "year") {
    return getDateYear(date, first);
  } else if (interval === "month") {
    return getDateMonth(date, first);
  } else {
    return getDateDay(date);
  }
}
function getDateYear(date, first) {
  if (first) {
    return `${date}-01-01`;
  } else {
    return `${date}-12-31`;
  }
}
function getDateMonth(date, first) {
  const dateArray = date.split("-");
  const year = dateArray[1];
  const month = moment().month(dateArray[0]);
  const lastDay = moment().month(dateArray[0]).year(year).daysInMonth();

  return `${year}-${month.format("MM")}-${first ? "01" : lastDay}`;
}
function getDateDay(date) {
  const dateArray = date.split("-");
  const year = dateArray[2];
  const month = moment().month(dateArray[0]).format("MM");
  const day = dateArray[1];

  return `${year}-${month}-${day}`;
}
</script>

<style lang="scss">
@import "src/styles/component";

.no-date-error {
  height: 100%;
  background-color: white !important;
}
.aggregation-form-bar {
  flex: 0 0 2rem;
}
.aggregation-chart-outer {
  height: 100%;
  overflow: hidden;

  > .no-date-container {
    background-color: white;
  }
  .aggregation-chart:not(.open) {
    padding-right: 300px;
    pointer-events: none;
    .chart-wrapper {
      pointer-events: auto;
    }
  }
  .no-data {
    padding-right: 300px;
  }
}
.container-chart-select {
  width: 180px !important;
}
.aggregation-chart-container {
  height: 100%;
}
.agg-selectors-limit {
  background: white;
  padding-right: 300px;
  .limit {
    color: $text;
  }
}
.aggregation-chart {
  height: 100%;
  .chart-screenshot-container {
    width: 100%;
  }
}
.aggregation-settings-container {
  border-left: solid thin $n2;
  background-color: fade-out($n1, 0.5);

  overflow-y: auto;
  position: absolute;
  width: 300px;
  right: 0;
  top: 2rem;
  bottom: 0;

  display: flex;
  flex-direction: column;

  .chart-selectors {
    display: flex;
    padding: 1rem;
  }
}
.agg-selectors-empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border: solid thin transparent;
  border-bottom-right-radius: $border-radius;

  .plus {
    font-size: 1.2rem;
    color: $text-light;
    margin-bottom: 1rem;
  }
  p {
    display: block;
    color: $text-light;
    font-weight: $thin;
    font-size: 1rem;
    text-align: center;
    padding: 0 2rem;
  }
  &:hover {
    background-color: fade-out($link, 0.9);
    border-color: $link;
    cursor: pointer;

    p,
    .plus {
      color: $link;
    }
  }
}
.select-btn.interval .multiselect__tags {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent !important;
}
.select-btn.type .multiselect__tags {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>