<template>
  <div class="widget-header" :class="{ small, open }" :id="id">
    <div class="widget-title-count">
      <h4 v-if="title && !titleForm" class="title" v-html="title"></h4>
      <slot v-else name="title" :_state="state"></slot>
      <h5 class="count" v-if="!noCount">
        <span v-if="state.loading">Loading {{ countLabel }}...</span>
        <span v-else
          ><span :id="`${id}-count`">{{ total | localeString }}</span>
          {{ countLabel }}</span
        >
      </h5>
    </div>

    <div class="widget-content">
      <slot name="content" :_state="state"></slot>
    </div>
    <div class="widget-actions" v-if="!hideActions">
      <slot name="actions"></slot>

      <!-- data settings summary for home page -->
      <btn
        v-if="
          !open &&
          $store.getters['settings/containerEnabled'] &&
          dataSettingsSummary
        "
        v-tooltip="'Open Data Settings Summary'"
        class="widget-action"
        variant="outline-circle"
        @click="openDataSettingsSummaryModal"
      >
        <icon icon="filter"></icon>
      </btn>

      <!-- Inline Help -->
      <btn
        :id="`${id}-help`"
        v-if="showHelp"
        v-tooltip="'Open Help Guide'"
        class="widget-action"
        variant="outline-circle"
        :disabled="disabled"
        @click="help"
      >
        <icon icon="question" />
      </btn>

       <!-- <btn
        v-tooltip="'Studies with multiple lead diseases will be counted under Multiple Lead Diseases.'"
        class="widget-action"
        aria-label="Info"
        variant="outline-circle"
        v-if='id=="home-disease-list-count-widget"'
      >
        <icon icon="question-circle" />
      </btn> -->

      <btn
        :id="`${id}-download`"
        v-if="showDownload"
        v-tooltip="'Download as Excel'"
        class="widget-action"
        variant="outline-circle"
        :disabled="disabled"
        @click="$emit('download')"
      >
        <icon icon="download" />
      </btn>

      <!-- External Help -->
      <!--<btn :id="`${id}-link`" v-if="$store.getters['auth/roles'].includes('DEVELOPER') && showHelp" v-tooltip="'Open Help Doc'" class="widget-action" variant="outline-circle" :disabled="disabled" @click="link">-->
      <!--<icon icon="book-reader"/>-->
      <!--</btn>-->

      <!-- expand -->
      <btn
        :id="`${id}-expand`"
        v-tooltip="'Expand'"
        v-if="!hideExpand"
        aria-label="Expand Chart"
        class="widget-action"
        variant="outline-circle"
        :disabled="disabled"
        @click="$emit('expand')"
      >
        <icon icon="expand" />
      </btn>

      <!-- close -->
      <btn
        :id="`${id}-close`"
        v-tooltip="'Close'"
        v-if="showClose"
        aria-label="Close"
        class="widget-action"
        variant="outline-circle"
        :disabled="disabled"
        @click="$emit('close')"
      >
        <icon icon="times" />
      </btn>
    </div>
  </div>
</template>

<script>
import { startIntro, handleIntroConfigs } from "@/services/IntroService";

export default {
  name: "widget-header",
  props: {
    id: String,
    state: Object,
    title: String,
    open: Boolean,
    titleForm: Boolean,
    disabled: Boolean,
    noCount: Boolean,
    hideExpand: Boolean,
    showScreenshot: Boolean,
    showClose: Boolean,
    showGuide: Boolean,
    showHelp: Boolean,
    showDownload: Boolean,
    hideActions: Boolean,
    small: Boolean,
    total: {
      type: Number,
      default: 0,
    },
    countLabel: {
      type: String,
      default: "studies",
    },
    introConfigs: {
      type: Array,
      default: () => [],
    },
    // helpLink: {
    //   type: String,
    //   default: ''
    // },
    dataSettingsSummary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    defaultIntroSteps() {
      return [
        {
          element: `#${this.id}-help`,
          tooltipClass: "regular-tooltip-bg",
        },
        // {
        //   element: `#${this.id}-link`,
        //   tooltipClass: 'regular-tooltip-bg'
        // },
        {
          element: `#${this.id}-expand`,
          tooltipClass: "regular-tooltip-bg",
        },
      ];
    },
  },
  methods: {
    help() {
      const introSteps = this.handleIntroConfigs(
        this.introConfigs,
        this.defaultIntroSteps
      );
      if (this.$h.truthy(introSteps)) {
        this.startIntro(introSteps, {});
      }
    },
    // link() {
    //   if (this.helpLink) {
    //     window.open(this.helpLink, '_blank')
    //   }
    // },
    startIntro,
    handleIntroConfigs,
    openDataSettingsSummaryModal() {
      //this.$store.dispatch('modal/openDataSettingsSummary')
      this.$store.dispatch("modal/openFormSummary", [{}, {}, {}]);
    },
  },
};
</script>

<style>
.widget-title-count {
  flex: 0 0 auto;
}
.setting-summary {
  flex: 0 1 100%;
}
.title-for-capture {
  display: none;
}
</style>